import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../globals/global.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'shop-app-social-share',
  templateUrl: './social-share.component.html',
  styleUrls: ['./social-share.component.scss']
})
export class SocialShareComponent implements OnInit, OnChanges {
  public isCollapsed: boolean;
  public facebookShareURL: string;
  public twitterShareURL: string;
  public linkedinShareURL: string;
  public webShopSettings = this.globalService.webShopSettings();
  public userSettings = this.globalService.getUserSettings();
  @Input() productDetail: any;
  @Input() productImageID: any;
  @Input() productId: any;
  @Input() isOrderSuccessPage: any;
  @Input() productBatchID: any;
  public facebookShareURLDisplayStatus: boolean = false;
  public twitterShareURLDisplayStatus: boolean = false;
  public linkedinShareURLDisplayStatus: boolean = false;
  constructor(public globalService: GlobalService, private route: ActivatedRoute) {

  }

  ngOnInit() {
  }
  ngOnChanges(): void {
    const productID = this.route.snapshot.params ? this.route.snapshot.params.productID : null;
    if (!this.productDetail && !this.productId) {
      this.productDetail = productID;
    } else if (!this.productDetail && (productID === undefined || productID === null)) {
      this.productDetail = this.productId;
    } else if (productID) {
      this.productDetail = productID;
    } else {

    }
    if (this.productBatchID !== undefined) {
      this.productDetail = this.productBatchID;
    }
    this.isCollapsed = false;
    // this.facebookShareURL = environment.ENVIRONMENT.shareURL + '/SocialSharing/SharingPage.aspx?' + 'ProductID='
    //   + this.productDetail + '&language=' + this.userSettings.LanguageCode + '&subDomain=' + this.webShopSettings.DomainName
    //   + '&social=facebook&order=0&v=1&ImageID=' + this.productImageID;
    // this.twitterShareURL = environment.ENVIRONMENT.shareURL + '/SocialSharing/SharingPage.aspx?'
    // + 'ProductID=' + this.productDetail + '&language=' + this.userSettings.LanguageCode + '&subDomain=' + this.webShopSettings.DomainName
    //   + '&social=twitter&order=0&v=1&ImageID=' + this.productImageID;
    // this.linkedinShareURL = environment.ENVIRONMENT.shareURL
    //   + '/SocialSharing/SharingPage.aspx?' + 'ProductID=' + this.productDetail + '&language=' + this.userSettings.LanguageCode
    //   + '&subDomain=' + this.webShopSettings.DomainName
    //   + '&social=linkedin&order=0&v=1&ImageID=' + this.productImageID;
    let orderValue = 0
    if (this.isOrderSuccessPage !== undefined) {
      orderValue = 1
    }
    if (this.webShopSettings) {
      this.facebookShareURL = window.location.origin + '/' + environment.ENVIRONMENT.socialURL +
        '/SocialSharing/SharingPage.aspx?' + 'ProductID=' + this.productDetail + '&language=' + this.userSettings.LanguageCode +
        '&social=facebook&order=' + orderValue + '&v=1&ImageID=' + this.productImageID + '&CustomerID=' + this.webShopSettings.CustomerID +
        '&subDomain=' + this.webShopSettings.DomainName;
      this.twitterShareURL = window.location.origin + '/' + environment.ENVIRONMENT.socialURL + '/SocialSharing/SharingPage.aspx?'
        + 'ProductID=' + this.productDetail + '&language=' + this.userSettings.LanguageCode
        + '&social=twitter&order=' + orderValue + '&v=1&ImageID=' + this.productImageID + '&CustomerID=' + this.webShopSettings.CustomerID +
        '&subDomain=' + this.webShopSettings.DomainName;
      this.linkedinShareURL = window.location.origin + '/' + environment.ENVIRONMENT.socialURL + '/SocialSharing/SharingPage.aspx?' +
        'ProductID=' + this.productDetail + '&language=' + this.userSettings.LanguageCode
        + '&social=linkedin&order=' + orderValue + '&v=1&ImageID=' + this.productImageID + '&CustomerID=' + this.webShopSettings.CustomerID +
        '&subDomain=' + this.webShopSettings.DomainName;

      if (this.webShopSettings.SocialMedias.length > 0) {
        const fbdata = this.webShopSettings.SocialMedias.filter(
          data1 => (data1.ShortCode === 'FB'));
        if (fbdata[0] !== undefined) {
          this.facebookShareURLDisplayStatus = true;
        }

        const twitterdata = this.webShopSettings.SocialMedias.filter(
          data1 => (data1.ShortCode === 'TW'));
        if (twitterdata[0] !== undefined) {
          this.twitterShareURLDisplayStatus = true;
        }

        const linkedindata = this.webShopSettings.SocialMedias.filter(
          data1 => (data1.ShortCode === 'LI'));
        if (linkedindata[0] !== undefined) {
          this.linkedinShareURLDisplayStatus = true;
        }
      }
    }
  }
  share_icon() {
    document.getElementById('.mrpText').focus();
  }
}
