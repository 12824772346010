import { Component, OnInit } from '@angular/core';
import { OrdersService } from 'app/linknet-common/restful-services/restful.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { Router } from '@angular/router';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-order-sample',
  templateUrl: './order-sample.component.html',
  styleUrls: ['./order-sample.component.scss']
})
export class OrderSampleComponent implements OnInit {
  public orderSampleList = [];
  public orderSampleListNoRecord: Boolean = false;
  public loaderShow: any;
  public orderSampleTitle: any;
  public printInvoiceUrl: any;
  constructor(private _httpOrdersService: OrdersService, private toastService: ToastService,
    private router: Router, private globalService: GlobalService,
    public titleService: Title, private translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit() {
    try {
      this.orderSampleTitle = this.translate.get('PRODUCT_DETAIL.ORDER_SAMPLE_LABEL').subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
      this.getOrderSample();
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getOrderSample() {
    try {
      this.loaderShow = true;
      const params = {
        'onlySampleOrders': true,
      }
      this._httpOrdersService.getOrderSample(params).subscribe(
        (data: any[]) => {
          this.orderSampleList = data['ItemsCollection'];
          this.loaderShow = false;
          if (this.orderSampleList.length === 0) {
            this.orderSampleListNoRecord = true;
          }
        }, error => {
          // this.toastService.error(JSON.parse(error._body).Message);
          this.toastr.error(error.error.Message);
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }

  orderDetail(order, param, currencyCode, vATPercentage) {
    try {
      this.globalService.overwriteSession('orderDetail', 'OrderFullDetails', order);
      this.globalService.overwriteSession('orderDetail', 'SubOrder', param);
      this.globalService.overwriteSession('orderDetail', 'OrderedCurrencyCode', currencyCode);
      this.globalService.overwriteSession('orderDetail', 'VATPercentage', vATPercentage);
      this.router.navigate(['/orderdetail', order.OrderID, param.OrderItemID , false, false], { replaceUrl: false });
    } catch (error) {
      this.toastr.error('Error: ' + error);
    }
  }
  printInvoice(orderId) {
    try {
      const userSettings = this.globalService.getUserSettings();
      this.printInvoiceUrl = document.getElementById('invoicedownload');
      this.printInvoiceUrl.href = environment.ENVIRONMENT.baseURL + userSettings.LanguageCode
        + '/invoices/pdf/' + orderId;
      this.printInvoiceUrl.click();
    } catch (error) {
      this.toastr.error(error);
    }
  }
}
