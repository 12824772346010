import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})

export class LandingPageComponent implements OnInit{
    @Output() setup = new EventEmitter<any>();
    project : string = "";
    constructor(private router: Router){
        this.project = this.getParameterByName('project', window.location.href);
        setTimeout(()=>{
            if(this.project !== "91sb" && this.project !== "aed"){
                this.router.navigate(['/']);
                this.setup.emit('signup');
            }
        }, 100)
        
    }
    ngOnInit(){
        
    }
    
    signup(){
        let params: any = {};
        if (this.project === '91sb') {
            params['IsSb91'] = 'Y';
        } else if (this.project === 'aed') {
            params['IsAed'] = 'Y';
        }
        const queryString = new URLSearchParams(params).toString();
        const url = `${location.origin}?${queryString}`;
        window.open(url, '_blank');

        // this.router.navigate(['/'], { queryParams: params, replaceUrl: false });
        // this.setup.emit('signup')
    }

    importuser(){
        window.open(`https://${this.project}.hyperkart.co.in/admin-login` , '_blank');
        // window.location.href = `https://${this.project}.hyperkart.co.in/admin-login`;
    }

    enqyiryForm(){
        let url = `${location.origin}/enquiryform`;
        window.open(url , '_blank');
        // this.router.navigate(['/enquiryform']);
        // this.setup.emit('enquiryform')
    }

    getParameterByName(name, url) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&|]" + name + "(=([^&#|]*)|&|#|$)", 'i'),
        results = regex.exec(url);
        if (!results)
          return null;
        if (!results[2])
          return '';
        return results[2].replace(/\+/g, " ");
      }
}