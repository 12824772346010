import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'shop-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Input() headingText: any;
  @Input() headingTextNoTranslate: any;
  @Input() closeButtonYN: any;
  @Input() level: string;
  @Input() color: string;
  public breadCrumbList: any;
  public lastIndex: any;
  public mainCategoryLastIndex: any = '';
  public pgTitle: any;

  constructor(public router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe((_evt) => {
      this.getBreadCrumbsList();
    });
    this.getBreadCrumbsList();
    this.pgTitle = this.headingText;
  }

  getBreadCrumbsList() {
    setTimeout(() => {
      this.breadCrumbList = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
      if (this.breadCrumbList !== null) {
        for (let i = 0; i < this.breadCrumbList.length; i++) {
          if (this.breadCrumbList[i].url === '/') {
            this.lastIndex = i
          }
        }
        if (this.lastIndex === 0) {
        } else {
          this.breadCrumbList = this.breadCrumbList.slice(Number(this.lastIndex), this.breadCrumbList.length);
        }

        if (this.breadCrumbList.length > 0) {
          for (let l = 0; l < this.breadCrumbList.length; l++) {
            if (this.breadCrumbList[l].url.indexOf('/search/') >= 0) {
              const urlCategoryId = this.breadCrumbList[l].url.split('/');
              const categoryList = JSON.parse(sessionStorage.getItem('categoryList'));
              if (categoryList !== '' && categoryList !== undefined && categoryList !== null) {
                const matchingCategoryId = categoryList.filter(
                  data => (Number(data.CategoryID) === Number(urlCategoryId[2])));
                if (matchingCategoryId.length !== 0) {
                  this.mainCategoryLastIndex = l;
                }
              }
            }
          }
        }
      }

      let removeDuplicate = [];
      if (this.breadCrumbList.length > 0) {
        for (let k = 0; k < this.breadCrumbList.length; k++) {
          if (k === 0) {
            removeDuplicate[k] = this.breadCrumbList[k];
          } else {
            let deleteStatus = false;
            for (let m = k + 1; m < this.breadCrumbList.length; m++) {
              if ((this.breadCrumbList[k]['group'] === this.breadCrumbList[m]['group'])) {
                deleteStatus = true;
              }
            }
            if (!deleteStatus) {
              removeDuplicate[k] = this.breadCrumbList[k];
              /* To Remove Main Category with Sub categories Concept Code (once main
               category clik then previous main and sub categories are deleted in breadcrumb) */
              if (this.breadCrumbList[k].url.indexOf('/search/') >= 0) {
                if (this.mainCategoryLastIndex !== '' && k !== this.mainCategoryLastIndex &&
                  k < this.mainCategoryLastIndex) {
                  removeDuplicate.splice(k);
                }
              }
            }
          }
        }
      }
      removeDuplicate = removeDuplicate.filter(function (x) { return (x !== (undefined || null || '')); });
      // check ordersuccess breadcrumb is Exist (if it is exist means then remove Shopping Cart and Checkout Breadcrumb need to Remove)
      const orderSuccessStatus = removeDuplicate.filter(file => file.group === 'orderSuccess');
      if (orderSuccessStatus !== null && orderSuccessStatus.length > 0) {
        // Remove Shopping Cart from Breadcrumb List
        const shoppingCartBreadCrumbIndex = removeDuplicate.findIndex(item => item.group === 'shoppingcart');
        if (shoppingCartBreadCrumbIndex >= 0) {
          removeDuplicate.splice(shoppingCartBreadCrumbIndex, 1);
        }

        // Remove Checkout from Breadcrumb List
        const checkoutBreadCrumbIndex = removeDuplicate.findIndex(item => item.group === 'checkout');
        if (checkoutBreadCrumbIndex >= 0) {
          removeDuplicate.splice(checkoutBreadCrumbIndex, 1);
        }
      }
      removeDuplicate = removeDuplicate.filter(function (x) { return (x !== (undefined || null || '')); });
      this.breadCrumbList = removeDuplicate;
      sessionStorage.setItem('breadCrumbsList', JSON.stringify(this.breadCrumbList));
      this.breadCrumbList = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
    }, 500);
  }

  goBack(url, i) {
    // if (window.history.length > 1) {
    //   this.location.back()
    // } else {
    //   this.router.navigate(['/home'])
    // }
    setTimeout(() => {
      if (i !== 0) {
        this.breadCrumbList = this.breadCrumbList.slice(0, (i + 1));
      }
      sessionStorage.setItem('breadCrumbsList', JSON.stringify(this.breadCrumbList));
      const advancedSearch = url.split('&');
      const folderUrlSplit = url.split('?');
      if (url === '/search?joinlinkorders=true') {
        const postParams = {
          joinlinkorders: true
        }
        this.router.navigate(['/search'], { queryParams: postParams, replaceUrl: false });
      } else if (advancedSearch[0].indexOf('CategoryID') > 0 || advancedSearch[0].indexOf('ProductName') > 0) {
        const searchParams = JSON.parse(sessionStorage.getItem('searchParamsbreadCrumbs'))
        this.router.navigate(['/search'], { queryParams: searchParams, replaceUrl: false });
      } else if (folderUrlSplit[1] && folderUrlSplit[1].indexOf('FolderID') >= 0) {
        const folderID = sessionStorage.getItem('FolderID');
        const postParams = {
          FolderID: folderID
        }
        this.router.navigate(['/search'], { queryParams: postParams, replaceUrl: false });
      } else {
        this.router.navigate([url])
      }
    }, 100);
  }
  closePage() {
    let prePage;
    const currentPageIndex = sessionStorage.getItem('currentPageIndex');
    const prePages = JSON.parse(sessionStorage.getItem('prePages'));
    prePage = '/';
    if (prePages[(Number(currentPageIndex) - 1)] && prePages[(Number(currentPageIndex) - 1)]['url']) {
      prePage = prePages[(Number(currentPageIndex) - 1)]['url'];
    }
    let prevPage = 'http://' + window.location.hostname + prePage;
    if (window.location.hostname === 'localhost') {
      prevPage = 'http://localhost:4100' + prePage;
    }
    sessionStorage.setItem('currentPageIndex', (Number(currentPageIndex) - 1).toString());
    prePages.splice(Number(currentPageIndex), 1);
    sessionStorage.setItem('prePages', JSON.stringify(prePages));
    window.location.href = prevPage;
  }
}
