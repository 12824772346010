import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()

export class GlobalService {
  // Observable string sources
  public defaultUsersettings = { 'LanguageCode': 'en', 'CurrencyID': 2, 'CurrencyCode': 'DKK' };
  private logedinSource = new Subject<object>();
  public updateCategoryList = new Subject<object>();
  currentCategoryList = this.updateCategoryList.asObservable();
  public sessionData: any;
  // Observable string streams
  loggedIn$ = this.logedinSource.asObservable();
  constructor() { }

  // Service message commands
  loggedin(mission: object) {
    this.logedinSource.next(mission);
  }

  overwriteSession(sessionName, sessionKey, sessionValue) {
    this.sessionData = sessionStorage.getItem(sessionName);
    try {
      if ((this.sessionData === '') || (this.sessionData === undefined) || (this.sessionData === null)) {
        sessionStorage.setItem(sessionName, '{}');
        this.sessionData = sessionStorage.getItem(sessionName);
      }
      this.sessionData = JSON.parse(this.sessionData);
      this.sessionData[sessionKey] = sessionValue;
      sessionStorage.setItem(sessionName, JSON.stringify(this.sessionData));
    } catch (error) {
    }
  }

  /**
   * Get Present Currency Id and Language ID
   */

  getUserSettings() {
    try {
      const userSettings = {
        LanguageCode: sessionStorage.getItem('userSettings') ?
          JSON.parse(sessionStorage.getItem('userSettings')).LanguageCode :
          this.defaultUsersettings.LanguageCode,
        CurrencyID: sessionStorage.getItem('userSettings') ?
          JSON.parse(sessionStorage.getItem('userSettings')).CurrencyID :
          this.defaultUsersettings.CurrencyID,
        CurrencyCode: sessionStorage.getItem('userSettings') ?
          JSON.parse(sessionStorage.getItem('userSettings')).CurrencyCode :
          this.defaultUsersettings.CurrencyCode,
        UserID: sessionStorage.getItem('userSettings') ?
          JSON.parse(sessionStorage.getItem('userSettings')).UserID : null,
        CurrencySymbol: sessionStorage.getItem('userSettings') ?
          JSON.parse(sessionStorage.getItem('userSettings')).CurrencySymbol : null,
        CountryCode: sessionStorage.getItem('userSettings') ?
          JSON.parse(sessionStorage.getItem('userSettings')).CountryCode : null
      };
      return userSettings;
    } catch (error) {
    }
  }

  /**
 * Get get Logged In Data
 */

  getLoggedInData() {
    try {
      const loggedInData = sessionStorage.getItem('loggedInData') ? JSON.parse(sessionStorage.getItem('loggedInData')) : null;
      return loggedInData;
    } catch (error) {
    }
  }

  /**
   * Get Webshop settings from Session storage.
   */

  webShopSettings() {
    try {
      const webShopSetting = sessionStorage.getItem('webshopDetail') ? JSON.parse(sessionStorage.getItem('webshopDetail')) : null;
      return webShopSetting;
    } catch (error) {
    }
  }
}

@Injectable()
export class CurrencySetterService extends GlobalService {
  currency: Info = {
    currency: sessionStorage.getItem('currency') ?
      sessionStorage.getItem('currency') : this.defaultUsersettings.CurrencyCode
  };
  setCurrency(currency) {
    this.currency.currency = currency;
  }
}

export interface Info {
  currency: string;
  // tslint:disable-next-line:eofline
}

@Injectable()
export class MessageDataService extends GlobalService {
  numberOfUnreadEmails: UnReadMailInfo = {
    numberOfUnreadEmails: sessionStorage.getItem('unreadmails') ?
      sessionStorage.getItem('unreadmails') : ''
  }
  setNumberOfUnreadEmails(param) {
    this.numberOfUnreadEmails = param;
    sessionStorage.setItem('unreadmails', param);
  }
}

export interface UnReadMailInfo {
  numberOfUnreadEmails: any;
}
