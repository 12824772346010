import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { ProductService } from 'app/linknet-common/restful-services/restful.service';
import { ToastService } from '../../typescripts/pro/alerts';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-product-folders',
  templateUrl: './product-folders.component.html',
  styleUrls: ['./product-folders.component.scss']
})
export class ProductFoldersComponent implements OnInit {
  public folderList: any;
  public translatedMessage: any;
  public folderID: any;
  public folderTitle: any;
  public folderForm: any;
  public isShowEdit: any;
  public deleteFolderValue: any;
  public dataResponse: any;
  public folderListNoRecord: Boolean = false;
  public loaderShow: any;
  public productFolderTitle: any;
  @ViewChild('FolderModal') public folderModal;

  constructor(public globalService: GlobalService, public _httpProductService: ProductService,
    private router: Router, private toastService: ToastService, private translate: TranslateService,
    private _httpProducts: ProductService, public formBuilder: FormBuilder,
    public titleService: Title, private toastr: ToastrService) { }

  ngOnInit() {
    this.productFolderTitle = this.translate.get('HEADING.FOLDERS').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    this.folderListNoRecord = false;
    try {
      Promise.resolve(null).then(() => this.getAllfolders());
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getAllfolders() {
    try {
      this.loaderShow = true;
      const logInData = this.globalService.getLoggedInData();
      if (logInData) {
        this._httpProductService.getUserFolders().subscribe(
          (data: any[]) => {
            this.folderList = data;
            this.folderList.sort(this.dynamicFolderTitleSort('FolderTitle'));
            this.loaderShow = false;
            if (this.folderList.length === 0) {
              this.folderListNoRecord = true;
            } else {
              this.folderListNoRecord = false;
            }
          });
      } else {
        // this.translatedMessage = (this.translate.get('PRODUCT_DETAIL.PLEASE_LOGIN_TO_FOLDER_PRODUCTS'));
        this.translate.get('PRODUCT_DETAIL.PLEASE_LOGIN_TO_FOLDER_PRODUCTS').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }
  /**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */
  dynamicFolderTitleSort(property) {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder === -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  viewAllProducts(param) {
    try {
      if(param !== undefined){
      sessionStorage.setItem('FolderID', JSON.stringify(param.FolderID));
      const postParams = {
        'FolderID': param.FolderID
      };
      this.router.navigate(['/search'], { queryParams: postParams, replaceUrl: false });
    }
    } catch (error) {
       this.toastr.error(error);
    }
  }

  showEdit(folder) {
    this.isShowEdit = true;
    this.folderID = folder.FolderID;
    this.folderTitle = folder.FolderTitle;
    this.folderForm = this.formBuilder.group({
      'FolderTitle': [this.folderTitle, [Validators.required]]
    });
  }

  updateFolder() {
    try {
      const logInData = this.globalService.getLoggedInData();
      if (logInData) {
        const param = {
          'FolderTitle': this.folderForm.value.FolderTitle,
          'FolderID': this.folderID,
          'Type': 'User',
          'UserID': this.globalService.getLoggedInData().UserID,
          'action': 'update',
          'isDeleted': false
        };
        this._httpProducts.updateFolder(param).subscribe(
          (data: any[]) => {
            if (data) {
              this.router.navigate(['/folders']);
              //  this.translatedMessage = (this.translate.get('FOLDERS.FOLDER_UPDATED_SUCCESS'));
              this.translate.get('FOLDERS.FOLDER_UPDATED_SUCCESS').subscribe((res: string) => {
                this.translatedMessage = res;
              });
              this.toastr.success(this.translatedMessage);
            }
          }, error => {
            this.toastr.error(error);
          });
      } else {
        // this.translatedMessage = (this.translate.get('FOLDERS.PLEASE_LOGIN_TO_CREATE_FOLDER'));
        this.translate.get('FOLDERS.PLEASE_LOGIN_TO_CREATE_FOLDER').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  showFolderModal(folder) {
    this.deleteFolderValue = folder;
    this.folderModal.show();
  }

  hideFolderModal() {
    this.folderModal.hide();
  }

  deleteFolder() {
    try {
      const postParams = {
        'FolderID': this.deleteFolderValue.FolderID
      };
      // this.translatedMessage = (this.translate.get('FOLDERS.FOLDER_DELETE_SUCCESS_MSG'));
      this.translate.get('FOLDERS.FOLDER_DELETE_SUCCESS_MSG').subscribe((res: string) => {
        this.translatedMessage = res;
      });
      this._httpProductService.deleteFolder(postParams).subscribe(
        (data: any[]) => {
          if (data) {
            this.toastr.success(this.translatedMessage);
            this.folderList = [];
            this.dataResponse = data;
            this.hideFolderModal();
            this.getAllfolders();
          }
        });
    } catch (error) {
      this.toastr.error(error);
    }
  }

  clear() {
    this.folderForm = this.formBuilder.group({
      'FolderTitle': ['', [Validators.required]]
    });
  }
}
