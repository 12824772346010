import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../globals/global.service';
import 'rxjs/Rx';
import { map } from 'rxjs/operators';

@Injectable()
export class OrdersService {

  constructor(private http: HttpClient, private _httpClient: HttpClient, public globalService: GlobalService) { }

  /**
   * Service for Join Order
   */
  getJoinOrdersList(pageIndex, pageSize, isList) {
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const userData = this.globalService.getLoggedInData();
    if (pageIndex === null || pageIndex === undefined || pageIndex.pageIndex === 1) {
      pageIndex = 1;
    }
    if (!pageSize) {
      pageSize = 20;
    }
    let homeCatgeoryList = '';
    if (isList === false) {
      homeCatgeoryList = '&returnHomePageCategoryProducts=true'
    }
    return this._httpClient.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode +
      '/productspreview?filterBatchProducts=true&activeLinkOrders=true&currencyID='
      + userSettings.CurrencyID + '&CustomerID=' + webShopSettings.CustomerID + homeCatgeoryList + '&isWebshop=true' + (userData != null ? '&userID=' + userData.UserID : '')
      + '&PageIndex=' + pageIndex + '&PageSize=' + pageSize, {responseType: 'json'});
  }

  getProductBlocksOrders(pageIndex, pageSize, productBlocksID) {
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const userData = this.globalService.getLoggedInData();
    if (pageIndex === null || pageIndex === undefined || pageIndex.pageIndex === 1) {
      pageIndex = 1;
    }
    if (!pageSize) {
      pageSize = 20;
    }
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/productblocks/' + productBlocksID + '?filterBatchProducts=true&CustomerID='
      + webShopSettings.CustomerID + '&currencyID='
      + userSettings.CurrencyID + (userData != null ? '&userID=' + userData.UserID : '') + '&pageIndex=' + pageIndex + '&PageSize=' + pageSize, {responseType: 'json'});
  }

  getOrders(params) {
    let addparams: any;
    if (params.history) {
      addparams = '&history=' + params.history;
    } else {
      addparams = '&ongoing=' + params.ongoing;
    }
    const userSettings = this.globalService.getUserSettings();
    const userData = this.globalService.getLoggedInData();
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/orders?UserID=' + userData.UserID + addparams
      + '&suborders=' + params.suborders, {responseType: 'json'});
  }

  getOrdersDetails(params) {
    const userSettings = this.globalService.getUserSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/orders/' + params + '?suborders=true', {responseType: 'json'});
  }

  getOrderSample(params) {
    let addparams: any;
    if (params.onlySampleOrders) {
      addparams = '&onlySampleOrders=' + params.onlySampleOrders;
    } else {
      addparams = '';
    }
    const userSettings = this.globalService.getUserSettings();
    const userData = this.globalService.getLoggedInData();
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/orders?UserID=' + userData.UserID + addparams, {responseType: 'json'});
  }

  getAllInvoice(param) {
    const userSettings = this.globalService.getUserSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/invoices/getAllInvoices/' + param, {responseType: 'json'});
  }

  getOrderUnauthorised() {
    const userSettings = this.globalService.getUserSettings();
    const userData = this.globalService.getLoggedInData();
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/orders?userID=' +  userData.UserID + '&onlyOrdersWithoutTransportation=true', {responseType: 'json'});
  }

  getTrackOrderSearch(params){
    return this.http.post(environment.ENVIRONMENT.fulfillment + 'trackOrder/GetTrackOrderSearch', params, {responseType: 'json'});
  }
}
