import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { ToastService } from 'app/typescripts/pro/alerts';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-join-order-modified',
  templateUrl: './join-order-modified.component.html',
  styleUrls: ['./join-order-modified.component.scss']
})
export class JoinOrderModifiedComponent implements OnInit {

  @Input() productionLineList: any;
  @Input() productDetail: any;
  @Input() productCurrentQty: any;
  public productionLineListData: any;
  public joinOrders;
  public qtyChangeValue: any;
  public nextIconStatus: Boolean = true;
  public productionLineListIndex = 0;
  public currency: any;
  public currentProductionLinePrice: any;
  public nextProductionLinePrice: any;
  public productionPieces: any;
  @Input() dynamicProductRangePricesParent: number;
  @Input() productMaxInputValue: any;
  public productionLineData: any;
  public productionLineListLength: any;
  public productLinePosition: number;
  public productionLineDataQty: number;
  public joinOrderPrice: any;
  public currentRange;
  public range;
  public progressBarPercentage;
  public nextPriceDrop;
  public showUpArrow = false;
  public qtyChangeStatus: Boolean = false;
  public isInitialQuantitySet: Boolean = false;
  public isFirstCall: Boolean = true;
  public stopBlinking: Boolean = false;
  @ViewChild('ProductModal') public productModal;
  @ViewChild('MoreInfoModal') public moreInfoModal;
  @ViewChild('PriceMoreInfoModal') public priceMoreInfoModal;
  @Output() shoppingCarts = new EventEmitter();  
  webshopDetails: any;
  calculatedPrice: number;

  /** new-code */
  @Input() joinOrderDateSelect: Boolean = false;
  @Output() resetNewOrderDate = new EventEmitter();
  @Input() resetJoinOrderStatus: Boolean;
  @Output() commonPrice = new EventEmitter();
  /** new-code */

  constructor(private toastService: ToastService, public globalService: GlobalService, private toastr: ToastrService) {
    this.webshopDetails = this.globalService.webShopSettings();    
  }

  ngOnInit() {
    try {
      if (!this.isInitialQuantitySet) {
        this.productCurrentQty = 0;      
      }

      if (this.productionLineList) {
        this.productionLineDataQty = this.productionLineList[0].Quantity + this.productCurrentQty;
        this.qtyChangeValue = this.productCurrentQty;
        this.currency = this.globalService.getUserSettings().CurrencyCode;
        this.productionLineListData = this.productionLineList[this.productionLineListIndex];
        this.productionLineListLength = this.productionLineList.length;
        if (this.productionLineListLength === 1) {
          this.nextIconStatus = false;
        }

        /** TO Check Fixed Units */
        if (this.productDetail.FixedUnits) {
          if (this.productCurrentQty === undefined) {
            this.productCurrentQty = 1;
            this.productCurrentQty = Number(this.productDetail.PiecesPerUnit * this.productCurrentQty);
          }
        }

        if (this.productCurrentQty === undefined) {
          this.productCurrentQty = this.productDetail.MinimumOrder;
        }   

        // this.ngOnChanges();
      }

    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnChanges(): void {
    if (this.isFirstCall)
    {
      // If user searched by quantity, put that value as initial quantity on product details page
      const breadCrumbsList = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
      var searchUrl = breadCrumbsList[breadCrumbsList.length-1].url
      const idxQuantity = searchUrl.indexOf('Quantity=');
      if (idxQuantity >= 0 && searchUrl.length > idxQuantity + 9) {
        searchUrl = searchUrl.substring(idxQuantity);
        var quantityStr = '';
        if (searchUrl.indexOf('&') === -1) {
          quantityStr = searchUrl.substring(9);
        }
        else {
          quantityStr = searchUrl.substring(9, searchUrl.indexOf('&'));
        }
        if (quantityStr !== '') {
          this.productCurrentQty = Number(quantityStr);
          this.isInitialQuantitySet = true;
          if (this.productDetail.FixedUnits) {
            this.productCurrentQty = Math.floor(this.productCurrentQty / this.productDetail.PiecesPerUnit);
          }  
        }
      } 
      this.isFirstCall = false;
    }

    this.productionLineListData = this.productionLineList[this.productionLineListIndex];
    this.qtyChangeStatus = false;
    if (this.qtyChangeValue !== this.productCurrentQty) {
      this.qtyChangeStatus = true;
      /** TO Check Fixed Units */
      if (this.productDetail.FixedUnits) {
        this.productCurrentQty = Number(this.productDetail.PiecesPerUnit * this.productCurrentQty);
      }
      this.qtyChangeValue = this.productCurrentQty;
    }    
    this.progressChanges();
  }

  progressChanges() {
    if (this.productionLineListData !== undefined) {
      this.nextProductionLinePrice = '';
      for (let _i = 0; _i < this.productionLineListData.ProductionLinePrices.length; _i++) {
        if (this.productionLineListData.ProductionLinePrices[_i].MaximumQuantity >= (Number(this.productionLineListData.Quantity) + Number(this.productCurrentQty)) && 
            (this.productionLineListData.ProductionLinePrices[_i].MinimumQuantity <= (Number(this.productionLineListData.Quantity) + Number(this.productCurrentQty)))) {
          this.currentProductionLinePrice = this.productionLineListData.ProductionLinePrices[_i].Price;
          if (this.productionLineListData.ProductionLinePrices[_i + 1] !== undefined) {
            this.nextProductionLinePrice = this.productionLineListData.ProductionLinePrices[_i + 1].Price;
          }
          if (this.qtyChangeStatus) {
            this.joinOrderPrice = Number(this.productionLineListData.ProductionLinePrices[_i].Price);
          } else {
            this.joinOrderPrice = Number(this.productionLineListData.ProductionLinePrices[_i].Price) + Number(this.dynamicProductRangePricesParent);
          }
          if (this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices.length > 0 &&
              this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices[0].isFixedLogo) {
            this.calculatedPrice = 0;
            this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices.forEach(element => {
              this.calculatedPrice += element.Price;
            });
            this.joinOrderPrice = this.joinOrderPrice + this.calculatedPrice;
          }
          this.joinOrderPrice = (this.joinOrderPrice).toFixed(2);

          // Progress bar
          if (Number(this.productionLineListData.ProductionLinePrices[_i].MaximumQuantity) === 2147483647)
          {
              this.progressBarPercentage = 100;
              this.nextPriceDrop = 0;
          }
          else
          {
              this.progressBarPercentage = ((Number(this.productionLineListData.Quantity) + Number(this.productCurrentQty)) / (Number(this.productionLineListData.ProductionLinePrices[_i].MaximumQuantity) + 1) * 100).toFixed(0);
              this.nextPriceDrop = (Number(this.productionLineListData.ProductionLinePrices[_i].MaximumQuantity) + 1) - (Number(this.productionLineListData.Quantity) + Number(this.productCurrentQty));
          }

          this.productDetail.DropMinQuantity = this.productionLineListData.ProductionLinePrices[_i].MinimumQuantity;          
        }
      }
     // this.commonPrice.emit({ 'commonPrice': this.joinOrderPrice, 'currency': this.currency });
    }
  }

  next() {
    try {
      this.qtyChangeStatus = true;
      if (this.productionLineListIndex < this.productionLineList.length) {
        this.productionLineListIndex = this.productionLineListIndex + 1;
        this.productionLineListData = this.productionLineList[this.productionLineListIndex];
      }
      this.productLinePosition = this.productLinePosition + 1;
      const length = (this.productionLineList.length) - 1;
      if (this.productionLineListIndex === length) {
        this.nextIconStatus = false;
        this.stopBlinking = true;
      } else {
        this.nextIconStatus = true;
      }
      this.ngOnChanges();
    } catch (error) {
      this.toastr.error(error);
    }
  }

  pre() {
    try {
      this.productLinePosition = this.productLinePosition - 1;
      this.nextIconStatus = true;
      this.productionLineListIndex = this.productionLineListIndex - 1;
      this.productionLineListData = this.productionLineList[this.productionLineListIndex];
      this.ngOnChanges();
    } catch (error) {
      this.toastr.error(error);
    }
  }

  showProductModal() {
    this.productModal.show();
  }

  showMoreInfoModal() {
    this.moreInfoModal.show();
  }

  showPriceMoreInfoModal() {
    this.priceMoreInfoModal.show();
  }

  // shoppingCartShow(deliveryDate) {
  //   this.shoppingCarts.emit({ 'DeliveryDate': deliveryDate });
  // }
  shoppingCartShow(data) {
    if (data.data.DeliveryDate !== '') {
      const param = {
        'DeliveryDate': data.data.DeliveryDate,
        'OrderQty': data.data.OrderQty
      }
      this.shoppingCarts.emit({ param });
    } else {
      this.shoppingCarts.emit({ 'DeliveryDate': this.productionLineListData.DeliveryDate });
    }
  }

  resetNewOrderDateFunc(ev){
    console.log(this.resetJoinOrderStatus)
    if (ev.currentTarget.checked === true) {
      this.resetNewOrderDate.emit({ 'DeliveryDate': this.productionLineListData.DeliveryDate, 'checked': true });
      this.commonPrice.emit({ 'commonPrice': this.joinOrderPrice, 'currency': this.currency });
      this.resetJoinOrderStatus = true;
    } else{
      this.resetNewOrderDate.emit({ 'DeliveryDate': '', 'checked': false });
    }
  }

}
