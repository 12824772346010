import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { WebshopService } from 'app/linknet-common/restful-services/restful.service';
import { environment } from "environments/environment";
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from "ngx-toastr";
import * as XLSX from 'xlsx';

@Component({
    selector: "shop-app-smartworks",
    templateUrl: "./smartworks.component.html",
    styleUrls: ["./smartworks.component.scss"],
})

export class SmartWorksComponent implements OnInit{
    userType: string = '';
    showImageError: boolean;
    arrayBuffer: any;
    file: File;
    Excelupload = false;
    transactionId: any;
    exelLen: number;
    @ViewChild("userModal") public userModal;
    @ViewChild("faqModal") public faqModal;
    @ViewChild('colMd8') colMd8!: ElementRef;
    @ViewChild('colMd4') colMd4!: ElementRef;
    @ViewChild('hrmModal') public hrmModal;
    excelFile: any;
    loaderShow: boolean = false;
    domainName = null;
    paymentData: any;
    smartWorks: boolean = false;
    hrm: boolean = false;
    points:string = "R";
    webshop: any;
      constructor(private router: Router, public _httpWebservice: WebshopService, public toastr: ToastrService, private sanitizer: DomSanitizer){
      this.webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if(this.webshop.DomainName === "sw" || this.webshop.DomainName === "smartworks"){
        this.smartWorks = true
      }
      else if(this.webshop.DomainName === "247hrm" || this.webshop.DomainName === "247hrm"){
        this.hrm = true;
      }
      else{
        this.router.navigate(['/']);
      }
    }
    ngOnInit() {
        this.userType = 'I'
    }
    ngAfterViewInit() {
      this.setEqualHeight();
      if(this.webshop.DomainName === "247hrm" || this.webshop.DomainName === "247hrm"){
        this.hrm = true;
        if(!sessionStorage.getItem('swpay') && history.state.hrm){
          this.hrmModal.show();
        }
      }
    }
  
    setEqualHeight() {
      const colMd4Height = this.colMd4.nativeElement.offsetHeight;
      this.colMd8.nativeElement.querySelector('.card').style.minHeight = colMd4Height + 'px';
    }
  
    onResize(){
      this.colMd8.nativeElement.querySelector('.card').style.minHeight = 'auto';
      this.setEqualHeight();
    }

    addusers(){
        this.router.navigate(['/add-users'])
    }
    
    onImportUser(event){
        const getImg = event.srcElement !== undefined ? event.srcElement.value.substr(event.srcElement.value.indexOf('.')) : '';
        if ((getImg.toLowerCase() !== '.xlsx' && getImg.toLowerCase() !== '.xls' && getImg.toLowerCase() !== '.csv')) {
          this.showImageError = true;
          return;
        } else {
          this.Excelupload = true;
          this.showImageError = false;
          this.file = event.target.files[0];
          event.target.value = '';
          this.Upload();
        }
    }

    Upload() {
        let fileReader = new FileReader();
        fileReader.onload = (_e) => {
          this.arrayBuffer = fileReader.result;
          var data = new Uint8Array(this.arrayBuffer);
          var arr = new Array();
          for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
          var bstr = arr.join("");
          var workbook = XLSX.read(bstr, { type: "binary" });
          var first_sheet_name = workbook.SheetNames[0];
          var worksheet = workbook.Sheets[first_sheet_name];
    
          this.excelFile = XLSX.utils.sheet_to_json(worksheet, { raw: true })

          if(this.excelFile.length > 0 && this.smartWorks){
    
            let swdata = JSON.parse(sessionStorage.getItem("swdata"));
            this.exelLen = this.excelFile.length;
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append("DomainName", swdata.DomainName);
            formData.append("SWEntityId", swdata.EntityId);
            formData.append("InternalExternal", this.userType);
            formData.append("CompanyId", swdata.CompanyId);
            formData.append("CompanyName", swdata.SWCompanyName);
            this.loaderShow = true;
            this._httpWebservice.bulkUserCreation(formData).subscribe((data:any)=>{
              this.loaderShow = false;
              if(data.IsSuccess){
                this.userModal.show({
                  backdrop: 'static',
                  keyboard: false
                });
                  this.transactionId = data.TransId;
                  sessionStorage.setItem('subscriptionid', this.transactionId);
                  this.excelFile.forEach((e:any)=>{
                      e.UserID = swdata.UserID;
                      e.TransactionId = this.transactionId;
                  })
                  this.paymentData = data;
              }
            }, error => {
              this.loaderShow = false;
              this.toastr.error(error.error.Message);
            })

          }else if(this.excelFile.length > 0 && this.hrm){
            let hrmdata = JSON.parse(sessionStorage.getItem("247hrm"));
            this.exelLen = this.excelFile.length;
            const formdata = new FormData();
            formdata.append("DomainName", hrmdata.DomainName);
            formdata.append("UserId", hrmdata.UserID);
            formdata.append("Points", this.points);
            formdata.append("file", this.file);
            this.loaderShow = true;
            
            this._httpWebservice.bulkUserCreationHrm(formdata).subscribe((data:any)=>{
              this.loaderShow = false;
              if(data.IsSuccess){
                  this.userModal.show({
                    backdrop: 'static',
                    keyboard: false
                  });
                  this.transactionId = data.TransId;
                  this.excelFile.forEach((e:any)=>{
                      e.UserID = hrmdata.UserID;
                      e.TransactionId = this.transactionId;
                  })
                  this.paymentData = data;
              }
            }, error => {
              this.loaderShow = false;
              this.toastr.error(error.error);
            })

          }else{
            this.toastr.error('Please provide user data')
          }
    
          //console.log(this.Comparedata, "Excel")
        }
        fileReader.readAsArrayBuffer(this.file);
    }

    userImported(){
        let data = {
          excelFile: this.excelFile,
          file: this.file,
          paymentData: this.paymentData
        }
        this.router.navigate(['/import-user-list'], { state: { data }, replaceUrl: false });
    }

    showFAQ(){
      let path = environment.ENVIRONMENT.fileserver+'/UserDocuments/';
      this.domainName = this.sanitizer.bypassSecurityTrustResourceUrl(path + 'FAQs-CSPOC_&_End_Users.pdf');
      this.faqModal.show();
    }
}