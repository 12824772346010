import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { WebshopService } from '../../linknet-common/restful-services/restful.service';
import { ToastService } from '../../typescripts/pro';
import { GlobalService } from '../../linknet-common/globals/global.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild('DeleteModal') public deleteModal;
  public type = '';
  public settingsTitle: any;
  public translatedMessage: any;
  public loggedInData: any;
  constructor(public titleService: Title, private translate: TranslateService, public _httpWebshopService: WebshopService,
    public toastService: ToastService, public globalService: GlobalService, public router: Router,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.settingsTitle = this.translate.get('HEADING.PROFILE_SETTINGS').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
  }
  userDataPolicy() {
    this.type = 'userdatatype';
  }
  deleteUser(openPopup) {
    try {
      this.type = 'deleteUser';
      if (openPopup === true) {
        this.deleteModal.show();

      } else {
        this._httpWebshopService.deleteUserProfile().subscribe(
          (data: any[]) => {
            if (data) {
             // this.translatedMessage = (this.translate.get('MESSAGECENTER.To_DELETE_SUCCESS'));
              this.translate.get('MESSAGECENTER.To_DELETE_SUCCESS').subscribe((res: string) => {
                this.translatedMessage = res;
              });
              this.toastr.success(this.translatedMessage);
              this.deleteModal.hide();
              this.logout();
            } else {
            }
          }, error => {
            this.toastr.error(error);
          });
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }
  // Logout Function
  logout() {
    try {
      // Remove saved data from sessionStorage
      sessionStorage.removeItem('loggedInData');
      // Remove all saved data from sessionStorage
      const webshopDetail = sessionStorage.getItem('webshopDetail');
      sessionStorage.setItem('webshopDetail', webshopDetail)
      this.loggedInData = {};
      this.globalService.loggedin(null);
      this.router.navigate([''], { preserveQueryParams: false, replaceUrl: true });
      const data = JSON.parse(sessionStorage.getItem('webshopDetail'));
      this.globalService.overwriteSession('userSettings', 'LanguageCode', data.DefaultLanguageCode);
      this.globalService.overwriteSession('userSettings', 'LanguageID', data.DefaultLanguageID);
      this.globalService.overwriteSession('userSettings', 'CurrencyCode', data.DefaultCurrencyCode);
      this.globalService.overwriteSession('userSettings', 'CurrencyID', data.DefaultCurrencyID);
      location.reload();
    } catch (error) {
      this.toastr.error(error);
    }
  }

}
