import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../globals/global.service';
// tslint:disable-next-line:import-blacklist
import { Observable ,  Subject, BehaviorSubject } from 'rxjs';
import 'rxjs/Rx';
import { map } from 'rxjs/operators';

@Injectable()
export class MessageService {

  public data;
  private productTagsSource = new BehaviorSubject([]);
  currentProductTagsData = this.productTagsSource.asObservable();

  private selectionFormatState = new Subject<any>();
  constructor(private http: HttpClient, public globalService: GlobalService) {
  }

  setState(state: any) {
    this.selectionFormatState.next(state);
  }

  getState(): Observable<any> {
    return this.selectionFormatState.asObservable();
  }

  setProductTagsSource(productTagData: any) {
    this.productTagsSource.next(productTagData);
  }

  getProductTagsSource(): Observable<any> {
    return this.productTagsSource.asObservable();
  }


  getUnreadCount() {
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const loggedInData = this.globalService.getLoggedInData();
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/emails?CustomerID=' + webShopSettings.CustomerID + '&user_id=' +
      loggedInData.UserID + '&isUnreadEmailCount=true', {responseType: 'json'});
  }
  /**
     * Service for Emails
  */

  getEmails(page, type) {
    let userID = 'user_id=';
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const loggedInData = this.globalService.getLoggedInData();
    let constoler = 'emails';
    let queryString = 'CustomerID=' + webShopSettings.CustomerID + '&' + type + '=true&';
    if (page.pageIndex == null || page.pageIndex === undefined) {
      page.pageIndex = 1;
    }
    if (page.pageSize == null || page.pageSize === undefined) {
      page.pageSize = environment.ENVIRONMENT.itemsPerPage[0].value;
    }
    if (type === 'inquiries' || type === 'claims') {
      constoler = type;
      queryString = '';
    }
    if (type === 'claims') {
      constoler = type;
      queryString = 'CustomerID=' + webShopSettings.CustomerID + '&';
    }
    if (type === 'inquiries') {
      userID = 'userID=';
    }
    // return this.http.get(environment.ENVIRONMENT.baseURL
    //   + userSettings.LanguageCode + '/' + constoler + '?' + queryString +
    //   userID + loggedInData.UserID + '&pageIndex=' + page.pageIndex + '&pageSize=' + page.pageSize).pipe(map(
    //     (response: Response) => (response.json()))
    //   );
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/' + constoler + '?' + queryString +
      userID + loggedInData.UserID + '&pageIndex=' + page.pageIndex + '&pageSize=' + page.pageSize, {responseType: 'json'});
  }

  getEmailList(postParams) {
    const userSettings = this.globalService.getUserSettings();
    return this.http.post(environment.ENVIRONMENT.baseURL2 + userSettings.LanguageCode + '/Users/EmailTrackingDetails', postParams, {responseType: 'json'})
  }

  /**
     * Service for get Email
  */

  getEmail(id) {
    const userSettings = this.globalService.getUserSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/emails?email_id=' + id + '&setAsReadByUser=true', {responseType: 'json'});
  }

  updateStatus(id) {
    const userSettings = this.globalService.getUserSettings();
    const loggedInData = this.globalService.getLoggedInData();
    return this.http.get(environment.ENVIRONMENT.baseURL2
      + userSettings.LanguageCode + '/Users/EmailUpdate?MailId=' + id + '&RoleName='+ loggedInData.Roles[0].RoleName, {responseType: 'json'});
  }

  /**
    * Service for get Email
 */

  getEnquiry(id) {
    const userSettings = this.globalService.getUserSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/inquiries/' + id, {responseType: 'json'});
  }

  /**
    * Service for get Email
 */

  getClaims(id) {
    const userSettings = this.globalService.getUserSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/claims?claim_id=' + id, {responseType: 'json'});
  }

  /**
     * Service for Emails
  */

  getClaim(pageIndex) {
    const userSettings = this.globalService.getUserSettings();
    const loggedInData = this.globalService.getLoggedInData();
    const webShopSettings = this.globalService.webShopSettings();
    if (pageIndex == null || pageIndex === undefined) {
      pageIndex = 0;
    }
    return this.http.get(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/claims?CustomerID=' + webShopSettings.CustomerID +
      '&user_id=' + loggedInData.UserID + '&pageIndex=' + pageIndex, {responseType: 'json'});
  }

  /**
     * Send  Email
  */

  sendEmail(data) {
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const loggedInData = this.globalService.getLoggedInData();
    data.CustomerID = webShopSettings.CustomerID;
    data.SenderID = loggedInData.UserID;
    return this.http.post(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/emails', data, {responseType: 'json'});
  }

  /**
     * Send  Email
  */

 sendUpdateEmail(data) {
  const userSettings = this.globalService.getUserSettings();
  const webShopSettings = this.globalService.webShopSettings();
  const loggedInData = this.globalService.getLoggedInData();
  data.CustomerID = webShopSettings.CustomerID;
  data.SenderID = loggedInData.UserID;
  return this.http.put(environment.ENVIRONMENT.baseURL
    + userSettings.LanguageCode + '/emails', data, {responseType: 'json'});
}
  /**
    * Bulk  Action
 */

  bulkActions(data) {
    const userSettings = this.globalService.getUserSettings();
    const loggedInData = this.globalService.getLoggedInData();
    return this.http.post(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/emails/bulkactions?user_id=' + loggedInData.UserID, data, {responseType: 'json'});
  }

  /**
  * send  claim
*/
  claimsAction(postParams) {
    const userSettings = this.globalService.getUserSettings();
    return this.http.post(environment.ENVIRONMENT.baseURL
      + userSettings.LanguageCode + '/claims', postParams, {responseType: 'json'});
  }
}
