import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { INgxMyDpOptions } from 'ngx-mydatepicker';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { DatePipe } from '@angular/common';
import { ProductService } from 'app/linknet-common/restful-services/restful.service';
import { ToastrService } from 'ngx-toastr';
import { IMyDateModel } from 'app/typescripts/pro';
import { TranslateService } from '@ngx-translate/core';
import { CommonServicesService } from 'app/linknet-common/common-services/common-services.service';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'shop-app-new-order-modified-new',
  templateUrl: './new-order-modified-new.component.html',
  styleUrls: ['./new-order-modified-new.component.scss']
})
export class NewOrderModifiedNewComponent implements OnInit, OnChanges {
  deliverDate: string;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription;
  @Input() productDetailPrice: any;
  @Input() steps: number;
  @Input() orderQuantity: any;
  @Input() productInputVal: any = {};
  @Input() productCurrentQty: any = {};
  @Input() productDetail: any = {};
  @Input() selectedIds: any = [];
  @Input() productionLineList: any;
  @Output() productCartModal = new EventEmitter();
  public productPriceData = [];
  @Input() dynamicProductRangePricesParent: number;
  @Input() dynamicProductRangeStatusParent: Boolean;
  public productPrintPriceFirstRecord: number;
  @ViewChild('PriceMoreInfoModal') public priceMoreInfoModal;
  public isExpressOrder: Boolean = false;
  public minDate: any;
  public isFutureStockProduct: Boolean = false;
  public newOrderProductionDays: number;
  public productDeliveryWeeksFrom: number;
  public newOrderProductionDaysTest: number;
  public minimumDeliveryDate: any;
  public currency: any;
  public initialDate: any;
  public initialDateSplit: any;
  public firstPrintPrice: any;
  public qtyChangeValue: any;
  public productHighlightPriceRange: any;
  public productHighlightPriceIndex: any;
  public orderDeliveryDate: any;
  public myOptions: INgxMyDpOptions = {
    dateFormat: 'dd-mm-yyyy'
  };
  public shoppingItems: Array<any> = [];
  public qtyChangeStatus: boolean = false;
  @Output() shoppingCarts = new EventEmitter();
  @Output() enquiryCount = new EventEmitter();
  @Input() expressOrder: boolean = false;
  @Output() expressOrderChange = new EventEmitter();
  @Input() expressOrderDateValue: any;
  @Input() productMaxInputValue: any;
  @Input() disableNewOrderButton: boolean;
  @Input() shoppingCartData: any; // new-code
  @Input() parentCustData: any; // new-code
  @Output() expressOrderDateValueChange = new EventEmitter();
  @Output() firstTabStatusValueChange = new EventEmitter();
  public stockModalDisplayStatus: boolean = true;
  public newOrderDeliveryDatePosition: any;
  public newOrderPricePosition: any;
  public webshopDetails: any;
  transportationhandlingcosts: any;
  public quantityStr = '';
  // tslint:disable-next-line:variable-name
  Calculatedprice: number;
  public logoProductPrintPrice: any;
  public logoProductHandlingCost: any;
  public additionalCostForProduct: any;
  public governmentalCostForProduct: any;
  public productPrice: any;
  public totalProductPrice: any;
  public discontVal:any;
  @Input() shoppingCartItemIdStatus: boolean;
  public expressOrderCheckesStatus: boolean = false;
  public changeDeliveryDateText: boolean = false;  // for display text as delivery date / Earliest disp. date in HTML Page
  public wsaStatus: boolean = false;
  isParentCustomer: any;
  isSmartworks:boolean = false;
  userPoints: Subscription;
  balancePoints: any;
  initPoints: any = null;
  toastRef: any;
  errorQty:any;
  firstError: boolean = true;
  isMobile: boolean;
  constructor(private router: Router, public globalService: GlobalService, private toastService: ToastService,
    public datepipe: DatePipe, public _httpProductService: ProductService, private toastr: ToastrService,
    public translate: TranslateService, private commonService: CommonServicesService) {
      if (window.innerWidth > 768) {
        this.isMobile = false;
      }else{
       this.isMobile = true;
      }
      this.currency = this.globalService.getUserSettings().CurrencyCode;

      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if(webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName){
        this.isParentCustomer = true;
      }else{
        this.isParentCustomer = false;
      }

      if(webshopDetail.DomainName === "sw" || webshopDetail.DomainName === "smartworks"){
        this.isSmartworks = true;
      }
      
  }

  ngOnInit() {

    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( _evt => {
        if (window.innerWidth > 768) {
           this.isMobile = false;
        }else{
          this.isMobile = true;
        }
    })
   
    try {
      
      this.webshopDetails = this.globalService.webShopSettings();
      if (this.webshopDetails.ChangeDeliveryDateText) {
        this.changeDeliveryDateText = true;
      }
      if (Number(this.webshopDetails.CustomerID) === 45) {
        this.wsaStatus = true;
      }

      // If user searched by quantity, put that value as initial quantity on product details page
      const breadCrumbsList = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
      var searchUrl = breadCrumbsList[breadCrumbsList.length - 1].url
      const idxQuantity = searchUrl.indexOf('Quantity=');
      if (idxQuantity >= 0 && searchUrl.length > idxQuantity + 9) {
        searchUrl = searchUrl.substring(idxQuantity);
        this.quantityStr = '';
        if (searchUrl.indexOf('&') === -1) {
          this.quantityStr = searchUrl.substring(9);
        }
        else {
          this.quantityStr = searchUrl.substring(9, searchUrl.indexOf('&'));
        }
        if (this.quantityStr !== '') {
          this.productCurrentQty = Number(this.quantityStr);
        }
      }

      // If user searched by delivery date, set it as initial delivery date for new order
      var deliveryDateStr = '';
      var searchUrl = breadCrumbsList[breadCrumbsList.length - 1].url;
      const idxDeliveryDate = searchUrl.indexOf('DeliveryDate=');
      if (idxDeliveryDate >= 0 && searchUrl.length > idxDeliveryDate + 13) {
        searchUrl = searchUrl.substring(idxDeliveryDate);
        if (searchUrl.indexOf('&') === -1) {
          deliveryDateStr = searchUrl.substring(13);
        }
        else {
          deliveryDateStr = searchUrl.substring(13, searchUrl.indexOf('&'));
        }
      }


      if (this.productDetail.isInternalStockProduct && !this.productDetail.isTransportationHandlingCostFree) {
        this.newOrderDeliveryDatePosition = 2;
        this.newOrderPricePosition = 3;
      } else {
        this.newOrderDeliveryDatePosition = 2;
        this.newOrderPricePosition = 3;
      }

      if (this.productDetail.ProductPrices &&
        this.productDetail.ProductPrices[0].ProductPrintPrices.length > 0) {
        this.newOrderDeliveryDatePosition = 3;
        this.newOrderPricePosition = 4;
      }
      // this.productHighlightPriceIndex = 0;
      this.productPriceData = this.productDetailPrice;
      if (this.dynamicProductRangePricesParent === undefined) {
        this.dynamicProductRangePricesParent = 0;
      }
      if (this.productPriceData.length > 0) {
        //  this.firstPrintPrice = Number(this.productPriceData[0].Price) +
        //    Number(this.dynamicProductRangePricesParent);
        if(this.isParentCustomer){
        this.firstPrintPrice = Number(this.productPriceData[0].Price);
        }else{
          this.firstPrintPrice = Number(this.productPriceData[0].CampaignPrice);
        }
        var priceIndex = 0;
        if (this.productCurrentQty > 0) {
          this.productPriceData.forEach((prc, index) => {
            if ((this.productCurrentQty >= prc.MinimumQuantity) && (this.productCurrentQty <= prc.MaximumQuantity)) {
              if(this.isParentCustomer){
              this.firstPrintPrice = Number(prc.Price);
              }else{
                this.firstPrintPrice = Number(prc.CampaignPrice);
              }
              priceIndex = index;
            }
          });
        }

        /** old-code hide */
        if (this.productPriceData[priceIndex].ProductPrintPrices.length > 0 && this.productPriceData[priceIndex].ProductPrintPrices[0].isFixedLogo) {
          this.Calculatedprice = 0;
          this.productPriceData[priceIndex].ProductPrintPrices.forEach(element => {
            if(this.isParentCustomer){
            this.Calculatedprice += element.Price;
            }else{
              this.Calculatedprice += element.CampaignPrice;
            }
          });
          this.firstPrintPrice = Number(this.firstPrintPrice) + Number(this.Calculatedprice);
        }
        this.currency = this.globalService.getUserSettings().CurrencyCode;
       // if (this.currency !== 'zł') {
          this.firstPrintPrice = (this.firstPrintPrice).toFixed(2);
       // }

        /** new-code */
        this.productPrice = Math.round(this.firstPrintPrice);
        this.productPrice = this.productPrice;
        if(this.productDetail.MRP > this.productPrice){
        const y = this.productDetail.MRP - this.productPrice;
        const d = (y*100) / this.productDetail.MRP;
        this.totalProductPrice = parseInt(this.productPrice);
        this.discontVal = d.toFixed(2);
        }
      
          
            this.totalProductPrice = parseInt(this.productPrice);
          
        
        /** new-code */
      }

      /** TO Check Fixed Units */
      /* if (this.productDetail.FixedUnits) {
         this.productCurrentQty = this.productDetail.PiecesPerUnit * this.productCurrentQty;
       }
       this.qtyChangeValue = this.productCurrentQty; */

      

      if (deliveryDateStr !== undefined && deliveryDateStr !== '') {
        try {
          deliveryDateStr = deliveryDateStr.split('%2F').join('-');
          this.deliverDate = this.datepipe.transform(deliveryDateStr, 'yyyy-MM-dd');
        }
        catch {
          this.deliverDate = this.productDetail.SelectedDeliveryDate;
        }
      }
      else {
        this.deliverDate = this.productDetail.SelectedDeliveryDate;
      }

      this.initialDate = this.productDetail.EarliestDeliveryDate.split('-');
      this.initialDateSplit = this.initialDate[2].split('T');
      this.initialDateSplit[0] = this.initialDateSplit[0] - 1;
      this.myOptions = {
        dateFormat: 'dd-mm-yyyy',
        disableUntil: { year: this.initialDate[0], month: this.initialDate[1], day: this.initialDateSplit[0] }
      }

      /** esixting-code-overwrite */
      this.expressOrderCheckesStatus = false;
      this.expressOrder = false;
      if ((this.shoppingCartData.ShoppingCartItemID === '' || this.shoppingCartData.ShoppingCartItemID === 0) &&
        this.quantityStr === '') {
        this.productCurrentQty = 0;
      } else {
        if (this.shoppingCartData.isExpressOrder) {
          this.deliverDate = this.datepipe.transform(this.shoppingCartData.EarliestDeliveryDate, 'yyyy-MM-dd');
          this.expressOrderCheckesStatus = true;
          this.expressOrder = true;
        } else {
          this.deliverDate = this.datepipe.transform(this.shoppingCartData.DeliveryDate, 'yyyy-MM-dd');
        }
      }
      /** esixting-code-overwrite */


      setTimeout(() => {
        if (this.webshopDetails.ChangeDeliveryDateText && this.shoppingCartData.ShoppingCartItemID === 0 &&
          this.shoppingCartData.isInternalStockProduct && this.shoppingCartData.StockBalance > 0) {
          // this.translate.get('PRODUCT_CARD.TWO_DAYS').subscribe((res: string) => {
          //   $('#newDeliveryDate').val(res);
          // });
        }
        if(!this.isParentCustomer){
          this.userPoints = this.commonService.getPoints().subscribe((data) => {
            this.balancePoints = data;
          });
        }
      }, 500);
    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    /** To Check Fixed Units */
    if(!this.isParentCustomer){
        if(!this.shoppingCartData.isVisible && !this.initPoints){
          this.initPoints = this.balancePoints;
        }
        let avlPoints = this.balancePoints;
        if(!this.shoppingCartData.isVisible && this.initPoints){
          avlPoints = this.initPoints;
          this.balancePoints = this.initPoints;
          this.qtyChangeValue = 0;
        }
        let qty = 0;
        let tempCost = 0;
        this.qtyChangeValue = parseInt(this.qtyChangeValue)
        this.productCurrentQty = parseInt(this.productCurrentQty)
        if(this.totalProductPrice){
         
            if(this.errorQty || this.errorQty===0){
              this.qtyChangeValue = this.errorQty;
            }
            if(!this.qtyChangeValue){
              if(this.productCurrentQty){
                this.qtyChangeValue = this.productCurrentQty;
              }else{
                this.qtyChangeValue = 0;
              }
            }
            if(!this.productCurrentQty){
              this.productCurrentQty = 0;
            }
            if(this.qtyChangeValue < this.productCurrentQty){
                qty = this.productCurrentQty - this.qtyChangeValue;
            }else{
                qty = this.qtyChangeValue -  this.productCurrentQty;
            }
            if(this.qtyChangeValue === this.productCurrentQty){
                qty = this.productCurrentQty;
            }
            if(!this.shoppingCartData.isVisible){
            qty = (this.productCurrentQty === 0)? 0:qty;
            }
            tempCost = this.productPrice * qty;
          
         
              if(this.qtyChangeValue === this.productCurrentQty){
                if(!this.shoppingCartData.isVisible){
                avlPoints = this.balancePoints - (this.productPrice * this.productCurrentQty);
                }
                else{
                  avlPoints = this.balancePoints;
                }
              }
             else if(this.qtyChangeValue < this.productCurrentQty){
                avlPoints = this.balancePoints - tempCost;
              }else{
                avlPoints = this.balancePoints + tempCost;
              }
          
          if(avlPoints < 0){
            /*avlPoints = 0;
            if(this.toastRef){
              this.toastr.clear(this.toastRef.ToastId);
            }
            this.toastRef = this.toastr.error("You don't have points to select a product");
            if(!this.errorQty && this.errorQty !== 0){
              this.errorQty = this.qtyChangeValue;
            }*/
            
          }else{
            this.firstError = false;
            this.errorQty = '';
            if(this.toastRef){
              this.toastr.clear(this.toastRef.ToastId);
            }
          }
        }

        if(avlPoints >= 0){
        /*this.balancePoints = (avlPoints === 0) ? this.balancePoints : avlPoints;
        this.userPoints.unsubscribe();
        this.commonService.setPoints(avlPoints);*/
        }
    }

    if (this.productDetail.FixedUnits && changes.productCurrentQty) {
      this.productCurrentQty = this.productDetail.PiecesPerUnit * this.productCurrentQty;
    }

    if(this.isParentCustomer){
      if(this.productCurrentQty > 500){
          const count = this.productCurrentQty
          this.enquiryCount.emit({
            'OrderQty': count
          });
          this.productCurrentQty = 0;
      }
    }else{
      if(this.productCurrentQty > 50){
        const count = this.productCurrentQty
        this.enquiryCount.emit({
          'OrderQty': count
        });
        this.productCurrentQty = 0;
    }
    }

    this.productPriceData = [];
    let indexValue = 0;
    this.qtyChangeStatus = false;
    let qty = this.productCurrentQty;
    if(!this.productCurrentQty){
      qty = 1;
    }
    if (this.productDetailPrice.length > 0) {
      for (let _i = 0; _i < this.productDetailPrice.length; _i++) {

        // code for highlight price range in display
        if (this.productDetailPrice[_i].MaximumQuantity >= qty &&
          this.productDetailPrice[_i].MinimumQuantity <= qty) {
          this.productHighlightPriceRange = this.productDetailPrice[_i];
          this.productHighlightPriceIndex = _i;
          indexValue = _i;
        }
      }
      if (this.productHighlightPriceRange === '' || this.productHighlightPriceRange === undefined) {
        this.productHighlightPriceRange = [];
        this.productHighlightPriceIndex = 0;
      }

      for (let _i = 0; _i < this.productDetailPrice.length; _i++) {
        this.productPriceData.push(this.productDetailPrice[_i]);
        if (this.qtyChangeValue !== this.productCurrentQty) {
          this.qtyChangeStatus = true;
          this.qtyChangeValue = this.productCurrentQty;
          if (this.productDetailPrice[indexValue]['ProductPrintPrices'].length > 0) {
            this.dynamicProductRangePricesParent = this.productDetailPrice[indexValue]['ProductPrintPrices'][0]['Price'];
          }
        }
      }

      // if (changes.productCurrentQty && changes.productCurrentQty.previousValue !== undefined) {
      if (this.firstPrintPrice && this.firstPrintPrice !== null) {
        if (this.productPriceData.length !== 0) {
          const index = this.productHighlightPriceIndex;
          if (changes.productCurrentQty) {
            if(this.isParentCustomer){
            this.firstPrintPrice = Number(this.productPriceData[index].Price);
            }else{
              this.firstPrintPrice = Number(this.productPriceData[index].CampaignPrice);
            }
          } else if (changes.dynamicProductRangePricesParent) {
            if(this.isParentCustomer){
              this.firstPrintPrice = Number(this.productPriceData[index].Price) + Number(this.dynamicProductRangePricesParent);
            }else{
              this.firstPrintPrice = Number(this.productPriceData[index].CampaignPrice) + Number(this.dynamicProductRangePricesParent);
            }
          }
          if (this.productPriceData[index].ProductPrintPrices.length > 0 &&
            this.productPriceData[index].ProductPrintPrices[0].isFixedLogo && !changes.productionLineList
            && !changes.expressOrder && !changes.expressOrderDateValue) {
            this.Calculatedprice = 0;
            if(this.isParentCustomer){
            this.firstPrintPrice = Number(this.productPriceData[index].Price);
            }else{
              this.firstPrintPrice = Number(this.productPriceData[index].CampaignPrice);
            }
            this.productPriceData[index].ProductPrintPrices.forEach(element => {
              if(this.isParentCustomer){
              this.Calculatedprice += element.Price
              }
              else{
                this.Calculatedprice += element.CampaignPrice
              }
            });
            this.firstPrintPrice = Number(this.firstPrintPrice) + Number(this.Calculatedprice);
          }
        //  if (this.currency !== 'zł') {
            this.firstPrintPrice = Number(this.firstPrintPrice).toFixed(2);
        //  }

          /** new-code => for ProductPrintPrice, handling cost, additonal cost, governmental cost  */
          this.logoProductPrintPrice = 0;
          this.logoProductHandlingCost = 0;
          this.additionalCostForProduct = 0;
          this.governmentalCostForProduct = 0;

          if (this.shoppingCartData.isFixedLogo) {
            // this.logoProductPrintPrice += Number(this.shoppingCartData.ProductPrintPrices[0].Price) +
            //    Number(this.shoppingCartData.ProductPrintPrices[0].HandlingCost);
            if (this.shoppingCartData.ProductPrintPrices[0] !== undefined) {
              if (this.shoppingCartData.ProductPrintPrices[0].HandlingCost !== undefined) {
                this.logoProductPrintPrice += Number(this.shoppingCartData.ProductPrintPrices[0].HandlingCost);
              }
            }
          }

          if (this.shoppingCartData.length > 0 && this.shoppingCartData.Price.Logos !== undefined && this.shoppingCartData.Price.Logos !== null) {
            for (let j = 0; j < this.shoppingCartData.Price.Logos.length; j++) {
              // Logo Print Price
              // tslint:disable-next-line:max-line-length            
              if (this.shoppingCartData.Price.Logos[j].ProductPrintPrice !== undefined && this.shoppingCartData.Price.Logos[j].ProductPrintPrice !== null && !this.shoppingCartData.isFixedLogo) {
        
                this.logoProductPrintPrice += Number(this.shoppingCartData.Price.Logos[j].ProductPrintPrice);
              }

              // HANDLING COST FOR PRODUCT PRICE SECTION
              // tslint:disable-next-line:max-line-length
              if (this.shoppingCartData.Price.Logos[j].HandlingCost !== undefined && this.shoppingCartData.Price.Logos[j].HandlingCost !== null && this.shoppingCartData.Price.Logos[j].HandlingCostTypeName === 'per piece' && !this.shoppingCartData.isFixedLogo) {
                this.logoProductHandlingCost += (this.shoppingCartData.Price.Logos[j].HandlingCost);
              }

              // ADDITIONAL COSTS For Product Price Section
              if (this.shoppingCartData.Price.AdditionalCosts !== undefined && this.shoppingCartData.Price.AdditionalCosts !== null) {
                for (let j = 0; j < this.shoppingCartData.Price.AdditionalCosts.length; j++) {
                  if (this.shoppingCartData.Price.AdditionalCosts[j].AdditionalCostTypeCode === 'PerUnit') {
                    if (this.shoppingCartData.Price.AdditionalCosts[j].AdditionalCostPrice !== undefined &&
                      this.shoppingCartData.Price.AdditionalCosts[j].AdditionalCostPrice !== null) {
                      this.additionalCostForProduct += (this.shoppingCartData.Price.AdditionalCosts[j].AdditionalCostPrice);
                    }
                  }
                }
              }

              // GOVERNMENTAL COSTS For Product Price Section
              if (this.shoppingCartData.Price.GovernmentalCosts !== undefined && this.shoppingCartData.Price.GovernmentalCosts !== null) {
                for (let j = 0; j < this.shoppingCartData.Price.GovernmentalCosts.length; j++) {
                  if (this.shoppingCartData.Price.GovernmentalCosts[j].GovernmentalCostTypeCode === 'PerUnit') {
                    this.governmentalCostForProduct += (this.shoppingCartData.Price.GovernmentalCosts[j].GovernmentalCostPrice);
                  }
                }
              }
            }
            this.logoProductPrintPrice = Number(this.logoProductPrintPrice).toFixed(2);
            this.logoProductHandlingCost = Number(this.logoProductHandlingCost).toFixed(2);
            this.additionalCostForProduct = Number(this.additionalCostForProduct).toFixed(2);
            this.governmentalCostForProduct = Number(this.governmentalCostForProduct).toFixed(2);
          }

          // tslint:disable-next-line:max-line-length
          this.productPrice = (Math.round(this.firstPrintPrice) + Math.round(this.logoProductPrintPrice) + Math.round(this.logoProductHandlingCost) + Math.round(this.additionalCostForProduct) + Math.round(this.governmentalCostForProduct));
          this.productPrice = this.productPrice;
         
          if(this.productDetail.MRP > this.productPrice){
          const y = this.productDetail.MRP - this.productPrice;
          const d = (y*100) / this.productDetail.MRP;
            this.discontVal = d.toFixed(2);
          }
          if(!this.isParentCustomer && !this.errorQty && this.errorQty !== 0){
            this.productPrice = this.productPrice;
            if(this.productCurrentQty){
              this.totalProductPrice = parseInt(this.productPrice) * parseInt(this.productCurrentQty);
            }else{
              this.totalProductPrice = parseInt(this.productPrice);
            }
          }else{
            this.totalProductPrice = parseInt(this.productPrice);
          }
          
          
          /** new-code => for ProductPrintPrice, handling cost, additonal cost, governmental cost  */
        }
      }
      // }
    }

    if (changes.dynamicProductRangePricesParent !== undefined) {
      if (changes.dynamicProductRangePricesParent.currentValue === '' ||
        changes.dynamicProductRangePricesParent.currentValue === '0') {
        this.newOrderDeliveryDatePosition = 2;
        this.newOrderPricePosition = 3;
        if (this.productDetail.ProductPrices &&
          this.productDetail.ProductPrices[0].ProductPrintPrices.length > 0) {
          this.newOrderDeliveryDatePosition = 3;
          this.newOrderPricePosition = 4;
        }
      }
    }
    else {
      if (this.productDetail.isInternalStockProduct && !this.productDetail.isTransportationHandlingCostFree) {
        this.newOrderDeliveryDatePosition = 2;
        this.newOrderPricePosition = 3;
      } else {
        this.newOrderDeliveryDatePosition = 2;
        this.newOrderPricePosition = 3;
      }
      if (this.productDetail.ProductPrices &&
        this.productDetail.ProductPrices[0].ProductPrintPrices.length > 0) {
        this.newOrderDeliveryDatePosition = 3;
        this.newOrderPricePosition = 4;
      }
    }

    /** new-code => To Enable Checkout Button in First tab when Product Have only one Tab */
    // tslint:disable-next-line:max-line-length
    if (changes.productCurrentQty) {
      if (this.productDetail.MinimumOrder > this.productCurrentQty || this.productMaxInputValue < this.productCurrentQty) {
        //   this.firstTabStatusValueChange.emit(false);
      }
    }
    /** new-code => To Enable Checkout Button in First tab when Product Have only one Tab */

    if(this.userPoints) {
      this.userPoints.unsubscribe()
    }
  }

  expressOrderDate(ev) {
    if (ev.currentTarget.checked === true) {
      this.expressOrder = true;
      this.deliverDate = this.productDetail.ExpressOrderDeliveryDate;
      this.expressOrderDateValue = this.productDetail.ExpressOrderDeliveryDate;
    } else {
      this.expressOrder = false;
      this.deliverDate = this.productDetail.SelectedDeliveryDate;
      this.expressOrderDateValue = this.productDetail.ExpressOrderDeliveryDate;
    }
    this.expressOrderChange.emit(this.expressOrder);
    this.expressOrderDateValueChange.emit(this.expressOrderDateValue);
  }

  setIsExpressOrder(params) {
    const product = params.product;
    this.isExpressOrder = params.checked;
    if (product.isInternalStockProduct === true
      && (product.ProductFutureStock) && (product.ProductFutureStock[0])) {
      this.isFutureStockProduct = true;
    }
    return this.calculateDays(params);
  }

  onDateChanged(data: IMyDateModel) {
    this.deliverDate = this.datepipe.transform(data.jsdate, 'yyyy-MM-dd');
    this.expressOrderDateValueChange.emit(this.deliverDate);
  }

  calculateDays(parmDate) {
    if (parmDate.MinimumDeliveryDate) {
      this.minimumDeliveryDate = parmDate.minimumDeliveryDate.date;
      this.minimumDeliveryDate = (this.minimumDeliveryDate.year + '/'
        + this.minimumDeliveryDate.month + '/' + ((this.minimumDeliveryDate.day) + 5));
      const oneDay = 24 * 60 * 60 * 1000;
      let firstDate = new Date(this.minimumDeliveryDate ? this.minimumDeliveryDate : new Date());
      if (this.isFutureStockProduct) {
        firstDate = new Date();
      }
      const secondDate = new Date(parmDate.date);
      firstDate.setHours(0, 0, 0, 0);
      secondDate.setHours(0, 0, 0, 0);
      const diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
      return diffDays;
    }
  }

  showPriceMoreInfoModal() {
    this.priceMoreInfoModal.show();
  }

  shoppingCartShow(data) {
    if (data.data.DeliveryDate !== '') {
      // const param = {
      //   'DeliveryDate': data.data.DeliveryDate,
      //   'OrderQty': data.data.OrderQty,
      //   'isDisabled': data.data.isDisabled ? data.data.isDisabled : false,
      //   'newOrderButtonClickStatus': true, 
      //   'paramExists': true
      // }
      // this.shoppingCarts.emit({ param });
      this.shoppingCarts.emit({
        'DeliveryDate': data.data.DeliveryDate,
        'OrderQty': data.data.OrderQty,
        'isDisabled': data.data.isDisabled ? data.data.isDisabled : false,
        'newOrderButtonClickStatus': true,
        'totalPrice': this.productPrice,
        'isMultipleCall': (data.data.isMultipleCall !== null && data.data.isMultipleCall !== undefined) ? data.data.isMultipleCall : '',
        'paramExists': true
      });
    } else {
      this.shoppingCarts.emit({ 'DeliveryDate': this.deliverDate, 'newOrderButtonClickStatus': true,  'totalPrice': this.productPrice });
    }
  }

  inrFormat(val: number) {
    let currencyCodes =  sessionStorage.getItem("CurrencyCode");
    if (currencyCodes && currencyCodes !== "ALL") {
      return `${currencyCodes} ${val}`;
    } else if (currencyCodes === "ALL") {
      return `${val}`;
    }
    else {
      return Number(val).toLocaleString('en-IN');
    } 
    // 
  }

  showProductCartModal() {
    if(!this.isMobile){
      this.productCartModal.emit(true);
    }else{
      this.router.navigate(['/shoppingcart'])
    }
  }

  quantityMsg(){
    this.toastr.error("Please Enter Quantity")
  }
  goto () {
    this.router.navigate(['/']);
  }
}
