import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  public translatedMessage: any;

  constructor(public globalService: GlobalService, private toastService: ToastService, private translate: TranslateService,
    private toastr: ToastrService) { }

  canActivate() {
    const userData = this.globalService.getLoggedInData();
    if (userData) {
      return true;
    } else {
    //  this.translatedMessage = (this.translate.get('ERRORS.PLEASE_LOGIN_TO_ACCESS_LINK'));
    // this.toastService.error(this.translatedMessage.value);

      this.translate.get('ERRORS.PLEASE_LOGIN_TO_ACCESS_LINK').subscribe((res: string) => {
        this.translatedMessage = res;
      });

      if(this.translatedMessage)this.toastr.error(this.translatedMessage);
      
    }
  }

  canActivateChild() {
    const userData = this.globalService.getLoggedInData();
    if (userData) {
      return true;
    } else {
      // this.translatedMessage = (this.translate.get('ERRORS.PLEASE_LOGIN_TO_ACCESS_LINK'));
      // this.toastService.error(this.translatedMessage.value);

      this.translate.get('ERRORS.PLEASE_LOGIN_TO_ACCESS_LINK').subscribe((res: string) => {
        this.translatedMessage = res;
      });
      this.toastr.error(this.translatedMessage);
    }
  }

}
