import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../../../linknet-common/validators';
import { WebshopService } from '../../../linknet-common/restful-services/restful.service';
import { ToastService } from '../../../typescripts/pro';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { SettingsComponent } from '../settings.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-more-address',
  templateUrl: './more-address.component.html',
  styleUrls: ['./more-address.component.scss']
})
export class MoreAddressComponent implements OnInit {
  @ViewChild('MessageModal') public messageModal;
  @ViewChild('DeleteModal') public deleteModal;
  public moreAddressForm: any;
  public editData: any;
  optionsSelect: any[];
  countries: any;
  translatedMessage: any;
  arrayList: any;
  settings = {
    columns: {
      DeliveryContactPerson: {
        title: 'Contact Person',
        filter: false
      },
      DeliveryCompanyName: {
        title: 'Company Name',
        filter: false
      },
      DeliveryEmail: {
        title: 'Email',
        filter: false
      },
      DeliveryPhoneNumber: {
        title: 'PhoneNumber',
        filter: false
      },
      DeliveryAddress: {
        title: 'Address',
        filter: false
      },
      DeliveryAddress2: {
        title: 'Address2',
        filter: false
      },
      DeliveryAddress3: {
        title: 'Address3',
        filter: false
      },
      DeliveryPostalCode: {
        title: 'PostalCode',
        filter: false
      },
      DeliveryCity: {
        title: 'City',
        filter: false
      },
      DeliveryCountry: {
        title: 'Country',
        filter: false
      }
    }
  };
  usersDeliveryAddressID: any;
  showUpdateButton: Boolean = false;
  constructor(public formBuilder: FormBuilder, public _httpWebshopService: WebshopService, public toastService: ToastService,
    public translate: TranslateService, public globalService: GlobalService, public settingsComponent: SettingsComponent,
    private toastr: ToastrService) {
    settingsComponent.type = 'moreaddress';
    this._httpWebshopService.getAllCountries().subscribe(
      (data: any[]) => {
        this.optionsSelect = [];
        this.countries = data;
        this.countries.forEach(elementForCountry => {
          const countryVal = {
            'value': elementForCountry.Alpha2Code,
            'label': elementForCountry.Name,
          };
          this.optionsSelect.push(countryVal);
        });
      },
      error => {
        // this.toastService.error(JSON.parse(error._body).Message);
        this.toastr.error(error.error.Message);
      });
  }

  ngOnInit() {
    this.setForm()
  }
  setForm() {
    this.moreAddressForm = this.formBuilder.group({
      'DeliveryCompanyName': [''],
      'DeliveryContactPerson': ['', [Validators.required]],
      'DeliveryPhoneNumber': ['', [Validators.required]],
      'DeliveryEmail': ['', [Validators.required, ValidationService.emailValidator]],
      'DeliveryAddress': ['', [Validators.required]],
      'DeliveryAddress2': [''],
      'DeliveryAddress3': [''],
      'DeliveryCity': ['', [Validators.required]],
      'DeliveryPostalCode': [''],
      'DeliveryCountry': [null, [Validators.required]],
      'isDefaultAddress': [false, [Validators.required]],
      'deliveryInstruction': ['']
    });
    this.showUpdateButton = false;
  }
  postMoreDeliveryAddress(value) {
    this.showUpdateButton = false;
    const loggedInData = this.globalService.getLoggedInData();
    const sendData = {
      'DeliveryCompanyName': this.moreAddressForm.value.DeliveryCompanyName,
      'DeliveryContactPerson': this.moreAddressForm.value.DeliveryContactPerson,
      'DeliveryPhoneNumber': this.moreAddressForm.value.DeliveryPhoneNumber,
      'DeliveryEmail': this.moreAddressForm.value.DeliveryEmail,
      'DeliveryAddress': this.moreAddressForm.value.DeliveryAddress,
      'DeliveryAddress2': this.moreAddressForm.value.DeliveryAddress2,
      'DeliveryAddress3': this.moreAddressForm.value.DeliveryAddress3,
      'DeliveryCity': this.moreAddressForm.value.DeliveryCity,
      'DeliveryPostalCode': this.moreAddressForm.value.DeliveryPostalCode,
      'DeliveryCountry': this.moreAddressForm.value.DeliveryCountry,
      'UserID': loggedInData.UserID,
      'isDefaultAddress': this.moreAddressForm.value.isDefaultAddress,
      'DeliveryInstructions': this.moreAddressForm.value.deliveryInstruction
    }
    if (value === 1) {
      this._httpWebshopService.createuserdeliveryaddress(sendData).subscribe(
        _data => {
         // this.translatedMessage = (this.translate.get('SIGNUP.SAVED_SUCESS'));
          this.translate.get('SIGNUP.SAVED_SUCESS').subscribe((res: string) => {
            this.translatedMessage = res;
          });
          this.toastr.success(this.translatedMessage);
          this.getAllAddress();
          this.setForm()
        },
        error => {
          // this.toastService.error(JSON.parse(error._body).Message);
          this.toastr.error(error.error.Message);
        });
    } else {
      sendData['UsersDeliveryAddressID'] = this.usersDeliveryAddressID;
      this._httpWebshopService.updateuserdeliveryaddress(sendData).subscribe(
        _data => {
         // this.translatedMessage = (this.translate.get('SIGNUP.UPDATE_SCUCESS'));
          this.translate.get('SIGNUP.UPDATE_SCUCESS').subscribe((res: string) => {
            this.translatedMessage = res;
          });
          this.toastr.success(this.translatedMessage);
          this.getAllAddress();
          this.setForm()
        },
        error => {
          // this.toastService.error(JSON.parse(error._body).Message);
          this.toastr.error(error.error.Message);
        });
    }
  }
  // for Decimal you can use this as
  onlyDecimalNumberKey(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // tslint:disable-next-line:curly
    if ((charCode < 48 || charCode > 57))
      return false;
    return true;
  }
  getAllAddress() {
    this._httpWebshopService.getMoreDeliveryAddress(0).subscribe(
      (data: any[]) => {
        if (data['DeliveryAddresses'] && data['DeliveryAddresses'].length > 0) {
          this.messageModal.show()
          this.arrayList = data['DeliveryAddresses']
        } else {
          this.messageModal.hide()
        //  this.translatedMessage = (this.translate.get('MESSAGECENTER.NO_ADDRESS_FOUND'));
          this.translate.get('MESSAGECENTER.NO_ADDRESS_FOUND').subscribe((res: string) => {
            this.translatedMessage = res;
          });
          this.toastr.error(this.translatedMessage);
        }
      },
      error => {
        // this.toastService.error(JSON.parse(error._body).Message);
        this.toastr.error(error.error.Message);
      });
  }
  editAddress(data) {
    this.editData = data;
    this.usersDeliveryAddressID = data.UsersDeliveryAddressID;
    this.showUpdateButton = true;
    this.moreAddressForm.patchValue({
      DeliveryPhoneNumber: data.DeliveryPhoneNumber,
      DeliveryCompanyName: data.DeliveryCompanyName,
      DeliveryEmail: data.DeliveryEmail,
      DeliveryCountry: data.DeliveryCountry,
      DeliveryAddress: data.DeliveryAddress,
      DeliveryAddress2: data.DeliveryAddress2,
      DeliveryAddress3: data.DeliveryAddress3,
      DeliveryCity: data.DeliveryCity,
      DeliveryPostalCode: data.DeliveryPostalCode,
      DeliveryContactPerson: data.DeliveryContactPerson,
      isDefaultAddress: data.isDefaultAddress,
      deliveryInstruction: data.DeliveryInstructions
    }, { onlySelf: false, emitEvent: false });
    this.messageModal.hide()
  }
  deleteAddress() {
    this._httpWebshopService.deleteuserdeliveryaddress(this.editData).subscribe(
      _data => {
       // this.translatedMessage = (this.translate.get('MESSAGECENTER.To_DELETE_SUCCESS'));
        this.translate.get('MESSAGECENTER.To_DELETE_SUCCESS').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.success(this.translatedMessage);
        this.getAllAddress();
        this.deleteModal.hide();
      },
      error => {
        // this.toastService.error(JSON.parse(error._body).Message);
        this.toastr.error(error.error.Message);
      });
  }
  openDeleteModal(data) {
    this.editData = data;
    this.deleteModal.show()
  }

  startsWithSearchFn(term: string, item) {
    term = term.toString().toLowerCase();
    return item.label.toLowerCase().lastIndexOf(term, 0) === 0;
  }
}
