import { Component, EventEmitter, Input, Output, OnInit, SimpleChanges,ViewChild,ElementRef } from '@angular/core';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions, UploadStatus } from 'ngx-uploader';
import { WebshopService } from 'app/linknet-common/restful-services/restful.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { environment } from '../../../../environments/environment';
import { GetExtensionPipe } from 'app/get-extension.pipe';
import { ToastrService } from 'ngx-toastr';
import { flyInOut } from 'app/typescripts/pro/animations/animations.component';

@Component({
  selector: 'shop-app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  clearfile : any = '';
  @ViewChild('fileInput')
myInputVariable: ElementRef;
  @Input() uploadLocation: any;
  @Input() isSettingPage: any;
  @Input() noLoginRequired: Boolean = false;
  @Output() uploadFileDataValue = new EventEmitter<any>();
  @Output() imageName = new EventEmitter<any>();
  @Output() refresh = new EventEmitter();
  @Input() customOptions: any;
  @Input() isInquiryPage: any;
  @Input() isMultipleAddress: any;
  @Input() productDetail: any;
  @Input() isShoppingCart: any;
  @Input() isEmailLogoLater: any;
  @Input() isClaimsPage: any;
  @Input() imageUrlList = [];
  @Input() imageNameList = [];
  @Input() logoIndex: any;
  public translatedMessage: any;
  public uploadFileData: any;
  public getExtension: any;
  @Input() userImageURL: any;
  @Input() panImageURL: any;
  @Input() gstImageURL: any;
  @Input() rocImageURL: any;
  @Input() xlImageURL: any;
  public filePath: any;
  public options: UploaderOptions;
  public formData: FormData;
  public files: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public loaderShow: any;
  public logoName: any;
  public logIndata: any;
  public userLogos: any;
  public isParentCustomer: Boolean = false;
  public panpdfView: Boolean = false;
  public gstpdfView: Boolean = false;
  public rocpdfView: Boolean = false;
  public imageDetails: any;
  public isVoucherKart: boolean = false;
  @Input() csvFileName: any;
  @ViewChild('fileInput', { read: ElementRef }) fileInput: ElementRef;

  constructor(public _httpWebservice: WebshopService, public globalService: GlobalService,
    public toastService: ToastService, public translate: TranslateService, public _httpWebshopService: WebshopService,
    private toastr: ToastrService) {
    this.files = [];
    this.uploadInput = new EventEmitter<UploadInput>();
    this.humanizeBytes = humanizeBytes;
    const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName) {
      this.isParentCustomer = true;
    } else {
      this.isParentCustomer = false;
    }
    if(webshopDetail.DomainName === "voucherkart"){
      this.isVoucherKart = true;
    }
  }

  ngOnInit() {
    this.logIndata = this.globalService.getLoggedInData();
    const userSettings = this.globalService.getLoggedInData();
    this.imageDetails = userSettings;
    if (userSettings && !this.isInquiryPage && this.isShoppingCart !== true && !this.isClaimsPage) {
      this.userImageURL = userSettings.ImageURL;
      if (userSettings.PanImageUrl) {
        this.panImageURL = userSettings.PanImageUrl;
        fetch(this.panImageURL, { method: "HEAD" })
          .then(response => response.headers.get("Content-Type"))
          .then(type => {
            let fileType = `.${type.replace(/.+\/|;.+/g, "")}`;
            if (fileType === '.pdf') {
              this.panpdfView = true;
            }
          });
      }
      if (userSettings.GSTImageUrl) {
        this.gstImageURL = userSettings.GSTImageUrl;
        fetch(this.gstImageURL, { method: "HEAD" })
          .then(response => response.headers.get("Content-Type"))
          .then(type => {
            let fileType = `.${type.replace(/.+\/|;.+/g, "")}`;
            if (fileType === '.pdf') {
              this.gstpdfView = true;
            }
          });
      }
      if (userSettings.ROCImageUrl) {
        this.rocImageURL = userSettings.ROCImageUrl;
        fetch(this.rocImageURL, { method: "HEAD" })
          .then(response => response.headers.get("Content-Type"))
          .then(type => {
            let fileType = `.${type.replace(/.+\/|;.+/g, "")}`;
            if (fileType === '.pdf') {
              this.rocpdfView = true;
            }
          });
      }
    }
    if (this.productDetail) {
      this.userImageURL = this.productDetail.ImageURL;
    }
  }


  onUploadOutput(output: UploadOutput, imgName: string): void {     

    if (output.type === 'dragOver' || output.type === 'dragOut') {
    }
     else {
      this.loaderShow = true;
    }
    
    const logInData = this.globalService.getLoggedInData();
    this.logIndata = logInData;
    this.getExtension = output.file ? GetExtensionPipe.prototype.transform(output.file.name) : '';
    if (this.getExtension === '') {
      this.getExtension = this.files[0] ? GetExtensionPipe.prototype.transform(this.files[0].name) : '';
      this.loaderShow = false;
    }
    if (this.uploadLocation === 'UserImages') {
      this.filePath = 'UserImages';
      if (imgName == 'pan' || imgName == 'gst' || imgName == 'roc') {
        this.filePath = 'UserDocuments';
      }
    } else if (this.uploadLocation === 'InquiryImages') {
      this.filePath = 'InquiryFiles'
    } else if (this.uploadLocation === 'MultipleAddress') {
      this.filePath = 'BulkAddress'
    } else if (this.uploadLocation === 'CustomerImages') {
      this.filePath = 'CustomerImages'
    } else if (this.uploadLocation === 'GiftingImage') {
      this.filePath = 'GiftingImage'
    }
    else {
      this.filePath = 'UserLogos'
    }
    if ((logInData) || (this.noLoginRequired)) {
      if (((this.getExtension.toLowerCase() === 'pdf' || this.getExtension.toLowerCase() === 'jpg' || this.getExtension.toLowerCase() === 'gif'
        || this.getExtension.toLowerCase() === 'png' || this.getExtension.toLowerCase() === 'jpeg') && (this.uploadLocation === 'GiftingImage' || this.uploadLocation === 'CustomerImages' || this.uploadLocation === 'UserImages' || this.uploadLocation === 'InquiryImages')) ||
        ((this.getExtension.toLowerCase() === 'pdf' || this.getExtension.toLowerCase() === 'ai' || this.getExtension.toLowerCase() === 'eps') &&
          (this.uploadLocation === 'userlogo')) || (this.uploadLocation === 'MultipleAddress' && (this.getExtension.toLowerCase() === 'csv')) || (this.uploadLocation === 'MultipleAddress' && (this.getExtension.toLowerCase() === 'xls' || this.getExtension.toLowerCase() === 'xlsx'))) {
        if (output.type === 'allAddedToQueue') {

          const event: UploadInput = {
            type: 'uploadAll',
            url: environment.ENVIRONMENT.baseURL + 'en/uploadfile',
            method: 'POST',
            data: { filePath: this.filePath }
          };
          this.uploadInput.emit(event);
          this.toastr.info("File uploaded successfully");          
          if(this.getExtension.toLowerCase() === 'csv'){
            this.fileInput.nativeElement.value = null;
          } 

        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
          this.files[0] = output.file;
        } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
          const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        } else if (output.type === 'removed') {
          this.files = this.files.filter((file: UploadFile) => file !== output.file);
        } else if (output.type === 'dragOver') {
          this.dragOver = true;
        } else if (output.type === 'dragOut') {
          this.dragOver = false;
        } else if (output.type === 'drop') {
          this.dragOver = false;
        } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
        } else if (output.type === 'done') {
          const param = {
            'fileName': output.file.response[1]
          }
          switch (this.uploadLocation) {
            case 'userlogo':
              if (this.getExtension.toLowerCase() === 'pdf' || this.getExtension.toLowerCase() === 'ai' ||
                this.getExtension.toLowerCase() === 'eps') {
                if (logInData && logInData.UserID) {
                  this._httpWebservice.saveUserLogo(param).subscribe(
                    (data: any[]) => {
                      if (data) {
                        this.logoName = output.file.response[1];
                        output.file.response[2] = this.logoIndex;
                        this.uploadFileDataValue.emit(output.file.response)
                        this.refresh.emit();
                        this.loaderShow = false;
                        // this.translatedMessage = (this.translate.get('FILE_UPLOAD.FILE_UPLOAD_SUCCESS'));
                        // this.toastService.success(this.translatedMessage.value);

                        this.translate.get('FILE_UPLOAD.FILE_UPLOAD_SUCCESS').subscribe((res: string) => {
                          this.translatedMessage = res;
                        });
                        this.toastr.success(this.translatedMessage);
                      }
                    }
                  )
                } else {
                  this.logoName = output.file.response[1];
                  output.file.response[2] = this.logoIndex;
                  this.uploadFileDataValue.emit(output.file.response);
                  this.refresh.emit();
                  this.loaderShow = false;
                }
              }
              break;
            case 'GiftingImage':
              this.userImageURL = output.file.response[2];
              this.uploadFileDataValue.emit(output.file.response);
              this.imageName.emit(imgName);
              setTimeout(() => {
                this.loaderShow = false;
              }, 100);
              break;
            case 'CustomerImages':
              const logoUrlLists = {
                'FileName': this.userImageURL
              }
              this.userImageURL = output.file.response[2];
              this.uploadFileDataValue.emit(output.file.response);
              this.imageName.emit(imgName);
              setTimeout(() => {
                this.loaderShow = false;
              }, 100);
              break;
            case 'UserImages':
              if (this.getExtension.toLowerCase() === 'pdf' && imgName === 'photo') {
                this.logoName = false;
                this.translate.get('FILE_UPLOAD.FILE_TYPE_NOT_SUPPORTED').subscribe((res: string) => {
                  this.translatedMessage = res;
                });
                this.uploadInput.emit({ type: 'removeAll' });
                this.toastr.info(this.translatedMessage);
                setTimeout(() => {
                  this.loaderShow = false;
                }, 100);
                return;
              }
              const imageUrlLists = {
                'FileName': this.userImageURL
              }
              if (imgName == 'photo') {
                this.userImageURL = output.file.response[2];
                output.file.response[3] = imgName;
              }
              if (imgName == 'pan') {
                this.panImageURL = output.file.response[2];
                output.file.response[3] = imgName;
                if (this.getExtension.toLowerCase() === 'pdf') {
                  this.panpdfView = true;
                }
              }

              if (imgName == 'gst') {
                this.gstImageURL = output.file.response[2];
                output.file.response[3] = imgName;
                if (this.getExtension.toLowerCase() === 'pdf') {
                  this.gstpdfView = true;
                }
              }

              if (imgName == 'roc') {
                this.rocImageURL = output.file.response[2];
                output.file.response[3] = imgName;
                if (this.getExtension.toLowerCase() === 'pdf') {
                  this.rocpdfView = true;
                }
              }


              this.imageUrlList.push(imageUrlLists);
              if (this.isClaimsPage === true) {
                this.uploadFileDataValue.emit(this.imageUrlList);
              } else {
                output.file.response[2] = this.logoIndex;
                this.uploadFileDataValue.emit(output.file.response);
                this.imageName.emit(imgName);
              }
              setTimeout(() => {
                this.loaderShow = false;
              }, 100);
              break;
            case 'InquiryImages':
              this.userImageURL = output.file.response[2];
              const fileUrlList = {
                'FileURL': this.userImageURL
              }
              this.imageUrlList.push(fileUrlList);
              const userImageName = output.file.response[1];
              const imageNameList = {
                'FileName': userImageName
              }
              this.imageNameList.push(imageNameList);
              if (this.isInquiryPage === true) {
                this.uploadFileDataValue.emit(this.imageNameList);
              } else {
                output.file.response[2] = this.logoIndex;
                this.uploadFileDataValue.emit(output.file.response)
              }
              setTimeout(() => {
                this.loaderShow = false;
              }, 100);
              break;
            case 'MultipleAddress':
              this.xlImageURL = output.file.response[2]
              this.csvFileName = output.file.response[0];
              this.uploadFileDataValue.emit(output.file.response);
              const xlfileUrlList = {
                'FileURL': output.file.response[2]
              }
              this.imageUrlList.push(xlfileUrlList)
              const xlimageNameList = {
                'FileName': output.file.response[1]
              }
              this.imageNameList.push(xlimageNameList);
              setTimeout(() => {
                this.loaderShow = false;
              }, 100);
              break;
          }
          this.files = this.files.filter(file => file.progress.status !== UploadStatus.Done);
        }
      } else if (typeof output.file !== 'undefined') {
        this.logoName = false;
        this.translate.get('FILE_UPLOAD.FILE_TYPE_NOT_SUPPORTED').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.uploadInput.emit({ type: 'removeAll' });
        this.toastr.info(this.translatedMessage);
        setTimeout(() => {
          this.loaderShow = false;
        }, 100);
      }

    } else {
      // this.translatedMessage = (this.translate.get('FILE_UPLOAD.PLEASE_LOGIN_TO_UPLOAD_LOGO'));
      this.translate.get('FILE_UPLOAD.PLEASE_LOGIN_TO_UPLOAD_LOGO').subscribe((res: string) => {
        this.translatedMessage = res;
      });
      this.toastr.info(this.translatedMessage);
      setTimeout(() => {
        this.loaderShow = false;
      }, 100);
    }
  }


  cancelUpload(id: string): void {
    try {
      this.uploadInput.emit({ type: 'cancel', id: id });
    } catch (error) {
      this.toastr.error(error);
    }
  }

  removeFile(id: string): void {
    try {
      this.uploadInput.emit({ type: 'remove', id: id });
    } catch (error) {
      this.toastr.error(error);
    }
  }

  removeAllFiles(): void {
    try {
      this.uploadInput.emit({ type: 'removeAll' });
    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  
    if (changes.csvFileName !== undefined) {
      if (changes.csvFileName.currentValue === "") {
        this.csvFileName = '';
      }
    }  
  }

}
