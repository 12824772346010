import { Component, OnInit, Input, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { ToastService } from 'app/typescripts/pro/alerts';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from 'ngx-image-gallery';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-marketingimage-block',
  templateUrl: './marketingimage-block.component.html',
  styleUrls: ['./marketingimage-block.component.scss']
})
export class MarketingimageBlockComponent implements OnInit {

  public productImageURL: URL;
  public tempProductImageUrl: URL;
  public imageID: any;
  public galleryList: any = [];
  public openImageIndex: any;
  public productId: any;
  public productBatchID: any;
  public selectImgIndex: any;
  public webShopSettings: any;
  public imageSetForLogoPosition: Boolean = false;
  public selectedProductColorID: any;
  @Input() productDetail: any;
  @Input() productImage: any = [];
  // get reference to gallery component
  @ViewChild(NgxImageGalleryComponent) ngxImageGallery: NgxImageGalleryComponent;
  // gallery configuration
  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
    showThumbnails: false
  };
  // gallery images
  public images: GALLERY_IMAGE[];
  constructor(private toastService: ToastService, private globalService: GlobalService, private toastr: ToastrService) {
  }

  ngOnInit() {
    try {
      this.webShopSettings = this.globalService.webShopSettings();
      this.productImageURL = this.productImage.ProductImages ? this.productImage.ProductImages[0].ImageURL : this.productImage.ImageURL;
      this.productId = this.productImage.ProductID;
      this.imageID = this.productImage.ProductImages[0].ProductImageID;
      this.populateGalery();
    } catch (error) {
      this.toastr.error(error);
    }
  }

  populateGalery() {
    this.galleryList = [];
    this.productImage.ProductImages.forEach(element => {
      const imageList = {
        'url': element.ImageURL,
        'extUrl': element.ImageURL,
        'thumbnailUrl': element.ImageURL
      };
      this.galleryList.push(imageList);
    });
    this.images = this.galleryList;
  }

  ngOnChanges(_changes: SimpleChanges): void {
      this.populateGalery();
  }

  // open gallery
  openGallery(index) {
    if (this.imageSetForLogoPosition) {
      this.ngxImageGallery.open(0);
    }
    else {
      if (this.selectImgIndex) {
        this.ngxImageGallery.open(this.selectImgIndex);
      } else {
        this.ngxImageGallery.open(index);
      }
    }
  }

  getImgId(imgId, productID, idx) {
    this.selectImgIndex = idx;
    this.imageID = imgId;
    this.productBatchID = productID;
    if (this.imageSetForLogoPosition) {
      this.populateGalery();
    }
  }

  getProductUrlOnMouseLeave () {
    if (this.tempProductImageUrl) {
      //this.productImageURL = this.tempProductImageUrl;
    }
  }

}
