import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastService } from 'app/typescripts/pro/alerts';
import { WebshopService, ProductService } from 'app/linknet-common/restful-services/restful.service';
import { GlobalService } from '../../linknet-common/globals/global.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-product-pdf',
  templateUrl: './product-pdf.component.html',
  styleUrls: ['./product-pdf.component.scss'],
  exportAs: 'child'
})
export class ProductPdfComponent implements OnInit {
  singleCost: {};
  public additionalCost: any;
  public additionalCostPrice = [];
  public brandingCost = [];
  public additionalCostValue: any;
  public additionalCostList: any;
  public setCountry: any;
  @ViewChild('pdfModal') public pdfModal;
  @Input() productId: any;
  public offerForm: any;
  public optionsSelect: Array<any>;
  public countries: Array<any>;
  public productInfo: any;
  public printPrice: Array<any>;
  public printPricesFiltered: Array<any> = [];
  public isFixedLogos: Boolean = false;
  public selectedLogoTypeID: any = '';
  public selectedLogoPositionID: any = '';
  public selectedLogoMaxAreaID: any = '';
  public productAdditionalCosts = [];
  public additionalCostDisplay = [];
  public additionalCosts: any;
  public quantities: any;
  public offerParams: any;
  public additionalCostsList: Array<any> = [];
  public htmlTag: any;
  public loaderShow: any;
  public translatedMessage: any;
  public offerQuantity2Focus: Boolean = false;
  public offerQuantity3Focus: Boolean = false;
  public checkCostOptionID: any = '';
  public showStatus: Boolean = false;
  public brandingValue: any = '';
  public ProductLogoTypesFiltered = [];
  public ProductLogoPositionsFiltered = [];
  public ProductLogoMaxAreasFiltered = [];
  public RecommendedTextStr: any = '';
  constructor(public formBuilder: FormBuilder, public toastService: ToastService, public _httpWebshopService: WebshopService,
    public _httpProductService: ProductService, public globalService: GlobalService, public translate: TranslateService,
    private toastr: ToastrService) {
  }

  ngOnInit() {
    this.translate.get('PRODUCT_DETAIL.RECOMMENDED').subscribe((res: string) => {
      this.RecommendedTextStr = res;
    });

    try {
      this.brandingCost = [];
      this.brandingValue = '';

      const userDetails = JSON.parse(sessionStorage.getItem('loggedInData'));
      if (userDetails !== null) {
        this.setCountry = userDetails.Country;
      }
      this.additionalCost = [];
      this.offerForm = this.formBuilder.group({
        'offerQuantity1': ['', [Validators.required]],
        'offerQuantity2': [''],
        'offerQuantity3': [''],
        'country': ['', [Validators.required]]
      });
    } catch (error) {
      this.toastr.error(error);
    }
  }

  additionalCostDisplayStatus(val, checkedStatus) {
    this.additionalCostDisplay[val] = checkedStatus;
  }

  showPdfModal() {
    try {
      this.showStatus = true;
      const logInData = this.globalService.getLoggedInData();
      if (logInData) {
        if (this.productId !== undefined) {
          this.getProductDetail(this.productId);
        }
        const countryList = JSON.parse(sessionStorage.getItem('countryList'));
        if (countryList !== null) {
          this.optionsSelect = countryList;
        } else {
          this.getCountryList();
        }
        this.pdfModal.show();
      } else {
       // this.translatedMessage = (this.translate.get('PRODUCT_DETAIL.PLEASE_LOGIN_TO_GENERATE_OFFER'));
        this.translate.get('PRODUCT_DETAIL.PLEASE_LOGIN_TO_GENERATE_OFFER').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getCountryList() {
    try {
      this._httpWebshopService.getAllCountries().subscribe(
        (data: any[]) => {
          this.optionsSelect = [];
          this.countries = data;
          this.countries.forEach(elementForCountry => {
            const countryVal = {
              'value': elementForCountry.Alpha2Code,
              'label': elementForCountry.Name,
            };
            this.optionsSelect.push(countryVal);
          });
          sessionStorage.setItem('countryList', JSON.stringify(this.optionsSelect));
        },
        error => {
          // this.toastService.error(JSON.parse(error._body).Message);
          this.toastr.error(error.error.Message);
        });
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getProductDetail(productId) {
    try {
      this._httpProductService.getProductDetail(productId, undefined, undefined, undefined).subscribe(
        data => {
          this.productInfo = data;
          this.offerForm.patchValue({
            offerQuantity1: data['MinimumOrder'],
            country: this.setCountry
          })

          this.productAdditionalCosts = data['ProductAdditionalCosts'];
          for (let i = 0; i < this.productAdditionalCosts.length; i++) {
            this.additionalCostDisplay[i] = false;
            if (this.productAdditionalCosts[i].isOptionalForBuyer === false) {
              this.productAdditionalCosts[i]['isChecked'] = true;
              if (this.productAdditionalCosts[i].ProductAdditionalCostOptions) {
                // tslint:disable-next-line:max-line-length
                this.productAdditionalCosts[i]['AdditionalCostOptionID'] = this.productAdditionalCosts[i].ProductAdditionalCostOptions[0].ProductAdditionalCostOptionID;
              }
            }
          }

          this.printPrice = [];
          this.printPricesFiltered = [];

          const ProductPrices = this.productInfo.ProductPrices;
          ProductPrices.forEach((elementForProduct, index) => {
            const productPrintPrices = elementForProduct.ProductPrintPrices;
            productPrintPrices.forEach(elementForPrintPrice => {
              const pushParams = {
                'NumberOfColors': elementForPrintPrice.NumberOfColors,
                'LogoTypeID': elementForPrintPrice.LogoTypeID,
                'Description': (elementForPrintPrice.LogoPositionDescription != undefined ? elementForPrintPrice.LogoPositionDescription + " - " : "") +
                               elementForPrintPrice.Description +
                               (elementForPrintPrice.LogoMaxAreaDescription != undefined ? " (" + elementForPrintPrice.LogoMaxAreaDescription + ")" : ""),
                'LogoPositionID': elementForPrintPrice.LogoPositionID,
                'LogoMaxAreaID': elementForPrintPrice.LogoMaxAreaID
              };

              if (this.printPrice.filter(pp => pp.Description === pushParams.Description).length === 0) {
                this.printPrice.push(pushParams);
              }

              // Is Fixed Logo(s)
              if (index === 0) {
                if (elementForPrintPrice.isFixedLogo) {
                  this.isFixedLogos = true;
                }
                if (this.isFixedLogos) {
                  this.brandingCost.push(pushParams);
                }
              }
            });
          });

          this.printPricesFiltered = this.printPrice;

          this.productInfo.ProductLogoPositions.forEach((element) => { if (element.isDefault) element.RecommendedText = " (" + this.RecommendedTextStr + ")" });
          this.productInfo.ProductLogoTypes.forEach((element) => { if (element.isDefault) element.RecommendedText = " (" + this.RecommendedTextStr + ")" });
          this.productInfo.ProductLogoMaxAreas.forEach((element) => { if (element.isDefault) element.RecommendedText = " (" + this.RecommendedTextStr + ")" });

          this.ProductLogoPositionsFiltered = this.productInfo.ProductLogoPositions;
          this.ProductLogoTypesFiltered = this.productInfo.ProductLogoTypes;
          this.ProductLogoMaxAreasFiltered = this.productInfo.ProductLogoMaxAreas;

          if (!this.isFixedLogos) {
            // Set filters to their default values (if there are default values)
            // if (this.productInfo.DefaultLogoTypeID != undefined) this.selectedLogoTypeID = this.productInfo.DefaultLogoTypeID; else this.selectedLogoTypeID = '';
            // if (this.productInfo.DefaultLogoPositionID != undefined) this.selectedLogoPositionID = this.productInfo.DefaultLogoPositionID; else this.selectedLogoPositionID = '';
            // if (this.productInfo.DefaultLogoMaxAreaID != undefined) this.selectedLogoMaxAreaID = this.productInfo.DefaultLogoMaxAreaID; else this.selectedLogoMaxAreaID = '';
            this.selectedLogoTypeID = '';
            this.selectedLogoPositionID = '';
            this.selectedLogoMaxAreaID = '';
            if (this.productInfo.DefaultLogoTypeID != undefined || this.productInfo.DefaultLogoPositionID != undefined || this.productInfo.DefaultLogoMaxAreaID != undefined) {
              this.applyLogoFilters(false);
            }
          }
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }

  applyLogoFilters(isClear) {
    if (!isClear) {
      this.brandingValue = '';
    }

    this.printPricesFiltered = this.printPrice;

    setTimeout(() => {
      if (this.selectedLogoTypeID != '')
        this.printPricesFiltered = this.printPricesFiltered.filter(ppp => ppp.LogoTypeID == this.selectedLogoTypeID);
      if (this.selectedLogoPositionID != '')
        this.printPricesFiltered = this.printPricesFiltered.filter(ppp => ppp.LogoPositionID == this.selectedLogoPositionID || ppp.LogoPositionID == undefined);
      if (this.selectedLogoMaxAreaID != '')
        this.printPricesFiltered = this.printPricesFiltered.filter(ppp => ppp.LogoMaxAreaID == this.selectedLogoMaxAreaID || ppp.LogoMaxAreaID == undefined);

      // Put filters back to original state (with all items in them)
      this.ProductLogoPositionsFiltered = [];
      this.productInfo.ProductLogoPositions.forEach((element) => { 
        this.ProductLogoPositionsFiltered.push(element);
      });

      this.ProductLogoTypesFiltered = [];
      this.productInfo.ProductLogoTypes.forEach((element) => { 
        this.ProductLogoTypesFiltered.push(element);
      });

      this.ProductLogoMaxAreasFiltered = [];
      this.productInfo.ProductLogoMaxAreas.forEach((element) => { 
        this.ProductLogoMaxAreasFiltered.push(element);
      });

      // Disable Logo Positions which would result in 0 print prices
      this.ProductLogoPositionsFiltered.forEach(element => {
        if (this.printPrice.filter(ppp => 
              (ppp.LogoPositionID == element.LogoPositionID || ppp.LogoPositionID == undefined) &&
              (this.selectedLogoTypeID == '' || ppp.LogoTypeID == this.selectedLogoTypeID) &&
              (this.selectedLogoMaxAreaID == '' || ppp.LogoMaxAreaID == this.selectedLogoMaxAreaID || ppp.LogoMaxAreaID == undefined)).length == 0) {
          element.isDisabled = true;
        }
        else {
          element.isDisabled = false;
        }
      });
      // Enabled Logo Positions should be shown before disabled ones (default Logo Position should be first among enabled or disabled ones)
      this.ProductLogoPositionsFiltered.sort(function(x, y) {return (x.isDisabled === y.isDisabled) ? (x.isDefault ? -2 : 0) : x.isDisabled ? 1 : -1;});

      // Disable Logo Types which would result in 0 print prices
      this.ProductLogoTypesFiltered.forEach(element => {
        if (this.printPrice.filter(ppp => 
              ppp.LogoTypeID == element.LogoTypeID &&
              (this.selectedLogoPositionID == '' || ppp.LogoPositionID == this.selectedLogoPositionID || ppp.LogoPositionID == undefined) &&
              (this.selectedLogoMaxAreaID == '' || ppp.LogoMaxAreaID == this.selectedLogoMaxAreaID || ppp.LogoMaxAreaID == undefined)).length == 0) {
          element.isDisabled = true;
        }
        else {
          element.isDisabled = false;
        }
      });
      // Enabled Logo Types should be shown before disabled ones (default Logo Type should be first among enabled or disabled ones)
      this.ProductLogoTypesFiltered.sort(function(x, y) {return (x.isDisabled === y.isDisabled) ? (x.isDefault ? -2 : 0) : x.isDisabled ? 1 : -1;});    

      // Disable Logo Areas which would result in 0 print prices
      this.ProductLogoMaxAreasFiltered.forEach(element => {
        if (this.printPrice.filter(ppp =>
              (ppp.LogoMaxAreaID == element.LogoMaxAreaID || ppp.LogoMaxAreaID == undefined) &&
              (this.selectedLogoPositionID == '' || ppp.LogoPositionID == this.selectedLogoPositionID || ppp.LogoPositionID == undefined) &&
              (this.selectedLogoTypeID == '' || ppp.LogoTypeID == this.selectedLogoTypeID)).length == 0) {
          element.isDisabled = true;
        }
        else {
          element.isDisabled = false;
        }
      });
      // Enabled Logo Areas should be shown before disabled ones
      this.ProductLogoMaxAreasFiltered.sort(function(x, y) {return (x.isDisabled === y.isDisabled) ? 0 : x.isDisabled ? 1 : -1;});

      // Remove Logo Positions which would result in 0 print prices
      // for (let index = this.ProductLogoPositionsFiltered.length - 1; index >= 0; index--) {
      //   if (this.printPrice.filter(ppp =>
      //         (ppp.LogoPositionID == this.ProductLogoPositionsFiltered[index].LogoPositionID || ppp.LogoPositionID == undefined) &&
      //         (this.selectedLogoTypeID == '' || ppp.LogoTypeID == this.selectedLogoTypeID) &&
      //         (this.selectedLogoMaxAreaID == '' || ppp.LogoMaxAreaID == this.selectedLogoMaxAreaID || ppp.LogoMaxAreaID == undefined)).length == 0)
      //   this.ProductLogoPositionsFiltered.splice(index, 1);
      // }

      // Remove Logo Types which would result in 0 print prices
      // for (let index = this.ProductLogoTypesFiltered.length - 1; index >= 0; index--) {
      //   if (this.printPrice.filter(ppp =>
      //         ppp.LogoTypeID == this.ProductLogoTypesFiltered[index].LogoTypeID &&
      //         (this.selectedLogoPositionID == '' || ppp.LogoPositionID == this.selectedLogoPositionID || ppp.LogoPositionID == undefined) &&
      //         (this.selectedLogoMaxAreaID == '' || ppp.LogoMaxAreaID == this.selectedLogoMaxAreaID || ppp.LogoMaxAreaID == undefined)).length == 0)
      //   this.ProductLogoTypesFiltered.splice(index, 1);
      // }

      // Remove Logo Areas which would result in 0 print prices
      // for (let index = this.ProductLogoMaxAreasFiltered.length - 1; index >= 0; index--) {
      //   if (this.printPrice.filter(ppp =>
      //         (ppp.LogoMaxAreaID == this.ProductLogoMaxAreasFiltered[index].LogoMaxAreaID || ppp.LogoMaxAreaID == undefined) &&
      //         (this.selectedLogoPositionID == '' || ppp.LogoPositionID == this.selectedLogoPositionID || ppp.LogoPositionID == undefined) &&
      //         (this.selectedLogoTypeID == '' || ppp.LogoTypeID == this.selectedLogoTypeID)).length == 0)
      //   this.ProductLogoMaxAreasFiltered.splice(index, 1);
      // }      
    }, 1);
  }

  // getUniqueDescription() {
  //   Array.from(new Set(this.printPrice.map((itemInArray) => itemInArray.Description)))

  //   const curr = this.printPrice.map(data => data.Description); // Unique print prices
  //   this.printPrice = curr.filter((x, i, a) => x && a.indexOf(x) === i);
  // }

  hideFolderModal() {
    this.brandingCost = [];
    this.brandingValue = '';
    this.showStatus = false;
    this.pdfModal.hide();
  }

  getPrintPrice() {
    if (this.brandingValue !== '')
    {
      // Check if logo on that logo position is already added. If it is, don't allow adding another logo on same position.
      // if (this.brandingValue.LogoPositionID != undefined) {
      //   if (this.brandingCost.filter(data => data.LogoPositionID === this.brandingValue.LogoPositionID).length > 0) {
      //     const errorValue = this.translate.get('SHOPPING_CART.DOUBLE_LOGO_SAME_POSITION');
      //     this.toastService.error(errorValue['value']);
      //     return;
      //   }
      // }

      const set = {
        'Description': (this.brandingValue.LogoPositionDescription != undefined ? this.brandingValue.LogoPositionDescription + " - " : "") +
                       this.brandingValue.Description +
                       (this.brandingValue.LogoMaxAreaDescription != undefined ? " (" + this.brandingValue.LogoMaxAreaDescription + ")" : ""),
        'LogoTypeID': this.brandingValue.LogoTypeID,
        'NumberOfColors': this.brandingValue.NumberOfColors,
        'LogoPositionID': this.brandingValue.LogoPositionID,
        'LogoMaxAreaID': this.brandingValue.LogoMaxAreaID
      }
      this.brandingCost.push(set);

      this.selectedLogoTypeID = '';
      this.selectedLogoPositionID = '';
      this.selectedLogoMaxAreaID = '';
      this.applyLogoFilters(false);
      //this.brandingValue = '';

      //this.brandingCost = this.brandingCost.filter((value, index, array) => !array.filter((v, i) => JSON.stringify(value) == JSON.stringify(v) && i < index).length);
    }
  }

  removeBrand(_obj , index) {
    if (index === 0) {
      this.brandingCost.splice(0, 1)
    } else {
      this.brandingCost.splice(index, 1)
    }
  }

  generatePdf() {
    this.additionalCostsList = [];
    for (let i = 0; i < this.productAdditionalCosts.length; i++) {
      this.singleCost = {};
      if (this.productAdditionalCosts[i].isChecked === true) {
        this.singleCost['AdditionalCostID'] = this.productAdditionalCosts[i].ProductAdditionalCostID;
        if (this.productAdditionalCosts[i].ProductAdditionalCostOptions !== undefined) {
          for (let j = 0; j < this.productAdditionalCosts[i].ProductAdditionalCostOptions.length; j++) {
            if (this.productAdditionalCosts[i].AdditionalCostOptionID > 0) {
              this.singleCost['AdditionalCostOptionID'] =
                this.productAdditionalCosts[i].AdditionalCostOptionID;
            }
          }
        }
      }
      if (this.singleCost['AdditionalCostOptionID'] || this.singleCost['AdditionalCostID']) {
        this.additionalCostsList.push(this.singleCost);
      }

    }

    try {
      if (this.offerForm.value.offerQuantity1 === '0' || this.offerForm.value.offerQuantity2 === '0' ||
        this.offerForm.value.offerQuantity3 === '0') {
      //  this.translatedMessage = (this.translate.get('PRODUCT_DETAIL.QUANTITY_NOT_VALID'));
        this.translate.get('PRODUCT_DETAIL.QUANTITY_NOT_VALID').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
      } else {
        this.loaderShow = true;
        const webShopSettings = this.globalService.webShopSettings();
        const userSettings = this.globalService.getUserSettings();
        this.quantities = [{
          // tslint:disable-next-line:radix
          'QuantityAmount': parseInt(this.offerForm.value.offerQuantity1),
        }]
        if (this.offerForm.value.offerQuantity2 !== '') {
          this.quantities = [{
            // tslint:disable-next-line:radix
            'QuantityAmount': parseInt(this.offerForm.value.offerQuantity1),
          },
          {
            // tslint:disable-next-line:radix
            'QuantityAmount': parseInt(this.offerForm.value.offerQuantity2),
          }]
        }
        if (this.offerForm.value.offerQuantity3 !== '') {
          this.quantities = [{
            // tslint:disable-next-line:radix
            'QuantityAmount': parseInt(this.offerForm.value.offerQuantity1),
          },
          {
            // tslint:disable-next-line:radix
            'QuantityAmount': parseInt(this.offerForm.value.offerQuantity2),
          },
          {
            // tslint:disable-next-line:radix
            'QuantityAmount': parseInt(this.offerForm.value.offerQuantity3),
          }]
        }

        const userData = this.globalService.getLoggedInData();
        if (this.brandingCost.length !== 0 || this.brandingValue !== '') {
          const brandArray = [];
          if (this.brandingCost.length !== 0) {
            this.brandingCost.forEach(element => {
              const send = {
                'LogoTypeID': element.LogoTypeID,
                'NumberofColors': element.NumberOfColors,
                'LogoPositionID': element.LogoPositionID,
                'LogoMaxAreaID': element.LogoMaxAreaID,
              }
              brandArray.push(send);
            });
          } else
          {
            // If user just selected branding mathod, but did't add it to the list and there is nothing in the list we will consider that he wanted to include this branding method
            // tslint:disable-next-line:max-line-length
            brandArray.push({ 'LogoTypeID': this.brandingValue.LogoTypeID, 'NumberofColors': this.brandingValue.NumberOfColors, 'LogoPositionID': this.brandingValue.LogoPositionID, 'LogoMaxAreaID': this.brandingValue.LogoMaxAreaID });
          }
          this.offerParams = {
            'Quantities': this.quantities,
            'ProductID': this.productId,
            'CustomerID': webShopSettings.CustomerID,
            'CurrencyID': userSettings.CurrencyID,
            'DeliveryCountry': this.offerForm.value.country,
            'Logos': brandArray,
            'AdditionalCosts': this.additionalCostsList
          }
          if (userData !== null && userData !== undefined) {
            if (userData !== '') {
              this.offerParams.UserID = userData.UserID;
            }
          }
        } else {
          this.offerParams = {
            'Quantities': this.quantities,
            'ProductID': this.productId,
            'CustomerID': webShopSettings.CustomerID,
            'CurrencyID': userSettings.CurrencyID,
            'DeliveryCountry': this.offerForm.value.country,
            'AdditionalCosts': this.additionalCostsList
          }
          if (userData !== null && userData !== undefined) {
            if (userData !== '') {
              this.offerParams.UserID = userData.UserID;
            }
          }
        }
        Object.keys(this.offerParams).forEach((key) => (this.offerParams[key] === '') && (this.offerParams[key] === null)
          && (this.offerParams[key] === undefined) && delete this.offerParams[key]);

        this._httpWebshopService.getOfferGeneratorData(this.offerParams).subscribe(
          data => {
            this.ngOnInit();

            var file = new Blob([data], {type: 'application/pdf'});

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(file, 'offer.pdf');
            } else {
              // const offerPdf = 'data:application/pdf;base64,' + data;

              this.htmlTag = document.getElementById('offerdownload');
              var fileURL = window.URL.createObjectURL(file);
              this.htmlTag.href = fileURL;
              this.htmlTag.click();
            }
            this.loaderShow = false;
            this.brandingCost = [];
            this.brandingValue = '';
            this.additionalCost = [];
            this.additionalCostPrice = [];
            this.pdfModal.hide();
          },
          error => {
            // this.toastService.error(error._body);
            this.toastr.error('Error: ' + error.error.Message);
            this.loaderShow = false;
          });
      }
    } catch (error) {
      this.toastr.error(error);
      this.loaderShow = false;
    }
  }

  // for Decimal you can use this as
  onlyDecimalNumberKey(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // tslint:disable-next-line:curly
    if ((charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  minQuantityCheck(value, status) {
    if (value !== '') {
      if (this.productInfo.MinimumOrder <= value) {

      } else {
        if (status === 'q1') {
          this.offerForm.patchValue({
            offerQuantity1: this.productInfo.MinimumOrder,
          })
        } else if (status === 'q2') {
          this.offerForm.patchValue({
            offerQuantity2: this.productInfo.MinimumOrder,
          })
        } else if (status === 'q3') {
          this.offerForm.patchValue({
            offerQuantity3: this.productInfo.MinimumOrder,
          })
        } else {
          this.offerForm.patchValue({
            offerQuantity1: this.productInfo.MinimumOrder,
          })
        }
      }
    }
  }
}
