import { Component, OnInit, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { environment } from 'environments/environment.prod';
import { Injectable } from '@angular/core';

@Component({
  selector: 'shop-app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
@Injectable()
export class PaginationComponent implements OnInit, OnChanges {
  public totalPages;
  public optionsSelect;
  public pageNumbers: any = [];
  public pageSize;
  public itemFrom: any = 0;
  public itemTo: any = 0;
  public itemsPerPage: any = [];
  public pages: any = [];
  public initialized: Boolean = false;
  public status: Boolean = false;
  public sessionPageIndex: any;
  public sessionPageSize: any;
  public tmpPages: any = [];
  @Input() currentPage: any;
  @Input() totalCount: any;
  @Input() perPageCount: any;
  @Output() pageEvent = new EventEmitter<any>();
  @Output() perPageCountChange = new EventEmitter<any>();
  @Output() currentPageChange = new EventEmitter<any>();
  constructor() { }

  update(val: string) {
    this.perPageCount = val;
    this.perPageCountChange.emit(this.perPageCount);
  }

  updateCurrentpage(val: string) {
    this.currentPage = val;
    this.currentPageChange.emit(this.currentPage);
  }

  ngOnInit() {
    this.initialized = true;
    this.pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this.totalCount / this.perPageCount); i++) {
      const page = {
        'label': i,
        'value': i
      }
      this.pageNumbers.push(page);
    }
    this.optionsSelect = environment.ENVIRONMENT.itemsPerPage;
    this.itemsPerPage.push(this.optionsSelect[0].value);
    this.perPageCount = this.optionsSelect[0].value;
    this.sessionPageSize = JSON.parse(sessionStorage.getItem('pageSize'));
    if (this.sessionPageSize !== null) {
      this.perPageCount = this.sessionPageSize;
    } else {
      this.perPageCount = this.optionsSelect[0].value;
    }
    this.sessionPageIndex = JSON.parse(sessionStorage.getItem('pageIndex'));
    if (this.sessionPageIndex !== null) {
      this.currentPage = this.sessionPageIndex;
    }
    this.tmpPages = [];
    this.status = false;
    // To Hide Extra Pages in Dropdown (per page dropdown display based on record)
    for (let i = 0; i < this.optionsSelect.length; i++) {
      if ((Number(this.optionsSelect[i].value) >= Number(this.totalCount) ||
        Number(this.optionsSelect[i].value) <= Number(this.totalCount)) && !this.status) {
        if (Number(this.optionsSelect[i].value) >= Number(this.totalCount)) {
          this.status = true;
        }
        this.tmpPages[i] = {
          'label': this.optionsSelect[i].label,
          'value': this.optionsSelect[i].value
        }
      }
    }
    if (Number(this.totalCount) > 0 && this.tmpPages.length === 0) {
      this.tmpPages[0] = {
        'label': this.optionsSelect[0].label,
        'value': this.optionsSelect[0].value
      }
    }
  }

  pagination(page) {
    sessionStorage.setItem('pageIndex', JSON.stringify(page));
    this.updateCurrentpage(page);
    this.currentPage = page;
    this.pages = [page];
    const pages = {
      'pageIndex': page,
      'pageSize': this.perPageCount
    }
    this.pageEvent.emit(pages);
  }
  ngOnChanges() {
    /** Re intialize Page NUmbers in Drop Down */
    this.pageNumbers = [];
    for (let i = 1; i <= Math.ceil(this.totalCount / this.perPageCount); i++) {
      const page = {
        'label': i,
        'value': i
      }
      this.pageNumbers.push(page);
    }
  }
  changePageSize(pageSize) {
    sessionStorage.setItem('pageSize', JSON.stringify(pageSize));
    this.update(pageSize);
    this.perPageCount = pageSize;
    this.totalPages = Array.apply(null, Array((Math.ceil(this.totalCount / this.perPageCount))).fill(null)
      .map(function (val, i) { val = val; return i + 1 }));
    this.initialized = false;
    this.ngOnChanges()
    this.sessionPageIndex = JSON.parse(sessionStorage.getItem('pageIndex'));
    this.pagination(1);
  }
}
