import { Component, OnInit } from '@angular/core';
import { OrdersService } from 'app/linknet-common/restful-services/restful.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { Router } from '@angular/router';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-order-production',
  templateUrl: './order-production.component.html',
  styleUrls: ['./order-production.component.scss']
})
export class OrderProductionComponent implements OnInit {
  public orderProductionList = [];
  public orderProductionListNoRecord: Boolean = false;
  public loaderShow: any;
  public orderProductionTitle: any;
  public translatedMessage: any;
  public printInvoiceUrl: any;
  public currencyCode: any;
  public changeDeliveryDateText: Boolean = false;  // for display text as delivery date / Earliest disp. date in HTML Page
  public wsaStatus: Boolean = false;
  constructor(private _httpOrdersService: OrdersService,
    private toastService: ToastService, private router: Router, private globalService: GlobalService,
    public titleService: Title, private translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit() {
    try {
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (webshopDetail.ChangeDeliveryDateText) {
        this.changeDeliveryDateText = true;
      }
      if (Number(webshopDetail.CustomerID) === 45) {
        this.wsaStatus = true;
      }
      
      this.orderProductionTitle = this.translate.get('HEADING.ORDER_PRODUCTION').subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
      this.getOrderProduction();
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getOrderProduction() {
    try {
      this.loaderShow = true;
      const params = {
        'ongoing': true,
        'suborders': true,
      }
      this._httpOrdersService.getOrders(params).subscribe(
        data => {
          this.orderProductionList = data['ItemsCollection'];
          this.loaderShow = false;
          if (this.orderProductionList.length === 0) {
            this.orderProductionListNoRecord = true;
          }
        }, error => {
          // this.toastService.error(JSON.parse(error._body).Message);
          this.toastr.error(error.error.Message);
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }

  // tslint:disable-next-line:variable-name
  orderDetail(order, param, currencyCode, VATPercentage) {
    try {
      this.globalService.overwriteSession('orderDetail', 'OrderFullDetails', order);
      this.globalService.overwriteSession('orderDetail', 'SubOrder', param);
      this.globalService.overwriteSession('orderDetail', 'OrderedCurrencyCode', currencyCode);
      this.globalService.overwriteSession('orderDetail', 'VATPercentage', VATPercentage);
      this.router.navigate(['/orderdetail', order.OrderID, param.OrderItemID, false, false], { replaceUrl: false });
    } catch (error) {
      this.toastr.error('Error: ' + error);
    }
  }
  printInvoice(orderId) {
    try {
      const userSettings = this.globalService.getUserSettings();
      this.printInvoiceUrl = document.getElementById('invoicedownload');
      this.printInvoiceUrl.href = environment.ENVIRONMENT.baseURL + userSettings.LanguageCode
        + '/invoices/pdf/' + orderId;
      this.printInvoiceUrl.click();
    } catch (error) {
      this.toastr.error(error);
    }
  }
}
