import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { GlobalService } from "../../linknet-common/globals/global.service";
import { FormBuilder, NgSelectOption, Validators } from "@angular/forms";
import { ValidationService } from "../../linknet-common/validators";
import { CampaignService, WebshopService, ProductService, VoucherService } from "../../linknet-common/restful-services/restful.service";
import { ToastService } from "../../typescripts/pro";
import { CookieService } from "ngx-cookie-service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";
import { ToastrService } from "ngx-toastr";
import { fromEvent, Observable, Subscription } from "rxjs";
import {NgbAccordion, NgbAccordionConfig} from '@ng-bootstrap/ng-bootstrap';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgSelectComponent } from "@ng-select/ng-select";
import { finalize } from "rxjs/operators";
import { HeaderTwoComponent } from "../header-two/header-two.component";
import { log } from "console";


@Component({
  selector: "shop-app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
  exportAs: "child",
})
export class CheckoutComponent implements OnInit {

  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription;
  custommessage = [

    { Name: "Standard"},
    { Name: "Customized" },
    { Name: "Greeting Card"}

  ]
  allowCheck: boolean;
  colorIndex = -1;
  itemsList: any;
  itemsTempList: any;
  goCheckout: Boolean;
  tabCompletedCount: number;
  tabCount: number;
  translatedMessage: any;
  paymentTypeID: any;
  cartId: any;
  orderSuccess: boolean = false;
  @ViewChild("termsAndCondition") public termsAndCondition;
  @ViewChild("staticTabs") public staticTabs;
  @ViewChild("DeleteModal") public deleteModal;
  @ViewChild("DeleteModals") public deleteModals;
  @ViewChild("ShippingTypeModal") public shippingModal;
  @ViewChild('acc, charges') accordionComponent: NgbAccordion;
  @ViewChild("DeleteModalOneItem") public deleteModalOneItem;
  enableimageUpload:boolean =  false;
  enableimageUpload2:boolean =  false;
  enableimageUpload1:boolean =  false;
  @ViewChild("deliveryCountryElement") public deliveryCountryElement;
  public isOrderSuccessPage: any = true;
  public showSuccess;
  public comment;
  public createFolder;
  public cartParams: any;
  public shoppingCartList: any = [];
  public shoppingCartItemParams: any;
  public dealdiscountParams: any;
  // public addPurchaseorderNo: any;
  public logIndata: any;
  public isGuestLogin: Boolean = false;
  public showUserDetailPage: Boolean = false;
  public showChangeAddressButton: Boolean = true;
  public showSaveButton: Boolean = false;
  public showPayment = false;
  public checkoutForm: any;
  public checkout2Form: any;
  public optionsSelect: Array<any>;
  public countries: any;
  public userLoginData: any;
  public disabledFields: Boolean = false;
  public isSearchCountryActive: boolean = false;  
  public orderData: any = {};
  public agreeTermsConditions: boolean = false;
  public userDataPolicy: Boolean = false;
  public webShopSettings = this.globalService.webShopSettings();
  // public paymentTypes = this.webShopSettings.PaymentTypes;
  public paymentTypes: any;
  public paymentTypesData: any;
  public termsAndConditionText: any;
  public getShoppingCartIDList: any;
  public orderSuccessData: any;
  public confirmationTab = false;
  public CID: any;
  public orderId: any;
  public totalQuantity = 0;
  public printInvoiceUrl: any;
  public loaderShow: Boolean = false;
  public getUserCurrentBudget: any;
  public grandTotalAmount: any;
  public grandTotalAmountInParentCurency: any;
  public invoicePaymentTypeStatus: Boolean = true;
  public invoicePaymentAdvanceTypeStatus: Boolean = true;
  public isUnAuthorized: Boolean = false;
  public isParentCustomer: boolean = false;
  public isVoucherKart: boolean = false;
  public isChildShop: boolean = false;
  public pointsSelectError: Boolean = false;
  public isLowIE: any;
  public emailOTP: any = "";
  loggedInData: any;
  showInvoiceButton: boolean = false;
  arrayList: any;
  giftRate:any;
  optionsSelects: any[];
  campaignSelect: any[];
  deliveryType: any[];
  deliveryTypeChild: any[];
  deliveryCustom: any[];
  pointsArray = [];
  packingArray = [];
  initialValue: any;
  initialDelivery: any;
  editData: any;
  usersDeliveryAddressID: any = null;
  allowSaveMoreAddress: Boolean = false;
  preSelectPaymentOptionStatus: Boolean = false;
  valuesForm: any;
  public changeDeliveryDateText: Boolean = false; // for display text as delivery date / Earliest disp. date in HTML Page
  public isSocialMediaSharingEnabled: Boolean = false; // for display Social Icons in product Card
  public postalCodeErrorCount = 0;
  public wsaStatus: Boolean = false;
  public showPickupWarehouseButton: Boolean = true;
  public ispProductList = [];
  public nonispProductList = [];
  public selectedISPProducts = [];
  requireUsersApproval: Boolean = false;
  public blockShop: Boolean = false;
  public duplicatenonispProductList = [];
  public otpSuccess: boolean = false;
  webshopData: any;
  public campaignList: any;
  public isMultipleAddress = true;
  public imageUrlList = [];
  public customOptions = {allowedContentTypes: ['text/csv']};
  enableCustomInput: boolean;
  deliveryPackaging = [{ Name: "Standard"}, { Name: "Special"}, { Name: "Customized" }]
  enableTemplate: boolean;
  @Input() imageNameList: Array<any> = [];
  public fileNameCSV: any = '';
  public csvEilePath: any;
  public csvFileName: string = '';
  public FileName: any = '';
  public customImgOptions = { allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', "application/pdf",] };
  CustomImageFullFilePath: any;

  paymentStatus: boolean = false; 
  selectedOption: string;

  CustomMessage: any;
  Custommessage: any;
  ShoppingCartID: any;

  // public itemsList: any;
  public itemsListA: any;
  public priceList: any;
  balancePoints: any = 0;
  tempPoints: any = 0;
  public cartCount;
  avlPoints: any;
  totalPrice: any = 0;
  public toggleClass = [];
  balanceRewardpoints: any = 0;
  disableCheckout: Boolean = false;
  public joinOrderCounts: Number = 0;
  walletData: any;
  emptyItem: boolean = true;
  dealdiscount:any;
  totaldealdiscount:any;
  DealCode:any;
  public totalhide: Boolean = false;
  public dealdiscountvalue: Boolean = false;  
  gtotal:any;
  public totalvalue: Boolean = false; 
  public dealsuccess: Boolean = false; 
  public dealnotvalid: Boolean = false; 
  public dealerror: Boolean = false; 
  public dealnone: Boolean = false; 
  dealnonemsg: string = 'Please enter Deal Code.';
  finaldiscount:any;
  public finalval: Boolean = false; 
  filepattern:any;
  customerID: any;
  delivertRequirement: boolean;
  filter: boolean = false;
  preorder: string;
  childShopPayment: boolean = false;
  showWallets: boolean;
  wallettopup: any;
  isMobile: boolean = false;
  activeId: string = 'panel-one';
  activeId2: string = 'add-charges';
  activechargeId: string = 'add-charges'
  isSmartworks: boolean = false;
  packagingText: string = 'A standard tamperproof cover for physical gifts and our standard template for digital gifts';
  giftText: string = 'A standard Best Wishes gift tag';
  deliveryText: string = 'All your gifts will be delivered here';
  isPenna: boolean;
  greetingCard: boolean;
  customOwlOptions: OwlOptions = {
    loop: false,
    margin: 10,
    autoplay:false,
    dots: false,
    navSpeed: 700,  
    slideBy: 5,
    lazyLoad: true,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 2,
        slideBy: 2
      },
      480: {
        items: 5,
        slideBy: 5
      },
      940: {
        items: 5,
        slideBy: 5
      }
    },
    nav: true
}
themeList: Object;
selectedTheme: any;
packingPrice:any =0;
selected: any = 0;
giftImg: string = '../../../../assets/theme.jpg';
giftRecieverName = "Reciever";
giftMessage: string = "You have received a Gift Vouhcer";
isCorpkonnex: boolean = false;
@ViewChild('ngselect') ngselect :NgSelectComponent;
@ViewChild('ngselected') ngselected :NgSelectComponent;
campaignID:any;
@ViewChild('refVar') public refVar:HeaderTwoComponent
isZydus:boolean = false;
isEmail:any;
isSaas: boolean = false;
subscriptionPlan = false;
subscriptionPlan1 = false;
currencyCode:string = "";
constructor(
    public _httpWebshop: WebshopService,
    public _httpWebshopService: WebshopService,
    private campaignService: CampaignService,
    public cookieService: CookieService,
    public globalService: GlobalService,
    public toastService: ToastService,
    public formBuilder: FormBuilder,
    public translate: TranslateService,
    public router: Router,
    public commonService: CommonServicesService,
    private toastr: ToastrService,
    public _httpProductService: ProductService,
    config: NgbAccordionConfig,
    private httpVoucher: VoucherService
  ) {

    const loggedUserData = JSON.parse(sessionStorage.getItem("loggedInData"));
    if(loggedUserData && loggedUserData.ISSaas === "Y"){
      this.isSaas = true;
    }else{
      this.isSaas = false;
    }
    if(loggedUserData && loggedUserData.ISSaas === "Y" && (loggedUserData.SubScriptionId === 1 || loggedUserData.SubScriptionId === "1" || loggedUserData.SubScriptionId === 2 || loggedUserData.SubScriptionId === "2")){
      this.subscriptionPlan = true;
    }else{
      this.subscriptionPlan = false;
    }
    if(loggedUserData && loggedUserData.ISSaas === "Y" && (loggedUserData.SubScriptionId === 1 || loggedUserData.SubScriptionId === "1")){
      this.subscriptionPlan1 = true;
    }
    /*this._httpWebshopService.getWebmshopDetail().subscribe((data: any[]) => {
      sessionStorage.setItem("webshopDetail", JSON.stringify(data));
    });*/
    if (window.innerWidth > 768) {
      this.isMobile = false;
    }else{
     this.isMobile = true;
     config.closeOthers = true;
    }
    this.usersDeliveryAddressID = null;
    this.logIndata = this.globalService.getLoggedInData();
    console.log("delivery",this.initialDelivery)

    if (this.logIndata !== null) {
      this.checkoutForm = this.formBuilder.group({
        DeliveryCompanyName: [""],
        DeliveryContactPerson: ["", [Validators.required]],
        DeliveryPhoneNumber: ["", [Validators.required, Validators.maxLength(25)]],
        DeliveryEmail: ["", [Validators.required, ValidationService.emailValidator]],
        DeliveryAddress: ["", [Validators.required]],
        DeliveryAddress2: [""],
        DeliveryAddress3: [""],
        DeliveryCity: ["", [Validators.required]],
        DeliveryPostalCode: ["", [Validators.required]],
        DeliveryCountry: ["", [Validators.required]],
        DeliveryInstructions: [""],
        PurchaseOrderNumber: [""],
        VAT: [""],
      });
      this.checkout2Form = this.formBuilder.group({
        CustomMessage: [""],
        DeliveryAddressType: ["", [Validators.required]],
        GiftTag: [""],
        PackagingType: [""]
      })
      this.loggedInData = JSON.parse(sessionStorage.getItem("loggedInData"));
      if (this.loggedInData !== null) {
       
      this.customerID = this.loggedInData.UserID;
        this._httpWebshopService.getUserData(this.loggedInData.UserID).subscribe(
          (data: any[]) => {
            this.logIndata = data;
            sessionStorage.setItem("loggedInData", JSON.stringify(data));
            this.checkoutForm.patchValue(
              {
                DeliveryPhoneNumber: this.logIndata.DeliveryPhoneNumber.substring(0, 25),
                DeliveryCompanyName: this.logIndata.DeliveryCompanyName,
                DeliveryEmail: this.logIndata.DeliveryEmail,
                DeliveryCountry: this.logIndata.DeliveryCountry,
                DeliveryAddress: this.logIndata.DeliveryAddress,
                DeliveryAddress2: this.logIndata.DeliveryAddress2,
                DeliveryAddress3: this.logIndata.DeliveryAddress3,
                DeliveryCity: this.logIndata.DeliveryCity,
                DeliveryPostalCode: this.logIndata.DeliveryPostalCode,
                DeliveryContactPerson: this.logIndata.DeliveryContactPerson,
                DeliveryInstructions: this.logIndata.DeliveryInstructions,
                PurchaseOrderNumber: null,
                VAT: this.logIndata.VAT,
              },
              { onlySelf: false, emitEvent: false }
            );
            setTimeout(() => {
              this.checkout2Form.patchValue(
                {
                  CustomMessage: "",
                  DeliveryAddressType: (!this.isParentCustomer) ? 'Individual Delivery Address' : (this.isParentCustomer && this.logIndata.DeliveryAddressType) ? this.logIndata.DeliveryAddressType : 'Single Office Address',
                  GiftTag: "Standard",
                  PackagingType:"Standard"
                }
              )}, 1000);
          
            
            if (data["DeliveryAddresses"] && data["DeliveryAddresses"].length > 0) {
              this.optionsSelects = [];
              data["DeliveryAddresses"].forEach((element) => {
                if (element.isAdditionalAddress && element.isDefaultAddress) {
                  this.usersDeliveryAddressID = element.UsersDeliveryAddressID;
                }
                const value = {
                  label: element.DeliveryAddress,
                  value: element.isDefaultAddress,
                  DeliveryEmail: element.DeliveryEmail,
                  DeliveryCity: element.DeliveryCity,
                  DeliveryPhoneNumber: element.DeliveryPhoneNumber,
                  DeliveryCompanyName: element.DeliveryCompanyName,
                  DeliveryCountry: element.DeliveryCountry,
                  DeliveryAddressType: element.DeliveryAddressType,
                  GiftTag: "Standard",
                  PackagingType: "Standard",
                  Custommessage: element.CustomMessage,
                  DeliveryAddress: element.DeliveryAddress,
                  DeliveryAddress2: element.DeliveryAddress2,
                  DeliveryAddress3: element.DeliveryAddress3,
                  DeliveryPostalCode: element.DeliveryPostalCode,
                  DeliveryContactPerson: element.DeliveryContactPerson,
                  isAdditionalAddress: element.isAdditionalAddress,
                  UsersDeliveryAddressID: element.UsersDeliveryAddressID,
                  DeliveryInstructions: element.DeliveryInstructions,
                };
                if (element.isDefaultAddress === true) {
                  this.checkoutForm.patchValue(
                    {
                      DeliveryPhoneNumber: element.DeliveryPhoneNumber,
                      DeliveryCompanyName: element.DeliveryCompanyName,
                      DeliveryEmail: element.DeliveryEmail,
                      DeliveryCountry: element.DeliveryCountry,
                      DeliveryAddress: element.DeliveryAddress,
                      DeliveryAddress2: element.DeliveryAddress2,
                      DeliveryAddress3: element.DeliveryAddress3,
                      DeliveryCity: element.DeliveryCity,
                      DeliveryPostalCode: element.DeliveryPostalCode,
                      DeliveryContactPerson: element.DeliveryContactPerson,
                      PurchaseOrderNumber: null,
                      VAT: this.logIndata.VAT,
                      DeliveryInstructions: element.DeliveryInstructions,
                      UsersDeliveryAddressID: element.UsersDeliveryAddressID,
                    },
                    { onlySelf: false, emitEvent: false }
                  );
                  this.checkout2Form.patchValue({
                    CustomMessage: element.CustomMessage,
                    DeliveryAddressType:  element.DeliveryAddressType,
                    GiftTag: "Standard",
                    PackagingType:  "Standard",
                  })
                }
                this.optionsSelects.push(value);
              });
              this.initialValue = true;
            }
          },
          (error) => {
            // this.toastService.error(JSON.parse(error._body).Message);
            if (error.error.Message) {
              this.toastr.error(error.error.Message);
            }
          }
        );
      }

      this.showUserDetailPage = true;
      this.disabledFields = true;
    } else {
      this.checkoutForm = this.formBuilder.group({
        UserName: ["", [Validators.required]],
        DeliveryCompanyName: [""],
        DeliveryContactPerson: ["", [Validators.required]],
        DeliveryPhoneNumber: ["", [Validators.required]],
        DeliveryEmail: ["", [Validators.required, ValidationService.emailValidator]],
        DeliveryAddress: ["", [Validators.required]],
        DeliveryAddress2: [""],
        DeliveryAddress3: [""],
        DeliveryCity: ["", [Validators.required]],
        DeliveryPostalCode: ["", [Validators.required]],
        PurchaseOrderNumber: [""],
        DeliveryCountry: ["", [Validators.required]],
        VAT: [""],
        DeliveryInstructions: [""],
      });
      this.checkout2Form = this.formBuilder.group({
        CustomMessage: [""],
        DeliveryAddressType: ["", [Validators.required]],
        GiftTag: [""],
        PackagingType: [""]
      })
      this.isGuestLogin = true;
    }
    this.getThemes()
  }

  ngOnInit() {
    if(sessionStorage.getItem("CurrencyCode")){
      this.currencyCode = sessionStorage.getItem("CurrencyCode");
    }
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( _evt => {
        if (window.innerWidth > 768) {
           this.isMobile = false;
        }else{
          this.isMobile = true;
        }
    })
    
      if (sessionStorage.getItem("webshopDetail") !== undefined && sessionStorage.getItem("webshopDetail") !== null) {
        this.requireUsersApproval = JSON.parse(sessionStorage.getItem("webshopDetail")).RequireUsersApproval;
      }
      /** Code to check Whether Loggedin user is Active/InActive */
      const loggedInData = this.globalService.getLoggedInData();
      if (loggedInData !== null) {
        this._httpWebshopService.getUserActiveStatus({ UserID: loggedInData.UserID }).subscribe(
          (_data) => { },
          (error) => {
            if (error.status === 500) {
              try {
                // Remove saved data from sessionStorage
                sessionStorage.removeItem("loggedInData");
                sessionStorage.removeItem("categoryList");
                sessionStorage.removeItem("colorsList");
                sessionStorage.removeItem("categoryOptionList");
                sessionStorage.removeItem("checkoutProductsList");
                // based on filter section regarding product tags.
                if (sessionStorage.getItem("productTags") !== null && sessionStorage.getItem("productTags") !== undefined && sessionStorage.getItem("productTags") !== "") {
                  sessionStorage.removeItem("productTags");
                }
                // Remove all saved data from sessionStorage
                const webshopDetail = sessionStorage.getItem("webshopDetail");
                sessionStorage.setItem("webshopDetail", webshopDetail);
                const data = JSON.parse(sessionStorage.getItem("webshopDetail"));
                this.loggedInData = {};
                this.globalService.loggedin(null);
                // this.router.navigate([''], { preserveQueryParams: false, replaceUrl: true });
                this.globalService.overwriteSession("userSettings", "LanguageCode", data.DefaultLanguageCode);
                this.globalService.overwriteSession("userSettings", "LanguageID", data.DefaultLanguageID);
                this.globalService.overwriteSession("userSettings", "CurrencyCode", data.DefaultCurrencyCode);
                this.globalService.overwriteSession("userSettings", "CurrencyID", data.DefaultCurrencyID);
                //  location.reload();
                setTimeout(() => {
                  sessionStorage.removeItem("webshopDetail");
                  const storageData = {
                    CustomerID: data.CustomerID,
                  };
                  sessionStorage.setItem("webshopDetail", JSON.stringify(storageData));
                  sessionStorage.removeItem("themeReApplyStatus");
                }, 100);
                const postParams = {
                  userdeactive: true,
                };
                setTimeout(() => {
                  this.router.navigate(["/"], { queryParams: postParams, replaceUrl: false });
                  setTimeout(() => {
                    location.reload();
                  }, 100);
                }, 100);
              } catch (error) {
                this.toastr.error(error);
              }
            } else {
              this.toastr.error(error.error.Message);
            }
          }
        );

        this.getDeliverytype();
      }

      const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
      const campaign = JSON.parse(sessionStorage.getItem("campaign"));
      if(campaign){
        this.campaignID = campaign.CampaignId;
      }
      this.webshopData = webshopDetail;
      if (this.webshopData.ParentCustomerDomainName === this.webshopData.DomainName) {
        this.isParentCustomer = true;
        this.preorder = 'order';
      } else {
        this.isParentCustomer = false;
        this.isChildShop = true;
        // this.getWallet();
        if(this.webshopData.DomainName === "voucherkart"){
          this.isVoucherKart = true;
          this.router.navigate(['/'])
        }else{
          // this.preorder = 'ChildShop';
          
        }
      }

      if(this.webshopData.DomainName === "sw" || this.webshopData.DomainName === "smartworks"){
        this.isSmartworks = true;
      }

      if(this.webshopData.DomainName === "pc"){
        this.isPenna = true;
      }

      if(this.webshopData.DomainName === "corpkonnex" || this.webshopData.DomainName === "ckonnex"){
        this.isCorpkonnex = true;
      }

      if(this.webshopData.DomainName === "zyduswellness"){
        this.isZydus = true;
      }

      if (Number(webshopDetail.CustomerID) === 45) {
        this.wsaStatus = true;
      }
      
      if (webshopDetail.ChangeDeliveryDateText) {
        this.changeDeliveryDateText = true;
      }


      /** new-code => for display Social Icons in product Card */
      let socialMediasDisplayStatus = false;
      if (webshopDetail.SocialMedias.length > 0) {
        const socialIcons = webshopDetail.SocialMedias.filter((ppp) => ppp.ShortCode !== "IN" && ppp.ShortCode !== "GP");
        if (socialIcons.length > 0) {
          socialMediasDisplayStatus = true;
        }
      }
      /** new-code => for display Social Icons in product Card */
      if (webshopDetail.isSocialMediaSharingEnabled && socialMediasDisplayStatus) {
        this.isSocialMediaSharingEnabled = true;
      }
      /** new-code => for display Social Icons in product Card */

      this.isLowIE = window.sessionStorage.isLowIE;
      this.CID = this.router.routerState.snapshot.url;

      if (this.CID.indexOf("/orderSuccess") !== -1) {
        try {
          this.campaignService.getCampaignForUser().subscribe((data: any) => {
            if (data && data.length !== 0) {
              this.campaignList = data;
              let a = JSON.parse(sessionStorage.getItem('campaign'));
              for (let d = 0; d < this.campaignList.length; d++) {
                if (this.campaignList[d].CampaignId === a['CampaignId']) {
                  this.commonService.setPoints(this.campaignList[d].BalancePoints);
                  this.campaignService.setActiveCampaign(this.campaignList[d]);
                  sessionStorage.setItem('campaign', JSON.stringify(this.campaignList[d]));
                }
              }
            }else{
              sessionStorage.removeItem('campaign');
              this.commonService.setPoints(0);
            }
          })

          this.CID = this.CID.split("/");
          this.orderId = this.CID[2];
          this.loaderShow = true;
          this._httpWebshopService.getOrderSuccess(this.orderId).subscribe((data1: any[]) => {
           
            this.loaderShow = false;
            this.orderSuccessData = data1;
            
            this.finaldiscount = data1["DealCodeAmount"];
            
            if(this.finaldiscount){
              this.finalval = true;
            }
            else{
              this.finalval = false;
            }
         
            if (this.orderSuccessData.OrderItems && this.orderSuccessData.OrderItems.length > 0) {
              for (let j = 0; j < this.orderSuccessData.OrderItems.length; j++) {
                this.showInvoiceButton = true;
                this.checkProduct(this.orderSuccessData.OrderItems[j].ProductID, j)
              }
            }
            if (this.orderSuccessData.Suborders && this.orderSuccessData.Suborders.length > 0) {
              for (let j = 0; j < this.orderSuccessData.Suborders.length; j++) {
                if (this.orderSuccessData.Suborders[j].isTransportationCalculated === false) {
                  this.showInvoiceButton = true;
                }
              }
            }
          }, (error) => {
            this.loaderShow = false;
            this.toastr.error("Error: " + error);
          });
        } catch (error) {
          this.toastr.error(error);
        }
        this.confirmationTab = true;
        if(!this.isMobile){
          setTimeout(()=>{
            this.staticTabs.setActiveTab(this.isParentCustomer ? 5 : 4);
          },200)
          
        }else{
          this.activePanel('panel-five');
        }
      }
      else if(this.isVoucherKart){
        this.router.navigate(["/checkout"], { replaceUrl: false });
      }
      else if (this.CID.indexOf("/checkout") !== -1) {
        //this.CID = this.CID.split("Status=");
        
      let fullurl = window.location.href;
      let url1 = this.getParameterByName('Status', fullurl)
      let url2 = this.getParameterByName('Message', fullurl)
      let atom = this.getParameterByName('AtomTrans', fullurl);

      if(sessionStorage.getItem("atomid")){
          if(atom === sessionStorage.getItem("atomid")){
            sessionStorage.removeItem('topuppay')
          }
      }
  
        let orderData = JSON.parse(sessionStorage.getItem('order'));
        let topup = JSON.parse(sessionStorage.getItem('topuppay'));

        if(url1 === 'Y' && topup){
          sessionStorage.setItem("atomid", atom)
          this.getShoppingCartList();
        }
        else if (url1 === 'Y' && orderData) {
          this.confirmationTab = true;
          this.orderData = orderData;
          if(!this.isMobile){
            setTimeout(()=>{
              this.staticTabs.setActiveTab(this.isParentCustomer ? 5 : 4);
            }, 100)
          }else{
            this.activePanel('panel-five');
          }
          if(!this.isParentCustomer){
            this.childShopPayment = true;
          }
          this.paymentStatus = true;
          this.totalQuantity = orderData.totalQuantity;
          this.cartId = sessionStorage.getItem('orderid');
          this.orderSuccess = true;
          this.createOrder();
        } else {
          this.confirmationTab = false;
          if(!this.isMobile){
            setTimeout(()=>{
              this.staticTabs.setActiveTab(1);
            },100)
          }else{
            this.activePanel('panel-one');
          }
        }
      } else {
        this.confirmationTab = false;
        if(!this.isMobile){
          setTimeout(()=>{
            this.staticTabs.setActiveTab(1);
          },100)
        }else{
          this.activePanel('panel-one');
        }
      }
      setTimeout(() => {
        this._httpWebshopService.getAllCountries().subscribe(
          (data: any[]) => {
            this.optionsSelect = [];
            this.countries = data;
            this.countries.forEach((elementForCountry) => {
              const countryVal = {
                value: elementForCountry.Alpha2Code,
                label: elementForCountry.Name,
              };
              this.optionsSelect.push(countryVal);
            });
          },
          (error) => {
            // this.toastService.error(JSON.parse(error._body).Message);
            this.toastr.error(error.error.Message);
          }
        );
      }, 500);
      let userBrowserID = "";
      const cookieExists: Boolean = this.cookieService.check("shoppingCardTokenId");
      if (!cookieExists) {
        let domainName = this._httpWebshopService.getSubdomain(window.location.hostname);
        domainName = domainName ? domainName : 'hyperkart';
        const randomString = this._httpWebshopService.randomGeneration(6);
        this.cookieService.set("shoppingCardTokenId", domainName + randomString);
        userBrowserID = domainName + randomString;
      } else {
        userBrowserID = this.cookieService.get("shoppingCardTokenId");
      }
      const userSettings = this.globalService.getUserSettings();
      const webShopSettings = this.globalService.webShopSettings();
      // const loggedInData = this.globalService.getLoggedInData();

      setTimeout(() => {
        if (loggedInData !== null) {
          if (this.checkoutForm.value.DeliveryCountry === "" || this.checkoutForm.value.DeliveryCountry === null) {
            this.checkoutForm.patchValue({
              DeliveryCountry: loggedInData.DeliveryCountry,
            });
          }
          this.cartParams = {
            UserID: loggedInData.UserID,
            CustomerID: webShopSettings.CustomerID,
            CurrencyID: 20,
            UserBrowserID: userBrowserID,
            InitialDeliveryCountry: loggedInData.Country,
            CustomerCountry: loggedInData.Country,
            DeliveryCountry: this.checkoutForm.value.DeliveryCountry,
            DeliveryAddressType: this.initialDelivery,
            GiftTag: "Standard",
            PackagingType: "Standard",
            ReturnOnlyValidCheckedVisibleCompleteItems: true, // new-code
            AddressLine1: loggedInData.Address,
            AddressLine2: loggedInData.Address2,
            AddressLine3: loggedInData.Address3,
            City: loggedInData.City,
            PostalCode: loggedInData.PostalCode,
          };
        } else {
          this.cartParams = {
            UserID: null,
            CustomerID: webShopSettings.CustomerID,
            CurrencyID: 20,
            UserBrowserID: userBrowserID,
            InitialDeliveryCountry: webShopSettings.Country,
            CustomerCountry: webShopSettings.Country,
            DeliveryCountry: this.checkoutForm.value.DeliveryCountry,
            ReturnOnlyValidCheckedVisibleCompleteItems: true, // new-code
            DeliveryAddressType: this.initialDelivery,
            GiftTag: "Standard",
            PackagingType: "Standard",  
            AddressLine1: webShopSettings.Address,
            AddressLine2: webShopSettings.Address2,
            AddressLine3: webShopSettings.Address3,
            City: webShopSettings.City,
            PostalCode: webShopSettings.PostalCode,
          };
        }
        this.loaderShow = false;
        if (!this.isParentCustomer) {
          const campagin = JSON.parse(sessionStorage.getItem('campaign'))
          if(campagin){
            this.cartParams.CampaignId = campagin.CampaignId;
          }
        }
        if(this.isVoucherKart){
          this.cartParams.IsVoucher = true;
        }
        this._httpWebshopService.getShoppingCart(this.cartParams).subscribe((data: any[]) => {
          this.loaderShow = false;        

          this.shoppingCartList = data;
     
          /** new-code => Restirct to display invisible: false items of shopping cart */
          const shoppingCartitemData = [];
          let n = 0;
          let m = 0;
          if(data["Items"].length > 0){
          this.cartId = data["Items"][0].ShoppingCartID;
          for (let i = 0; i < data["Items"].length; i++) {
            if (data["Items"][i].isVisible) {
              shoppingCartitemData.push(data["Items"][i]);
              // shoppingCartitemData[k] = data['Items'][i];
              // k++;
            }
            if (data["Items"][i].isInternalStockProduct) {
              //  this.showPickupWarehouseButton = true;
              this.ispProductList[n] = data["Items"][i];
              n++;
            }
            if (!data["Items"][i].isInternalStockProduct) {
              this.nonispProductList[m] = data["Items"][i];
              m++;
            }
          }
        }

          this.shoppingCartList["Items"] = shoppingCartitemData;

          // ***  New Code to delete pick up goods items
          this.duplicatenonispProductList = JSON.parse(
            sessionStorage.getItem("checkoutProductsList")
          );
          if (this.duplicatenonispProductList && this.duplicatenonispProductList.length > 0) {
            this.nonispProductList = [];
            this.ispProductList = [];
            for (var elem1 of this.shoppingCartList["Items"]) {
              for (var elem2 of this.duplicatenonispProductList) {
                if (!elem1.isInternalStockProduct) {
                  if (elem1.ShoppingCartItemID === elem2.ShoppingCartItemID) {
                    const found = this.nonispProductList.some(
                      (el) =>
                        el.ShoppingCartItemID === elem1.ShoppingCartItemID
                    );
                    const getIndex = this.nonispProductList.indexOf(
                      (el) =>
                        el.ShoppingCartItemID === elem1.ShoppingCartItemID
                    );
                    if (found) {
                      this.nonispProductList.splice(getIndex, 1);
                      this.nonispProductList.push(elem2);
                    } else {
                      this.nonispProductList.push(elem2);
                    }
                  } else {
                    const found = this.nonispProductList.some(
                      (el) =>
                        el.ShoppingCartItemID === elem1.ShoppingCartItemID
                    );
                    if (!found) {
                      this.nonispProductList.push(elem1);
                    }
                  }
                } else if (elem1.isInternalStockProduct) {
                  if (elem1.ShoppingCartItemID === elem2.ShoppingCartItemID) {
                    const found = this.ispProductList.some(
                      (el) =>
                        el.ShoppingCartItemID === elem1.ShoppingCartItemID
                    );
                    const getIndex = this.ispProductList.indexOf(
                      (el) =>
                        el.ShoppingCartItemID === elem1.ShoppingCartItemID
                    );
                    if (found) {
                      this.ispProductList.splice(getIndex, 1);
                      this.ispProductList.push(elem2);
                    } else {
                      this.ispProductList.push(elem2);
                    }
                  } else {
                    const found = this.ispProductList.some(
                      (el) =>
                        el.ShoppingCartItemID === elem1.ShoppingCartItemID
                    );
                    if (!found) {
                      this.ispProductList.push(elem1);
                    }
                  }
                }
              }
            }
          } else {
            // const getIndex = this.nonispProductList.findIndex(get => get.ShoppingCartItemID === elem1.ShoppingCartItemID);
            // this.nonispProductList.splice(getIndex, 1);
          }
          //  New Code to delete pick up goods items ****

          /** new-code => To diplay Product Total Cost with Transportation, Handling Cost if exists */
          for (let j = 0; j < this.shoppingCartList["Items"].length; j++) {
            // tslint:disable-next-line:max-line-length
            this.shoppingCartList["Items"][j]["Price"][
              "totalCostIncludingTransportationHandlingCost"
            ] = Number(
              this.shoppingCartList["Items"][j]["Price"][
              "TotalWithoutTransportationCost"
              ]
            );
            // tslint:disable-next-line:max-line-length

            if (this.shoppingCartList["Items"][j]["Price"]["SuborderID"] !==undefined && this.shoppingCartList["Items"][j]["Price"]["SuborderID"] !== null) {
              if (this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"] !== null) {
                if (Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"]) > 0) {
                  this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] += Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"]);
                }
              }
              if (this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"] !== null) {
                if (Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"]) > 0) {
                  this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] += Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"]);
                }
              }
            } else {
              if (
                this.shoppingCartList["Items"][j]["Price"][
                "TransportationCost"
                ] !== undefined &&
                this.shoppingCartList["Items"][j]["Price"][
                "TransportationCost"
                ] !== null
              ) {
                if (
                  Number(
                    this.shoppingCartList["Items"][j]["Price"][
                    "TransportationCost"
                    ]
                  ) > 0
                ) {
                  this.shoppingCartList["Items"][j]["Price"][
                    "totalCostIncludingTransportationHandlingCost"
                  ] += Number(
                    this.shoppingCartList["Items"][j]["Price"][
                    "TransportationCost"
                    ]
                  );
                }
              }
            }
          }
          /** new-code => To diplay Product Total Cost with Transportation, Handling Cost if exists */

          /** new-code => Restirct to display invisible: false items of shopping cart */
          const cartCount = shoppingCartitemData.length;

          /** new-code */
          sessionStorage.setItem("shoppingCartDatas", JSON.stringify(shoppingCartitemData));
          /** new-code */

          sessionStorage.setItem("shoppingCartCount", cartCount.toString());
          this._httpWebshopService.setCount(
            sessionStorage.getItem("shoppingCartCount")
          );
          if (this.shoppingCartList.GrandTotal !== undefined) {
            this.grandTotalAmount =
              this.shoppingCartList.GrandTotal.toFixed(2);
          }
          this.itemsList = data["Items"];

        },
          (error) => {
            this.toastr.error("Alert: " + error.Message);
            this.loaderShow = false;
            this.blockShop = true;
          }
        );
        this.allowSaveMoreAddress = false;
      }, 500);
    

    if (!this.isParentCustomer) {
      const getLogged = this.globalService.getLoggedInData();
      this._httpWebshopService.getRedeemUser(getLogged.UserID).subscribe((_res) => {

        if (this.tempPoints < 0) {
          this.tempPoints = 0;
        }
        this.avlPoints = this.tempPoints;
        let a = this.campaignService.getActiveCampaign().subscribe((res) => {
          if (res) {
            this.commonService.setPoints(res.BalancePoints);
            this.balancePoints = res.BalancePoints | 0;
            this.tempPoints = this.balancePoints - this.totalPrice;
          } else {

          }
        });
        a.unsubscribe();
      });
    }

  }

  ngAfterViewInit(){
    if(this.isSmartworks){
      setTimeout(() => {
        this.disableNgselect();
      }, 2000);  
    }
    setTimeout(() => {
      if(!this.isParentCustomer && this.orderSuccessData){
        if(this.orderSuccessData.OrderID){
          this.refVar.ngOnInit();
        }
      }
    }, 2000);
  }
  disableNgselect(){
    if(this.ngselect.itemsList.items.length === 1){
      this.ngselect.isDisabled = true;
    }else{
      this.ngselect.isDisabled = false;
    }
    if(this.ngselected.itemsList.items.length === 1){
      this.ngselected.isDisabled = true;
    }else{
      this.ngselected.isDisabled = false;
    }
  }

  deliveryCountry(country) {
    this.postalCodeErrorCount = 0;
    this.cartParams.DeliveryCountry = country;
  }

  startsWithSearchFn(term: string, item) {
    term = term.toString().toLowerCase();
    return item.label.toLowerCase().lastIndexOf(term, 0) === 0;
  }

  getExtension(filename) {
    if (typeof filename !== "undefined") {
      return filename.split(".").pop();
    } else {
      return "";
    }
  }

  guestUser() {
    this.showUserDetailPage = true;
    this.showSaveButton = true;
    this.showChangeAddressButton = false;
  }

  getWallet(){
    this.loaderShow = true;
    this._httpWebshopService.getWallets().subscribe( (data:any) =>{
        this.loaderShow = false;
        this.walletData = data.filter((i)=> i.isActive === true);
        if(this.walletData.length === 0){
            this.emptyItem = true;
        }else{
            this.emptyItem = false;
        }
    }, _eroor=>{
        this.loaderShow = false;
    });
    
}

  // for Decimal you can use this as
  onlyDecimalNumberKey(event) {
    const charCode = event.which ? event.which : event.keyCode;
    // tslint:disable-next-line:curly
    if (charCode < 48 || charCode > 57) return false;
    return true;
  }

  saveUserDetails() {
    if (this.logIndata !== null) {
      this.disabledFields = true;
      this.showChangeAddressButton = true;
      if (this.allowSaveMoreAddress || !this.usersDeliveryAddressID) {
        this.postMoreDeliveryAddress();
      }
      else {
        this.updateDeliveryAddress();
      }
    } else {
      this.showUserDetailPage = true;
      this.disabledFields = true;
      this.showChangeAddressButton = true;
    }
  }

  closeUserDetails(status) {
    this.disabledFields = true;
    if (this.logIndata !== null) {
      this.showUserDetailPage = true;
      this.showChangeAddressButton = true;
      if (status) {
        this.checkoutForm.patchValue(
          {
            DeliveryPhoneNumber: this.valuesForm.DeliveryPhoneNumber.value.substring(0, 25),
            DeliveryCompanyName: this.valuesForm.DeliveryCompanyName.value,
            DeliveryEmail: this.valuesForm.DeliveryEmail.value,
            DeliveryCountry: this.valuesForm.DeliveryCountry.value,
            DeliveryAddress: this.valuesForm.DeliveryAddress.value,
            DeliveryAddress2: this.valuesForm.DeliveryAddress2.value,
            DeliveryAddress3: this.valuesForm.DeliveryAddress3.value,
            DeliveryCity: this.valuesForm.DeliveryCity.value,
            DeliveryPostalCode: this.valuesForm.DeliveryPostalCode.value,
            DeliveryContactPerson: this.valuesForm.DeliveryContactPerson.value,
            PurchaseOrderNumber: null,
            VAT: this.logIndata.VAT,
            DeliveryInstructions: this.valuesForm.DeliveryInstructions.value,
          },
          { onlySelf: false, emitEvent: false }
        );
        this.checkout2Form.patchValue({
          CustomMessage: this.valuesForm.CustomMessage.value,
          DeliveryAddressType: this.valuesForm.DeliveryAddressType.value,
          GiftTag: this.valuesForm.GiftTag.value,
          PackagingType: this.valuesForm.PackagingType.value,
        })
      } else {
        this.checkout2Form.patchValue({
          CustomMessage:
          this.checkout2Form.value.CustomMessage !== ""
            ? this.checkout2Form.value.CustomMessage
            : this.valuesForm.CustomMessage.value,
          DeliveryAddressType:
              this.checkout2Form.value.DeliveryAddressType !== ""
                ? this.checkout2Form.value.DeliveryAddressType
                : this.valuesForm.DeliveryAddressType.value,
          GiftTag: this.checkout2Form.value.GiftTag !== "" ? this.checkout2Form.value.GiftTag : this.valuesForm.GiftTag.value,
          PackagingType:
              this.checkout2Form.value.PackagingType !== ""
                ? this.checkout2Form.value.PackagingType
                : this.valuesForm.PackagingType.value,
        })
        this.checkoutForm.patchValue(
          {
            // tslint:disable-next-line:max-line-length
            DeliveryPhoneNumber:
              this.checkoutForm.value.DeliveryPhoneNumber !== ""
                ? this.checkoutForm.value.DeliveryPhoneNumber.substring(0, 25)
                : this.valuesForm.DeliveryPhoneNumber.value.substring(0, 25),
            DeliveryCompanyName:
              this.checkoutForm.value.DeliveryCompanyName !== ""
                ? this.checkoutForm.value.DeliveryCompanyName
                : this.valuesForm.DeliveryCompanyName.value,
            // tslint:disable-next-line:max-line-length
            DeliveryEmail:
              this.checkoutForm.value.DeliveryEmail !== ""
                ? this.checkoutForm.value.DeliveryEmail
                : this.valuesForm.DeliveryEmail.value,
            // tslint:disable-next-line:max-line-length
            DeliveryCountry:
              this.checkoutForm.value.DeliveryCountry !== ""
                ? this.checkoutForm.value.DeliveryCountry
                : this.valuesForm.DeliveryCountry.value,
            DeliveryAddress:
              this.checkoutForm.value.DeliveryAddress !== ""
                ? this.checkoutForm.value.DeliveryAddress
                : this.valuesForm.DeliveryAddress.value,
            // tslint:disable-next-line:max-line-length
            DeliveryAddress2:
              this.checkoutForm.value.DeliveryAddress2 !== ""
                ? this.checkoutForm.value.DeliveryAddress2
                : this.valuesForm.DeliveryAddress2.value,
            DeliveryAddress3:
              this.checkoutForm.value.DeliveryAddress3 !== ""
                ? this.checkoutForm.value.DeliveryAddress3
                : this.valuesForm.DeliveryAddress3.value,
            // tslint:disable-next-line:max-line-length
            DeliveryCity:
              this.checkoutForm.value.DeliveryCity !== ""
                ? this.checkoutForm.value.DeliveryCity
                : this.valuesForm.DeliveryCity.value,
            DeliveryPostalCode:
              this.checkoutForm.value.DeliveryPostalCode !== ""
                ? this.checkoutForm.value.DeliveryPostalCode
                : this.valuesForm.DeliveryPostalCode.value,
            // tslint:disable-next-line:max-line-length
            DeliveryContactPerson:
              this.checkoutForm.value.DeliveryContactPerson !== ""
                ? this.checkoutForm.value.DeliveryContactPerson
                : this.valuesForm.DeliveryContactPerson.value,
            PurchaseOrderNumber: null,
            VAT: this.logIndata.VAT,
            DeliveryInstructions:
              this.checkoutForm.value.DeliveryInstructions !== ""
                ? this.checkoutForm.value.DeliveryInstructions
                : this.valuesForm.DeliveryInstructions.value,
          },
          { onlySelf: false, emitEvent: false }
        );
      }
    } else {
      this.showUserDetailPage = false;
      this.showChangeAddressButton = true;
    }
  }

  selectedAddress(value) {
    if (value.isAdditionalAddress) {
      this.usersDeliveryAddressID = value.UsersDeliveryAddressID;
    } else {
      this.usersDeliveryAddressID = null;
    }
    this.checkoutForm.patchValue(
      {
        DeliveryPhoneNumber: value.DeliveryPhoneNumber.substring(0, 25),
        DeliveryCompanyName: value.DeliveryCompanyName,
        DeliveryEmail: value.DeliveryEmail,
        DeliveryCountry: value.DeliveryCountry,
        DeliveryAddressType: value.DeliveryAddressType,
        GiftTag: value.GiftTag,
        PackagingType: value.PackagingType,
        DeliveryAddress: value.DeliveryAddress,
        DeliveryAddress2: value.DeliveryAddress2,
        DeliveryAddress3: value.DeliveryAddress3,
        DeliveryCity: value.DeliveryCity,
        DeliveryPostalCode: value.DeliveryPostalCode,
        DeliveryContactPerson: value.DeliveryContactPerson,
        PurchaseOrderNumber: null,
        VAT: this.logIndata.VAT,
      },
      { onlySelf: false, emitEvent: false }
    );
    this.checkout2Form.patchValue({
      CustomMessage: value.CustomMessage,
      DeliveryAddressType: value.DeliveryAddressType,
      GiftTag: value.GiftTag,
      PackagingType: value.PackagingType,
    })
  }

  changeAddress(status, params) {
    this.valuesForm = params.controls;
    this.disabledFields = false;
    this.showChangeAddressButton = false;
    if (status) {
    } else {
      const valuesForGST = this.isParentCustomer === true ? this.logIndata.VAT : "";
      this.allowSaveMoreAddress = true;
      this.checkoutForm = this.formBuilder.group({
        DeliveryCompanyName: [""],
        DeliveryContactPerson: ["", [Validators.required]],
        DeliveryPhoneNumber: ["", [Validators.required, Validators.pattern("^[6-9][0-9]{9}$")]],
        DeliveryEmail: ["", [Validators.required, ValidationService.emailValidator],],
        DeliveryAddress: ["", [Validators.required]],
        DeliveryAddress2: [""],
        DeliveryAddress3: [""],
        DeliveryCity: ["", [Validators.required]],
        DeliveryPostalCode: ["", [Validators.required]],
        DeliveryCountry: ["", [Validators.required]],
        PurchaseOrderNumber: [""],
        DeliveryInstructions: [""],
        VAT: [valuesForGST],
      });
      this.checkout2Form = this.formBuilder.group({
        CustomMessage: [""],
        DeliveryAddressType: ["", [Validators.required]],
        GiftTag: [""],
        PackagingType: [""]
      })
    }
  }

  postMoreDeliveryAddress() {
    const loggedInData = this.globalService.getLoggedInData();
    const sendData = {
      DeliveryCompanyName: this.checkoutForm.value.DeliveryCompanyName,
      DeliveryContactPerson: this.checkoutForm.value.DeliveryContactPerson,
      DeliveryPhoneNumber: this.checkoutForm.value.DeliveryPhoneNumber.substring(0, 25),
      DeliveryEmail: this.checkoutForm.value.DeliveryEmail,
      DeliveryAddress: this.checkoutForm.value.DeliveryAddress,
      DeliveryAddress2: this.checkoutForm.value.DeliveryAddress2,
      DeliveryAddress3: this.checkoutForm.value.DeliveryAddress3,
      DeliveryCity: this.checkoutForm.value.DeliveryCity,
      DeliveryPostalCode: this.checkoutForm.value.DeliveryPostalCode,
      DeliveryCountry: this.checkoutForm.value.DeliveryCountry,
      DeliveryAddressType: this.checkoutForm.value.DeliveryAddressType,
      GiftTag: this.checkoutForm.value.GiftTag,
      PackagingType: this.checkoutForm.value.PackagingType,
      CustomMessage: this.checkout2Form.value.CustomMessage,
      DeliveryInstructions: this.checkoutForm.value.DeliveryInstructions,
      UserID: loggedInData.UserID,
      isDefaultAddress: true,
    };
    this._httpWebshopService.createuserdeliveryaddress(sendData).subscribe((_data) => {
        this.translate.get("SIGNUP.SAVED_SUCESS").subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.success(this.translatedMessage);
        this.ngAfterViewInit();
        this.router.navigate(["/checkout"], { replaceUrl: false });
      },(error) => {
        // this.toastService.error(JSON.parse(error._body).Message);
        this.toastr.error(error.error.Message);
      }
    );
  }

  updateDeliveryAddress() {
    const loggedInData = this.globalService.getLoggedInData();
    const sendData = {
      'DeliveryCompanyName': this.checkoutForm.value.DeliveryCompanyName.substring(0, 50),
      'DeliveryContactPerson': this.checkoutForm.value.DeliveryContactPerson,
      'DeliveryPhoneNumber': this.checkoutForm.value.DeliveryPhoneNumber.substring(0, 25),
      'DeliveryEmail': this.checkoutForm.value.DeliveryEmail,
      'DeliveryAddress': this.checkoutForm.value.DeliveryAddress,
      'DeliveryAddress2': this.checkoutForm.value.DeliveryAddress2,
      'DeliveryAddress3': this.checkoutForm.value.DeliveryAddress3,
      'DeliveryCity': this.checkoutForm.value.DeliveryCity,
      'DeliveryPostalCode': this.checkoutForm.value.DeliveryPostalCode,
      'DeliveryCountry': this.checkoutForm.value.DeliveryCountry,
      'PurchaseOrderNumber': this.checkoutForm.value.PurchaseOrderNumber,
      'DeliveryInstructions': this.checkoutForm.value.DeliveryInstructions,
      'UsersDeliveryAddressID': this.usersDeliveryAddressID,
      // 'GiftTag': this.checkoutForm.value.GiftTag,
      // 'PackagingType': this.checkoutForm.value.PackagingType,
      // 'CustomMessage': this.checkoutForm.value.CustomMessage,
      'VAT': this.checkoutForm.value.VAT,
      'UserID': loggedInData.UserID,
      'isDefaultAddress': true,
    }
    this._httpWebshopService.updateuserdeliveryaddress(sendData).subscribe(_data => {
        this.translate.get('SIGNUP.SAVED_SUCESS').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.success(this.translatedMessage);
        this.router.navigate(['/checkout'], { replaceUrl: false });
      },
      error => {
        this.toastr.error(error.error.Message);
      });
  }



  deleteAddress() {
    const sendData = {
      UsersDeliveryAddressID: this.usersDeliveryAddressID,
    };
    this._httpWebshopService.deleteuserdeliveryaddress(sendData).subscribe(
      (_data) => {
        this.translate.get("MESSAGECENTER.To_DELETE_SUCCESS").subscribe((res: string) => {
            this.translatedMessage = res;
          });
        this.toastr.success(this.translatedMessage);
        this.deleteModal.hide();
        this.router.navigate(["/checkout"], { replaceUrl: false });
      },
      (error) => {
        // this.toastService.error(JSON.parse(error._body).Message);
        this.toastr.error(error.error.Message);
      }
    );
  }

  openDeleteModal() {
    this.deleteModals.show();
  }
  openShippingTypeModal() {
    this.shippingModal.show();
  }

  getEmailOTP() {
    const data = "Email=" + this.logIndata.Email + "&UserName=" + this.logIndata.UserName + "&language_id=" + this.logIndata.LanguageID + "&DomainName="+ this.webshopData.DomainName + "&CustomerTheme=" + this.logIndata.CustomerTheme;
    this._httpWebshopService.triggerEmailOTP(data).subscribe();

    let a = JSON.parse(sessionStorage.getItem('campaign'))

    this.pointsArray.push(a['RewardId']);
    
  }

  paymentSelect(i){
    this.paymentTypeID = i;
  }

  getDeliverytype() {
    this._httpWebshopService.deliveryType().subscribe((data: any) => {
      if (data) {
        this.deliveryType = [];
        this.deliveryTypeChild = [];
        this.deliveryCustom = [];
        data.forEach((element) => {
          if (this.isParentCustomer) {
            if (element.Type === "A") {
              const value = {
                Name: element.Name,
              };
              this.deliveryType.push(value);
            }
          } else {
            if (element.Type === "C") {
              const value = {
                Name: element.Name,
              };
              this.deliveryType.push(value);
              setTimeout(() => {
                if (!this.isParentCustomer) {
                  if (this.deliveryType) {
                    this.initialDelivery = this.deliveryType[0].Name;
                    this.checkoutForm.value.DeliveryAddressType = this.initialDelivery;
                  }
                }
              }, 500);
            }
          }
          if (element.Type === "P") {
            const value = {
              Name: element.Name,
            };
            this.deliveryCustom.push(value);
          }

          // if(element.Type === 'C'){
          //   const value = {
          //     'Name': element.Name
          //   }
          //   this.deliveryTypeChild.push(value);
          // }
          if (this.isParentCustomer) {
            if (this.deliveryType) {
              this.initialDelivery = this.deliveryType[0].Name;
              this.checkoutForm.value.DeliveryAddressType =
                this.initialDelivery;
            }
          }
          // }
          // if(!this.isParentCustomer){
          //   if(this.deliveryTypeChild){
          //     this.initialDelivery = this.deliveryTypeChild[0].Name;
          //     this.checkoutForm.value.DeliveryAddressType = this.initialDelivery;
          //   }
          // }
        });
        this.checkout2Form.patchValue(
          {
            CustomMessage: "",
            DeliveryAddressType: this.logIndata.DeliveryAddressType ? this.logIndata.DeliveryAddressType : this.deliveryType[0].Name,
            GiftTag: "Standard",
            PackagingType:"Standard"
          }
        )
      }
    });
  }
  changeDeliveryAddress(item) {
    console.log("addres",item)
    if (item.toLowerCase() === "multiple office addresses") {
      this.enableTemplate = true;
      this.fileNameCSV = "";
      this.csvEilePath = "";
      this.csvFileName = "";
      this.deliveryText = 'Get your items delivered digitally or physically, directly to your beneficiaries.';
    }
    else {
      this.enableTemplate = false;
      this.fileNameCSV = "";
      this.deliveryText = 'All your gifts will be delivered here';
    }
  }

  sampletemplateDownload() {
    var templateLink = "http://15.206.52.226:8883/Sample/BulkAddressSample.csv";
    this.filepattern = templateLink;
  }

  nextTab(i) {
    this.showPayment = false;
    this._httpWebshopService.getUserCurrentBudget().subscribe((data: any[]) => {
      this.getUserCurrentBudget = data;
    });
    if (i === 1) {
     
        if(!this.isMobile){
          setTimeout(() => {
            this.staticTabs.setActiveTab(1);
          }, 50);
        }else{
          this.activePanel('panel-one');
        }
    
    }

    if( i === 2){
      this.delivertRequirement = true;
        if(!this.isMobile){
          setTimeout(() => {
            this.staticTabs.setActiveTab(i);
          }, 50);
        }else{
          this.activePanel('panel-two');
        }
     
    }

    if (i === 3) {
      if(!this.isParentCustomer){
        if(!this.isMobile){
          setTimeout(() => {
            this.staticTabs.setActiveTab(2);
            console.log(this.staticTabs,"tabs");
          }, 1500);
        }else{
          this.activePanel('panel-two');
        }
      }
      const loggedInData = this.globalService.getLoggedInData();
      this.orderData = this.checkoutForm.value;
      // this.shoppingCartList["UserID"] = loggedInData.UserID;
      // this.shoppingCartList["CustomerID"] = loggedInData.CustomerID;
      this.shoppingCartList["InitialDeliveryCountry"] =  this.orderData.DeliveryCountry;
      //  this.shoppingCartList['DeliveryCountry'] = this.cartParams.DeliveryCountry;
      this.shoppingCartList["DeliveryCountry"] = this.orderData.DeliveryCountry;
      this.shoppingCartList["DeliveryInstructions"] =  this.orderData.DeliveryInstructions;
      let postalcodeErrorExample = "";
      let postalcodeErrorIs = "";
      let deliveryPostalcodeError = "";
      let postalcodeProceedText = "";
      this.translate.get("SIGNUP.POSTALCODE_ERROR_EXAMPLE").subscribe((res: string) => {
          postalcodeErrorExample = res;
        });
      this.translate.get("SIGNUP.POSTALCODE_ERROR_IS").subscribe((res: string) => {
          postalcodeErrorIs = res;
        });
      this.translate.get("SIGNUP.DELIVERY_POSTALCODE_ERROR").subscribe((res: string) => {
          deliveryPostalcodeError = res;
        });
      this.translate.get("SIGNUP.POSTALCODE_PROCEED_TEXT").subscribe((res: string) => {
          postalcodeProceedText = res;
        });
      var country = "";
      var postalCode = "";
      if (this.checkoutForm.controls.DeliveryCountry.value !== undefined) {
        country = this.checkoutForm.controls.DeliveryCountry.value.trim();
      }
      if (this.checkoutForm.controls.DeliveryPostalCode.value !== undefined) {
        postalCode = this.checkoutForm.controls.DeliveryPostalCode.value.trim();
      }
      this._httpWebshopService.checkPostalCode(country, postalCode).subscribe(
        (data) => {
          let returnStatus;
          if (data["ValidPostalRegex"]) {
            returnStatus = true;
          } else {
            this.postalCodeErrorCount += 1;
            if (Number(this.postalCodeErrorCount) > 1) {
              returnStatus = true;
            } else {
              const countryResponse = this.countries.find((item) => item.Alpha2Code === country);
              let displayText = "";
              if (data["VaildPostalcode"] !== "") {
                displayText = deliveryPostalcodeError + countryResponse.Name + " " + postalcodeErrorIs + " " + data["PostalValidMessage"] + "\n  <br/>" + postalcodeErrorExample + data["VaildPostalcode"] +"\n  <br/>" + postalcodeProceedText;
                this.toastr.error("Error: " + displayText, "", {
                  closeButton: true,
                  enableHtml: true,
                });
                returnStatus = false;
              } else {
                returnStatus = true;
              }
            }
          }

          if (returnStatus) {
            this.updateShoppingCartData(i);
          }
        }, (error) => {
          this.toastr.error("Error: " + error.error.Message);
        });

        // if(!this.isParentCustomer){
        //   if(this.shoppingCartList.GrandTotal - this.balancePoints > 0){
        //     let outstanding = this.shoppingCartList.GrandTotal - this.balancePoints;
        //     let topup = this.walletData.filter((e)=> {return e.Price > outstanding });
        //     if(topup.length !== 0){
        //       this.wallettopup = topup[0];
        //     }else{
        //       let  l = this.walletData.length - 1
        //       this.wallettopup = this.walletData[l]
        //     }
        //     this.showWallets = true;
        //   }else{
        //     this.showWallets = false;
        //   }
        // }
    }

    if (i === 4 && this.checkOut()) {
      if(!this.isParentCustomer){
        if(!this.isMobile){
          setTimeout(() => {
            this.staticTabs.setActiveTab(3);
            // console.log(this.staticTabs,"tabs");
          }, 1500);
        }else{
          this.activePanel('panel-three');
        }
      }
      if (!this.isParentCustomer && !this.childShopPayment) {
        this.getEmailOTP();
      }
      this._httpWebshopService.availablePaymentTypes().subscribe(
        (data1: any[]) => {
          this.paymentTypes = data1;
          this.paymentTypeID = this.paymentTypes[0].ID;
          this.orderData["ShoppingCartItems"] = [];
          for (let j = 0; j < this.shoppingCartList.Items.length; j++) {
            if (this.shoppingCartList.Items[j].isChecked === true && this.shoppingCartList.Items[j].isVisible) {
              if (this.shoppingCartList.Items[j].Price.isTransportationCalculated === false) {
                this.isUnAuthorized = true;
              }
              this.orderData.ShoppingCartItems.push({
                shoppingCartItemID:
                  this.shoppingCartList.Items[j].ShoppingCartItemID,
                shippingMethod: this.shoppingCartList.ShippingMethod,
              });
            }
          }

          if (this.orderData.ShoppingCartItems.length > 0) {
            this.showPayment = true;
          } else {
            this.translate.get("HEADER_ONE.PLEASE_SELECT_ONE").subscribe((res: string) => {
                this.translatedMessage = res;
              });
          }

        },
        (error) => {
          this.toastr.error("Error: " + error);
        }
      );

      // Get User Budget API call
      if (this.logIndata !== null) {
        this._httpWebshopService.getUserCurrentBudget().subscribe((data: any[]) => {
            this.getUserCurrentBudget = data;
          
              if(!this.isMobile){
                setTimeout(() => {
                  this.staticTabs.setActiveTab(i);
                }, 50);
              }else{
                let a = '';
                if(i === 1){
                  a = 'panel-one'
                }
                else if(i === 2){
                  a = 'panel-two'
                }
                else if(i === 3){
                  a = 'panel-three'
                }
                else if(i === 4){
                  a = 'panel-four'
                }
                else if(i === 5){
                  a = 'panel-five'
                }
                this.activePanel(a);
              }
            
          });
      }
    }
    if (this.logIndata !== null) {
      this.orderData.UserID = this.logIndata.UserID;
    }
    this.orderData.CustomerID = this.shoppingCartList.CustomerID;
    this.orderData.CurrencyID = this.shoppingCartList.CurrencyID;
    this.orderData.UserBrowserID = this.shoppingCartList.UserBrowserID;
  }

//   walletTopup(){
//     this.toastr.error("You don't have enough points to proceed.");
//  const campagin = JSON.parse(sessionStorage.getItem('campaign'));
//     let campaignID;
//     if(campagin){
//       campaignID = campagin.CampaignId;
//     }

//         if(!campaignID){
//            campaignID = '177'
//         }

//        let walletID = this.wallettopup.WalletID;

        
//         let params = {
//             "UserId": this.logIndata.UserID.toString(),
//             "RewardPoints": this.wallettopup.Price,
//             "Desc": "",
//             "ExpairyDate": "",
//             "CampaignId": parseInt(campaignID),
//             "IsActive": true,
//             "Action": "Add",
//             "IsPointAdd": true,
//             "Email": this.logIndata.Email,
//             "PhoneNumber": this.logIndata.PhoneNumber,
//             "UserName": this.logIndata.UserName,
//             "page": 'checkout'
//         }
        
//         if(this.logIndata.DomainName === "voucherkart"){
//             params.Action = 'V';
//         }
//         sessionStorage.setItem('topuppay', JSON.stringify(params))
       
//         this._httpWebshopService.webTinyUrl().subscribe(data =>{
//           let query = "OrderId="+ walletID+"&customerID="+this.logIndata.UserID+"&IsRewardKart=Y&Domain="+this.logIndata.DomainName+"&Pre=TopUp&ReturnURL="+data+"&Email="+this.logIndata.Email+"&Mobile="+this.logIndata.PhoneNumber+"&campaignID="+campaignID;
//           const paymentURL = environment.ENVIRONMENT.pbase + 'AtomPayment/AtomPaymentRequest?' + query;
//           window.location.href = paymentURL;   
//         });
//   }

  validateOTP() {
    if(this.isSaas && this.subscriptionPlan1 && (this.shoppingCartList.GrandTotal > this.balancePoints)){
      this.toastr.error("Insufficient reward points. Please topup your wallet to proceed further.");
      return;
    }
    this.loaderShow = true;
    const data = "Email=" + this.logIndata.Email + "&OTP=" + this.emailOTP;
    this._httpWebshopService.validateEmailOTP(data).subscribe(
      (data: any) => {
        if (data) {
          this.otpSuccess = true;
          //this.getPoints();
          //this.proceedOrder();
          
          if((!this.isParentCustomer && this.balancePoints) && (this.shoppingCartList.GrandTotal > this.balancePoints)){
            this.makePayment();
          }
          else if ((!this.isParentCustomer && this.balancePoints) &&(this.balancePoints >= this.shoppingCartList.GrandTotal)){
            this.getRewardPoints();
          }
          else{
            this.createOrder();
          }
        }
      },
      (_error) => {
        this.toastr.error("Error: Please enter the correct OTP");
        this.loaderShow = false;
      }
    );
  }

  getRewardPoints(){
    this.loaderShow = true;
    this._httpWebshopService.getCampaignRewardPoints(this.campaignID).subscribe((data:any) => {
    
    if(data){
      this.loaderShow = false;
      if(data[0].AvailableCredit >= this.shoppingCartList.GrandTotal){
        this.createOrder();
      }else{
        this.toastr.error("Insufficient Funds");
        return;
      }
    }else{
      this.loaderShow = false;
    }
    },
    (_error) => {
      this.toastr.error(_error.error.Message);
      this.loaderShow = false;
    })

  }

  getPoints() {

    this._httpWebshopService.getRewardPoints().subscribe((data: any) => {
      if (data) {
        this.campaignSelect = [];
        data.forEach((element) => {
          const value = {
            RewardId: element.RewardId,
            CampaignName: element.CampaignName,
            AvailableCredit: element.AvailableCredit,
            Description: element.Description,
          };
          this.campaignSelect.push(value);
        });
        this.loaderShow = false;
      }
    });
  }

  onChange(points: string, isChecked: boolean) {
    this.pointsSelectError = false;
    if (isChecked) {
      this.pointsArray.push(points);
    } else {
      let index = this.pointsArray.indexOf(points);
      this.pointsArray.splice(index, 1);
    }
  }

  onPackingChange(event) {
    if (event.Name === 'Customized') {   
      this.enableCustomInput = true;
      this.enableimageUpload = false;
      this.CustomMessage = 'Customized';
      this.packagingText = 'Gift wrapper with your branding for your physical gifts or your brand template for digital gifts - Rs.15'
    }
    else if(event.Name === 'Standard') {
      
      this.enableCustomInput = false;
      this.enableimageUpload = true;
      this.CustomMessage = 'Customized';
      this.packagingText = 'A standard tamperproof cover for physical gifts and our standard template for digital gifts';
    }
    else if(event.Name === 'Special') {
      this.enableCustomInput = false;
      this.enableimageUpload = false;
      this.enableimageUpload2 = true
      this.CustomMessage = 'Customized';
      this.packagingText = 'A special gift wrapper for your physical gifts or a special template for digital gifts - Rs.10';
    }

    if(this.greetingCard){
      this.enableCustomInput = true;
    }
    if(this.enableimageUpload === true && this.enableimageUpload1 === true){
      this.enableCustomInput = false;
    }
    if(event.Name === 'Special' && this.greetingCard === false){
      this.enableCustomInput = false;
    }
  }

  onGiftChange(event){
    if (event.Name === 'Customized') {
      this.giftText = 'A gift tag or label with your special message or a letter inserted with your gift - Rs.3'
      this.giftRate =3;
      this.greetingCard = false;
      this.enableimageUpload1 = false;
    }
    else if(event.Name === 'Standard') {
      this.giftText = 'A standard Best Wishes gift tag';
      this.greetingCard = false;
      this.enableimageUpload1 = true;
    }
    else if(event.Name === 'Greeting Card') {
      this.enableCustomInput = true;
      this.greetingCard = true;
      this.enableimageUpload1 = false;
      this.giftText = 'A greeting card with your branding and your own message - Rs.6';
    }

    if(this.enableimageUpload === true && this.enableimageUpload1 === true){
      this.enableCustomInput = false;
    }
    if(this.enableimageUpload2 === true && this.greetingCard === false){
      this.enableCustomInput = false;
    }
  }


  proceedOrder() {
    this._httpWebshopService.payemntCheck().subscribe(data =>{
      if(data){
        this.makePayment();
      }else{
        if (!this.pointsArray) {
          this.pointsSelectError = true;
          return false;
        } else {
          this.createOrder();
        }
      }
    })
  }

  // directPayment(i:any){
  //     this._httpWebshopService.availablePaymentTypes().subscribe(
  //       (data1: any[]) => {
  //         this.paymentTypes = data1;
  //         this.paymentTypeID = this.paymentTypes[1].ID;
  //         this.orderData["ShoppingCartItems"] = [];
  //         for (let j = 0; j < this.shoppingCartList.Items.length; j++) {
  //           if (this.shoppingCartList.Items[j].isChecked === true && this.shoppingCartList.Items[j].isVisible) {
  //             if (this.shoppingCartList.Items[j].Price.isTransportationCalculated === false) {
  //               this.isUnAuthorized = true;
  //             }
  //             this.orderData.ShoppingCartItems.push({
  //               shoppingCartItemID:
  //                 this.shoppingCartList.Items[j].ShoppingCartItemID,
  //               shippingMethod: this.shoppingCartList.ShippingMethod,
  //             });
  //           }
  //         }

  //         if (this.orderData.ShoppingCartItems.length > 0) {
  //           this.showPayment = true;
  //         } else {
  //           this.translate.get("HEADER_ONE.PLEASE_SELECT_ONE").subscribe((res: string) => {
  //               this.translatedMessage = res;
  //             });
  //         }

  //       },
  //       (error) => {
  //         this.toastr.error("Error: " + error);
  //       }
  //     );
  //     Get User Budget API call
  //     if (this.logIndata !== null) {
  //       this._httpWebshopService.getUserCurrentBudget().subscribe((data: any[]) => {
  //           this.getUserCurrentBudget = data;
          
  //             if(!this.isMobile){
  //               setTimeout(() => {
  //                 this.staticTabs.setActiveTab(i);
  //               }, 50);
  //             }else{
  //               let a = '';
  //               if(i === 4){
  //                 a = 'panel-five'
  //               }
  //               // else if(i === 2){
  //               //   a = 'panel-two'
  //               // }
  //               // else if(i === 3){
  //               //   a = 'panel-three'
  //               // }
  //               // else if(i === 4){
  //               //   a = 'panel-four'
  //               // }
  //               // else if(i === 5){
  //               //   a = 'panel-five'
  //               // }
  //               this.activePanel(a);
  //             }
            
  //         });
  //     }
  //     this.makePayment();
  //   }
  

  makePayment(i?:any) {
    if(!this.balancePoints && !this.isParentCustomer){
      this.checkOut();
      this._httpWebshopService.availablePaymentTypes().subscribe(
              (data1: any[]) => {
                this.paymentTypes = data1;
                this.paymentTypeID = this.paymentTypes[1].ID;
                this.orderData["ShoppingCartItems"] = [];
                for (let j = 0; j < this.shoppingCartList.Items.length; j++) {
                  if (this.shoppingCartList.Items[j].isChecked === true && this.shoppingCartList.Items[j].isVisible) {
                    if (this.shoppingCartList.Items[j].Price.isTransportationCalculated === false) {
                      this.isUnAuthorized = true;
                    }
                    this.orderData.ShoppingCartItems.push({
                      shoppingCartItemID:
                        this.shoppingCartList.Items[j].ShoppingCartItemID,
                      shippingMethod: this.shoppingCartList.ShippingMethod,
                    });
                  }
                }
      
                if (this.orderData.ShoppingCartItems.length > 0) {
                  this.showPayment = true;
                } else {
                  this.translate.get("HEADER_ONE.PLEASE_SELECT_ONE").subscribe((res: string) => {
                      this.translatedMessage = res;
                    });
                }
      
              },
              (error) => {
                this.toastr.error("Error: " + error);
              }
            );
            if (this.logIndata !== null) {
              this._httpWebshopService.getUserCurrentBudget().subscribe((data: any[]) => {
                  this.getUserCurrentBudget = data;
                
                    if(!this.isMobile){
                      setTimeout(() => {
                        this.staticTabs.setActiveTab(i);
                      }, 50);
                    }else{
                      let a = '';
                      if(i === 1){
                        a = 'panel-one'
                      }
                      else if(i === 2){
                        a = 'panel-two'
                      }
                      else if(i === 3){
                        a = 'panel-three'
                      }
                      else if(i === 4){
                        a = 'panel-four'
                      }
                      else if(i === 5){
                        a = 'panel-five'
                      }
                      this.activePanel(a);
                    }
                  
                });
            }
          
          if (this.logIndata !== null) {
            this.orderData.UserID = this.logIndata.UserID;
          }
          this.orderData.CustomerID = this.shoppingCartList.CustomerID;
          this.orderData.CurrencyID = this.shoppingCartList.CurrencyID;
          this.orderData.UserBrowserID = this.shoppingCartList.UserBrowserID;

            this.orderData.totalQuantity = this.totalQuantity;
            sessionStorage.setItem('order', JSON.stringify(this.orderData));
            sessionStorage.setItem('orderid', this.cartId);
            if((this.balancePoints && !this.isParentCustomer) && (this.shoppingCartList.GrandTotal - this.balancePoints !== 0)){
              this.preorder = "CkRedeem";
            }
            else if(!this.balancePoints && !this.isParentCustomer){
            this.preorder = "CkOrder";
            }
            if(this.isZydus){
              this.isEmail = this.logIndata.Email ? this.logIndata.Email : 'herbert@rewardkart.in';
            }else{
              this.isEmail = this.logIndata.Email;
            }
            const domain = this.isParentCustomer ? "Hyperkart" : this.campaignID;
            this._httpWebshopService.webTinyUrl().subscribe(data =>{
            const paymentURL = environment.ENVIRONMENT.pbase + 'AtomPayment/AtomPaymentRequest?OrderId=' + this.cartId + '&customerID='+this.loggedInData.UserID+'&IsRewardKart=N&Domain='+domain+'&Pre='+this.preorder+'&ReturnURL='+data+'&Email='+ this.isEmail +'&Mobile='+this.loggedInData.PhoneNumber;
            window.location.href = paymentURL;         
    });
    
  }else{
    this.orderData.totalQuantity = this.totalQuantity;
    sessionStorage.setItem('order', JSON.stringify(this.orderData));
    sessionStorage.setItem('orderid', this.cartId);
    if((this.balancePoints && !this.isParentCustomer) && (this.shoppingCartList.GrandTotal - this.balancePoints !== 0)){
      this.preorder = "CkRedeem";
    }
    else if(!this.balancePoints && !this.isParentCustomer){
    this.preorder = "CkOrder";
    }
    if(this.isZydus){
      this.isEmail = this.logIndata.Email ? this.logIndata.Email : 'herbert@rewardkart.in';
    }else{
      this.isEmail = this.logIndata.Email;
    }
    if (this.isParentCustomer) {
      this.preorder = 'order';
    }
    const domainName = this.isParentCustomer ? "Hyperkart" : this.campaignID;
    this._httpWebshopService.webTinyUrl().subscribe(data =>{
      const paymentURL = environment.ENVIRONMENT.pbase + 'AtomPayment/AtomPaymentRequest?OrderId=' + this.cartId + '&customerID='+this.loggedInData.UserID+'&IsRewardKart=N&Domain='+domainName+'&Pre='+this.preorder+'&ReturnURL='+data+'&Email='+ this.isEmail +'&Mobile='+this.loggedInData.PhoneNumber;
      window.location.href = paymentURL;         
    });
  }
    // let shoppingCartCount = 0;
    // sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
    // this._httpWebshopService.setCount(0);  
    // console.log('idelete1',sessionStorage.getItem("shoppingCartCount"));
    //this.deleteShoppingCartItemNew(0);
   
    
   
    //console.log(paymentURL)
    //window.location.assign(environment.ENVIRONMENT.paymentURL + '/PaymentForms/PaymentRequest.aspx?OrderId=' + this.cartId + '&ApplicationSource=Rewardkart');
  }

  createOrder() {
    
    if(!this.balancePoints && !this.isParentCustomer){
      const loggedInData = this.globalService.getLoggedInData();
      let userBrowserID = "";
      const cookieExists: Boolean = this.cookieService.check("shoppingCardTokenId");
      if (!cookieExists) {
        let domainName = this._httpWebshopService.getSubdomain(window.location.hostname);
        domainName = domainName ? domainName : 'hyperkart';
        const randomString = this._httpWebshopService.randomGeneration(6);
        this.cookieService.set("shoppingCardTokenId", domainName + randomString);
        userBrowserID = domainName + randomString;
      } else {
        userBrowserID = this.cookieService.get("shoppingCardTokenId");
      }
      const userSettings = this.globalService.getUserSettings();
      const webShopSettings = this.globalService.webShopSettings();
      setTimeout(() => {
        if (loggedInData !== null) {
          if (this.checkoutForm.value.DeliveryCountry === "" || this.checkoutForm.value.DeliveryCountry === null) {
            this.checkoutForm.patchValue({
              DeliveryCountry: loggedInData.DeliveryCountry,
            });
          }
          this.cartParams = {
            UserID: loggedInData.UserID,
            CustomerID: webShopSettings.CustomerID,
            CurrencyID: 20,
            UserBrowserID: userBrowserID,
            InitialDeliveryCountry: loggedInData.Country,
            CustomerCountry: webShopSettings.Country,
            DeliveryCountry: this.checkoutForm.value.DeliveryCountry,
            DeliveryAddressType: this.initialDelivery,
            GiftTag: "Standard",
            PackagingType: "Standard",
            ReturnOnlyValidCheckedVisibleCompleteItems: true, // new-code
            AddressLine1: loggedInData.Address,
            AddressLine2: loggedInData.Address2,
            AddressLine3: loggedInData.Address3,
            City: loggedInData.City,
            PostalCode: loggedInData.PostalCode,
          };
        } else{
          this.cartParams = {
            UserID: null,
            CustomerID: webShopSettings.CustomerID,
            CurrencyID: 20,
            UserBrowserID: userBrowserID,
            InitialDeliveryCountry: webShopSettings.Country,
            CustomerCountry: webShopSettings.Country,
            DeliveryCountry: this.checkoutForm.value.DeliveryCountry,
            ReturnOnlyValidCheckedVisibleCompleteItems: true, // new-code
            DeliveryAddressType: this.initialDelivery,
            GiftTag: "Standard",
            PackagingType: "Standard",  
            AddressLine1: webShopSettings.Address,
            AddressLine2: webShopSettings.Address2,
            AddressLine3: webShopSettings.Address3,
            City: webShopSettings.City,
            PostalCode: webShopSettings.PostalCode,
          };
        }
        this.loaderShow = false;
        if(this.isVoucherKart){
          this.cartParams.IsVoucher = true;
        }
        this._httpWebshopService.getShoppingCart(this.cartParams).subscribe((data: any[]) => {
          this.loaderShow = false;        

          this.shoppingCartList = data;
          const shoppingCartitemData = [];
          let n = 0;
          let m = 0;
          if(data["Items"].length > 0){
          this.cartId = data["Items"][0].ShoppingCartID;
          for (let i = 0; i < data["Items"].length; i++) {
            if (data["Items"][i].isVisible) {
              shoppingCartitemData.push(data["Items"][i]);
            }
            if (data["Items"][i].isInternalStockProduct) {
              this.ispProductList[n] = data["Items"][i];
              n++;
            }
            if (!data["Items"][i].isInternalStockProduct) {
              this.nonispProductList[m] = data["Items"][i];
              m++;
            }
          }
        }

            this.shoppingCartList["Items"] = shoppingCartitemData;
            this.orderData["ShoppingCartItems"] = [];
                for (let j = 0; j < this.shoppingCartList.Items.length; j++) {
                  if (this.shoppingCartList.Items[j].isChecked === true && this.shoppingCartList.Items[j].isVisible) {
                    if (this.shoppingCartList.Items[j].Price.isTransportationCalculated === false) {
                      this.isUnAuthorized = true;
                    }
                    this.orderData.ShoppingCartItems.push({
                      shoppingCartItemID:
                        this.shoppingCartList.Items[j].ShoppingCartItemID,
                      shippingMethod: this.shoppingCartList.ShippingMethod,
                    });
                  }
                }
    // this._httpWebshopService.availablePaymentTypes().subscribe(
    //   (data1: any[]) => {
    //     this.paymentTypes = data1;
    //     this.paymentTypeID = this.paymentTypes[1].ID;
    //     this.orderData["ShoppingCartItems"] = [];
    //     for (let j = 0; j < this.shoppingCartList.Items.length; j++) {
    //       if (this.shoppingCartList.Items[j].isChecked === true && this.shoppingCartList.Items[j].isVisible) {
    //         if (this.shoppingCartList.Items[j].Price.isTransportationCalculated === false) {
    //           this.isUnAuthorized = true;
    //         }
    //         this.orderData.ShoppingCartItems.push({
    //           shoppingCartItemID:
    //           this.shoppingCartList.Items[j].ShoppingCartItemID,
    //           shippingMethod: this.shoppingCartList.ShippingMethod,
    //         });
    //       }
    //     }

    //     if (this.orderData.ShoppingCartItems.length > 0) {
    //       this.showPayment = true;
    //     } else {
    //       this.translate.get("HEADER_ONE.PLEASE_SELECT_ONE").subscribe((res: string) => {
    //           this.translatedMessage = res;
    //         });
    //     }

    //   },
    //   (error) => {
    //     this.toastr.error("Error: " + error);
    //   }
    // );
     
    this.loaderShow = true;
    this.orderData.DeliveryRecipient = this.orderData.DeliveryContactPerson;
    this.orderData.PaymentTypeID = 4;
    this.orderData.ShippingMethod = this.orderData.ShoppingCartItems[0].shippingMethod ? this.orderData.ShoppingCartItems[0].shippingMethod : "Courier";
    this.orderData.Purchaseorderno = "";
    this.orderData.IsChildShop = this.isChildShop;
    delete this.orderData.PurchaseOrderNumber;
    /** For ISP Product Warehouse Concept => LIN-5785 Task */
    this.orderData.PickupatWarehouseItems = this.selectedISPProducts;
    this.orderData.RewardIds = this.pointsArray.toString();
    
    this.orderData.DealCode = this.DealCode;
    this.orderData.ChildShopIsPayment = this.childShopPayment;
    if(this.isParentCustomer){
      this.orderData.Ispayment = this.paymentTypeID;
    }
    else if(!this.isParentCustomer){
      if(this.balancePoints && (this.balancePoints >= this.shoppingCartList.GrandTotal)){
        this.orderData.RedeemReward = true;
        this.orderData.ChildShopIsPayment = false;
      }
      else if(this.balancePoints && (this.shoppingCartList.GrandTotal > this.balancePoints)){
          this.orderData.RedeemReward = false;
          this.orderData.ChildShopIsPayment = true;
      }
      else if(!this.balancePoints){
          this.orderData.RedeemReward = false;
          this.orderData.ChildShopIsPayment = false;
      }
    }else{
      this.orderData.RedeemReward  = true;
    }
    if(this.isSmartworks){
      this.orderData.IsSmartWorks = true;
    }else{
      this.orderData.IsSmartWorks = false;
    }
    if(this.isVoucherKart){
      this.orderData.IsVoucher = true;
    }else{
      this.orderData.IsVoucher = false;
      this.orderData.IsChildShop = this.isChildShop;
    }
    if(this.isCorpkonnex){
      this.orderData.IsCorpKonnex  = true;
    }else{
      this.orderData.IsCorpKonnex  = false;
    }
    this.orderData.IsPennaCement = this.isPenna;
    let pcdata:any = '';
    if(this.isPenna){
      pcdata = JSON.parse(sessionStorage.getItem('pcdata'));
      this.orderData.UserCode = pcdata.User_Code;
    }
    this.orderData.PennaDealerCode = this.isPenna ? pcdata.Dealer_Code : '';
    this._httpWebshopService.orderCreate(this.orderData).pipe(
      finalize(() => {
        this.loaderShow = false;
      })
    ).subscribe((data: any[]) => {

      if(data){
        this.loaderShow = false;
      }
      if (!this.isParentCustomer) {
        this.commonService.setReedem(true);
      }
      const responseData = data;
      if(!this.isPenna){
        this.orderSubmit(data["OrderID"]);
      }
      if(this.isVoucherKart){
        //this.voucherSubmit(data["OrderID"]);
      }
      else{
       //this.orderSubmit(data["OrderID"]);
      }


      if (responseData !== null && String(responseData) !== "") {

        /** For ISP Product Warehouse Concept => LIN-5785 Task - Start here */
        this.ispProductList = [];
        this.nonispProductList = [];
        this.selectedISPProducts = [];


        /** For ISP Product Warehouse Concept => LIN-5785 Task - End here */
        if ((Number(this.orderData.PaymentTypeID) === 3 || Number(this.orderData.PaymentTypeID) === 4) && this.isUnAuthorized !== true) {
          this.loaderShow = false;

          if (this.router.navigate(["/orderSuccess/" + data["OrderID"] + "/0"], { replaceUrl: false })) {
            // this.refVar.ngOnInit();
            // let shoppingCartCount = 0;
            // sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
            // this._httpWebshopService.setCount(0);  
            // console.log('idelete1',sessionStorage.getItem("shoppingCartCount"));
            // this.orderData.totalQuantity = this.totalQuantity;
            this.deleteShoppingCartItemNew(0);
          }

        }

        else if (this.isUnAuthorized === true) {
          this.loaderShow = false;
          this.router.navigate(["/orderSuccess/" + data["OrderID"] + "/0"], {
            replaceUrl: false,
          });

          // let shoppingCartCount = 0;
          // sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
          // this._httpWebshopService.setCount(0);  
          // console.log('idelete1',sessionStorage.getItem("shoppingCartCount"));
          // this.orderData.totalQuantity = this.totalQuantity;
          this.deleteShoppingCartItemNew(0);
          // this.router.navigate(['/unauthorisedorders'], { replaceUrl: false });
        }

        else {

          // this.router.navigate(['/'], { replaceUrl: false });
          this.loaderShow = false;
          //window.location.href = data['RedirectUrl'];

          if (this.router.navigate(["/orderSuccess/" + data["OrderID"] + "/0"], { replaceUrl: false })) {

            this.deleteShoppingCartItemNew(0);
            // location.reload();

          }
        }
      } else {
        this.loaderShow = false;
      }
    },
      (error) => {
        if(this.isVoucherKart){
          this.toastr.error("Insufficient reward points. Please topup your wallet to proceed further.");
        }else{
          this.toastr.error("Error: " + error.error.Message);
        }
        this.loaderShow = false;
      }
    );
  })
      }, 250); 
    }
    else{
      this.loaderShow = true;
      this.orderData.DeliveryRecipient = this.orderData.DeliveryContactPerson;
      this.orderData.PaymentTypeID = 4;
      this.orderData.ShippingMethod = this.orderData.ShoppingCartItems[0].shippingMethod;
      this.orderData.Purchaseorderno = "";
      this.orderData.IsChildShop = this.isChildShop;
      delete this.orderData.PurchaseOrderNumber;
      /** For ISP Product Warehouse Concept => LIN-5785 Task */
      this.orderData.PickupatWarehouseItems = this.selectedISPProducts;
      this.orderData.RewardIds = this.pointsArray.toString();
      
      this.orderData.DealCode = this.DealCode;
      this.orderData.ChildShopIsPayment = this.childShopPayment;
      if(this.isParentCustomer){
        this.orderData.Ispayment = this.paymentTypeID;
      }
      else if(!this.isParentCustomer){
        if(this.balancePoints && (this.balancePoints >= this.shoppingCartList.GrandTotal)){
          this.orderData.RedeemReward = true;
          this.orderData.ChildShopIsPayment = false;
        }
        else if(this.balancePoints && (this.shoppingCartList.GrandTotal > this.balancePoints)){
            this.orderData.RedeemReward = false;
            this.orderData.ChildShopIsPayment = true;
        }
        else if(!this.balancePoints){
            this.orderData.RedeemReward = false;
            this.orderData.ChildShopIsPayment = false;
        }
      }else{
        this.orderData.RedeemReward  = true;
      }
      if(this.isSmartworks){
        this.orderData.IsSmartWorks = true;
      }else{
        this.orderData.IsSmartWorks = false;
      }
      if(this.isVoucherKart){
        this.orderData.IsVoucher = true;
      }else{
        this.orderData.IsVoucher = false;
        this.orderData.IsChildShop = this.isChildShop;
      }
  
      if(this.isCorpkonnex){
        this.orderData.IsCorpKonnex  = true;
      }else{
        this.orderData.IsCorpKonnex  = false;
      }
  
      this.orderData.IsPennaCement = this.isPenna;
      let pcdata:any = '';
      if(this.isPenna){
        pcdata = JSON.parse(sessionStorage.getItem('pcdata'));
        this.orderData.UserCode = pcdata.User_Code;
      }
      this.orderData.PennaDealerCode = this.isPenna ? pcdata.Dealer_Code : '';
  
  
      //  console.log('orderid',this.orderData)
      this._httpWebshopService.orderCreate(this.orderData).pipe(
        finalize(() => {
          this.loaderShow = false;
        })
      ).subscribe((data: any[]) => {
  
        if(data){
          this.loaderShow = false;
        }
  
        //  console.log('orderid',data)
        
  
        if (!this.isParentCustomer) {
          this.commonService.setReedem(true);
        }
        const responseData = data;
        if(!this.isPenna){
          this.orderSubmit(data["OrderID"]);
        }
        if(this.isVoucherKart){
          //this.voucherSubmit(data["OrderID"]);
        }
        else{
         //this.orderSubmit(data["OrderID"]);
        }
  
  
        if (responseData !== null && String(responseData) !== "") {
  
          /** For ISP Product Warehouse Concept => LIN-5785 Task - Start here */
          this.ispProductList = [];
          this.nonispProductList = [];
          this.selectedISPProducts = [];
  
  
          /** For ISP Product Warehouse Concept => LIN-5785 Task - End here */
          if ((Number(this.orderData.PaymentTypeID) === 3 || Number(this.orderData.PaymentTypeID) === 4) && this.isUnAuthorized !== true) {
            // this.loaderShow = false;
  
            if (this.router.navigate(["/orderSuccess/" + data["OrderID"] + "/0"], { replaceUrl: false })) {
  
              // let shoppingCartCount = 0;
              // sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
              // this._httpWebshopService.setCount(0);  
              // console.log('idelete1',sessionStorage.getItem("shoppingCartCount"));
              // this.orderData.totalQuantity = this.totalQuantity;
              this.deleteShoppingCartItemNew(0);
            }
  
          }
  
          else if (this.isUnAuthorized === true) {
            this.loaderShow = false;
            this.router.navigate(["/orderSuccess/" + data["OrderID"] + "/0"], {
              replaceUrl: false,
            });
  
            // let shoppingCartCount = 0;
            // sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
            // this._httpWebshopService.setCount(0);  
            // console.log('idelete1',sessionStorage.getItem("shoppingCartCount"));
            // this.orderData.totalQuantity = this.totalQuantity;
            this.deleteShoppingCartItemNew(0);
            // this.router.navigate(['/unauthorisedorders'], { replaceUrl: false });
          }
  
          else {
  
            // this.router.navigate(['/'], { replaceUrl: false });
            this.loaderShow = false;
            //window.location.href = data['RedirectUrl'];
  
            if (this.router.navigate(["/orderSuccess/" + data["OrderID"] + "/0"], { replaceUrl: false })) {
  
              this.deleteShoppingCartItemNew(0);
              // location.reload();
  
            }
          }
        } else {
          this.loaderShow = false;
        }
      },
        (error) => {
          if(this.isVoucherKart){
            this.toastr.error("Insufficient reward points. Please topup your wallet to proceed further.");
          }else{
            this.toastr.error("Error: " + error.error.Message);
          }
          // this.loaderShow = false;
        },
        () => {
          // Set loader to false when the observable completes (success or error)
          this.loaderShow = false;
        }
      );
    }
  }

  checkOut() {
    /** new-code */
    const shoppingCartitemData = [];
    let k = 0;
    for (let i = 0; i < this.shoppingCartList["Items"].length; i++) {
      if (this.shoppingCartList["Items"][i].isVisible) {
        shoppingCartitemData[k] = this.shoppingCartList["Items"][i];
        k++;
      }
    }
    this.shoppingCartList["Items"] = shoppingCartitemData;
    /** new-code */
    /** new-code => To diplay Product Total Cost with Transportation, Handling Cost if exists */
    for (let j = 0; j < this.shoppingCartList["Items"].length; j++) {
      // tslint:disable-next-line:max-line-length
      this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] = Number(this.shoppingCartList["Items"][j]["Price"]["TotalWithoutTransportationCost"]);
      // tslint:disable-next-line:max-line-length

      if (this.shoppingCartList["Items"][j]["Price"]["SuborderID"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["SuborderID"] !== null) {
        if (this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"] !== null) {
          if (Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"]) > 0) {
            this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] += Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"]);
          }
        }
        if (this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"] !== null) {
          if (Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"]) > 0) {
            this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] += Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"]);
          }
        }
      } else {
        if (this.shoppingCartList["Items"][j]["Price"]["TransportationCost"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["TransportationCost"] !== null) {
          if (Number(this.shoppingCartList["Items"][j]["Price"]["TransportationCost"]) > 0) {
            this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] += Number(this.shoppingCartList["Items"][j]["Price"]["TransportationCost"]);
          }
        }
      }
    }
    /** new-code => To diplay Product Total Cost with Transportation, Handling Cost if exists */
    return this.commonService.readyForCheckout(this.shoppingCartList, true);
  }

  updateShoppingCartData(index) {

    /** For ISP Product Warehouse Concept => LIN-5785 Task - Start here */
    // let productList = [ ...this.ispProductList, ...this.nonispProductList];
    var productsList = [];
    if (this.ispProductList.length > 0) {
      this.ispProductList.forEach((element) => {
        if (element.warehouseSelected !== null && element.warehouseSelected !== undefined) {
          if (element.warehouseSelected === true) {
            if (this.selectedISPProducts.indexOf(element.ShoppingCartItemID) < 0) {
              this.selectedISPProducts.push(element.ShoppingCartItemID);
            }
          } else {
            if (this.selectedISPProducts.indexOf(element.ShoppingCartItemID) >= 0) {
              const sIdx = this.selectedISPProducts.findIndex((s: any) => Number(s) === Number(element.ShoppingCartItemID));
              this.selectedISPProducts.splice(sIdx, 1);
            }
          }
        }
      });
    }

    if (this.nonispProductList.length > 0) {
      this.nonispProductList.forEach((element) => {
        if (element.warehousesSelected !== null && element.warehousesSelected !== undefined) {
          if (element.warehousesSelected === true) {
            if (this.selectedISPProducts.indexOf(element.ShoppingCartItemID) < 0) {
              this.selectedISPProducts.push(element.ShoppingCartItemID);
            }
          } else {
            if (this.selectedISPProducts.indexOf(element.ShoppingCartItemID) >= 0) {
              const sIdx = this.selectedISPProducts.findIndex((s: any) => Number(s) === Number(element.ShoppingCartItemID));
              this.selectedISPProducts.splice(sIdx, 1);
            }
          }
        }
      });
    }
    productsList = this.nonispProductList.concat(this.ispProductList);
    sessionStorage.setItem("checkoutProductsList", JSON.stringify(productsList));
    /** For ISP Product Warehouse Concept => LIN-5785 Task - End Here */

    const webShopSettings = this.globalService.webShopSettings();
    const loggedInData = this.globalService.getLoggedInData();
    let AddressLine1 = this.shoppingCartList["AddressLine1"];
    let AddressLine2 = this.shoppingCartList["AddressLine2"];
    let AddressLine3 = this.shoppingCartList["AddressLine3"];
    let City = this.shoppingCartList["City"];
    let PostalCode = this.shoppingCartList["PostalCode"];
    if (loggedInData !== null) {
      if (this.shoppingCartList["AddressLine1"] !== this.checkoutForm.value.DeliveryAddress) {
        AddressLine1 = this.checkoutForm.value.DeliveryAddress;
      }
      if (this.shoppingCartList["AddressLine2"] !== this.checkoutForm.value.DeliveryAddress2) {
        AddressLine2 = this.checkoutForm.value.DeliveryAddress2;
      }
      if (this.shoppingCartList["AddressLine3"] !== this.checkoutForm.value.DeliveryAddress3) {
        AddressLine3 = this.checkoutForm.value.DeliveryAddress3;
      }
      if (this.shoppingCartList["City"] !== this.checkoutForm.value.DeliveryCity) {
        City = this.checkoutForm.value.DeliveryCity;
      }
      if (this.shoppingCartList["PostalCode"] !== this.checkoutForm.value.DeliveryPostalCode) {
        PostalCode = this.checkoutForm.value.DeliveryPostalCode;
      }
    }
    this.shoppingCartItemParams = {
      UserID: this.shoppingCartList["UserID"] ? this.shoppingCartList["UserID"] : loggedInData.UserID,
      CustomerID: this.shoppingCartList["CustomerID"] ? this.shoppingCartList["CustomerID"] : loggedInData.CustomerID,
      CurrencyID: 20,
      UserBrowserID: this.shoppingCartList["UserID"] ? null: this.shoppingCartList["UserBrowserID"],
      InitialDeliveryCountry: this.shoppingCartList["InitialDeliveryCountry"],
      DeliveryCountry: this.shoppingCartList["DeliveryCountry"],
      DeliveryAddressType: this.initialDelivery,
      GiftTag: this.checkoutForm.value.GiftTag,
      PackagingType: this.checkoutForm.value.PackagingType,
      CustomerCountry: this.shoppingCartList["CustomerCountry"],
      ReturnOnlyValidCheckedVisibleCompleteItems: true, // new-code
      AddressLine1: loggedInData !== null ? AddressLine1: this.checkoutForm.value.DeliveryAddress,
      AddressLine2: loggedInData !== null ? AddressLine2 : this.checkoutForm.value.DeliveryAddress2,
      AddressLine3: loggedInData !== null ? AddressLine3 : this.checkoutForm.value.DeliveryAddress3,
      City: loggedInData !== null ? City : this.checkoutForm.value.DeliveryCity,
      PostalCode: loggedInData !== null ? PostalCode : this.checkoutForm.value.DeliveryPostalCode,
      PickupatWarehouseItemIDs: this.selectedISPProducts,
      ShippingMethod: "Courier",
      DeliveryInstructions: this.shoppingCartList["DeliveryInstructions"],
      CustomMessage: this.checkout2Form.value.CustomMessage,
      BulkAddFileName: this.fileNameCSV,
      CustomImageFullFilePath: this.CustomImageFullFilePath,
      CustomImageFile: this.FileName,
      IsVoucher: this.isVoucherKart
     
    };

    // this.addPurchaseorderNo = {
    //   'UserId': this.shoppingCartList['UserID'],
    //   'Purchaseorderno': (this.checkoutForm.value.PurchaseOrderNumber !== null && this.checkoutForm.value.PurchaseOrderNumber
    //     !== undefined) ? this.checkoutForm.value.PurchaseOrderNumber : ''
    // }
    // this._httpWebshopService.addPurchaseOrderNumber(this.addPurchaseorderNo).subscribe(
    //   (_data: any[]) => {

    //   });

    this.loaderShow = true;

    this._httpWebshopService.getShoppingCartItems(this.shoppingCartItemParams).subscribe((data: any[]) => {
          this.loaderShow = false;      

          this.shoppingCartList = data;   
              
          this.itemsList = data["Items"]; 
          
        // this.gtotal = data["GrandTotal"];

       

          /** new-code => To check all steps are completed for product */
          this.itemsTempList = data["Items"];
          this.totalQuantity = 0;
          for (let i = 0; i < this.itemsTempList.length; i++) {
            this.totalQuantity += this.itemsTempList[i].OrderedQuantity;
            if (this.itemsTempList[i].isChecked) {
              
              let tabCount = 1;
              if (
                this.itemsTempList[i].ProductColors &&
                this.itemsTempList[i].ProductColors.length >= 1
              ) {
                tabCount = tabCount + 1;
              }
              if (
                this.itemsTempList[i].ProductSizes &&
                this.itemsTempList[i].ProductSizes.length > 0
              ) {
                tabCount = tabCount + 1;
              }
              if (
                this.itemsTempList[i].AdditionalCosts &&
                this.itemsTempList[i].AdditionalCosts.length > 0
              ) {
                tabCount = tabCount + 1;
              } else if (
                this.itemsTempList[i].Price !== "undefined" &&
                this.itemsTempList[i].Price !== undefined
              ) {
                if (
                  this.itemsTempList[i].Price.GovernmentalCosts &&
                  this.itemsTempList[i].Price.GovernmentalCosts.length > 0
                ) {
                  // this.tabCount = this.tabCount + 1;
                }
              }
              if (
                this.itemsTempList[i].ProductProperties &&
                this.itemsTempList[i].ProductProperties.length > 0
              ) {
                tabCount = tabCount + 1;
              }
              // if (this.itemsList[i].Logos && this.itemsList[i].Logos.length > 0 && !this.itemsList[i].isFixedLogo) {
              // if (this.itemsList[i].Logos && this.itemsList[i].Logos.length > 0 && this.itemsList[i].Logos[0].PrintPriceID !== undefined && !this.itemsList[i].isSampleWithLogo && !this.itemsList[i].isSampleWithoutLogo && !this.itemsList[i].isProceedWithoutLogo) {
              // tslint:disable-next-line:max-line-length
              if (
                this.itemsList[i].Logos &&
                this.itemsList[i].Logos.length > 0 &&
                !this.itemsList[i].isSampleWithLogo &&
                !this.itemsList[i].isSampleWithoutLogo &&
                !this.itemsList[i].isProceedWithoutLogo
              ) {
                tabCount = tabCount + 1;
              }

              if (
                this.itemsList[i].Logos &&
                this.itemsList[i].Logos.length > 0 &&
                (this.itemsList[i].isProceedWithoutLogo ||
                  this.itemsList[i].isSampleWithLogo ||
                  this.itemsList[i].isSampleWithoutLogo)
              ) {
                tabCount = tabCount + 1;
              }

              // completed Step Count
              let tabCompletedCount = 1;
              if (this.itemsTempList[i].isColorStepCompleted) {
                tabCompletedCount = tabCompletedCount + 1;
              }
              if (this.itemsTempList[i].isSizesStepCompleted) {
                tabCompletedCount = tabCompletedCount + 1;
              }
              if (
                this.itemsTempList[i].isAdditionalCostsStepCompleted &&
                this.itemsTempList[i].AdditionalCosts.length > 0
              ) {
                tabCompletedCount = tabCompletedCount + 1;
              }
              if (this.itemsTempList[i].isProductPropertiesStepCompleted) {
                tabCompletedCount = tabCompletedCount + 1;
              }
              if (
                this.itemsTempList[i].Logos.length > 0 &&
                this.itemsTempList[i].isLogosStepCompleted
              ) {
                tabCompletedCount = tabCompletedCount + 1;
              }
              this.itemsTempList[i].isAllStepCompleted = true;
              this.itemsTempList[i].isCheckboxChecked = true;
              if (tabCompletedCount !== tabCount) {
                this.itemsTempList[i].isAllStepCompleted = false;
                this.itemsTempList[i].isCheckboxChecked = false;
              }
            }
          }
          this.itemsList = [];
          //  this.shoppingCartList.TotalWithoutVAT = 0;
          //  this.shoppingCartList.VATAmount = 0;
          for (let i = 0; i < this.itemsTempList.length; i++) {
            /** For ISP Product Warehouse Concept => LIN-5785 Task - Start here */
            this.itemsTempList[i].pickupwarehouseItem = false;
            if (this.selectedISPProducts.length > 0) {
              if (this.selectedISPProducts.indexOf(this.itemsTempList[i].ShoppingCartItemID) !== -1) {
                this.itemsTempList[i].pickupwarehouseItem = true;
              }
            }
            /** For ISP Product Warehouse Concept => LIN-5785 Task - End here */

            if(this.itemsTempList[i].ProductPrices.length > 0){
              this.itemsTempList[i].ProductPrices.forEach((e:any)=>{
                if((this.itemsTempList[i].OrderedQuantity >= e.MinimumQuantity) && (this.itemsTempList[i].OrderedQuantity <= e.MaximumQuantity)){
                  let op = parseInt(this.itemsTempList[i].OriginalPrice);
                  if(op > e.Price){
                    const y = this.itemsTempList[i].OriginalPrice - e.Price;
                    const d = (y*100) / this.itemsTempList[i].OriginalPrice;
                    this.itemsTempList[i].DiscountUpto = d.toFixed(2);
                  }
                }
              })
            }
            if (this.itemsTempList[i].isAllStepCompleted && this.itemsTempList[i].isCheckboxChecked) {
              //  this.shoppingCartList.TotalWithoutVAT += (this.itemsTempList[i].Price.TotalWithoutTransportationCost +
              //    this.itemsTempList[i].Price.TransportationCost);
              this.itemsList.push(this.itemsTempList[i]);
            }

          }

          //  this.shoppingCartList.VATAmount = (this.shoppingCartList.TotalWithoutVAT * this.shoppingCartList.VATPercentage / 100);
          //  this.shoppingCartList.GrandTotal = Number(this.shoppingCartList.TotalWithoutVAT) + Number(this.shoppingCartList.VATAmount);
          this.shoppingCartList.Items = this.itemsList;
          /** new-code => To check all steps are completed for product */

          /** new-code => To diplay Product Total Cost with Transportation, Handling Cost if exists */
          for (let j = 0; j < this.shoppingCartList["Items"].length; j++) {
            // tslint:disable-next-line:max-line-length
            this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] = Number(this.shoppingCartList["Items"][j]["Price"]["TotalWithoutTransportationCost"]);
            // tslint:disable-next-line:max-line-length

            if (this.shoppingCartList["Items"][j]["Price"]["SuborderID"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["SuborderID"] !== null) {
              if (this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"] !== null) {
                if (Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"]) > 0) {
                  this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] += Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationBasicCost"]);
                }
              }
              if (this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"] !== null) {
                if (Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"]) > 0) {
                  this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] += Number(this.shoppingCartList["Items"][j]["Price"]["SuborderTransportationHandlingCost"]);
                }
              }
            } else {
              if (this.shoppingCartList["Items"][j]["Price"]["TransportationCost"] !== undefined && this.shoppingCartList["Items"][j]["Price"]["TransportationCost"] !== null) {
                if (Number(this.shoppingCartList["Items"][j]["Price"]["TransportationCost"]) > 0) {
                  this.shoppingCartList["Items"][j]["Price"]["totalCostIncludingTransportationHandlingCost"] += Number(this.shoppingCartList["Items"][j]["Price"]["TransportationCost"]);
                }
              }
            }
          }
          /** new-code => To diplay Product Total Cost with Transportation, Handling Cost if exists */

          this.allowCheck = false;
          for (let i = 0; i < this.itemsList.length; i++) {
            for (let j = 0; j < this.itemsList[i].ProductColors.length; j++) {
              if (
                this.itemsList[i].ProductColorID === this.itemsList[i].ProductColors[j].ProductColorID
              ) {
                this.itemsList[i].colorIndex = j;
              }
            }
            if (this.itemsList[i].isChecked && this.itemsList[i].isValid) {
              this.allowCheck = true;
            }
          }

         
            if(!this.isMobile){
              setTimeout(() => {
              this.staticTabs.setActiveTab(index);
              }, 50);
            }else{
              this.activePanel('panel-three');
            }
          
        },
        (error) => {
          this.toastr.error("Alert: " + error.error.Message);
          this.loaderShow = false;
          this.blockShop = true;
        }
      );

    // If supplied currency is child currency (unsupported) currency, we call API once more with parent currency to show user Grand Total in parent currency (currency in which order will be placed)
    if (this.shoppingCartList.ParentCurrencyID !== undefined) {
      const webShopSettings = this.globalService.webShopSettings();
      if (webShopSettings != null && !webShopSettings.UseChildCurrencyWhenPossible) {
        this.shoppingCartItemParams.CurrencyID = this.shoppingCartList.ParentCurrencyID;
        if (!this.isParentCustomer) {
          const campagin = JSON.parse(sessionStorage.getItem('campaign'))
          this.shoppingCartItemParams.CampaignId = campagin.CampaignId;
        }

        if(this.isVoucherKart){
          this.shoppingCartItemParams.IsVoucher = true;
        }else{
          this.shoppingCartItemParams.IsVoucher = false;
        }

        this._httpWebshopService.getShoppingCartItems(this.shoppingCartItemParams).subscribe((data: any[]) => {
   
          this.grandTotalAmountInParentCurency =
            data["GrandTotal"].toFixed(2);
        },
          (error) => {
            this.toastr.error("Alert: " + error.error.Message);
            this.loaderShow = false;
          }
        );
      }
    }

  }

  openTermAndConditionModel() {
    this.termsAndCondition.show();
    const webShopSettings = this.globalService.webShopSettings();
    this.termsAndConditionText = webShopSettings.CustomersConditionText;
  }

  openUserDataPolicyModel() {
    this.termsAndCondition.show();
    const webShopSettings = this.globalService.webShopSettings();
    this.termsAndConditionText = webShopSettings.CustomersConditionText;
  }

  ////////////////////////  Delete cart items /////////////////////

  openDeleteModel(shoppingCartId) {
    this.getShoppingCartIDList = [];
    this.getShoppingCartIDList = shoppingCartId;
    if (this.getShoppingCartIDList.length !== 0) {
      this.deleteModal.show();
    } else {
      this.translate.get("HEADER_ONE.PLEASE_SELECT_ONE").subscribe((res: string) => {
        this.translatedMessage = res;
      });
      this.toastr.error(this.translatedMessage);
    }
  }

  openDeleteModelSelected() {

    this.getShoppingCartIDList = [];
    for (let i = 0; i < this.itemsList.length; i++) {
      /** new-code => Proceed to checkout only product which completed all steps  */
      if (this.itemsList[i].isCheckboxChecked) {
        this.getShoppingCartIDList.push(this.itemsList[i].ShoppingCartItemID); // old-code
      }
      /** new-code => Proceed to checkout only product which completed all steps  */
    }
    if (this.getShoppingCartIDList.length !== 0) {
      this.deleteModal.show();
    } else {
      // this.translatedMessage = (this.translate.get('HEADER_ONE.PLEASE_SELECT_ONE'));
      this.translate
        .get("HEADER_ONE.PLEASE_SELECT_ONE")
        .subscribe((res: string) => {
          this.translatedMessage = res;
        });
      this.toastr.error(this.translatedMessage);
    }
  }


  deleteShoppingCartItemNew(type) {
    if(!this.itemsList || this.itemsList.length === 0){
      this.itemsList = JSON.parse(sessionStorage.getItem('shoppingCartDatas'));
    }
    this.getShoppingCartIDList = [];
    for (let i = 0; i < this.itemsList.length; i++) {
      /** new-code => Proceed to checkout only product which completed all steps  */
      if (this.itemsList[i].isChecked) {
        this.getShoppingCartIDList.push(this.itemsList[i].ShoppingCartItemID); // old-code
   
      }

    }

    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const loggedInData = this.globalService.getLoggedInData();
    if (loggedInData !== null) {
      this.cartParams = {
        UserID: loggedInData.UserID,
        CustomerID: webShopSettings.CustomerID,
        CurrencyID: 20,
        DeleteShoppingCartItemIDs: this.getShoppingCartIDList,
      };
    } else {
      this.cartParams = {
        UserID: null,
        CustomerID: webShopSettings.CustomerID,
        CurrencyID: 20,
        DeleteShoppingCartItemIDs: this.getShoppingCartIDList,
      };
    }
    try {
      this.loaderShow = true;
      // console.log(this.cartParams);
      this._httpWebshop.deleteShoppingCartItem(this.cartParams).subscribe(
        (_data) => {
          // console.log("test")
          // console.log(_data);

          this.loaderShow = false;
          if (type === 0) {
            this.deleteModal.hide();
          } else {
            this.deleteModalOneItem.hide();
          }
          this.getShoppingCartList();
          //this.router.navigate(["/"]);
        },
        (error) => {
          this.toastr.error(error);
        }
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getShoppingCartList() {
    try {
      let userBrowserID = "";
      const cookieExists: Boolean = this.cookieService.check(
        "shoppingCardTokenId"
      );
      if (!cookieExists) {
        let domainName = this._httpWebshopService.getSubdomain(
          window.location.hostname
        );
        domainName = domainName ? domainName : 'hyperkart';
        const randomString = this._httpWebshopService.randomGeneration(6);
        this.cookieService.set(
          "shoppingCardTokenId",
          domainName + randomString
        );
        userBrowserID = domainName + randomString;
      } else {
        userBrowserID = this.cookieService.get("shoppingCardTokenId");
      }
      const userSettings = this.globalService.getUserSettings();
      const webShopSettings = this.globalService.webShopSettings();
      const loggedInData = this.globalService.getLoggedInData();
      if (loggedInData !== null) {
        this.cartParams = {
          UserID: loggedInData.UserID,
          CustomerID: webShopSettings.CustomerID,
          CurrencyID: 20,
          UserBrowserID: userBrowserID,
          InitialDeliveryCountry: loggedInData.Country,
          ReturnOnlyVisibleItems: true,

        };
      } else {
        this.cartParams = {
          UserID: null,
          CustomerID: webShopSettings.CustomerID,
          CurrencyID: 20,
          UserBrowserID: userBrowserID,
          InitialDeliveryCountry: userSettings.CountryCode,
          ReturnOnlyVisibleItems: true,

        };
      }
      this.loaderShow = true;

      this._httpWebshop.getShoppingCart(this.cartParams).subscribe((data: any[]) => {
     
        this.shoppingCartList = data;           

        sessionStorage.setItem("cartData", JSON.stringify(this.shoppingCartList));
        this.loaderShow = false;
        this.itemsList = data["Items"];
        this.itemsListA = data["Items"];
        this.checkOutStatus();
        /** new-code => Restirct to display invisible: false items of shopping cart */
        const shoppingCartitemData = [];
        let k = 0;
        let totalQuantity = 0;
        this.totalPrice = 0;
        for (let i = 0; i < data["Items"].length; i++) {
          this.itemsList[i].disableUpdate = true;
          if (data["Items"][i].isVisible) {
            shoppingCartitemData[k] = data["Items"][i];
            k++;
          }
        }
        this.itemsList = shoppingCartitemData;
        this.itemsListA = shoppingCartitemData;
        this.checkOutStatus();

        for (let i = 0; i < this.itemsList.length; i++) {
          this.toggleClass[i] = false;
          if (this.itemsList[i].isChecked) {
            this.itemsList[i].isCheckboxChecked = true;
            this.disableCheckout = false;
            let tabCount = 1;
            if (
              this.itemsList[i].ProductColors &&
              this.itemsList[i].ProductColors.length >= 1
            ) {
              tabCount = tabCount + 1;
            }
            if (
              this.itemsList[i].ProductSizes &&
              this.itemsList[i].ProductSizes.length > 0
            ) {
              tabCount = tabCount + 1;
            }
            if (this.itemsList[i].AdditionalCosts && this.itemsList[i].AdditionalCosts.length > 0) {
              tabCount = tabCount + 1;
            } else if (this.itemsList[i].Price !== "undefined" && this.itemsList[i].Price !== undefined) {
              totalQuantity = totalQuantity + this.itemsList[i].Price.OrderedQuantity;
              this.itemsList[i].Price.ProductPrice = Math.round(this.itemsList[i].Price.ProductPrice);
              this.itemsList[i].Price.TotalProductPrice = parseInt(this.itemsList[i].Price.TotalProductPrice);
              this.totalPrice = this.totalPrice + this.itemsList[i].Price.TotalProductPrice;

              if (this.itemsList[i].Price.PaymentFlag.toLowerCase() !== 'true') {
                this.orderSuccess = false;
              }
            }
            if (this.itemsList[i].ProductProperties && this.itemsList[i].ProductProperties.length > 0) {
              tabCount = tabCount + 1;
            }

            if (this.itemsList[i].Logos && this.itemsList[i].Logos.length > 0 && !this.itemsList[i].isSampleWithLogo && !this.itemsList[i].isSampleWithoutLogo && !this.itemsList[i].isProceedWithoutLogo) {
              tabCount = tabCount + 1;
            }

            if (this.itemsList[i].Logos && this.itemsList[i].Logos.length > 0 && (this.itemsList[i].isProceedWithoutLogo || this.itemsList[i].isSampleWithLogo || this.itemsList[i].isSampleWithoutLogo)) {
              tabCount = tabCount + 1;
            }

            // completed Step Count
            let tabCompletedCount = 1;
            if (this.itemsList[i].isColorStepCompleted) {
              tabCompletedCount = tabCompletedCount + 1;
            }
            if (this.itemsList[i].isSizesStepCompleted) {
              tabCompletedCount = tabCompletedCount + 1;
            }
            if (this.itemsList[i].isAdditionalCostsStepCompleted && this.itemsList[i].AdditionalCosts.length > 0) {
              tabCompletedCount = tabCompletedCount + 1;
            }
            if (this.itemsList[i].isProductPropertiesStepCompleted) {
              tabCompletedCount = tabCompletedCount + 1;
            }
            if (this.itemsList[i].Logos.length > 0 && this.itemsList[i].isLogosStepCompleted) {
              tabCompletedCount = tabCompletedCount + 1;
            }

            this.itemsList[i].isAllStepCompleted = true;
            this.itemsList[i].isCheckboxChecked = true;
            if (tabCompletedCount !== tabCount) {

            }
          }
        }
        // if (!this.isParentCustomer) {
        //   const getLogged = this.globalService.getLoggedInData();
        //   this._httpWebshopService.getRedeemUser(getLogged.UserID).subscribe((_res) => {

        //     if (this.tempPoints < 0) {
        //       this.tempPoints = 0;
        //     }
        //     this.avlPoints = this.tempPoints;
        //     let a = this.campaignService.getActiveCampaign().subscribe((res) => {
        //       if (res) {
        //         this.commonService.setPoints(res.BalancePoints);
        //         this.balancePoints = res.BalancePoints;
        //         this.tempPoints = this.balancePoints - this.totalPrice;
        //       } else {

        //       }
        //     });
        //     a.unsubscribe();
        //   });
        //   let topup = JSON.parse(sessionStorage.getItem('topuppay'));
        //   if(topup){
        //     let fullurl = window.location.href;
        //     let url1 = this.getParameterByName('Status', fullurl);
        //     if (url1 === 'Y'){
             
        //       let params = JSON.parse(sessionStorage.getItem('topuppay'));
        //       let campaign = JSON.parse(sessionStorage.getItem('campaign'));
        //       let campaignList;
        //         this.campaignService.addRewards(params).subscribe(data=>{
        //             if(data === 2){
        //               let points:any = 0;
        //               let a =  this.commonService.getPoints().subscribe(data=>{
        //                   points = data;
        //               });
        //                     let updated = parseInt(params.RewardPoints) + parseInt(points);
        //                     this.commonService.setPoints(updated);
        //                     this.balancePoints = updated;
        //                     campaignList[0].BalancePoints = updated;
        //                     sessionStorage.setItem('campaign', JSON.stringify(campaignList[0]));
        //                // this.commonService.setPoints(campaignList[0].BalancePoints);
        //                 this.campaignService.setActiveCampaign(campaignList[0]);
        //                 let outstanding = this.shoppingCartList.GrandTotal - this.balancePoints;
        //                 let newoutstanding = outstanding - this.dealdiscount;
        //                 this.totaldealdiscount = newoutstanding;
        //                 if(newoutstanding){
        //                   let topup = this.walletData.filter((e)=> {return e.Price > newoutstanding });
        //                   if(topup.length !== 0){
        //                     this.wallettopup = topup[0];
        //                   }else{
        //                     let  l = this.walletData.length - 1
        //                     this.wallettopup = this.walletData[l]
        //                   }
        //                 }else{
        //                   // this.showWallets = false;
        //                 }
        //             }
        //         });
        //         this.campaignService.getCampaignForUser().subscribe((data: any) => {
        //             //this.campaign.CampaignId
        //             if (data) {
        //                 campaignList = data;
        //                 campaignList = campaignList.filter((data)=> data.CampaignId === campaign.CampaignId);
        //                 sessionStorage.setItem('campaign', JSON.stringify(campaignList[0]));
        //                 this.commonService.setPoints(campaignList[0].BalancePoints);
        //                 this.campaignService.setActiveCampaign(campaignList[0])
        //             }
        //         });
        //     }
        //     sessionStorage.removeItem('topuppay')
        //     this.nextTab(3)
        //   }
        // }

        for (let i = 0; i < this.itemsList.length; i++) {
          for (let j = 0; j < this.itemsList[i].ProductColors.length; j++) {
            if (
              this.itemsList[i].ProductColorID ===
              this.itemsList[i].ProductColors[j].ProductColorID
            ) {
              this.itemsList[i].colorIndex = j;
            }
          }
        }
        this.balanceRewardpoints = this.avlPoints - this.totalPrice;
        this._httpWebshop.changeMessage(shoppingCartitemData.length);
        this._httpWebshop.currentMessage.subscribe(
          (message) => (this.cartCount = message)
        );

        this.cartCount = shoppingCartitemData.length;

        sessionStorage.setItem(
          "shoppingCartDatas",
          JSON.stringify(shoppingCartitemData)
        );

        sessionStorage.setItem(
          "shoppingCartCount",
          this.cartCount.toString()
        );
        setTimeout(() => {
          if (sessionStorage.getItem("joinOrderCounts") !== "undefined" && sessionStorage.getItem("joinOrderCounts") !== null && sessionStorage.getItem("joinOrderCounts") !== "") {
            this.joinOrderCounts = JSON.parse(sessionStorage.getItem("joinOrderCounts"));
          }
        }, 5000);
      },
        (error) => {
          this.toastr.error(error);
        }
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }

  checkOutStatus() {
    let sum = 0;
    let selected = 0;
    for (let c = 0; c < this.itemsList.length; c++) {
      if (!this.itemsList[c].disableUpdate || !this.itemsList[c].Price.OrderedQuantity || parseInt(this.itemsList[c].Price.OrderedQuantity) === 0) {
        this.disableCheckout = true;
      }
      if (!this.itemsList[c].isCheckboxChecked) {
        sum++;
      } else {
        selected++;
      }
    }
    if (sum === this.itemsList.length) {
      this.disableCheckout = true;
    }
  }

  deleteShoppingCartItem() {

    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const loggedInData = this.globalService.getLoggedInData();
    if (loggedInData !== null) {
      this.cartParams = {
        UserID: loggedInData.UserID,
        CustomerID: webShopSettings.CustomerID,
        CurrencyID: 20,
        DeleteShoppingCartItemIDs: [this.getShoppingCartIDList],
      };
    } else {
      this.cartParams = {
        UserID: null,
        CustomerID: webShopSettings.CustomerID,
        CurrencyID: 20,
        DeleteShoppingCartItemIDs: [this.getShoppingCartIDList],
      };
    }
    try {
      this._httpWebshopService.deleteShoppingCartItem(this.cartParams).subscribe((data: any[]) => {

        this.loaderShow = true;
        this.deleteModal.hide();

        this.ngOnInit();
     
        if (data["Items"].length === 0) {
          this.router.navigate(["/"]);
        } else {
          /** new-code => Restirct to display invisible: false items of shopping cart */
          const shoppingCartitemData = [];
          let k = 0;
          for (let i = 0; i < data["Items"].length; i++) {
            if (data["Items"][i].isVisible) {
              shoppingCartitemData[k] = data["Items"][i];
              k++;
            }
          }
          var notDeletedItems = [];
          const deletedData = JSON.parse(sessionStorage.getItem("checkoutProductsList"));
          for (var elem1 of deletedData) {
            for (var elem2 of shoppingCartitemData) {
              if (elem1.ShoppingCartItemID === elem2.ShoppingCartItemID) {
                notDeletedItems.push(elem1);
              } else {
                // const getIndex = data.findIndex(get => get.ShoppingCartItemID === elem1.ShoppingCartItemID);
                // data.splice(getIndex, 1);
              }
            }
          }

          sessionStorage.setItem("checkoutProductsList", JSON.stringify(notDeletedItems));
          if (notDeletedItems.length == 0) {
            this.nonispProductList = [];
            this.ispProductList = [];
          }
          /** new-code => Restirct to display invisible: false items of shopping cart */
          const shoppingCartCount = shoppingCartitemData.length;
          /** new-code */
          sessionStorage.setItem("shoppingCartDatas", JSON.stringify(shoppingCartitemData));
          /** new-code */

          sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
          this._httpWebshopService.setCount(sessionStorage.getItem("shoppingCartCount"));
          this.nextTab(3);
          if(!this.isMobile){
            this.staticTabs.setActiveTab(3);
          }else{
            this.activePanel('panel-three');
          }
          setTimeout(() => {
            this.loaderShow = false;
          }, 1000);
        }
      },
        (error) => {
          this.toastr.error("Error: " + error);
        }
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }

  createInvoice(params) {   
 
    try {
      const userSettings = this.globalService.getUserSettings();
      this.printInvoiceUrl = document.getElementById("invoicedownload");
      if(this.isMobile && this.printInvoiceUrl.download){
          this.printInvoiceUrl.download = this.printInvoiceUrl.download.replace(".html", "").trim();      
      }
      this.printInvoiceUrl.href = environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + "/invoices/pdf/" + params.OrderID + (params.OrderConfirmationOnlyForISPs ? "?forceProformaInvoiceTitle=true" : "");        
      this.printInvoiceUrl.click();  
    } catch (error) {       
      this.toastr.error(error);
    }
  }

  getAllAddress() {
    this._httpWebshopService.getMoreDeliveryAddress(1).subscribe(
      (data: any[]) => {
        if (data["DeliveryAddresses"] && data["DeliveryAddresses"].length > 0) {
          this.optionsSelects = [];
          data["DeliveryAddresses"].forEach((element) => {
            const value = {
              label: element.DeliveryAddress,
              value: element.isDefaultAddress,
              DeliveryEmail: element.DeliveryEmail,
              DeliveryCity: element.DeliveryCity,
              DeliveryPhoneNumber: element.DeliveryPhoneNumber,
              DeliveryCompanyName: element.DeliveryCompanyName,
              DeliveryCountry: element.DeliveryCountry,
              DeliveryAddressType: element.DeliveryAddressType,
              DeliveryAddress: element.DeliveryAddress,
              DeliveryAddress2: element.DeliveryAddress2,
              DeliveryAddress3: element.DeliveryAddress3,
              DeliveryPostalCode: element.DeliveryPostalCode,
              DeliveryContactPerson: element.DeliveryContactPerson,
              DeliveryInstructions: element.DeliveryInstructions,
            };
            if (element.isDefaultAddress === true) {
              this.checkoutForm.patchValue(
                {
                  DeliveryPhoneNumber: element.DeliveryPhoneNumber,
                  DeliveryCompanyName: element.DeliveryCompanyName,
                  DeliveryEmail: element.DeliveryEmail,
                  DeliveryCountry: element.DeliveryCountry,
                  DeliveryAddressType: element.DeliveryAddressType,
                  DeliveryAddress: element.DeliveryAddress,
                  DeliveryAddress2: element.DeliveryAddress2,
                  DeliveryAddress3: element.DeliveryAddress3,
                  DeliveryCity: element.DeliveryCity,
                  DeliveryPostalCode: element.DeliveryPostalCode,
                  DeliveryContactPerson: element.DeliveryContactPerson,
                  DeliveryInstructions: element.DeliveryInstructions,
                  PurchaseOrderNumber: null,
                  VAT: this.logIndata.VAT,
                },
                { onlySelf: false, emitEvent: false }
              );
            }
            this.optionsSelects.push(value);
          });
          this.initialValue = true;
        } else {
          // this.toastService.error('No results found');
        }
      },
      (error) => {
        // this.toastService.error(JSON.parse(error._body).Message);
        this.toastr.error(error.error.Message);
      }
    );
  }

  inrFormat(val: number) {
    let currencyCodes =  sessionStorage.getItem("CurrencyCode");
    if(currencyCodes =="ALL"){
      // return `${this.shoppingCartList.CurrencyCode} ${val}`;
      return `${val}`;
    }else{
      return `${currencyCodes} ${val}`
    }
    // return Number(val).toLocaleString("en-IN");
  }

  setUploadedImageData(ev) {

    try {   

        let _xlFileName = ev;
        this.fileNameCSV = ev[1];
        this.csvFileName = ev[0];
        this.csvEilePath = ev[2];        
   
    } catch (error) {
      this.toastr.error(error);
    }
  }

  setUploadedCustomImageData(ev) {

    try {

      let _xlFileName = ev;    
      this.FileName = ev[1];
      this.CustomImageFullFilePath = ev[2];
      this.giftImg = ev[2];        
    } catch (error) {
      this.toastr.error(error);
    }
  }

  checkProduct(productID, j) {
    this._httpProductService.getProductDetail(productID, 'en', '', '').subscribe((data: any) => {
      let category = data.ProductCategories[0].Name.toLowerCase();
      if (category.includes('digital')) {
        this.orderSuccessData.OrderItems[j].delMessage = 'Your digital voucher code will be delivered to your email or mobile number within 24 to 48 hours depending on the processing time taken by the selected brand.';
      }
    })
  }

  orderSubmit(id) {
    this.loaderShow = true;
    this._httpWebshopService.orderSubmit(id).subscribe();
    this.loaderShow = false;
  }
  /*voucherSubmit(id){
    this._httpWebshopService.voucherSubmit(id).subscribe();
  }*/


  getParameterByName(name, url) {
    //if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&|]" + name + "(=([^&#|]*)|&|#|$)", 'i'),
    results = regex.exec(url);
    if (!results)
      return null;
    if (!results[2])
      return '';
    return results[2].replace(/\+/g, " ");
  }
//new codes

getdealdiscount(){

  if(!this.DealCode){

    this.toastr.error("Please enter Deal Code.");
    // this.dealnone = true;
  }  

  else if(this.DealCode){

    const isWhitespace = (this.DealCode || '').trim().length === 0;

    if(isWhitespace){
  
      this.toastr.error("Please enter Deal Code.");
      
    }

    const webShopSettings = this.globalService.webShopSettings();
    const loggedInData = this.globalService.getLoggedInData();
    let AddressLine1 = this.shoppingCartList["AddressLine1"];
    let AddressLine2 = this.shoppingCartList["AddressLine2"];
    let AddressLine3 = this.shoppingCartList["AddressLine3"];
    let City = this.shoppingCartList["City"];
    let PostalCode = this.shoppingCartList["PostalCode"];
    if (loggedInData !== null) {
      if (this.shoppingCartList["AddressLine1"] !== this.checkoutForm.value.DeliveryAddress) {
        AddressLine1 = this.checkoutForm.value.DeliveryAddress;
      }
      if (this.shoppingCartList["AddressLine2"] !== this.checkoutForm.value.DeliveryAddress2) {
        AddressLine2 = this.checkoutForm.value.DeliveryAddress2;
      }
      if (this.shoppingCartList["AddressLine3"] !== this.checkoutForm.value.DeliveryAddress3) {
        AddressLine3 = this.checkoutForm.value.DeliveryAddress3;
      }
      if (this.shoppingCartList["City"] !== this.checkoutForm.value.DeliveryCity) {
        City = this.checkoutForm.value.DeliveryCity;
      }
      if (this.shoppingCartList["PostalCode"] !== this.checkoutForm.value.DeliveryPostalCode) {
        PostalCode = this.checkoutForm.value.DeliveryPostalCode;
      }
    }

    const DealCodevalue = this.DealCode.trim();
  
    this.dealdiscountParams = {
  
      UserID: this.shoppingCartList["UserID"],
      CustomerID: this.shoppingCartList["CustomerID"],
      CurrencyID: 20,
      UserBrowserID: this.shoppingCartList["UserID"] ? null: this.shoppingCartList["UserBrowserID"],
      InitialDeliveryCountry: this.shoppingCartList["InitialDeliveryCountry"],
      DeliveryCountry: this.shoppingCartList["DeliveryCountry"],
      DeliveryAddressType: this.initialDelivery,
      GiftTag: this.checkoutForm.value.GiftTag,
      PackagingType: this.checkoutForm.value.PackagingType,
      CustomerCountry: this.shoppingCartList["CustomerCountry"],
      ReturnOnlyValidCheckedVisibleCompleteItems: true, // new-code
      AddressLine1: loggedInData !== null ? AddressLine1: this.checkoutForm.value.DeliveryAddress,
      AddressLine2: loggedInData !== null ? AddressLine2 : this.checkoutForm.value.DeliveryAddress2,
      AddressLine3: loggedInData !== null ? AddressLine3 : this.checkoutForm.value.DeliveryAddress3,
      City: loggedInData !== null ? City : this.checkoutForm.value.DeliveryCity,
      PostalCode: loggedInData !== null ? PostalCode : this.checkoutForm.value.DeliveryPostalCode,
      DealCode: DealCodevalue
     
    };
  
  
    this.loaderShow = true;
  
    this._httpWebshopService.getShoppingCartItems(this.dealdiscountParams).subscribe((data:any[]) => {
  
      this.loaderShow = false;   
  

        this.dealdiscount = data["DealCodeAmount"];     
        
      if(this.dealdiscount > 0 && this.gtotal >= this.dealdiscount){
  
        // this.toastr.success("Deal Code offer Sucessfully implemented.");       
        this.totaldealdiscount = data["GrandTotal"];
        this.totalhide = true;
        this.dealdiscountvalue = true;
        this.totalvalue = true;
        this.dealsuccess = true;
        this.dealnotvalid = false;
        this.dealerror = false;
      }

      else if(this.dealdiscount >= this.gtotal){

        // this.toastr.error(data['DealCodeMessage']);
        this.totalhide = false;
        this.dealdiscountvalue = false;
        this.totalvalue = false;
        this.dealsuccess = false;
        this.dealnotvalid = false;
        this.dealerror = true;
      }
  
      
      else if(this.dealdiscount === 0){
  
        // this.toastr.error("This Deal Code is not valid.");
        this.totalhide = false;
        this.dealdiscountvalue = false;
        this.totalvalue = false;
        this.dealsuccess = false;
        this.dealnotvalid = true;
        this.dealerror = false;
      }     

      // if(this.showWallets){
      //   let outstanding = this.shoppingCartList.GrandTotal - this.balancePoints;
      //   let newoutstanding = outstanding - this.dealdiscount;
      //   this.totaldealdiscount = newoutstanding;
      //   if(newoutstanding){
      //     let topup = this.walletData.filter((e)=> {return e.Price > newoutstanding });
      //     if(topup.length !== 0){
      //       this.wallettopup = topup[0];
      //     }else{
      //       let  l = this.walletData.length - 1
      //       this.wallettopup = this.walletData[l]
      //     }
      //   }else{
      //     this.showWallets = false;
      //   }
      // }
    
    })
  }
}


handleChange(dealValue: string) {

  const value = dealValue;

    if (!value) {
      
        this.dealdiscountvalue = false;
        this.totalhide = false;
        this.dealsuccess = false;
        this.dealnotvalid = false;
        this.dealerror = false;
        // this.dealerror = true;
      }    
}

onKeyPresscomma(event: KeyboardEvent) {
  const keyCode = event.which || event.keyCode;

  // Restrict comma key (',')
  if (keyCode === 44) {
    event.preventDefault();
  }
}

activePanel(name){
  // if(name == 1){
  //   this.makePayment();  }
    this.activeId = '';
    setTimeout(()=>{
      this.activeId = name;
      window.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      });
    },50)
  }

  insertCheckOut(){
    let params = {
      "Packaging": this.checkout2Form.value.PackagingType,
      "GiftTag": this.checkout2Form.value.GiftTag,
      "CustomMessage": this.checkout2Form.value.CustomMessage,
      "ShoppingCartID": this.cartId
    }
    this._httpWebshopService.insertCheckoutDetails(params).subscribe(_data=>{
      
    });
    this.nextTab(3);

  }
  getThemes(){
    this.httpVoucher.getGiftingTheme().subscribe((res:any)=>{
        let data = JSON.parse(res);
        this.themeList = (data.result);
        this.selectedTheme = this.themeList[0]; 
        this.selected = this.themeList[0].id;
        this.imgSelect(this.selectedTheme.header_image);
    })
}
  themeSelect(i){
    this.selectedTheme = this.themeList[i];
    this.selected = i;
    this.imgSelect(this.selectedTheme.header_image);
}

imgSelect(img){
  this.giftImg = img;
}

}