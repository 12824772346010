export * from "./webshop-services/webshop.service";
export * from "./security-services/security.service";
export * from "./product-services/product.service";
export * from "./orders-services/orders.service";
export * from "./points-services/points.service";
export * from "./category-services/category.service";
export * from "./message-services/message.service";
export * from "./campaign-services/campaign.service";
export * from "./voucher-services/voucher.service";
export class RestfulService {}
