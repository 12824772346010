import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../../../linknet-common/validators/index';
import { WebshopService } from '../../../linknet-common/restful-services/restful.service';
import { INgxMyDpOptions } from 'ngx-mydatepicker';
import { ToastrService } from "ngx-toastr";
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'shop-app-loyalty-programs',
    templateUrl: './loyalty-programs.component.html',
    styleUrls: ['./loyalty-programs.component.scss'],
})

export class LoyaltyPrograms implements OnInit{
    public loyaltyProgram: any;
    public optionsSelectSalutation: Array<any>;
    public optionsSelectDelivery: Array<any>;
    public loaderShow: Boolean = false;
    public myOptions: INgxMyDpOptions = {
      dateFormat: 'dd-mm-yyyy',
    };
    webshop:any;
    customerID:any;

    constructor(public datePipe: DatePipe, private formBuilder: FormBuilder, private _httpWebshopService: WebshopService, private toastr: ToastrService,public globalService: GlobalService){
      const d: Date = new Date();
      this.myOptions = {
        dateFormat: 'dd-mm-yyyy',
        disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() - 1 }
      }

      this._httpWebshopService.getBannerMaster().subscribe((data: any)=>{
        this.optionsSelectDelivery = [];

        data.TargetAudiences.forEach((element) => {
          const list = {
            value: element.Id,
            label: element.CustomerType,
          };
          this.optionsSelectDelivery.push(list);
        });
      })

        this.optionsSelectSalutation = [
            {
              'value': 'CAPT',
              'label': 'CAPT'
            },
            {
              'value': 'DR',
              'label': 'DR'
            },
            {
              'value': 'MR',
              'label': 'MR'
            },
            {
              'value': 'MRS',
              'label': 'MRS'
            },
            {
              'value': 'MISS',
              'label': 'MISS'
            },
            {
              'value': 'MS',
              'label': 'MS'
            }
        ];
        this.webshop = JSON.parse(sessionStorage.getItem('webshopDetail'));
        this.customerID = this.webshop.CustomerID;
    }

    loadForm(){
      const userSettings = this.globalService.getLoggedInData();
      this.loyaltyProgram = this.formBuilder.group({
        'Salutation': [userSettings.Salutation, [Validators.required]],
        'FirstName': [userSettings.FirstName, [Validators.required]],
        'LastName': [userSettings.LastName, [Validators.required]],
        'CompanyName': [userSettings.CompanyName, [Validators.required]],
        'Email': [userSettings.Email, [Validators.required, ValidationService.emailValidator]],
        'Department': [userSettings.Department],
        'PhoneNumber': [userSettings.PhoneNumber, [Validators.required, Validators.maxLength(10)]],
        'DigitalProducts': [true],
        'PhysicalProducts': [true],
        'MerchandiseLogo': [true],
        'WhiteLabelling': [true],
        'TargetAudience': [null, [Validators.required]],
        'RequiredBy': ['', [Validators.required]],
        'AnnualBudget': ['', [Validators.required]],
    });
    }

    ngOnInit() {
      this.loadForm();
    }

    onlyNumberKey(event) {
        const pattern = new RegExp('^[0-9\b]+$');
        const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!pattern.test(key) && event.charCode !== 0) {
          event.preventDefault();
          return false;
        }
    }

    proceed(){
      let board = JSON.parse(sessionStorage.getItem('billBoardList'));
      this.loyaltyProgram.value.RequiredBy = this.datePipe.transform(this.loyaltyProgram.value.RequiredBy.jsdate, 'dd/MM/yyyy');
      const params = {
        "Salutation": this.loyaltyProgram.value.Salutation,
        "FirstName": this.loyaltyProgram.value.FirstName,
        "LastName": this.loyaltyProgram.value.LastName,
        "CompanyName": this.loyaltyProgram.value.CompanyName,
        "Department": this.loyaltyProgram.value.Department,
        "CompanyEmail": this.loyaltyProgram.value.Email,
        "Mobile": this.loyaltyProgram.value.PhoneNumber,
        "DigitalProducts": this.loyaltyProgram.value.DigitalProducts,
        "PhysicalProducts": this.loyaltyProgram.value.PhysicalProducts,
        "Merchandise_CompanyLogo": this.loyaltyProgram.value.MerchandiseLogo,
        "WhiteLabelling_Program": this.loyaltyProgram.value.WhiteLabelling,
        "RequiredBy": this.loyaltyProgram.value.RequiredBy,
        "AnnualBudget": this.loyaltyProgram.value.AnnualBudget,
        "BillBoardId": board[2].BillboardAdID,
        "BannerId":1,
        "CustomerId": this.customerID
      }
      this.loaderShow = true;
      this._httpWebshopService.createBanner(params).subscribe((_data)=>{
          this.loadForm();
          this.loaderShow = false;
          this.toastr.success('Enquiry submitted.')
      },_error=>{
        this.loaderShow = false;
        this.toastr.error('Please try again.')
      })
    }
}