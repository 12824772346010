import { ToastModule } from './typescripts/pro/alerts/toast/toast.module';
import { BrowserModule, Title  } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MDBBootstrapModule } from './typescripts/free';
import { MDBBootstrapModulePro } from './typescripts/pro/index';
// import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { MDBSpinningPreloader } from './typescripts/pro/index';
import { WebshopRoutingModule } from './webshop/webshop-routing.module';
import { ShopCommonRoutingModule } from './linknet-common/linknet-common-routing.module';
import { ShopCommonModule } from './linknet-common/linknet-common.module';
import { WebshopModule } from './webshop/webshop.module';
import { EncodeUriPipe } from './encode-uri.pipe';
import { NgxEditorModule } from 'ngx-editor';
import {NgSelectModule} from '@ng-select/ng-select';
import { NG_SELECT_DEFAULT_CONFIG } from '@ng-select/ng-select';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { ToastrModule } from 'ngx-toastr';
import {AccordionModule} from "ngx-accordion";
import { ReverseArrayPipe } from './reverse-array.pipe';
import { AngularcliStarRatingModule } from 'angularcli-star-rating';
import { CaptchaComponent } from './captcha/captcha.component';
import { ChildShopEnquiryModule } from './webshop/banner-forms/childshop-enquiry/childshop-enquiry.module';
import { LandingPageModule } from './landing-page/landing-page.module';

@NgModule({
  declarations: [
    AppComponent,
    EncodeUriPipe,
    ReverseArrayPipe,
    CaptchaComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChildShopEnquiryModule,
    LandingPageModule,
    HttpClientModule,
    ShopCommonModule,
    WebshopRoutingModule,
    ShopCommonRoutingModule,
    WebshopModule,
    NgxEditorModule,
    ToastModule.forRoot(),
    ToastrModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    MDBBootstrapModulePro.forRoot(),
    NgbModule.forRoot(),
    NgbAccordionModule,
    NgSelectModule,
    NgxImageGalleryModule,
    AngularcliStarRatingModule,
    AccordionModule,
  ],
  providers: [ CookieService, MDBSpinningPreloader, Title, DatePipe,
    {
        provide: NG_SELECT_DEFAULT_CONFIG,
        useValue: {
            notFoundText: 'Not found.'
        }
    }
],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }

