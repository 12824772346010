import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NG_SELECT_DEFAULT_CONFIG } from '@ng-select/ng-select';
import { ChildShopEnquiryComponent } from "./childshop-enquiry.component";
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { MDBBootstrapModule } from '../../../../app/typescripts/free';
import { MDBBootstrapModulePro } from '../../../typescripts/pro';

@NgModule({
    imports: [
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        NgSelectModule,
        MDBBootstrapModule.forRoot(),
        MDBBootstrapModulePro.forRoot(),
        NgxMyDatePickerModule.forRoot()
    ],
    declarations: [ChildShopEnquiryComponent],
    exports: [ChildShopEnquiryComponent],
    schemas: [NO_ERRORS_SCHEMA],
    providers:[DatePipe, {
        provide: NG_SELECT_DEFAULT_CONFIG,
        useValue: {
          notFoundText: 'Not found.'
        }
      }]
})

export class ChildShopEnquiryModule{}