import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '../../typescripts/pro';
import { GlobalService } from '../../linknet-common/globals/global.service';
import { MessageService } from '../../linknet-common/restful-services/restful.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-order-claims',
  templateUrl: './order-claims.component.html',
  styleUrls: ['./order-claims.component.scss']
})
export class OrderClaimsComponent implements OnInit {
  @Input() orderDetails: any;
  public showDiscountInput: Boolean = false;
  public disableButton: Boolean = true;
  public imageUrlList: any;
  public quantityOfDefectGoods: any
  public claimDescription: any;
  public discountPercentage: any;
  public claimsType: any;
  public translatedMessage: any;
  public customOptions = { allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'] };
  public changeDeliveryDateText: Boolean = false;  // for display text as delivery date / Earliest disp. date in HTML Page
  public wsaStatus: Boolean = false;
  constructor(public router: Router, public toastService: ToastService, public globalService: GlobalService,
    public _httpMessageService: MessageService, public translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit() {
    const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (webshopDetail.ChangeDeliveryDateText) {
      this.changeDeliveryDateText = true;
    }
    if (Number(webshopDetail.CustomerID) === 45) {
      this.wsaStatus = true;
    }
  }

  cancel() {
    const orderID = JSON.parse(sessionStorage.getItem('orderDetail'));
    this.router.navigate(['/orderdetail/', orderID.OrderFullDetails.OrderID, this.orderDetails.OrderItemID, false, false],
      { queryParams: null, replaceUrl: true });
  }

  setUploadedImageData(ev) {
    try {
      this.imageUrlList = ev
    } catch (error) {
      this.toastr.error(error);
    }
  }
  submitClaims() {
    try {
      const userDetails = JSON.parse(sessionStorage.getItem('loggedInData'));
      const webShopSettings = this.globalService.webShopSettings();
      const postParams = {
        'claimType': 1,
        'DeliveryDate': this.orderDetails.DeliveryDate,
        'OrderNo': this.orderDetails.OrderItemIdManual,
        'ProductName': this.orderDetails.ProductName,
        'ProductImageURL': this.orderDetails.ProductImageURL,
        'QuantityOfWholeShipment': this.orderDetails.OrderedQuantity,
        'QuantityOfDefectGoods': this.quantityOfDefectGoods,
        'ClaimDescription': this.claimDescription,
        'DiscountPercentage': this.discountPercentage,
        'SenderID': userDetails.UserID,
        'ReceiverID': 1,
        'SenderType': 'g',
        'ReceiverType': 'admin',
        'ApprovarPictureName': 'test',
        'ClaimedPictureName1': 'testclaim',
        'ClaimedPictureName10': 'testclaim10',
        'EmailType': 'Claim',
        'CustomerID': webShopSettings.CustomerID,
        'ClaimTypeID': this.claimsType,
        'ClaimFiles': this.imageUrlList
      }
      this._httpMessageService.claimsAction(postParams).subscribe(
        _data => {
          //  this.translatedMessage = (this.translate.get('MESSAGECENTER.CLAIM_RAISED'));
          this.translate.get('MESSAGECENTER.CLAIM_RAISED').subscribe((res: string) => {
            this.translatedMessage = res;
          });
          this.toastr.success(this.translatedMessage);
          this.cancel();
        },
        error => {
          // this.toastService.error(JSON.parse(error._body).Message);
          this.toastr.error(error.error.Message);
        });
    } catch (error) {
      this.toastr.error(error);
    }
  }
  // for Decimal you can use this as
  onlyDecimalNumberKey(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // tslint:disable-next-line:curly
    if ((charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  valid() {
    if (this.orderDetails.OrderedQuantity < this.quantityOfDefectGoods) {
      this.quantityOfDefectGoods = null;
      // this.translatedMessage = (this.translate.get('MESSAGECENTER.ENTER_VALID_DEFECT_QUANTITY'));
      this.translate.get('MESSAGECENTER.ENTER_VALID_DEFECT_QUANTITY').subscribe((res: string) => {
        this.translatedMessage = res;
      });
      this.toastr.error(this.translatedMessage);
    }
  }

  validation() {
    if ((this.quantityOfDefectGoods !== undefined && this.quantityOfDefectGoods !== '')
      && (this.claimsType !== undefined && this.claimsType !== '')) {
      this.disableButton = false;
    } else {
      this.disableButton = true;
    }
  }
}
