import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CampaignService, CategoryService, VoucherService, WebshopService } from "app/linknet-common/restful-services/restful.service";
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { ToastrService } from "ngx-toastr";
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder } from "@angular/forms";
import { OwlOptions } from "ngx-owl-carousel-o";
import { NgbCarouselModule } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'shop-app-header-voucher',
    templateUrl : './header-voucher.component.html',
    styleUrls: ['./header-voucher.component.scss']
})

export class HeaderVoucherComponent implements OnInit {
  @Input() redirect: boolean;
  @Output() discount = new EventEmitter<any>();
  @Output() redeem = new EventEmitter<any>();
  @Output() clearFilter = new EventEmitter<any>();
  brandList: any;
  brandData: any;
  brandSearch: any;
  searchLabel:any;
  brandCount: any;
  topvoucherList: Array<any> = [];;
  voucherList: any;
  voucherListFilter: any = [];
  showMenu: boolean = false;
  categoryDropdownHeight: any;
  overflow: any = '';
  overflowClassY: any = '';
  @Input() discountOption: any;
  @Input() rdOption: any;
  logIndata: any;
  isVoucherKart:boolean = false;
  loaderShow:boolean = false;
  @ViewChild("campaignModal") public campaignModal;
  public campaignList = [];
  public balancePoints: any = 0;
  public campaignName : string = '';
  public getShoppingCartIDList: any;
  public cartParams: object = {};
  webShopSettings: any;  
  priceFrom: string = "";
  priceTo: string = "";
  public postParams = {};
  CID:any;
  queryParams:any;
  public ShowPricesIncludingLogoType: any;
  Quantity:any;
  public selectedColorID: any;
  public sortBy: string = "";
  public SupplierQualityLevel: string = '';
  isTagsFilterExists: boolean = false;
  public categoryId: any;
  public advancedSearchSorting = [];
  public categoryID: any = '';
  public clearButtonEnableStatus: Boolean = false;
  princepipes:boolean = false;
  public webshopDetail: any;
  webshopData: any;
  remainingTopLists: Array<any> = [];
  productListSearchParam:any;
  public isParentCustomer: boolean = false;
  public mainCategoryList: any;
  CategoryIDs: Array<any> = [];
  showFlagMenu: boolean = false;
  countryName: string = "Product Types";
  public isMobile: boolean;
  @Input() oldestProductFirstStatus: any;
  showSubcategory: boolean = false;
  myInterval: number = 2000;
  customOptions: OwlOptions = {
    loop: false,
    margin: 10,
    autoplay:true,
    dots: false,
    navSpeed: 700,  
    slideBy: 1,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    // responsive: {
    //   0: {
    //     items: 1,
    //     slideBy: 2
    //   },
    //   480: {
    //     items: 1,
    //     slideBy: 4
    //   },
    //   940: {
    //     items: 1,
    //     slideBy: 5
    //   }
    // },
    nav: false
}
  constructor(private toastr: ToastrService, private httpVoucher: VoucherService, private router: Router, public globalService: GlobalService,
    private _httpCategories: CategoryService,public campaignService: CampaignService,private commonService: CommonServicesService,private _http: WebshopService,
    private route: ActivatedRoute,private translate: TranslateService, private formBuilder: FormBuilder,
  ){
    if (/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.logIndata = this.globalService.getLoggedInData();
    const webshopData = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if((webshopData && webshopData.ParentCustomerDomainName) === (webshopData && webshopData.DomainName)){
      this.isParentCustomer = true;
    }
    if (webshopData && webshopData.DomainName === "voucherkart") {
      this.isVoucherKart = true;
    }else{
      this.isVoucherKart = false;
    }
    this.queryParams = this.router.routerState.snapshot.root.queryParams;
    this.ShowPricesIncludingLogoType = this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType : null;
    this.Quantity = this.queryParams.Quantity ? this.queryParams.Quantity : '';
    this.ShowPricesIncludingLogoType = this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType : null;
    this.selectedColorID = this.queryParams.ColorID ? parseInt(this.queryParams.ColorID || 0, 10) : '';

  }
  
  ngOnInit() {
    const campaign = JSON.parse(sessionStorage.getItem('campaign'));
    if(campaign || this.isParentCustomer){
      if(campaign){
        this.campaignService.setActiveCampaign(campaign);
      }
      this.getTOPVoucherList();
    }
    this.webShopSettings = this.globalService.webShopSettings()
    // this.changeCampaign();
    // this.getVoucherList();
    // if(!this.isVoucherKart){
    //   this.getBrandList();
    // }
    this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.webshopData = this.webshopDetail;
    if(this.webshopData && this.webshopData.DomainName === "princepipes"){
      this.princepipes = true;
    }
    if(this.webshopData.ParentCustomerDomainName === this.webshopData.DomainName){
      this.isParentCustomer = true;
    }else{
      this.isParentCustomer = false;
    }
    const filterDetail = JSON.parse(sessionStorage.getItem('advanceSearchParams'));
    /** new-code => Default Sort By Select Option Based on WSA */
    let defaultSortByOption = '0';
    // if (this.webshopDetail.DomainName === 'wsa') {
    if (Number(this.webshopDetail.CustomerID) === 45) {
      defaultSortByOption = '1';
    }
    if (this.oldestProductFirstStatus !== undefined && this.oldestProductFirstStatus !== null
      && this.oldestProductFirstStatus) {
      defaultSortByOption = '3';
    }

    if (Number(this.webshopDetail.CustomerID) === 15) {
      defaultSortByOption = '3';
    }
    this.productListSearchParam = this.formBuilder.group({
      'SortBy': [this.queryParams.SortBy ? this.queryParams.SortBy : defaultSortByOption, []],
    })
    const category = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.CategoryID : null;
    this.categoryId = category;
    this.postParams = {
      'CategoryID': this.categoryId ? this.categoryId : '',
      'ShowPricesIncludingLogoType': this.ShowPricesIncludingLogoType ? this.ShowPricesIncludingLogoType : this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType : null,
      'Quantity': this.Quantity ? this.Quantity : this.queryParams.Quantity ? this.queryParams.Quantity : '',
      'PriceFrom': this.priceFrom ? this.priceFrom : this.queryParams.PriceFrom,
      'PriceTo': this.priceTo ? this.priceTo : this.queryParams.PriceTo,
      'ColorID': this.selectedColorID ? this.selectedColorID : '',
      'ProductName': this.queryParams.ProductName ? this.queryParams.ProductName : '',
      'DeliveryDate': this.queryParams.DeliveryDate ? this.queryParams.DeliveryDate : '',
      'SortBy': this.sortBy ? this.sortBy : this.queryParams.SortBy,
      'SupplierQualityLevel': this.SupplierQualityLevel ? this.SupplierQualityLevel : '',
      'isTagsFilterExists': this.isTagsFilterExists
    };
    let i = 0;
    if (!this.webshopDetail.ShowAllProductsInSortByFilter) {
      i = 0;
    } else {
      i = i + 1;
    }
    if(this.princepipes){
      let val = 'HEADING.LOW_CROWNS';
      let val1 = 'HEADING.HIGH_CROWNS';
      this.translate.get(val).subscribe((res: string) => {
      this.advancedSearchSorting[0] = {
        'value': 2,
        'label': res
      };
    });
      this.translate.get(val1).subscribe((res: string) => {
        this.advancedSearchSorting[1] = {
          'value': 4,
          'label': res
        };
      });
      console.log(this.advancedSearchSorting)
    }
    if(this.isParentCustomer){
      if (this.webshopDetail.ShowAllProductsInSortByFilter) {
        this.translate.get('HEADING.ALL_PRODUCTS_AZ').subscribe(
          (res: string) => {
            this.advancedSearchSorting[0] = {
              'value': 0,
              'label': res
            };
            // tslint:disable-next-line:no-unused-expression
          });
      }
      this.translate.get('HEADING.ALL_PRODUCTS_ZA').subscribe(
        (res: string) => {
          this.advancedSearchSorting[i] = {
            'value': 5,
            'label': res
          };
        });
      this.translate.get('HEADING.CHEAPEST_PRODUCT').subscribe((res: string) => {
        this.advancedSearchSorting[i + 1] = {
          'value': 2,
          'label': res
        };
      });
      this.translate.get('HEADING.EXPENSIVE_PRODUCT').subscribe((res: string) => {
        this.advancedSearchSorting[i + 2] = {
          'value': 4,
          'label': res
        };
      });
      this._httpCategories.getMainCatgeories().subscribe( (data: any[]) => {
        this.mainCategoryList = data;
        this.mainCategoryList.forEach(data => {
          data['toggle'] = false;
          if(this.CategoryIDs.length !== 0){
            if(this.CategoryIDs.includes(data['CategoryID'].toString())){
              data['toggle'] = true;
            }
          }
        })
      })
    }
  }
  selectMainCategory(i){
    let len = this.mainCategoryList.length;
    if (this.mainCategoryList[i].toggle) {
      this.CategoryIDs.push(this.mainCategoryList[i].CategoryID.toString())
    }else{
      const getIndex = this.CategoryIDs.findIndex(get => get === this.mainCategoryList[i].CategoryID.toString());
      this.CategoryIDs.splice(getIndex, 1);
    }
    if(this.CategoryIDs.length !== 0){
      this.searchCategory();
    }else{
      this.router.navigate(['/'], {replaceUrl: false});
    }
  }
  searchCategory(val?:any){
    if(val.CategoryID){
    this.router.navigate(['/multi-search', val.CategoryID], { replaceUrl: false });
    }
    // let ids = '';
    // this.CategoryIDs.forEach(e=>{
    //   ids = ids+e+'/';
    // })
  }
 
  showFlag() {
    this.showFlagMenu =! this.showFlagMenu;
    this.showMenu = false;
  }
  showMenuEvent(event){
    this.showMenu = event;
    this.showFlagMenu = event;
    this.hideMenu();
  }
  hideMenu() {
    this.showMenu = false;
    this.showFlagMenu = false;
    if(this.isMobile){
      var otherelement = document.querySelectorAll(".header-two .navbar-collapse.show")[0] as HTMLElement;
      if(otherelement){
        var closeelement = document.querySelectorAll(".header-two .navbar-toggler")[0] as HTMLElement;
        closeelement.click();
      }
      var element = document.querySelectorAll(".navbar-toggler")[0] as HTMLElement;
      if(element){
        element.click();
      }
    }
  }
  onlyNumberKey(event) {
    const pattern = new RegExp('^[0-9\b]+$');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!pattern.test(key)
 && event.charCode !== 0) {
      event.preventDefault();
      return false;
    }
  }

  selectedSortBy(val:boolean) {
    try {
      let pfrom = parseInt(this.priceFrom);
      let pto = parseInt(this.priceTo)
      if(val&&!this.priceFrom){
        this.toastr.error("Please Enter Minimum Price")
        return false;
      } 
      if(val&&!this.priceTo){
        this.toastr.error("Please Enter Maximum Price")
        return false;
      }
      if(pto && pto <= pfrom){
        this.toastr.error("Minimum price can't be lesser than maximum price")
        return false;
      }
      sessionStorage.removeItem('pageIndex');
      sessionStorage.removeItem('pageSize');
      
      this.CID = this.router.routerState.snapshot.url;

      const subCategoryId = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.SubCategory : null;
      if (subCategoryId !== null && subCategoryId !== undefined) {
        this.postParams = {
          'CategoryID': this.queryParams.CategoryID ? this.queryParams.CategoryID : '',
          'ShowPricesIncludingLogoType': this.ShowPricesIncludingLogoType ? this.ShowPricesIncludingLogoType : this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType : null,
          'Quantity': this.Quantity ? this.Quantity : this.queryParams.Quantity ? this.queryParams.Quantity : '',
          'PriceFrom': this.priceFrom,
          'PriceTo': this.priceTo,
          'ColorID': this.selectedColorID ? this.selectedColorID : this.queryParams.ColorID,
          'ProductName': this.queryParams.ProductName ? this.queryParams.ProductName : '',
          'DeliveryDate': this.queryParams.DeliveryDate ? this.queryParams.DeliveryDate : '',
          'SortBy': this.sortBy ? this.sortBy : this.queryParams.SortBy,
          'SubCategory': subCategoryId,
          'SupplierQualityLevel': this.SupplierQualityLevel ? this.SupplierQualityLevel : null,
          'isTagsFilterExists': this.isTagsFilterExists
        }
      }
      if(this.CID.indexOf('/searchbrands/') !== -1){
        let a = this.CID.split('/');
        let id = a['2'];
        this.postParams['BrandId'] = id;
        this.postParams['CategoryID'] = null;
      }
      if(this.queryParams.BrandId){
        this.postParams['BrandId'] = this.queryParams.BrandId;
        this.postParams['CategoryID'] = null;
      }
      this.postParams['isTagsFilterExists'] = this.isTagsFilterExists;
      this.postParams['PriceFrom'] = this.priceFrom;
      this.postParams['PriceTo'] = this.priceTo;
      /*if (this.priceFilteReset) {
        this.postParams['PriceFrom'] = null;
        this.postParams['PriceTo'] = null;
      }*/
      this.showMenu = false;
      this.router.navigate(['/search'], { queryParams: this.postParams, replaceUrl: false });
    } catch (error) {
      this.toastr.error('Error: ' + error);
    }
  }
  ngAfterViewInit(){
    const campaign = JSON.parse(sessionStorage.getItem('campaign'));
    if(!campaign && !this.isParentCustomer){
      this.changeCampaign();
    }
  }
  brandPlaceHol(){
    setTimeout(() => {
      if(this.isVoucherKart){
        this.getBrandList();
        let placeHolder = document.getElementsByClassName("search_input_gobal") as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < placeHolder.length; i++) {
          placeHolder[2].setAttribute("placeholder", "Search for your Favourite Brand");
        }
      }
    }, 100);
  }
  getBrands(data){
    let brand = this.brandData.filter(e=>e.brand_name === data);
    let type = 'brands';
    sessionStorage.setItem('vouchersearch','yes');
    this.router.navigate(['/vouchers', type, brand[0].ID], { replaceUrl: false })
  }

  getBrandList(){
    this.brandList = [];
    this.httpVoucher.getVoucherBrandsSearch().subscribe((data:any)=>{
        this.brandData = data;
        this.brandData.forEach(element => {
          this.brandList.push(element.brand_name)
        });
        this.searchLabel = 'Search for your Favourite Brand';
        // if(!this.isVoucherKart){
        //   this.brandCount = this.brandData.length;
        // }
    })
}
changeCampaign(){
    this.campaignModal.show({
      backdrop: 'static',
      keyboard: false
    });
  this.loaderShow = true;
  this.campaignService.getCampaignForUser().subscribe((data: any) => {
    if(data) {
      //sessionStorage.setItem('campaign', JSON.stringify(data))
      this.loaderShow = false;
      this.campaignList = data;
      console.log(this.campaignList,"this.campaignList  this.campaignList");
      
      this.campaignList = this.campaignList.filter((e:any)=>{ return e.Aprovel === 'Aproved'});
    }
  },error=>{
    console.log(error)
    this.loaderShow = false;
  });
}

campaignSelect(id) {
  let a = this.campaignList;
  sessionStorage.setItem('campaign', JSON.stringify(this.campaignList))
  if (!a || (a['CampaignId'] !== id['CampaignId'])) {
    sessionStorage.setItem('campaign', JSON.stringify(id));
    this.commonService.setPoints(id.BalancePoints);
    this.campaignService.setActiveCampaign(id);
  }
  this.campaignName = id['CampaignName'];
  this.campaignModal.hide();
  setTimeout(() => {
    this.getTOPVoucherList();
  }, 500);

  this.deleteShoppingCart();
  // if(this.router.url  !== '/'){
  setTimeout(() => {
    this.router.navigate(['/']);
  }, 100);
  // }
}

deleteShoppingCart() {
  const sessionShoppingCartData = JSON.parse(sessionStorage.getItem("shoppingCartDatas"));
  let a = JSON.parse(sessionStorage.getItem('campaign'));
  this.getShoppingCartIDList = [];
  if (sessionShoppingCartData && sessionShoppingCartData.length > 0) {
    let len3 = sessionShoppingCartData.length;
    for (let i = 0; i < len3; i++) {
      this.getShoppingCartIDList.push(sessionShoppingCartData[i].ShoppingCartItemID);
    }
  }
  if (this.getShoppingCartIDList.length > 0) {
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const loggedInData = this.globalService.getLoggedInData();
    
    if (loggedInData !== null) {
      this.cartParams = {
        UserID: loggedInData.UserID,
        CustomerID: webShopSettings.CustomerID,
        CurrencyID: userSettings.CurrencyID,
        DeleteShoppingCartItemIDs: this.getShoppingCartIDList,
      };
    } else {
      this.cartParams = {
        UserID: null,
        CustomerID: webShopSettings.CustomerID,
        CurrencyID: userSettings.CurrencyID,
        DeleteShoppingCartItemIDs: this.getShoppingCartIDList,
      };
    }
    try {
      this._http.deleteShoppingCartItem(this.cartParams).subscribe(
        (_data) => {
          this.campaignService.setCartStatus(true)
        },
        (error) => {
          this.toastr.error(error);
        }
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }

}

getTOPVoucherList(){
  this.loaderShow = true;
  this._httpCategories.getCategories().subscribe({
    next: (data:any) => {
      this.loaderShow = false;
      if(data){
        if (this.isParentCustomer) {
          this.topvoucherList = data.slice(0,10);
          this.remainingTopLists = data.slice(10);
        } else {
          this.topvoucherList = data.slice(0,11);
          this.remainingTopLists = data.slice(11);
        }
          
      }
    }, error: _err => {
      console.log(_err,"err")
      this.loaderShow = false;
    }
  })
  // this.httpVoucher.getTopVoucherCategories().subscribe((data:any)=>{
  //   this.topvoucherList = data;
    /*/data.forEach((e:any, key:any)=>{
      if(key < 20) {
        this.voucherListFilter.push(e);
      }
    })*/
  // })
}

  getVoucherList(){
    this.httpVoucher.getVoucherCategories().subscribe((data:any)=>{
      this.voucherList = data;
    })
  }

  showMenus() {
    this.showMenu = !this.showMenu;
    if(this.showMenu){
      this.brandPlaceHol();
    }
    /*if(this.logIndata){
    
    }else{
      this.toastr.error("Please login to access the feature")
    }*/
  }

  getElementHeight() {
    const x = document.getElementById('catgeoryScreen');
    this.categoryDropdownHeight = x.clientHeight;
    if (x.clientHeight < 320) {
      this.overflow = 'visible'
    } else {
      this.overflowClassY = 'auto'
    }
  }


  voucherProducts(type, id){
    this.showMenu = false;
    this.router.navigate([type, id], { replaceUrl: false })
  }

  discountFilter(type){
    this.discountOption = type;
    let discountOption = this.discountOption;
    let rdOption = this.rdOption;
    this.discount.emit(type);
    if(this.redirect){
      this.router.navigate(['/voucher-filter'], {state: {discountOption, rdOption}})
    }
  }

  redeemFilter(type){
    //this.discountOption = '';
    this.rdOption = type;
    let discountOption = this.discountOption;
    let rdOption = this.rdOption;
    this.redeem.emit(type)
    if(this.redirect){
      this.router.navigate(['/voucher-filter'], {state: {discountOption, rdOption}})
    }
  }
  clearAll(){
    this.rdOption = '';
    this.discountOption = '';
    this.clearFilter.emit(true)
  }
  onFilterUpdate(event, type, isTagValue) {
    this.clearButtonEnableStatus = false;

    if (type === 'sortby' && isTagValue === undefined) {
      this.sortBy = event.SortBy.value;
    } else if (type === 'sortby' && isTagValue !== undefined) {
      this.sortBy = event.value;
    } else if (type === 'branding') {
      this.ShowPricesIncludingLogoType = event.ShowPricesIncludingLogoType.value;
    } else if (type === 'quantity') {
      this.Quantity = event.Quantity.value;
    }
    else { // type === 'sortByColor'
      sessionStorage.setItem('colorListDropDownStatus', JSON.stringify(0));
      this.selectedColorID = event;
    }

    this.CID = this.router.routerState.snapshot.url;
    this.CID = this.CID.split('/');
    if (this.CID.length > 2) {
      this.categoryID = this.CID[2];
    } else {
      if (this.queryParams.CategoryID !== undefined) {
        this.categoryID = this.queryParams.CategoryID;
      } else {
        this.categoryID = '';
      }
    }

    this.postParams = {
      'CategoryID': this.categoryID,
      'ShowPricesIncludingLogoType': this.ShowPricesIncludingLogoType ? this.ShowPricesIncludingLogoType : this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType : null,
      'Quantity': this.Quantity ? this.Quantity : this.queryParams.Quantity ? this.queryParams.Quantity : '',
      'PriceFrom': this.queryParams.PriceFrom ? this.queryParams.PriceFrom : '',
      'PriceTo': this.queryParams.PriceTo ? this.queryParams.PriceTo : '',
      'ColorID': this.selectedColorID ? this.selectedColorID : this.queryParams.ColorID,
      'ProductName': this.queryParams.ProductName ? this.queryParams.ProductName : '',
      'DeliveryDate': this.queryParams.DeliveryDate ? this.queryParams.DeliveryDate : '',
      'SortBy': this.sortBy ? this.sortBy : this.queryParams.SortBy,
      'SupplierQualityLevel': this.SupplierQualityLevel ? this.SupplierQualityLevel : this.queryParams.SupplierQualityLevel,
      'isTagsFilterExists': this.isTagsFilterExists
    };

    // this.selectedSortBy()
  }
  goHome() {
    this.router.navigate(['/']);
  }
}