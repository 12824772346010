import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { GlobalService, CurrencySetterService } from '../../../linknet-common/globals/global.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-print-price',
  templateUrl: './print-price.component.html',
  styleUrls: ['./print-price.component.scss'],
  exportAs: 'child'
})

export class PrintPriceComponent implements OnInit, OnChanges {
  public printPriceLength: any;
  public priceIndex: any;
  public printPrices: any;
  public currency: any;
  public start = 0;
  public end = 3;
  public productQty: any;
  public productPreviousQty: Number;
  public nextButtonDisable: Boolean = false;
  public previousButtonDisable: Boolean = false;
  public productCurrentQtyChange: any;
  public printPriceCalculation = [];
  public checkBoxChangeStatus: Boolean = false;
  public showBrandingMethod: any;
  public ShowPricesIncludingLogoType: Number = 0;
  public LogoTypesCodes = [];
  public emitAutoCheckedLogo = true;
  public selectedLogoTypeID: any = '';
  public selectedLogoPositionID: any = '';
  public selectedLogoMaxAreaID: any = '';
  public isFixedLogos: Boolean = false;
  public isLogosFilterChanged: Boolean = false;
  public fixFixedUnits: Boolean = false;
  public ProductLogoTypesFiltered = [];
  public ProductLogoPositionsFiltered = [];
  public ProductLogoMaxAreasFiltered = [];
  public printPricesFiltered = [];
  public printPriceChecked: Boolean = false;
  public justChangeImage: Boolean = false;
  @Input() productPrices: any;
  @Input() selectedIds = [];
  @Input() productDetail: any;
  @Input() productCurrentQty: any;
  @Input() dynamicProductRangePrices: number;
  @Input() dynamicProductRangeJoinPrices: number;
  @Input() ProductLogoTypes: any;
  @Input() DefaultLogoTypeID: any;
  @Input() ProductLogoPositions: any;
  @Input() DefaultLogoPositionID: any;
  @Input() ProductLogoMaxAreas: any;
  @Input() DefaultLogoMaxAreaID: any;
  @Output() dynamicProductRangePricesChange = new EventEmitter();
  @Output() dynamicProductRangeJoinPricesChange = new EventEmitter();
  @Output() selectedIdsChange = new EventEmitter();
  @Input() selectLogoPositionID: any;
  @Output() selectLogoPositionIDChange = new EventEmitter();
  @ViewChild('ProductLogoModal') public productLogoModal;
  printPriceIndex: any;
  constructor(public currencySetterService: CurrencySetterService, public globalService: GlobalService,
    private toastService: ToastService, private translate: TranslateService, private toastr: ToastrService) {
    try {
      this.currency = this.globalService.getUserSettings().CurrencyCode;
    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnInit() {
    this.translate.get('PRODUCT_DETAIL.RECOMMENDED').subscribe((res: string) => {
      this.ProductLogoPositions.forEach((element) => { if (element.isDefault) element.RecommendedText = " (" + res + ")" });
      this.ProductLogoTypes.forEach((element) => { if (element.isDefault) element.RecommendedText = " (" + res + ")" });
      this.ProductLogoMaxAreas.forEach((element) => { if (element.isDefault) element.RecommendedText = " (" + res + ")" });
    });

    this.ProductLogoPositionsFiltered = this.ProductLogoPositions;
    this.ProductLogoTypesFiltered = this.ProductLogoTypes;
    this.ProductLogoMaxAreasFiltered = this.ProductLogoMaxAreas;

    try {
      this.printPriceIndex = Number(window.sessionStorage.printPriceIndex);
      this.productCurrentQtyChange = this.productCurrentQty;
      this.previousButtonDisable = true;
      if (this.productPrices[this.printPriceIndex].ProductPrintPrices.length <= 3) {
        this.nextButtonDisable = true;
      }
      this.productPreviousQty = this.productCurrentQty;
      this.printPriceLength = this.productPrices[0].ProductPrintPrices.length;
      this.printPrices = this.productPrices[0].ProductPrintPrices ? this.productPrices[0].ProductPrintPrices.slice(this.start, this.end) : null;

      if (this.printPrices[0].isFixedLogo) {
        this.isFixedLogos = true;
        this.printPrices[0].checkedStatus = true;
      }
      
      this.priceIndex = 0;
      this.productQty = this.productDetail.MinimumOrder;
      this.dynamicProductRangePrices = this.printPrices[0].Price;
    } catch (error) {
      this.toastr.error(error);
    }

    this.showBrandingMethod = false;
    this.productCurrentQty = 0;

    // If user searched by quantity, put that value as initial quantity on product details page
    const breadCrumbsList = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
    var searchUrl = breadCrumbsList[breadCrumbsList.length-1].url;
    const idxQuantity = searchUrl.indexOf('Quantity=');
    if (idxQuantity >= 0 && searchUrl.length > idxQuantity + 9) {
      searchUrl = searchUrl.substring(idxQuantity);
      var quantityStr = '';
      if (searchUrl.indexOf('&') === -1) {
        quantityStr = searchUrl.substring(9);
      }
      else {
        quantityStr = searchUrl.substring(9, searchUrl.indexOf('&'));
      }
      if (quantityStr !== '') {
        this.productCurrentQty = Number(quantityStr);
        if (this.productDetail.FixedUnits) {
          this.productCurrentQty = Math.floor(this.productCurrentQty / this.productDetail.PiecesPerUnit);
        }              
      }
    } 

    // If user searched by branding type, cheapest logo of that type should be checked
    var searchUrl = breadCrumbsList[breadCrumbsList.length-1].url;
    const idxBrandingType = searchUrl.indexOf('ShowPricesIncludingLogoType=');
    if (idxBrandingType >= 0 && searchUrl.length > idxBrandingType + 28) {
      searchUrl = searchUrl.substring(idxBrandingType);
      var brandingStr = '';
      if (searchUrl.indexOf('&') === -1) {
        brandingStr = searchUrl.substring(28);
      }
      else {
        brandingStr = searchUrl.substring(28, searchUrl.indexOf('&'));
      }
      if (brandingStr !== '') {
        this.ShowPricesIncludingLogoType = Number(brandingStr);
        if (this.ShowPricesIncludingLogoType >= 1 && this.ShowPricesIncludingLogoType <= 4)
        {
          this.LogoTypesCodes.push("SILKSCREEN");
          this.LogoTypesCodes.push("PAD");
          this.LogoTypesCodes.push("SCREEN");
          this.LogoTypesCodes.push("TRANSFER");
          this.LogoTypesCodes.push("DOMING");
          this.LogoTypesCodes.push("ALLOVER");
          this.LogoTypesCodes.push("CERAMICXP");
          this.LogoTypesCodes.push("CERAMICPAD");
          this.LogoTypesCodes.push("CYLINDRICALSCREEN");
          this.LogoTypesCodes.push("VINYLSTICKER");
          this.LogoTypesCodes.push("LABEL");
          this.LogoTypesCodes.push("WPTRANSFER");
        }
        else if (this.ShowPricesIncludingLogoType == 5)
        {
          this.LogoTypesCodes.push("EMBROIDERY");
        }
        else if (this.ShowPricesIncludingLogoType == 6)
        {
          this.LogoTypesCodes.push("ENGRAVING");
          this.LogoTypesCodes.push("3DENGRAVING");
        }
        else if (this.ShowPricesIncludingLogoType == 7)
        {
          this.LogoTypesCodes.push("EMBOSSING");
        }
        else if (this.ShowPricesIncludingLogoType == 8)
        {
          this.LogoTypesCodes.push("DEBOSSING");
        }
        else if (this.ShowPricesIncludingLogoType == 9)
        {
          this.LogoTypesCodes.push("SUBLIMATION");
          this.LogoTypesCodes.push("DIGITAL");
          this.LogoTypesCodes.push("CMYK");
        }        
      }
    }

    const changes = {
      'productCurrentQty': {
        'currentValue': this.productCurrentQty,
        'firstChange': false,
        'previousValue': this.productCurrentQty
      }
    }

    this.ngOnChangeFn(changes);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.justChangeImage) {
      this.justChangeImage = false;
      return;
    }

    //console.log("ngOnChanges");
    this.fixFixedUnits = false;
    if (this.productCurrentQtyChange !== this.productCurrentQty && changes.productCurrentQty && changes.productCurrentQty.previousValue !== undefined) {
      this.checkBoxChangeStatus = false;
      this.start = 0;
      this.end = 3;
      this.previousButtonDisable = true;
      this.nextButtonDisable = false;
      this.printPriceCalculation = [];
      this.selectedIds = [];
      setTimeout(() => {
        let totalPrice = 0;
        totalPrice = Number(totalPrice.toFixed(2));
        this.dynamicProductRangePrices = totalPrice;
        this.dynamicProductRangePricesChange.emit(totalPrice);
        this.dynamicProductRangeJoinPricesChange.emit(totalPrice);
        this.selectedIdsChange.emit(this.selectedIds);
      }, 300);
      this.emitAutoCheckedLogo = true;
      this.fixFixedUnits = true;
    }

    // To Check Fixed Units
    if (this.productDetail.FixedUnits && changes.productCurrentQty && (this.ShowPricesIncludingLogoType == 0 || this.fixFixedUnits)) {
      this.productCurrentQty = this.productDetail.PiecesPerUnit * this.productCurrentQty;
    }

    if (!this.checkBoxChangeStatus) {
      // Qty Enter => Price Range Hide when qty is below MinimumQty and Max of Max Price Value
      const minimumOrder = this.productDetail.MinimumOrder;
      const priceArrayLength = this.productDetail.ProductPrices.length;
      const maxQtyValue = this.productDetail.ProductPrices[priceArrayLength - 1].MaximumQuantity;
      this.productCurrentQtyChange = this.productCurrentQty;

      if (this.productPrices.length > 0) {
        (this.productPrices).forEach((element, index) => {
          if (element.Price !== undefined) {
            if ((this.productCurrentQty >= element.MinimumQuantity) && (this.productCurrentQty <= element.MaximumQuantity)) {
              this.printPriceLength = this.productPrices[index].ProductPrintPrices.length;
              this.printPrices = this.productPrices[index].ProductPrintPrices ? this.productPrices[index].ProductPrintPrices.slice(this.start, this.end) : null;
              var isFixedLogos = false;
              if (this.ShowPricesIncludingLogoType == 0) {
                this.selectedIds = [];
              }
              (this.productPrices[index].ProductPrintPrices).forEach((element1) => {
                if (!this.printPrices[0].isFixedLogo) {
                  element1.checkedStatus = false;
                }
                else {
                  element1.checkedStatus = true;
                  isFixedLogos = true;
                }
              });

              // If user searched by branding method type, cheapest logo of that type should be pre-checked
              if (!isFixedLogos && this.ShowPricesIncludingLogoType > 0)
              {
                this.selectBranding(index);
                
                if (this.emitAutoCheckedLogo) {
                  this.emitAutoCheckedLogoFunction();
                }
              }

              this.priceIndex = index;
              this.productQty = this.productCurrentQty;
              if (this.productPrices[index].ProductPrintPrices.length <= 3) {
                this.nextButtonDisable = true;
              }
              if (this.printPriceLength !== 0) {
                this.showBrandingMethod = true;
              } 
              else {
                this.showBrandingMethod = false;
              }

              if (!isFixedLogos && (this.ShowPricesIncludingLogoType == 0 || this.isLogosFilterChanged)) {
                this.applyLogoFilters(false, false);
              }
            }
          }
        });
      }

      // Qty Enter => Price Range Hide when qty is below MinimumQty
      if (this.productCurrentQty < minimumOrder || this.productCurrentQty > maxQtyValue) {
        this.printPrices = [];
        this.previousButtonDisable = true;
        this.nextButtonDisable = true;
        this.productQty = '';
        this.isLogosFilterChanged = false;
      }

      // When product input qty change then select radio is set to position : 0
      if (this.productPreviousQty !== this.productCurrentQty) {
        this.productPreviousQty = this.productCurrentQty;
      }
    }
  }

  emitAutoCheckedLogoFunction() {
      this.emitAutoCheckedLogo = false;      
      setTimeout(() => {
        this.selectedIdsChange.emit(this.selectedIds);
      }, 1);             
  }

  ngOnChangeFn(changes) {
    //console.log("ngOnChangeFn");
    if (this.productCurrentQtyChange !== this.productCurrentQty && changes.productCurrentQty && changes.productCurrentQty.previousValue !== undefined) {
      this.checkBoxChangeStatus = false;
      this.start = 0;
      this.end = 3;
      this.previousButtonDisable = true;
      this.nextButtonDisable = false;
      this.printPriceCalculation = [];
      this.selectedIds = [];
      setTimeout(() => {
        let totalPrice = 0;
        totalPrice = Number(totalPrice.toFixed(2));
        this.dynamicProductRangePrices = totalPrice;
        this.dynamicProductRangePricesChange.emit(totalPrice);
        this.dynamicProductRangeJoinPricesChange.emit(totalPrice);
        this.selectedIdsChange.emit(this.selectedIds);
      }, 300);
    }

    // Check Fixed Units
    if (this.productDetail.FixedUnits && changes.productCurrentQty) {
      this.productCurrentQty = this.productDetail.PiecesPerUnit * this.productCurrentQty;
    }

    if (!this.checkBoxChangeStatus) {
      // Qty Enter => Price Range Hide when qty is below MinimumQty and Max of Max Price Value
      const minimumOrder = this.productDetail.MinimumOrder;
      const priceArrayLength = this.productDetail.ProductPrices.length;
      const maxQtyValue = this.productDetail.ProductPrices[priceArrayLength - 1].MaximumQuantity;
      this.productCurrentQtyChange = this.productCurrentQty;

      if (this.productPrices.length > 0) {
        (this.productPrices).forEach((element, index) => {
          if (element.Price !== undefined) {
            if ((this.productCurrentQty >= element.MinimumQuantity) && (this.productCurrentQty <= element.MaximumQuantity)) {
              this.printPriceLength = this.productPrices[index].ProductPrintPrices.length;
              this.printPrices = this.productPrices[index].ProductPrintPrices ? this.productPrices[index].ProductPrintPrices.slice(this.start, this.end) : null;
              var isFixedLogos = false;
              (this.productPrices[index].ProductPrintPrices).forEach((element1) => {
                this.selectedIds = [];
                if (!this.printPrices[0].isFixedLogo) {
                  element1.checkedStatus = false;
                } else {
                  element1.checkedStatus = true;
                  isFixedLogos = true;
                }
              });

              // If user searched by branding method type, cheapest logo of that type should be pre-checked
              if (!isFixedLogos && this.ShowPricesIncludingLogoType > 0 && !this.isLogosFilterChanged)
              {
                this.ngOnChanges(changes);
              }

              this.priceIndex = index;
              this.productQty = this.productCurrentQty;
              if (this.productPrices[index].ProductPrintPrices.length <= 3) {
                this.nextButtonDisable = true;
              }

              if (this.printPriceLength !== 0) {
                this.showBrandingMethod = true;
              } 
              else {
                this.showBrandingMethod = false;
              }

              if (!isFixedLogos && (this.ShowPricesIncludingLogoType == 0 || this.isLogosFilterChanged)) {
                this.applyLogoFilters(false, false);
              }
            }
          }
        });
      }

      // Qty Enter => Price Range Hide when qty is below MinimumQty
      if (this.productCurrentQty < minimumOrder || this.productCurrentQty > maxQtyValue) {
        this.printPrices = [];
        this.previousButtonDisable = true;
        this.nextButtonDisable = true;
        this.productQty = '';
        this.isLogosFilterChanged = false;
      }

      // When product input qty change then select radio is set to position : 0
      if (this.productPreviousQty !== this.productCurrentQty) {
        this.productPreviousQty = this.productCurrentQty;
      }
    }
  }

  selectBranding(priceIndex) {
    // Find all print prices for selected brandind method
    var printPricesWithBranding = this.productPrices[priceIndex].ProductPrintPrices.filter(ppp => this.LogoTypesCodes.indexOf(ppp.LogoTypeCode) !== -1 && 
      (Number(ppp.NumberOfColors) === this.ShowPricesIncludingLogoType || Number(ppp.NumberOfColors) === 0 || this.ShowPricesIncludingLogoType > 4));
    
    var printPricesWithBrandingSorted = printPricesWithBranding.sort((n1,n2) => n1.Price - n2.Price);
    if (printPricesWithBrandingSorted.length > 0) {
      var minPPP = printPricesWithBrandingSorted[0];

      (this.productPrices[priceIndex].ProductPrintPrices).forEach((element1, index1) => {
        if (element1.LogoTypeID === minPPP.LogoTypeID && element1.NumberOfColors === minPPP.NumberOfColors && element1.LogoPositionID ===  minPPP.LogoPositionID && element1.LogoMaxAreaID === minPPP.LogoMaxAreaID) {
          element1.checkedStatus = true;
          this.printPriceChecked = true;

          const checkExists = this.printPriceCalculation.filter(data => data.LogoTypeID === element1.LogoTypeID && data.NumberOfColors === element1.NumberOfColors && data.LogoPositionID === element1.LogoPositionID && data.LogoMaxAreaID === element1.LogoMaxAreaID);
          if (checkExists.length === 0) {
            this.printPriceCalculation.push({ 'LogoTypeID': element1.LogoTypeID, 'NumberOfColors': element1.NumberOfColors, 'LogoPositionID': element1.LogoPositionID, 'LogoMaxAreaID': element1.LogoMaxAreaID, 'price': element1.Price });
            this.selectedIds = [];
            this.selectedIds.push(element1.ProductPrintPriceID);
          }

          if (index1 > 2) {
            // If branding is not visible (not among first 3 brandings, scroll brandings list to the right)
            this.scrollPrices(index1 - 2);
          }

          setTimeout(() => {
            let totalPrice = Number(element1.Price);
            this.dynamicProductRangePrices = totalPrice;
            this.dynamicProductRangePricesChange.emit(totalPrice);
            this.dynamicProductRangeJoinPricesChange.emit(totalPrice);
          }, 1000);
        } 
        else {
          element1.checkedStatus = false;
        }
      });
    }
  }

  applyLogoFilters(isLogosFilterChanged, isLogoPositionChanged) {
    //console.log("applyLogoFilters");
    if (this.priceIndex != undefined) {
      if (isLogosFilterChanged) this.isLogosFilterChanged = true;
      this.printPriceChecked = false;

      this.printPricesFiltered = [];
      (this.productPrices[this.priceIndex].ProductPrintPrices).forEach((element1) => {
        this.printPricesFiltered.push(element1);
      });

      if (this.selectedLogoTypeID != '') {
        this.printPricesFiltered = this.printPricesFiltered.filter(ppp => ppp.LogoTypeID == this.selectedLogoTypeID || ppp.checkedStatus);
        this.isLogosFilterChanged = true;
      }
      if (this.selectedLogoPositionID != '') {
        this.printPricesFiltered = this.printPricesFiltered.filter(ppp => ppp.LogoPositionID == this.selectedLogoPositionID || ppp.LogoPositionID == undefined || ppp.checkedStatus);
        this.isLogosFilterChanged = true;
      }
      if (this.selectedLogoMaxAreaID != '') {
        this.printPricesFiltered = this.printPricesFiltered.filter(ppp => ppp.LogoMaxAreaID == this.selectedLogoMaxAreaID || ppp.LogoMaxAreaID == undefined || ppp.checkedStatus);
        this.isLogosFilterChanged = true;
      }

      // Put checked print prices at the beginning
      this.printPricesFiltered = this.printPricesFiltered.sort(function(x, y) {return (x.checkedStatus === y.checkedStatus) ? 0 : x.checkedStatus ? -1 : 1;});

      this.start = 0;
      this.end = 3;
      this.previousButtonDisable = true;
      this.nextButtonDisable = this.printPricesFiltered.length <= 3;

      this.printPrices = this.printPricesFiltered.slice(this.start, this.end);

      // Put filters back to original state (with all items in them)
      this.ProductLogoPositionsFiltered = [];
      this.ProductLogoPositions.forEach((element) => { this.ProductLogoPositionsFiltered.push(element) });
      this.ProductLogoTypesFiltered = [];
      this.ProductLogoTypes.forEach((element) => { this.ProductLogoTypesFiltered.push(element) });
      this.ProductLogoMaxAreasFiltered = [];
      this.ProductLogoMaxAreas.forEach((element) => { this.ProductLogoMaxAreasFiltered.push(element) });

      // Disable Logo Positions which would result in 0 print prices
      this.ProductLogoPositionsFiltered.forEach((element) => {
        if (this.productPrices[this.priceIndex].ProductPrintPrices.filter(ppp => 
              (ppp.LogoPositionID == element.LogoPositionID || ppp.LogoPositionID == undefined) &&
              (this.selectedLogoTypeID == '' || ppp.LogoTypeID == this.selectedLogoTypeID) &&
              (this.selectedLogoMaxAreaID == '' || ppp.LogoMaxAreaID == this.selectedLogoMaxAreaID || ppp.LogoMaxAreaID == undefined)).length == 0)
          element.isDisabled = true;
        else
          element.isDisabled = false;
      });
      // Enabled Logo Positions should be shown before disabled ones (default Logo Position should be first among enabled or disabled ones)
      this.ProductLogoPositionsFiltered.sort(function(x, y) {return (x.isDisabled === y.isDisabled) ? (x.isDefault ? -2 : 0) : x.isDisabled ? 1 : -1;});

      // Disable Logo Types which would result in 0 print prices
      this.ProductLogoTypesFiltered.forEach((element) => {
        if (this.productPrices[this.priceIndex].ProductPrintPrices.filter(ppp => 
              ppp.LogoTypeID == element.LogoTypeID &&
              (this.selectedLogoPositionID == '' || ppp.LogoPositionID == this.selectedLogoPositionID || ppp.LogoPositionID == undefined) &&
              (this.selectedLogoMaxAreaID == '' || ppp.LogoMaxAreaID == this.selectedLogoMaxAreaID || ppp.LogoMaxAreaID == undefined)).length == 0)
          element.isDisabled = true;
        else
          element.isDisabled = false; 
      });
      // Enabled Logo Types should be shown before disabled ones (default Logo Type should be first among enabled or disabled ones)
      this.ProductLogoTypesFiltered.sort(function(x, y) {return (x.isDisabled === y.isDisabled) ? (x.isDefault ? -2 : 0) : x.isDisabled ? 1 : -1;});

      // Disable Logo Areas which would result in 0 print prices
      this.ProductLogoMaxAreasFiltered.forEach((element) => {
        if (this.productPrices[this.priceIndex].ProductPrintPrices.filter(ppp => 
              (ppp.LogoMaxAreaID == element.LogoMaxAreaID || ppp.LogoMaxAreaID == undefined) &&
              (this.selectedLogoPositionID == '' || ppp.LogoPositionID == this.selectedLogoPositionID || ppp.LogoPositionID == undefined) &&
              (this.selectedLogoTypeID == '' || ppp.LogoTypeID == this.selectedLogoTypeID)).length == 0)
          element.isDisabled = true;
        else
          element.isDisabled = false;   
      });
      // Enabled Logo Areas should be shown before disabled ones
      this.ProductLogoMaxAreasFiltered.sort(function(x, y) {return (x.isDisabled === y.isDisabled) ? 0 : x.isDisabled ? 1 : -1;});

      if (isLogoPositionChanged) {
        // Emit changed LogoPositionID to image-block.component (to show image for that logo position, if any)
        this.justChangeImage = true;
        this.selectLogoPositionID = this.selectedLogoPositionID;
        this.selectLogoPositionIDChange.emit(this.selectedLogoPositionID);
      }
    }
  }

  selectCheapestBranding() {
    if (this.priceIndex != undefined && this.productPrices[this.priceIndex].ProductPrintPrices.length > 0) {
      var printPricesSorted = [];
      (this.productPrices[this.priceIndex].ProductPrintPrices).forEach((element1) => {
        printPricesSorted.push(element1);
        element1.checkedStatus = false;
      });

      printPricesSorted = printPricesSorted.sort((n1,n2) => n1.Price - n2.Price);
      var minPPP = printPricesSorted[0];
      
      this.printPriceCalculation = [];
      this.printPriceCalculation.push({ 'LogoTypeID': minPPP.LogoTypeID, 'NumberOfColors': minPPP.NumberOfColors, 'LogoPositionID': minPPP.LogoPositionID, 'LogoMaxAreaID': minPPP.LogoMaxAreaID, 'price': minPPP.Price });
      this.selectedIds = [];
      this.selectedIds.push(minPPP.ProductPrintPriceID);

      this.start = 0;
      this.end = 3;
      this.previousButtonDisable = true;
      this.nextButtonDisable = true;

      this.printPrices = printPricesSorted.filter(ppp => ppp.ProductPrintPriceID == minPPP.ProductPrintPriceID);
      this.printPrices[0].checkedStatus = true;
      this.isLogosFilterChanged = true;
      this.checkBoxChangeStatus = true;

      let totalPrice = Number(minPPP.Price.toFixed(2));
      this.dynamicProductRangePrices = totalPrice;
      this.dynamicProductRangePricesChange.emit(totalPrice);
      this.dynamicProductRangeJoinPricesChange.emit(totalPrice);
      this.selectedIdsChange.emit(this.selectedIds);

      // If cheapest print price has LogoPositionID, emit changed LogoPositionID to image-block.component (to show image for that logo position, if any)
      if (minPPP.LogoPositionID != undefined || this.selectLogoPositionID != undefined) {
        this.selectLogoPositionID = minPPP.LogoPositionID;
        this.selectLogoPositionIDChange.emit(minPPP.LogoPositionID);
      }

      // Put filters back to original state (with all items in them)
      this.ProductLogoPositionsFiltered = [];
      this.ProductLogoPositions.forEach((element) => { this.ProductLogoPositionsFiltered.push(element) });
      this.ProductLogoTypesFiltered = [];
      this.ProductLogoTypes.forEach((element) => { this.ProductLogoTypesFiltered.push(element) });
      this.ProductLogoMaxAreasFiltered = [];
      this.ProductLogoMaxAreas.forEach((element) => { this.ProductLogoMaxAreasFiltered.push(element) });

      // Enable all items in filters
      this.ProductLogoPositionsFiltered.forEach(element => { element.isDisabled = false; });
      this.ProductLogoPositionsFiltered.sort(function(x) {return (x.isDefault ? -1 : 0)});
      this.ProductLogoTypesFiltered.forEach(element => { element.isDisabled = false; });
      this.ProductLogoTypesFiltered.sort(function(x) {return (x.isDefault ? -1 : 0)});
      this.ProductLogoMaxAreasFiltered.forEach(element => { element.isDisabled = false; });
    }
  }

  scrollPrices(count) {
    if (this.start === 0) {
      for (let index = 1; index <= count; index++) {
        if (!this.nextButtonDisable) {
          this.next();
        }
      }
    }
  }

  next() {
    try {
      const productPriceLength = this.printPricesFiltered.length;
      if (this.end < productPriceLength) {
        this.end = this.end + 1;
        this.start = this.start + 1;
        this.printPrices = this.printPricesFiltered.slice(this.start, this.end);
      }
      this.previousButtonDisable = false;
      if (productPriceLength === this.end) {
        this.nextButtonDisable = true;
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  pre() {
    try {
      const productPriceLength = this.printPricesFiltered.length;
      if (this.start > 0) {
        this.end = this.end - 1;
        this.start = this.start - 1;
        this.printPrices = this.printPricesFiltered.slice(this.start, this.end);
      }
      if (productPriceLength > 3) {
        this.nextButtonDisable = false;
      }
      if (this.start === 0) {
        this.previousButtonDisable = true;
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  changePrice(price, event, productPrintPriceID, logoTypeID, numberOfColors, logoPositionID, logoMaxAreaID, ppp) {
    this.checkBoxChangeStatus = true;
    let totalPrice = 0;

    const checkExists = this.printPriceCalculation.filter(data => data.LogoTypeID === logoTypeID && data.NumberOfColors === numberOfColors && data.LogoPositionID === logoPositionID && data.LogoMaxAreaID === logoMaxAreaID);
    
    if (event && checkExists.length === 0) {
      if (logoPositionID != undefined) {
        // Check if there is already checked logo on that same logo position
        const checkLogoPosition = this.printPriceCalculation.filter(data => data.LogoPositionID === logoPositionID);
        if (checkLogoPosition.length > 0) {
          // const errorValue = this.translate.get('SHOPPING_CART.DOUBLE_LOGO_SAME_POSITION');
          // this.toastService.error(errorValue['value']);
          this.translate.get('SHOPPING_CART.DOUBLE_LOGO_SAME_POSITION').subscribe((res: string) => {
            this.toastr.error(res);
          });

          setTimeout(() => { ppp.checkedStatus = false; }, 500);
          return;
        }
      }

      this.printPriceChecked = true;
      this.printPriceCalculation.push({ 'LogoTypeID': logoTypeID, 'NumberOfColors': numberOfColors, 'LogoPositionID': logoPositionID, 'LogoMaxAreaID': logoMaxAreaID, 'price': price });
      
      // emit to image-block.component
      if (logoPositionID != undefined || this.selectLogoPositionID != undefined) {
        if (logoPositionID == undefined) this.selectLogoPositionID = '';
        else this.selectLogoPositionID = logoPositionID;
        this.selectLogoPositionIDChange.emit(this.selectLogoPositionID);
      }
    }
    else if (!event && checkExists.length > 0) {
      const index = (this.printPriceCalculation.findIndex(data => data.LogoTypeID === logoTypeID && data.NumberOfColors === numberOfColors && data.LogoPositionID === logoPositionID && data.LogoMaxAreaID === logoMaxAreaID));
      this.printPriceCalculation.splice(index, 1);

      // emit to image-block.component
      if (logoPositionID == undefined || this.selectLogoPositionID == logoPositionID) {
        if (this.selectedLogoPositionID != '') this.selectLogoPositionID = this.selectedLogoPositionID;
        else this.selectLogoPositionID = '';
        this.selectLogoPositionIDChange.emit(this.selectLogoPositionID);
      }

      // If unchecked print price is not according to filters, remove it from the list of print prices
      if (this.selectedLogoTypeID != '') {
        this.printPricesFiltered = this.printPricesFiltered.filter(ppp => ppp.LogoTypeID == this.selectedLogoTypeID || ppp.checkedStatus);
      }
      if (this.selectedLogoPositionID != '') {
        this.printPricesFiltered = this.printPricesFiltered.filter(ppp => ppp.LogoPositionID == this.selectedLogoPositionID || ppp.LogoPositionID == undefined || ppp.checkedStatus);
      }
      if (this.selectedLogoMaxAreaID != '') {
        this.printPricesFiltered = this.printPricesFiltered.filter(ppp => ppp.LogoMaxAreaID == this.selectedLogoMaxAreaID || ppp.LogoMaxAreaID == undefined || ppp.checkedStatus);
      }
      this.printPrices = this.printPricesFiltered.slice(this.start, this.end);
      if (this.printPrices.length < 3 && this.printPricesFiltered.length >= 3) {
        this.pre();
      }
      this.nextButtonDisable = this.printPricesFiltered.length <= this.end;
    }

    if (this.printPriceCalculation.length > 0) {
      this.printPriceCalculation.forEach(function (i) {
        totalPrice += Number(i.price);
        totalPrice = Number(totalPrice.toFixed(2));
      });
    }

    this.dynamicProductRangePrices = totalPrice;
    this.dynamicProductRangePricesChange.emit(totalPrice);
    this.dynamicProductRangeJoinPricesChange.emit(totalPrice);

    if (event === true) {
      this.selectedIds.push(productPrintPriceID);
    } 
    else {
      for (let i = 0; i < this.selectedIds.length; i++) {
        if (this.selectedIds[i] === productPrintPriceID) {
          this.selectedIds.splice(i, 1);
        }
      }
    }
    this.selectedIdsChange.emit(this.selectedIds);
  }

  showProductLogoModal() {
    this.productLogoModal.show();
  }
}
