import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'shop-app-product-batch-colors',
  templateUrl: './product-batch-colors.component.html',
  styleUrls: ['./product-batch-colors.component.scss']
})
export class ProductBatchColorsComponent implements OnInit {
  prodSize: any;
  @Input() productDetail: any = {};
  @Input() withHeader: any;
  @Input() size: String;
  @Input() minimum: any;
  @Input() showLength: any;
  @Input() selectColorId: any;
  @Output() selectColorIdChange = new EventEmitter();
  @Input() shoppingCartData: any;
  @Output() callSelectedColorAPI = new EventEmitter();

  public start: any = 0;
  public end: any;
  public prodColors: any;
  public batchColorStatus: Boolean = false;
  public stockBalanceStatus: Boolean = false;
  public productColorStockStatus: Boolean = false;
  public productColorFutureStockStatus: Boolean = false;
  public productSizeFutureStockStatus: Boolean = false;
  constructor() { }

  ngOnInit() {
    this.withHeader = this.withHeader ? this.withHeader : 'true';
    this.size = this.size ? this.size : 'lg';
    this.minimum = this.minimum ? this.minimum : null;
    if (this.productDetail.ProductBatchColors && this.productDetail.ProductBatchColors.length > 0) {
      this.end = this.minimum ? this.minimum : this.productDetail.ProductBatchColors.length;
      if (this.productDetail.ProductBatchColors.length > 0) {
        this.prodColors = this.productDetail.ProductBatchColors;
        this.batchColorStatus = true;
      }

      /** new-code => based on Product parent color id from Advanced search page */
      if (this.selectColorId !== null && this.selectColorId !== undefined) {
        let colorIndex = (this.productDetail.ProductBatchColors.findIndex(data => Number(data.ProductColorID) ===
          Number(this.selectColorId)));
        if (colorIndex < 0) {
          colorIndex = (this.productDetail.ProductBatchColors.findIndex(data => Number(data.ParentColorID) ===
            Number(this.selectColorId)));
          if (colorIndex >= 0) {
            this.selectColorId = this.productDetail.ProductBatchColors[colorIndex].ParentColorID;
            this.productDetail.ProductBatchColors[colorIndex].isSelected = true;
          }
        }
        this.productDetail.ProductBatchColors[colorIndex].isSelected = true;
        this.prodColors = this.productDetail.ProductBatchColors;
      }
      /** new-code => based on Product parent color id from Advanced search page */
      console.log("this.prodColors : ", this.prodColors);
    }

    if (this.productDetail.ProductBatchColors && this.productDetail.ProductBatchColors.length > 0) {
      for (let i = 0; i < this.productDetail.ProductBatchColors.length; i++) {
        if (this.productDetail.ProductBatchColors[i].ProductFutureStock.length > 0
          || Number(this.productDetail.ProductBatchColors[i].StockBalance) >= 0) {
          this.productColorStockStatus = true;
          if (this.productDetail.ProductBatchColors[i].ProductFutureStock.length > 0) {
            this.productColorFutureStockStatus = true;
          }
        }
      }
    }


    if (this.productDetail.ProductBatchColors && this.productDetail.ProductBatchSizes.length > 0) {
      for (let i = 0; i < this.productDetail.ProductBatchSizes.length; i++) {
        if (this.productDetail.ProductBatchSizes[i].ProductFutureStock &&
          this.productDetail.ProductBatchSizes[i].ProductFutureStock.length > 0
          || Number(this.productDetail.ProductBatchSizes[i].StockBalance) >= 0) {
          if (this.productDetail.ProductBatchSizes[i].ProductFutureStock &&
            this.productDetail.ProductBatchSizes[i].ProductFutureStock.length > 0) {
            this.productSizeFutureStockStatus = true;
          }
        }
      }
    }

    if (this.selectColorId > 0 && (this.shoppingCartData.ShoppingCartItemID === 0)) {
      this.callSelectedColorAPI.emit(Number(this.selectColorId));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("Changes In Batch Color");
    console.log(changes);
    console.log(this.shoppingCartData);
    if (this.shoppingCartData !== null && this.shoppingCartData !== '' && this.shoppingCartData !== undefined) {
      if (this.shoppingCartData.ProductColorID !== '' && this.shoppingCartData.ProductColorID !== null &&
        this.shoppingCartData.ProductColorID !== undefined) {
        this.selectColorId = this.shoppingCartData.ProductColorID;
      }
    }

    if (changes.selectColorId) {
      if (Number(changes.selectColorId.currentValue) !== 0 && changes.selectColorId.currentValue !== undefined) {
        this.selectColorId = Number(changes.selectColorId.currentValue);
        this.callSelectedColorAPI.emit(Number(changes.selectColorId.currentValue));
      }
    }
  }

  selectedColor(colorId) {
    this.selectColorId = colorId;
    this.selectColorIdChange.emit(colorId);
  }
}
