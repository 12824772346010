import { Component, ElementRef, Injectable, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "app/linknet-common/globals/global.service";
import { SecurityService, WebshopService } from "app/linknet-common/restful-services/restful.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'shop-app-voucher-login',
    templateUrl: './voucher-login.component.html',
    styleUrls: ['./voucher-login.component.scss'],
    exportAs: 'child'
  })
  
  @Injectable()
  
  export class VoucherLoginComponent implements OnInit  {
    @ViewChild('loginVoucherModal') public loginVoucherModal;
    mobilenumber: any;
    mFocus: boolean = false;
    eFocus: boolean = false;
    oFocus: boolean = false;
    signUp: boolean = false;
    fullName: any;
    userOTP: any;
    userEmail: any;
    recieverEmailVaild: boolean;
    webShopData: any;
    userRegistered: boolean = false;
    loginSection: boolean;
    userDetail: any;
    CID: any;
    swurl: any;
    translatedMessage: any;
    messageDataService: any;
    resndEnable: boolean = true;
  loaderShow: boolean;
  @ViewChild('inputField') inputField:ElementRef;
    constructor(private _httpWebshopService: WebshopService, public router: Router, public translate: TranslateService,private _http: SecurityService,  public globalService: GlobalService, private toastr: ToastrService){
        this.webShopData = this.globalService.webShopSettings();
    }
    ngOnInit(){
        
    }
    focus(){
      this.mFocus = true;
      this.inputField.nativeElement.focus();
    }
    showModal() {
        this.signUp = false;
        this.loginSection = false;
        this.mobilenumber = '';
        this.fullName = '';
        this.userOTP = '';
        this.userEmail = '';
        this.loginVoucherModal.show();
    }
    
    hideModal() {
       this.loginVoucherModal.hide();
    }

    onlyNumberKey(event) {
        const pattern = new RegExp('^[0-9\b]+$');
        const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!pattern.test(key) && event.charCode !== 0) {
          event.preventDefault();
          return false;
        }
    }
    onInputChange(event: any) {
        const pattern = new RegExp('^[a-zA-Z \b]+$');
        const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!pattern.test(key) && event.charCode !== 0) {
          event.preventDefault();
          return false;
        }
    }

    emailCheck(){
        if (this.userEmail && this.userEmail.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/)) {
            this.recieverEmailVaild = true;
        } else {
            this.recieverEmailVaild = false;
        }
    }

    validateMobile(){
        if(this.mobilenumber.length !== 10){
            this.toastr.error('Please enter valid mobile number')
        }else{
            this._http.vcUser(this.mobilenumber).subscribe((data:any) => {
                if(!data){
                    this.signUp = true;
                    this.genMobileOTP();
                }else{
                    this.loginSection = true;
                    this.genOTP();
                }
                if(this.resndEnable){
                    this.resndEnable = false;
                    setTimeout(()=>{
                        this.resndEnable = true;
                    }, 60000)
                }
            })
        }
    }

    genMobileOTP(){
    const data = 'Mobile='+ this.mobilenumber + '&CustomerName=&UserName='+ this.mobilenumber + '&language_id=1&CustomerTheme=#f23d49';
    this._httpWebshopService.triggerMobileOTP(data).subscribe((_res)=>{

    },_error=>{
      this.toastr.error('Error in generating OTP');
    });
    }

    genOTP(){
        this._http.vcGenOTP(this.mobilenumber).subscribe((_data:any) => {
            this.userOTP = '';
        });
    }

    validateOTP(){
        let param = {
            mobile: this.mobilenumber,
            OTP: this.userOTP
        }
        this._http.vcValOTP(param).subscribe((data:any) => {
            if(data){
                this.loggedIn(data);
                this.loginVoucherModal.hide();
            }else{
                this.toastr.error("Invalid OTP");
            }
        }, (_error) => {
          this.toastr.error("Invalid OTP");
          this.loaderShow = false;
        });
    }

    loggedIn(data){
        let loginStatus = true;
        if (data['RequireUsersApproval']) {
          if (data['IsRequireUsersApproved']) {
            loginStatus = true;
          } else {
            loginStatus = false;
            this.translate.get('LOGIN.PENDING_USER_ERROR_MSG').subscribe((res: string) => {
              this.translatedMessage = res;
            });
            this.toastr.error(this.translatedMessage);
          }
          //this.loginModal.hide();
        } else {
          loginStatus = true;
        }
    
        if (loginStatus) {
          this.userDetail = data;
          // Save data to sessionStorage
          sessionStorage.setItem('loggedInData', JSON.stringify(this.userDetail));
          this.globalService.loggedin(data);
          this.globalService.overwriteSession('userSettings', 'LanguageCode', data['UserLanguageCode']);
          this.globalService.overwriteSession('userSettings', 'LanguageID', data['LanguageID']);
          this.globalService.overwriteSession('userSettings', 'CurrencyCode', data['UserCurrencyCode']);
          this.globalService.overwriteSession('userSettings', 'CurrencyID', data['CurrencyID']);
          sessionStorage.removeItem('categoryList');
          sessionStorage.removeItem('categoryOptionList');
          sessionStorage.removeItem('colorsList');
          //this.loginModal.hide();
          // if (this.router.navigate(['/'], { replaceUrl: false })) {
          //   setTimeout(() => {
          //     location.reload();
          //   }, 100);
          // }
          if(sessionStorage.getItem('vocuherCartsItems')){
            let cartParam = JSON.parse(sessionStorage.getItem('vocuherCartsItems'))
            cartParam.UserID = this.userDetail.UserID;
            cartParam.Item.UserID = this.userDetail.UserID;
            this.loaderShow = true;
            this._httpWebshopService.addShoppingCart(cartParam).subscribe((data:any)=>{
            this.loaderShow = false;
            if(data){
                //this.shoppingCartItemID = data.Item.ShoppingCartItemID;
                
                    const shoppingCartCount = Number(sessionStorage.getItem("shoppingCartCount")) + 1;
                    sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
                    this.toastr.success('Voucher added to cart!');
                
                setTimeout(() => {
                  location.reload();
                }, 100);
                //this.router.navigate(['/checkout'])
            }
        }, _error=>{
            this.loaderShow = false;
        })
          }else{
            setTimeout(() => {
              location.reload();
            }, 100);
          }
          
        }
      
      }

    userSignUp(){
        let params = {
            "Name": this.fullName,
            "UserName": this.mobilenumber, 
            "Password": this.userOTP,
            "CustomerID": this.webShopData.CustomerID,
            "LanguageID": 1,
            "CurrencyID": 20,
            "PhoneNumber": this.mobilenumber,
            "DomainName": this.webShopData.DomainName,
            "City": "",
            "Email": this.userEmail,
            "DeliveryCountry": "India",
            "RoleCode": "User",
            "Country": "India",
            "PostalCode": "",
            "DeliveryAddress": "",
            "DeliveryCity": "",
            "DeliveryPostalCode": "",
            "UserType": "U",
            "isDeleted": false,
            "PaymentByInvoice": false,
            "PaymentByCard": true
        }
        this._http.vcSignUp(params).subscribe((data:any) => {
            if(data && data.IsSuccess){
                this.loggedIn(data);
                this.toastr.success('You are signed up successfully!');
               // this.loginVoucherModal.hide();
            }else if(data && data.Message){
                this.toastr.error("Please enter valid OTP");
            }
        },
        (error) => {
          // this.toastService.error(JSON.parse(error._body).Message);
          if (error.error.Message) {
            this.toastr.error(error.error.Message);
          }
        });
    }
  }