
import { Component, OnInit, Output, Input, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { ProductService } from 'app/linknet-common/restful-services/restful.service';
import { Subscription } from 'rxjs';
import { CommonServicesService } from 'app/linknet-common/common-services/common-services.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-product-detail-input-modified',
  templateUrl: './product-detail-input-modified.component.html',
  styleUrls: ['./product-detail-input-modified.component.scss']
})
export class ProductDetailInputModifiedComponent implements OnInit {

  @Input() orderQuantity: any;
  @Input() productDetail: any;
  @Output() orderQuantityChange = new EventEmitter();
  @Input() productPrices: any;
  @Input() quantityChangeStatus: any;
  @Output() quantityChangeStatusChange = new EventEmitter();
  @Input() shoppingCartData: any;
  @Output() callShoppingCartAPI = new EventEmitter();
  @Output() enquiryCount = new EventEmitter();
  public orderQuantityA: any;
  public errorStatus: Boolean = false;
  public isParentCustomer: Boolean = false;
  public maxQtyValue: string;
  public fixedUnits: any;
  public fixedUnitsMinimunValue: any;
  transportationhandlingcosts: any;
  public currency: any;
  addBackgroundClassCartons: Boolean = false;
  addBackgroundClassUnits: Boolean = false;
  transportationhandlingcostPrices: Object;
  userPoints: Subscription;
  balancePoints: any;
  toastRef: any;
  enableLogoButton: boolean = false;
  @Input() FileName: any;
  @Output() fileNameChange = new EventEmitter();  
  webshopDetail: any;
  public customOptions = { allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', "application/pdf",] };
  isSmartworks: boolean = false;

  constructor(public globalService: GlobalService, public _httpProductService: ProductService, private commonService: CommonServicesService, private toastr: ToastrService) {
    this.currency = this.globalService.getUserSettings().CurrencyCode;
    const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.webshopDetail = webshopDetail;
    if (webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName) {
      this.isParentCustomer = true;
    } else {
      this.isParentCustomer = false;
    }

    if(webshopDetail.DomainName === "sw" || webshopDetail.DomainName === "smartworks"){
      this.isSmartworks = true;
    }
  }


  getTransportationhandlingcosts(orderedQuantity) {
    if (this.productDetail.PiecesPerUnit !== undefined) {
      orderedQuantity = orderedQuantity * this.productDetail.PiecesPerUnit
    }
    if (this.productDetail.isInternalStockProduct && !this.productDetail.isTransportationHandlingCostFree) {
      this._httpProductService.getTransportationhandlingcosts({
        productID: this.productDetail.ProductID,
        quantity: orderedQuantity
      }).subscribe(
        (data: any[]) => {
          this.transportationhandlingcosts = data;
          if (this.transportationhandlingcosts.NumberOfCartons > 0) {
            this.addBackgroundClassCartons = false;
          } else {
            this.addBackgroundClassCartons = true;
          }
          if (this.transportationhandlingcosts.NumberOfUnits > 0) {
            this.addBackgroundClassUnits = false;
          } else {
            this.addBackgroundClassUnits = true;
          }
        });
    }
  }
  getTransportationhandlingcostPrices() {
    if (this.productDetail.isInternalStockProduct) {
      this._httpProductService.getTransportationhandlingcostPrices(this.productDetail.ProductID).subscribe(
        data => {
          this.transportationhandlingcostPrices = data;
        });
    }
  }

  ngOnInit() {    
    try {
      if (this.productDetail.FixedUnits) {
        this.orderQuantity = this.productDetail.MinimumOrder / this.productDetail.PiecesPerUnit;
      }
      if(this.shoppingCartData.FileName !== undefined && this.shoppingCartData.FileName !== ""){
        this.enableLogoButton = true;
      }
      // this.orderQuantity = '';
      /** new-code => set qty based on API response */
      let qty = 0;
      if (this.shoppingCartData.ShoppingCartItemID !== 0) {
        qty = this.shoppingCartData.OrderedQuantity;
        if (this.productDetail.FixedUnits) {
          qty = Number(this.shoppingCartData.OrderedQuantity / this.shoppingCartData.PiecesPerUnit);
        }
      }

      this.orderQuantity = (this.shoppingCartData.ShoppingCartItemID !== 0) ? qty : '';
      this.orderQuantityA = this.orderQuantity;
      /** new-code => set qty based on API response */

      const priceArrayLength = this.productDetail.ProductPrices.length;
      this.maxQtyValue = this.productDetail.ProductPrices[priceArrayLength - 1].MaximumQuantity;
      this.fixedUnitsMinimunValue = this.productDetail.MinimumOrder / this.productDetail.PiecesPerUnit
    } catch (_error) { }
    if(window.innerWidth >= 768 ) {
      setTimeout(function () { document.getElementById("quantity").focus(); }, 1000);
    }

    // If user searched by quantity, put that value as initial quantity on product details page
    const breadCrumbsList = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
    var searchUrl = breadCrumbsList[breadCrumbsList.length - 1].url;
    const idxQuantity = searchUrl.indexOf('Quantity=');
    var quantityStr = '';
    if (idxQuantity >= 0 && searchUrl.length > idxQuantity + 9 && (this.shoppingCartData.ShoppingCartItemID === '' ||
      this.shoppingCartData.ShoppingCartItemID === 0)) {
      searchUrl = searchUrl.substring(idxQuantity);
      if (searchUrl.indexOf('&') === -1) {
        quantityStr = searchUrl.substring(9);
      } else {
        quantityStr = searchUrl.substring(9, searchUrl.indexOf('&'));
      }
    }
    if (quantityStr !== '' && Number(quantityStr) >= this.productDetail.MinimumOrder) {
      this.orderQuantity = quantityStr;
      if (this.productDetail.FixedUnits) {
        this.orderQuantity = Math.floor(this.orderQuantity / this.productDetail.PiecesPerUnit);
      }
    }

    //  this.getTransportationhandlingcosts(this.orderQuantity);
    this.getTransportationhandlingcostPrices();
    /** new-code */
    if (Number(this.orderQuantity) === 0) {
      this.orderQuantity = '';
      this.orderQuantityChange.emit('');
    } else {
      this.orderQuantityChange.emit(Number(this.orderQuantity));
    }
    if (this.FileName === "") {
      this.fileNameChange.emit('');
    } else {
      this.fileNameChange.emit(this.FileName);
    }
    /** new-code */
  }

  ngOnChanges(changes: SimpleChanges): void {
    // If user searched by quantity, put that value as initial quantity on product details page and Call Shopping cart add API automatic
    if (changes.orderQuantity !== undefined) {
      //console.log("changhes PRODUCT INOUT MODOFIED : ", changes);
      if (changes.orderQuantity.previousValue === undefined) {
        if (this.productDetail.FixedUnits) {
          this.orderQuantity = Number(this.productDetail.PiecesPerUnit * changes.orderQuantity.currentValue);
        } else {
          this.orderQuantity = changes.orderQuantity.currentValue;
        }
        if (this.orderQuantity > this.maxQtyValue || this.orderQuantity < this.productDetail.MinimumOrder) {
          this.errorStatus = true;
        } else {
          /** new-code => Call Chopping cart API in product detail page based on QTY */
          const breadCrumbsList = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
          var searchUrl = breadCrumbsList[breadCrumbsList.length - 1].url
          const idxQuantity = searchUrl.indexOf('Quantity=');
          if (idxQuantity >= 0 && searchUrl.length > idxQuantity + 9) {
            if (this.shoppingCartData.ShoppingCartItemID === '' || this.shoppingCartData.ShoppingCartItemID === 0) {
              this.callShoppingCartAPI.emit(this.orderQuantity);
            }
          }
          /** new-code => Call Chopping cart API in product detail page based on QTY */
        }
      }
    }
    if (this.isParentCustomer) {
      if (this.orderQuantity > 500) {
        this.orderQuantity = 0;
      }
    } else {
      if (this.orderQuantity > 50) {
        this.orderQuantity = 0;
        this.orderQuantityA = 0;
      }
    }
  }

  enableUploadLogo(event) {
    if (event.target.checked == true) {
      this.enableLogoButton = true;
    }
    else {
      this.enableLogoButton = false;
      this.FileName = "";
      this.fileNameChange.emit(this.FileName);
    }
  }

  setUploadedImageData(ev) {
    try {
      this.FileName = ev[1];
      this.fileNameChange.emit(this.FileName);
    } catch (error) {
      this.toastr.error(error);
    }
  }

  preview(event) {
    this.FileName = event[0].name;
    this.fileNameChange.emit(this.FileName)
  }

  change(newValue: any) {
    this.errorStatus = false;
    if (!this.isParentCustomer) {
      this.userPoints = this.commonService.getPoints().subscribe((data) => {
        this.balancePoints = data;
      });

      if (newValue > 50) {
        this.enquiryCount.emit({
          'OrderQty': newValue
        });
        newValue = 0;
      }

      if (newValue && this.balancePoints === 0 && !this.orderQuantityA) {
        if (this.toastRef) {
          this.toastr.clear(this.toastRef.ToastId);
        }
        this.toastRef = this.toastr.error("You don't have points to select a product");
      } else {
        if (this.toastRef) {
          this.toastr.clear(this.toastRef.ToastId);
        }
      }
    }
    if (this.productDetail.FixedUnits) {
      this.orderQuantity = Number(this.productDetail.PiecesPerUnit * newValue);
    } else {
      this.orderQuantity = newValue;
    }
    if (this.orderQuantity > this.maxQtyValue || this.orderQuantity < this.productDetail.MinimumOrder) {
      this.errorStatus = true;
    } else {
      //console.log("Change FUNCTION VALUE : ", this.orderQuantity);
      /** new-code => Call Chopping cart API in product detail page based on QTY */
      this.callShoppingCartAPI.emit(this.orderQuantity);
      /** new-code => Call Chopping cart API in product detail page based on QTY */
    }
    this.orderQuantityChange.emit(newValue);
    this.fileNameChange.emit(this.FileName);
    this.quantityChangeStatus = 1;
    this.quantityChangeStatusChange.emit(newValue);
    if (Number(newValue) >= Number(this.maxQtyValue)) {
      this.orderQuantity = this.maxQtyValue;
    }
    if (this.userPoints) {
      this.userPoints.unsubscribe()
    }
  }

  // for Decimal you can use this as
  onlyDecimalNumberKey(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // tslint:disable-next-line:curly
    if (charCode !== 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  // for Number Only
  onlyNumberKey(event) {
    const pattern = new RegExp('^[0-9\b]+$');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!pattern.test(key) && event.charCode !== 0) {
      event.preventDefault();
      return false;
    }
  }


}
