import { Component, Injectable, OnInit, ViewChild } from "@angular/core";

@Component({
    selector: 'shop-app-login-message',
    templateUrl: './login-message.component.html',
    styleUrls: ['./login-message.component.scss'],
    exportAs: 'child'
  })
  
  @Injectable()
  
  export class LoginMessageComponent implements OnInit  {
    @ViewChild('loginMessageModal') public loginMessageModal;
    constructor(){

    }

    ngOnInit() {
        
    }
    showModal(){
        this.loginMessageModal.show()
    }
  }