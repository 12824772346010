import { Component, OnInit } from '@angular/core';
import { OrdersService } from 'app/linknet-common/restful-services/restful.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { Router } from '@angular/router';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {
  public orderHistoryList: Array<any>;
  public translatedMessage: any;
  public orderHistoryNoRecord: Boolean = false;
  public loaderShow: any;
  public orderHistoryTitle: any;
  public changeDeliveryDateText: Boolean = false;  // for display text as delivery date / Earliest disp. date in HTML Page
  public wsaStatus: Boolean = false;
  public isInvoice: Boolean = false;
  public goldenberryStatus: boolean = false;
  constructor(private _httpOrdersService: OrdersService, private toastService: ToastService,
    private router: Router, private globalService: GlobalService, private translate: TranslateService,
    public titleService: Title, private toastr: ToastrService) { }

  ngOnInit() {
    this.orderHistoryNoRecord = false;
    try {
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (webshopDetail.ChangeDeliveryDateText) {
        this.changeDeliveryDateText = true;
      }
      if (Number(webshopDetail.CustomerID) === 45) {
        this.wsaStatus = true;
      }

      this.orderHistoryTitle = this.translate.get('HEADING.ORDER_HISTORY').subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
      this.getOrderHistory();
    } catch (error) {
      this.toastr.error(error);
    }
    var webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));

    if (webshopDetail.ParentCustomerDomainName === 'goldenberry') {
      this.goldenberryStatus = true;
    }
    else {
      this.goldenberryStatus = false;
    }

    if (webshopDetail.DefaultInvoiceType === 1) {
      this.isInvoice = true;
    }
    else {
      this.isInvoice = false;
    }
  }

  printInvoice(param, index) {
    try {
      this._httpOrdersService.getAllInvoice(param).subscribe(
        (data: any[]) => {
          if (data.length > 0) {
            const invoiceID = data[index].InvoiceID;
            const orderWise = data[index].OrderWise;
            const langCode = JSON.parse(sessionStorage.getItem('userSettings')).LanguageCode;
            window.location.href = environment.ENVIRONMENT.baseURL + langCode
              + '/invoiceDownload/pdf/' + invoiceID + '?OrderWise=' + orderWise;
          } else {
            //  this.translatedMessage = (this.translate.get('ERRORS.INVOICE_NOT_AVAILABLE'));
            this.translate.get('ERRORS.INVOICE_NOT_AVAILABLE').subscribe((res: string) => {
              this.translatedMessage = res;
            });
            this.toastr.info(this.translatedMessage);
          }
        }
      )
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getOrderHistory() {
    try {
      this.loaderShow = true;
      const params = {
        'history': true,
        'suborders': true,
      }
      this._httpOrdersService.getOrders(params).subscribe(
        (data: any[]) => {
          this.orderHistoryList = data['ItemsCollection'];
          this.loaderShow = false;
          if (this.orderHistoryList.length === 0) {
            this.orderHistoryNoRecord = true;
          } else {
            this.orderHistoryNoRecord = false;
          }
        }, _error => {   this.loaderShow = false;
          // this.toastService.error(JSON.parse(error._body).Message);
          //this.toastr.error(error.error.Message);
        })
    } catch (error) {   this.loaderShow = false;
     // this.toastr.error(error);
    }
  }

  orderDetail(order, param, currencyCode, vATPercentage) {
    try {
      this.globalService.overwriteSession('orderDetail', 'OrderFullDetails', order);
      this.globalService.overwriteSession('orderDetail', 'SubOrder', param);
      this.globalService.overwriteSession('orderDetail', 'OrderedCurrencyCode', currencyCode);
      this.globalService.overwriteSession('orderDetail', 'VATPercentage', vATPercentage);
      this.router.navigate(['/orderdetail', order.OrderID, param.OrderItemID, true, false], { replaceUrl: false });
    } catch (error) {
      this.toastr.error('Error: ' + error);
    }
  }
}
