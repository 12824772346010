import { Component, OnInit, Output, Input, OnChanges, EventEmitter } from '@angular/core';
import { GlobalService, CurrencySetterService } from '../../linknet-common/globals/global.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-price-range',
  templateUrl: './price-range.component.html',
  styleUrls: ['./price-range.component.scss']
})

export class PriceRangeComponent implements OnInit, OnChanges {
  public printPrices: any;
  public currency: any;
  public start = 0;
  public end = 2;
  @Input() productPrices: any;
  @Input() mrp: any;
  @Input() parentCustmer: any;
  @Input() productUnitName: String;
  @Input() productDetail: any;
  @Input() productCurrentQty: any;
  @Input() productHighlightPriceRange: any;
  @Input() productHighlightPriceIndex: any;
  @Input() currentPrice: any;
  @Output() currentPriceChange = new EventEmitter();
  queryParams: any;
  constructor(public currencySetterService: CurrencySetterService, public globalService: GlobalService,
    private toastService: ToastService, private router: Router, private toastr: ToastrService) {
    try {
      this.queryParams = this.router.routerState.snapshot.root.queryParams;
      this.currency = this.globalService.getUserSettings().CurrencyCode;
    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }
  ngOnInit() {
    //console.log(this.parentCustmer)
    try {
      /*if (this.productPrices.length > 0) {
        let allow = true;
        for (let _i = 0; _i < this.productPrices.length; _i++) {
          // code for highlight price range in display
          if (this.productPrices[_i].MaximumQuantity >= this.productCurrentQty &&
            this.productPrices[_i].MinimumQuantity <= this.productCurrentQty) {
            this.productHighlightPriceRange = this.productPrices[_i];
            this.productHighlightPriceIndex = _i;
            this.currentPriceChange.emit(this.productPrices[_i].Price);
          }

          if (this.queryParams.PriceFrom !== undefined && allow && this.queryParams.PriceFrom < this.productPrices[_i].Price &&
             this.queryParams.PriceTo > this.productPrices[_i].Price) {
            allow = false;
            this.productHighlightPriceRange = this.productPrices[_i];
            this.productHighlightPriceIndex = _i;
          }
        }
      }*/
      if (this.productHighlightPriceIndex !== undefined) {
        this.start = this.productHighlightPriceIndex;
        this.end = this.productHighlightPriceIndex + 2;
        this.printPrices = this.productPrices ? this.productPrices : null;
      } else {
        this.printPrices = this.productPrices ? this.productPrices : null;
        this.start = 0;
        this.end =  2;
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  next() {
    try {
      /*if (this.end < this.productPrices.length) {
        this.end = this.end + 1;
        this.start = this.start + 1;
        this.printPrices = this.productPrices.slice(this.start, this.end);
      }*/
    } catch (error) {
      this.toastr.error(error);
    }
  }

  pre() {
    try {
     /* if (this.start > 0) {
        this.end = this.end - 1;
        this.start = this.start - 1;
        this.printPrices = this.productPrices.slice(this.start, this.end);
      }*/
    } catch (error) {
      this.toastr.error(error);
    }
  }

  inrFormat(val: number) {
    return Number(val).toLocaleString('en-IN');
  }
}
