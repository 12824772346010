import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDigitOnly]'
})
export class DigitOnlyDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: KeyboardEvent): void {
    const initialValue = this.el.nativeElement.value;
    const newValue = initialValue.replace(/[^0-9]/g, ''); 
    this.el.nativeElement.value = newValue;
    if (initialValue !== newValue) {
      event.stopPropagation();
    }
  }
}