import { HttpClient } from '@angular/common/http';
import {LocalData} from './local-data.service';
import {RemoteData} from './remote-data.service';


export function localDataFactory () {
  return () => {
    return new LocalData();
  };
}

export function remoteDataFactory (http: HttpClient) {
  return () => {
    return new RemoteData(http);
  };
}

// tslint:disable-next-line:variable-name
export let LocalDataFactoryProvider = {provide: LocalData, useFactory: localDataFactory};
// tslint:disable-next-line:variable-name
export let RemoteDataFactoryProvider = {provide: RemoteData, useFactory: remoteDataFactory, deps: [HttpClient]};
