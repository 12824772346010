import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appSetTabindex]'
})
export class SetTabindexDirective {
  public ele: any;
  public tabIndex: any;
  constructor() { }
  @HostListener('click', ['$event']) onClick($event) {
     this.setTabIndex($event)
}
@HostListener('focus', ['$event']) onfocus($event) {
  this.setTabIndex($event)
}
setTabIndex($event) {
  if ($event !== null) {
    this.tabIndex = $event.currentTarget.tabIndex;
  this.ele = $event.currentTarget.children[0].children[0].children[1].children[0];
  this.ele.setAttribute('tabIndex', this.tabIndex)
  }
}
}
