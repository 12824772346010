import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../restful-services/message-services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../typescripts/pro/alerts';
import { GlobalService } from '../../globals/global.service';
import { MessageCenterComponent } from '../message-center.component';
import { environment } from 'environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-message-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  public type: any;
  public selected: any;
  public messages: any;
  public spinnerShow;
  public perPageCount:any = 10;
  public totalCount;
  public currentPage:any = 1;
  public numberOfUnreadEmails;
  public checkedArray: Array<any> = [];
  public checkboxValue = [];
  public translatedMessage: any;
  public noMessageDisplayStatus: Boolean = false;
  loggedInData: any;
  isMobile : boolean = false;
  @ViewChild('MessageModal') public messageModal;
  webshopName:any;
  isVoucherKart:boolean = false;
  constructor(private _httpMessagesService: MessageService,
    private router: Router, private route: ActivatedRoute, public globalService: GlobalService,
    public messageCenterComponent: MessageCenterComponent, private toastService: ToastService,
    private translate: TranslateService, private toastr: ToastrService) {
    this.type = this.route.snapshot.params ? this.route.snapshot.params.type : null;
    messageCenterComponent.type = this.type;
    if (this.type === undefined) {
      this.type = 'inbox';
    }
    this.loggedInData = this.globalService.getLoggedInData();
    if (this.loggedInData === undefined || this.loggedInData === null) {
      this.router.navigate(['/'], { replaceUrl: false });
    } else {
      let pgIndex = sessionStorage.getItem('pageIndex');
      if(!pgIndex){
        pgIndex = '1';
      }
      const page = {
        'pageIndex': Number(pgIndex),
        'pageSize': environment.ENVIRONMENT.itemsPerPage[0].value
      }
      if (window.innerWidth > 768) {
        this.isMobile = false;
      }else{
       this.isMobile = true;
      }
      
      this.getMessages(page);
    }
  }

  ngOnInit() {
    const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
      this.webshopName = webshopDetail;
      if(this.webshopName.DomainName === "voucherkart"){
        this.isVoucherKart = true;
      }else{
        this.isVoucherKart = false;
      }
  }

  getMessages(page) {
    if(page.pageIndex){
    this.currentPage = page.pageIndex;
    }
    if(page.pageSize){
    this.perPageCount = page.pageSize;
    }
    this.spinnerShow = true;
    let params = {
      "UserID": this.loggedInData.UserID,
      "UserRole": this.loggedInData.Roles[0].RoleName,
      "pageNumber": this.currentPage,
      "pageSize": this.perPageCount
    }
    //this._httpMessagesService.getEmails(page, this.type).subscribe(
    this._httpMessagesService.getEmailList(params).subscribe(
      data => {
        this.noMessageDisplayStatus = true;
        this.messages = data;
        /*if (this.type === 'inbox') {
          this.numberOfUnreadEmails = data['NumberOfUnreadEmails'];
          this._httpMessagesService.setState(data['NumberOfUnreadEmails']);
        }*/
        this.spinnerShow = false;
        this.totalCount = data[0].total;
      }
    );
  }

  showMessageModal() {
    if (this.checkedArray.length !== 0) {
      this.messageModal.show();
    } else {
     // this.translatedMessage = (this.translate.get('HEADER_ONE.PLEASE_SELECT_ONE_MESSAGE'));
      this.translate.get('HEADER_ONE.PLEASE_SELECT_ONE_MESSAGE').subscribe((res: string) => {
        this.translatedMessage = res;
      });
      this.toastr.error(this.translatedMessage);
    }
  }

  hideMessageModal() {
    this.messageModal.hide();
  }

  bulkAction(actiontype, untrash = null) {
    try {
      this.messageModal.hide();
      if (this.checkedArray.length !== 0) {
        if (this.type === 'trash') {
          actiontype = 'DELETE'
        } 
        let value = true;
        if (this.type === 'trash' && untrash !== null) {
          actiontype = 'TRASH';
          value = false;
        }
        const param = {
          'ActionType': actiontype,
          'Value': value,
          'EmailIDs': this.checkedArray
        }
        this._httpMessagesService.bulkActions(param).subscribe(
          () => {
            if (this.type === 'trash') {
             // this.translatedMessage = (this.translate.get('MESSAGECENTER.To_DELETE_SUCCESS'));
              this.translate.get('MESSAGECENTER.To_DELETE_SUCCESS').subscribe((res: string) => {
                this.translatedMessage = res;
              });
            } else {
             // this.translatedMessage = (this.translate.get('MESSAGECENTER.To_TRASH_SUCCESS'));
              this.translate.get('MESSAGECENTER.To_TRASH_SUCCESS').subscribe((res: string) => {
                this.translatedMessage = res;
              });
            }
            if (actiontype === 'IMPORTANT') {
             // this.translatedMessage = (this.translate.get('MESSAGECENTER.To_IMPORTANT_SUCCESS'));
              this.translate.get('MESSAGECENTER.To_IMPORTANT_SUCCESS').subscribe((res: string) => {
                this.translatedMessage = res;
              });
            }
            this.toastr.success(this.translatedMessage);
            let pgIndex = sessionStorage.getItem('pageIndex');
            if(!pgIndex){
              pgIndex = '1';
            }
            const page = {
              'pageIndex': pgIndex,
              'pageSize': environment.ENVIRONMENT.itemsPerPage[0].value
            }
            this.getMessages(page);
            this.checkedArray = [];
          }, error => {
            this.toastr.error(error);
          });
      } else {
       // this.translatedMessage = (this.translate.get('HEADER_ONE.PLEASE_SELECT_ONE_MESSAGE'));
        this.translate.get('HEADER_ONE.PLEASE_SELECT_ONE_MESSAGE').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  checkBoxChecked(emailId) {
    if (this.checkedArray.indexOf(emailId) === -1) {
      this.checkedArray.push(emailId);
    } else {
      const index = this.checkedArray.indexOf(emailId, 0);
      this.checkedArray.splice(index, 1);
    }
  }

  openMail(params) {
    if (this.type === 'drafts') {
      params.isDraft = true;
      this.router.navigate(['/messages/compose'], { queryParams: params, replaceUrl: false });
    } else {
      this.router.navigate(['/messages/' + this.type + '/' + params.emailid], {state: params});
    }
  }
}
