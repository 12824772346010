import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'shop-app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent implements OnInit {
  public card: Boolean = false;
  public mediumHeading: Boolean = false;
  public smallHeading: Boolean = false;
  public details: Boolean = false;
  public titleHeading: Boolean = false;
  public noRecord: Boolean = false;
  public currencySymbol: Boolean = false;
  public isParentCustomer: Boolean = false;
  webshopData: any;
  @Input() headingText: any;
  @Input() headingTextNoTranslate: any;
  @Input() closeButtonYN: any;
  @Input() level: string;
  @Input() color: string;

  constructor() { }

  ngOnInit() {
    const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.webshopData = webshopDetail;
    if(this.webshopData && this.webshopData.ParentCustomerDomainName !== this.webshopData.DomainName){
      this.isParentCustomer = false;
    }else{
      this.isParentCustomer = true;
    }
    
    switch (this.level) {
      case 'card':
        this.card = true;
        break;
      case 'mh':
        this.mediumHeading = true;
        break;
      case 'sh':
        this.smallHeading = true;
        break;
      case 'details':
        this.details = true;
        break;
      case 'th':
        this.titleHeading = true;
        break;
      case 'noRecord':
        this.noRecord = true;
        break;
      case 'currencySymbol':
        this.currencySymbol = true;
        break;
      default:
        this.card = true;
        break;
    }
  }
}
