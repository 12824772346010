import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../restful-services/message-services/message.service';
import { ToastService } from '../../../typescripts/pro/alerts';
import { ActivatedRoute } from '@angular/router';
import { MessageCenterComponent } from '../message-center.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  public type;
  public id;
  public message: any = {};
  public messageClaims: Array<any>;
  public numberOfUnreadEmails;
  private subscription: Subscription;
  public changeDeliveryDateText: Boolean = false;  // for display text as delivery date / Earliest disp. date in HTML Page
  public wsaStatus: Boolean = false;
  constructor(private _httpMessagesService: MessageService,
    public messageCenterComponent: MessageCenterComponent,
    private route: ActivatedRoute, private toastService: ToastService, private router: Router,
    private toastr: ToastrService) {
    this.type = this.route.snapshot.params ? this.route.snapshot.params.type : null;
    this.id = this.route.snapshot.params ? this.route.snapshot.params.id : null;
    messageCenterComponent.type = this.type;
    if (this.type === undefined) {
      this.type = 'inbox';
    }

  }

  ngOnInit() {
    const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    
    if(history.state && history.state.emailid){
      this.message = history.state;
      if(history.state.UserRead !== 'Read'){
        this.updateStatus(history.state.emailid);
      }
    }else{
      this.router.navigate(['messages/list/inbox']);
    }
    if (webshopDetail.ChangeDeliveryDateText) {
      this.changeDeliveryDateText = true;
    }
    if (Number(webshopDetail.CustomerID) === 45) {
      this.wsaStatus = true;
    }
    this.subscription = this._httpMessagesService.getState().subscribe(
      selectionFormatState => {
        this.numberOfUnreadEmails = selectionFormatState;
      });
  }

  updateStatus(id){
    this._httpMessagesService.updateStatus(id).subscribe(
      (_data: any[]) => {
        this._httpMessagesService.setState(history.state.UnReadCount - 1);
        this.subscription = this._httpMessagesService.getState().subscribe(
          selectionFormatState => {
            this.numberOfUnreadEmails = selectionFormatState;
          });
      }, error => {
        // this.toastService.error('Error: ' + error._body);
        this.toastr.error('Error: ' + error.error.Message);
      }
    );
  }

  getMessage(id) {
    this._httpMessagesService.getEmail(id).subscribe(
      (data: any[]) => {
        this.message = data;
      }, error => {
        // this.toastService.error('Error: ' + error._body);
        this.toastr.error('Error: ' + error.error.Message);
      }
    );
  }

  getInquiries(id) {
    this._httpMessagesService.getEnquiry(id).subscribe(
      (data: any[]) => {
        this.message = data;
      }, error => {
        // this.toastService.error('Error: ' + error._body);
        this.toastr.error('Error: ' + error.error.Message);
      }
    );
  }

  getClaim(id) {
    this._httpMessagesService.getClaims(id).subscribe(
      (data: any[]) => {
        this.messageClaims = data;
      }, error => {
        // this.toastService.error('Error: ' + error._body);
        this.toastr.error('Error: ' + error.error.Message);
      }
    );
  }

  replyMessage(params) {
    this.router.navigate(['/messages/compose'], { queryParams: params, replaceUrl: false });
  }
}
