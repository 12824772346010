import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailComponent } from 'app/webshop/product-detail/product-detail.component';
import { ProductFoldersComponent } from 'app/webshop/product-folders/product-folders.component';
import { ProductLikesComponent } from 'app/webshop/product-likes/product-likes.component';
import { LogoBankComponent } from 'app/webshop/logo-bank/logo-bank.component';
import { OrderHistoryComponent } from 'app/webshop/order-history/order-history.component';
import { OrderProductionComponent } from 'app/webshop/order-production/order-production.component';
import { OrderSampleComponent } from 'app/webshop/order-sample/order-sample.component';
import { OrderDetailComponent } from 'app/webshop/order-detail/order-detail.component';
import { HeaderOneComponent } from 'app/webshop/header-one/header-one.component';
import { AuthGuardService } from 'app/linknet-common/auth-guard.service';
import { SettingsComponent } from 'app/webshop/settings/settings.component';
import { EditUserComponent } from 'app/webshop/settings/edit-user/edit-user.component';
import { ChangeUserPasswordComponent } from 'app/webshop/settings/change-user-password/change-user-password.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrderClaimsComponent } from './order-claims/order-claims.component';
import { OrderStatusComponent } from './order-status/order-status.component';
import { OrderUnauthorisedComponent } from './order-unauthorised/order-unauthorised.component';
import { PointsHistoryComponent } from './points-history/points-history.component';
import { UserBudgetComponent } from 'app/webshop/settings/user-budget/user-budget.component';
import { MoreAddressComponent } from './settings/more-address/more-address.component';
import { MarketingproductDetailComponent } from 'app/webshop/marketingproduct-detail/marketingproduct-detail.component';
import { AboutComponent } from './about/about-component';
import { FaqComponent } from './faq/faq-component';
import { LogoComplimentComponent}  from './banner-forms/logo-compliment/logo-compliment.component'
import { EmployeeRRComponent } from './banner-forms/employee-rr/employee-rr.component';
import { LoyaltyPrograms } from './banner-forms/loyalty-programs/loyalty-programs.component';
import { EnquireNowComponent } from './banner-forms/enquire-now/enquire-now.component';
import { WalletTopUpComponent } from './wallet-topup/wallet-topup.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import {VoucherProductsComponent} from './vouchers/voucher-products/voucher-products.component'
import { CreateVoucherComponent } from './vouchers/create-voucher/create-voucher.component';
import { VoucherCheckoutComponent } from './vouchers/checkout-voucher/checkout-voucher.component';
import { MultiSearchComponent } from './multiple-search/multiple-search.component';
import { VoucherFilterComponent } from './vouchers/voucher-filter/voucher-filter.component';
import { ShoppingCartMobileComponent } from './header-mobile/shoppingcart-mobile/shoppingcart-mobile.component';
import { SmartWorksComponent } from './smartworks/smartworks.component';
import { ImportedUserComponent } from './smartworks/user-list/user-list.component';
import { LoginComponent } from 'app/linknet-common/login/login.component';
import { TrackOrderComponent } from './track-order/track-order.component';
import { ChildShopEnquiryComponent } from './banner-forms/childshop-enquiry/childshop-enquiry.component';
import { LandingPageComponent } from 'app/landing-page/landing-page.component';

const routes: Routes = [
  {path: '',component: HomePageComponent, data: { title: 'Home' }, pathMatch: 'full'}, 
  {path: 'about-us', component: AboutComponent, pathMatch: 'full'},
  {path: 'faq', component: FaqComponent, pathMatch: 'full'}, 
  {path: 'search', component: ProductListComponent, pathMatch: 'full'}, 
  {path: 'multi-search', component: MultiSearchComponent, pathMatch: 'full'}, 
  {path: 'multi-search/:ids', component: MultiSearchComponent, pathMatch: 'full'}, 
  {path: 'search/:categoryId/:categoryName', component: ProductListComponent, data: { title: 'Product List' }, pathMatch: 'full'},
  {path: 'searchbrands/:BrandId/:BrandName', component: ProductListComponent, data: { title: 'Product List' }, pathMatch: 'full'},
  {path: 'settings', component: SettingsComponent, canActivateChild: [AuthGuardService],
    children: [
    {path: 'user/edituser', component: EditUserComponent},
    {path: 'user/changepassword', component: ChangeUserPasswordComponent},
    {path: 'user/budget', component: UserBudgetComponent},
    {path: 'user/moreaddress', component: MoreAddressComponent}
  ]},
  {path: 'marketingproductdetail/:productID/:Name', component: MarketingproductDetailComponent, data: { title: 'Marketing Product Detail' }, pathMatch: 'full'}, 
  {path: 'productdetail/:productID/:Name', component: ProductDetailComponent, data: { title: 'Product Detail' }, pathMatch: 'full'}, 
  {path: 'productdetail/:productID/:Name/:shoppingCartItemId', component: ProductDetailComponent, data: { title: 'Product Detail' }, pathMatch: 'full'},
  {path: 'folders', canActivate: [AuthGuardService], component: ProductFoldersComponent, data: { title: 'Product Folders' }, pathMatch: 'full'}, 
  {path: 'productlikes', canActivate: [AuthGuardService], component: ProductLikesComponent, data: { title: 'Product Likes' }, pathMatch: 'full'}, 
  {path: 'logo', canActivate: [AuthGuardService], component: LogoBankComponent, data: { title: 'Logo Bank' }, pathMatch: 'full'}, 
  {path: 'ordershistory', canActivate: [AuthGuardService], component: OrderHistoryComponent, data: { title: 'Order History' }, pathMatch: 'full'}, 
  {path: 'orderproduction', canActivate: [AuthGuardService], component: OrderProductionComponent, data: { title: 'Order Production' }, pathMatch: 'full'}, 
  {path: 'complimentlogo', canActivate: [AuthGuardService], component: LogoComplimentComponent, data: { title: 'Compliment Logo' }, pathMatch: 'full'}, 
  {path: 'employeeR&R', canActivate: [AuthGuardService], component: EmployeeRRComponent, data: { title: 'Employee R&R' }, pathMatch: 'full'},
  {path: 'loyaltyPrograms', canActivate: [AuthGuardService], component: LoyaltyPrograms, data: { title: 'Loyalty Programs' }, pathMatch: 'full'}, 
  {path: 'landingpage', component: LandingPageComponent, data: {title: ''}, pathMatch: 'full'},
  {path: 'enquiryform', component: ChildShopEnquiryComponent, data: { title: 'Loyalty Programs' }, pathMatch: 'full'}, 
  {path: 'enquireNow', canActivate: [AuthGuardService], component: EnquireNowComponent, data: { title: 'Enquire Now' }, pathMatch: 'full'},
  {path: 'ordersample', canActivate: [AuthGuardService], component: OrderSampleComponent, data: { title: 'Order Sample' }, pathMatch: 'full'},
  {path: 'orderhistory', canActivate: [AuthGuardService], component: OrderUnauthorisedComponent, data: { title: 'Order History' }, pathMatch: 'full'}, 
  {path: 'track-order', canActivate: [AuthGuardService], component: TrackOrderComponent, data: { title: 'Track Order' }, pathMatch: 'full'}, 
  {path: 'pointsHistory', canActivate: [AuthGuardService], component: PointsHistoryComponent, data: { title: 'Points History' }, pathMatch: 'full'},
  {path: 'orderdetail/:OrderID/:OrderItemID/:isOrderHistory/:isUnAuthorizedOrderPage', canActivate: [AuthGuardService], component: OrderDetailComponent, data: { title: 'Order History' }, pathMatch: 'full'},
  {path: 'orderhistory/:OrderID/:OrderItemID', component: OrderStatusComponent, data: { title: 'Order History' }, pathMatch: 'full'},
  {path: 'productblocksorders/:productBlocksID/:ProductBlockName', component: ProductListComponent, data: { title: 'Product List' }, pathMatch: 'full'}, 
  {path: 'logout', component: HeaderOneComponent, pathMatch: 'full'}, 
  {path: 'logout', component: HeaderOneComponent, pathMatch: 'full'}, 
  {path: 'orderclaims', component: OrderClaimsComponent, data: { title: 'Order Claims' }, pathMatch: 'full'},
  /*{path: 'shopping', component: ShoppingCartComponent, data: { title: 'Shopping Cart' }, pathMatch: 'full'},
  {path: 'shoppingcart/:shoppingCartItemId', component: ShoppingCartComponent, data: { title: 'Shopping Cart' }, pathMatch: 'full'}, */
  {path: 'shoppingcart', component: ShoppingCartMobileComponent, data: { title: 'Shopping Cart' }, pathMatch: 'full'},
  {path: 'checkout', component: CheckoutComponent, data: { title: 'Shopping Cart' },pathMatch: 'full'},
  {path: 'checkout/:cartID', component: CheckoutComponent, data: { title: 'Shopping Cart' }, pathMatch: 'full'},
  {path: 'orderSuccess/:orderId/0', component: CheckoutComponent, data: { title: 'Order Success' }, pathMatch: 'full'},
  {path: 'walletTopUp', component: WalletTopUpComponent, data: {title: 'Wallet Top Up'}, pathMatch: 'full'},
  {path: 'walletTopUp/:id', component: WalletTopUpComponent, data: {title: 'Wallet Top Up'}, pathMatch: 'full'},
  {path: 'voucher-orderSuccess/:orderId/0', component: VoucherCheckoutComponent, data: {title: 'Order Success'}, pathMatch: 'full'},
  {path: 'vouchers', component: VouchersComponent, data: {title: 'Vouchers'}, pathMatch: 'full'},
  {path: 'vouchers/:type/:id', component: VoucherProductsComponent, data: {title: 'Vouhcer Category'}, pathMatch: 'full'},
  {path: 'voucher-filter', component: VoucherFilterComponent, data: {title: 'Voucher Fitler'}, pathMatch: 'full'},
  {path: 'create-voucher', component: CreateVoucherComponent, data: {title: 'Create Voucher'}, pathMatch: 'full'},
  {path: 'checkout-voucher', component: VoucherCheckoutComponent, data: {title: 'Voucher Checkout'}, pathMatch: 'full'},
  {path: 'user-imports', component: SmartWorksComponent, data: {title: 'User Imports'}, pathMatch: 'full'},
  {path: 'import-user-list', component: ImportedUserComponent, data: {title: 'User Imports'}, pathMatch: 'full'},
  {path: 'Userimport', component: LoginComponent, data: {title: 'User Imports'}, pathMatch: 'full'}

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebshopRoutingModule { }
