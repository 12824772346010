import { Component, OnInit, ViewChild ,Input, Output, EventEmitter,} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import {Location} from '@angular/common';
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";
import { GlobalService } from "app/linknet-common/globals/global.service";
import { CategoryService } from "app/linknet-common/restful-services/category-services/category.service";
import { CampaignService, MessageService, VoucherService, WebshopService } from "app/linknet-common/restful-services/restful.service";
import { CookieService } from "ngx-cookie-service";
import { Subscription, concat } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { logging } from "protractor";

@Component({
    selector: "shop-app-header-mobile",
    templateUrl: "./header-mobile.component.html",
    styleUrls: ["./header-mobile.component.scss"],
  })

  export class HeaderMobileComponent implements OnInit {
    showCategoryMenu: boolean = false;
    categories: any = [];
    isCategoryEmpty: boolean;
    showFilterBox: boolean = false;
    showFiltersOnHomePage: Boolean = false;
    webshopDetail: any;
    webShopSettings: any;
    @ViewChild("campaignModal") public campaignModal;
    showMenuBox: boolean = false;
    public cartCount;
    public cartCountClass = "";
    public campaignName;
    private subscription: Subscription;
    private subscription2: Subscription;
    public numberOfUnreadEmails:any;
    public campaignList = [];
    public translatedMessage: any;
    public cartParams: any;
    public getShoppingCartIDList: any;
    loaderShow: boolean;
    isVoucherKart: boolean;
    public shoppingCartList: any;
    totalPrice: any = 0;
    isParentCustomer: boolean = true;
    isCorpKonnex: boolean = false;
    balancePoints: any;
    tempPoints: number;
    avlPoints: number;
    joinOrderCounts: any;
    voucherList: any;
    voucherListFilter: any = [];
    @Output() clearFilter = new EventEmitter<any>();
    @Input() discountOption: any;
    @Input() rdOption: any;
  redeem: any;
  redirect: any;
  isSmartworks: boolean = false;
  hideLogout: boolean = false;
  hideBackbtn: boolean = true;
  @Output() discount = new EventEmitter<any>();
  loggedInData: any;
  hrm: boolean = false;
  isPenna: boolean = false;
  isGrowWell: boolean = false;
  logIndata : any;
  

    constructor(public _httpWebshop: WebshopService, private _location: Location,
        public cookieService: CookieService,
        private toastr: ToastrService,
        public translate: TranslateService,
        public _httpMessageService: MessageService, public campaignService: CampaignService, private router: Router, private _httpCategories: CategoryService, private httpVoucher: VoucherService,private commonService: CommonServicesService,
        private globalService: GlobalService){
        this.logIndata = this.globalService.getLoggedInData();
        this.webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
        // if (this.webshopDetail.ShowFiltersOnHomePage) {
            this.showFiltersOnHomePage = true;
        // }
        if (this.webshopDetail.ParentCustomerDomainName === this.webshopDetail.DomainName) {
            this.isParentCustomer = true;
            
          } else {
            this.isParentCustomer = false;
            if(this.webshopDetail.DomainName === "voucherkart"){
              this.isVoucherKart = true;
            }
          }

          if(this.webshopDetail.DomainName === "smartworks" || this.webshopDetail.DomainName === "sw"){
            this.isSmartworks = true;
            let k = sessionStorage.getItem('urlkey')
            if(k && k === 'yes'){
              this.hideLogout = true;
            }
          }
          if(this.webshopDetail.DomainName === "247hrm" || this.webshopDetail.DomainName === "247hrm"){
            this.hrm = true;

          }
          if(this.webshopDetail.DomainName === "voucherkart"){
            this.isVoucherKart = true;
          }else{
            this.isVoucherKart = false;
          }
          if(this.webshopDetail.DomainName === "pc"){
            this.isPenna = true;
          }
          if(this.webshopDetail.DomainName === "corpkonnex" || this.webshopDetail.DomainName === "ckonnex"){
            this.isCorpKonnex = true;
          }
          if(this.webshopDetail.DomainName === "growmore" || this.webshopDetail.DomainName === "gm"){
            this.isGrowWell = true;
          }
          this.campaignService.getCartStatus().subscribe((e) => {
            if (e === true) {
              this._httpWebshop.getCount().subscribe((c)=>{
                this.cartCount = c;
              })
            }
          });
          this.subscription2 = this._httpMessageService.getState().subscribe((selectionFormatState) => {
            this.numberOfUnreadEmails = selectionFormatState;
        });
          this.subscription = this._httpWebshop.getCount().subscribe((selectionFormatState) => {
            this.cartCount = selectionFormatState;
            /** new-code */
            // if (this.cartCount > 0 && this.cartCount !== sessionStorage.getItem('shoppingCartCount')) {
            if (this.cartCount > 0) {
              this.cartCountClass = "graphic";
              setTimeout(() => {
                this.cartCountClass = "";
              }, 300);
            }
            /** new-code */
            // this.getShoppingCartList();
          });
    }
    ngOnInit() {
      let u = this.router.routerState.snapshot.url;
      if ((u === '/' || u.indexOf('/?') > -1)) {
        this.hideBackbtn = true;
      }else{
        this.hideBackbtn = false;
      }
      this.router.events.subscribe((evt) => {
        setTimeout(() => {
          this.webShopSettings = this.globalService.webShopSettings();
          if (this.webShopSettings && evt instanceof NavigationEnd) {
            if ((evt.url === '/' || evt.url.indexOf('/?') > -1)) {
              this.hideBackbtn = true;
            } else {
              this.hideBackbtn = false;
            }
          }
        }, 100)
      });
      this._httpCategories.getCategories().subscribe(data => {
        this.categories = data;
        if (this.categories.length > 0) {
          this.isCategoryEmpty = false;
        } else {
          this.isCategoryEmpty = true;
        }
    })
        this._httpWebshop.currentMessage.subscribe(
            (message) => (this.cartCount = message)
          );
        // this.vouchercategories();
        this.getShoppingCartList();
        const combinedObservable = concat(
          this.httpVoucher.getTopVoucherCategories(),
          this.httpVoucher.getVoucherCategories()
          
        );
        
        combinedObservable.subscribe({
          next: (data: any) => {
            this.voucherListFilter = this.voucherListFilter.concat(data);
          },
          complete: () => {
            // console.log(this.voucherListFilter)
          }
        });
    }

    ngAfterViewInit(){
    if (!this.isSmartworks && (this.isVoucherKart || (!this.isParentCustomer && this.router.url  === '/' ))) {
      let a = JSON.parse(sessionStorage.getItem('campaign'));
      if (!a) {
        this.changeCampaign();
      }else{
        this.campaignService.setActiveCampaign(a);
        this.getCategories();
      }
      if(this.isVoucherKart){
        this.changeCampaign();
      }
    }
    if(this.isSmartworks && this.router.url  === '/'){
      this.changeCampaign();
    }else{
      this.getCategories();
    }

    // New Code to combine Two API Responses using RXJS Concat
    
    // const combinedObservable = concat(
    //   this.httpVoucher.getVoucherCategories(),
    //   this.httpVoucher.getTopVoucherCategories()
    // );
    
    // combinedObservable.subscribe({
    //   next: (data: any) => {
    //     this.voucherListFilter = this.voucherListFilter.concat(data);
    //   },
    //   complete: () => {
        // console.log(this.voucherListFilter)
    //   }
    // });
  }

  getCategories(){
    this._httpCategories.getCategories().subscribe(data => {
      this.categories = data;
      if (this.categories.length > 0) {
        this.isCategoryEmpty = false;
      } else {
        this.isCategoryEmpty = true;
      }
    })
  }

  backPage(){
    this._location.back();
  }

    getShoppingCartList() {    
        try {
          let userBrowserID = "";
          const cookieExists: Boolean = this.cookieService.check(
            "shoppingCardTokenId"
          );
          if (!cookieExists) {
            let domainName = this._httpWebshop.getSubdomain(
              window.location.hostname
            );
            domainName = domainName ? domainName : 'hyperkart';
            const randomString = this._httpWebshop.randomGeneration(6);
            this.cookieService.set(
              "shoppingCardTokenId",
              domainName + randomString
            );
            userBrowserID = domainName + randomString;
          } else {
            userBrowserID = this.cookieService.get("shoppingCardTokenId");
          }
          const userSettings = this.globalService.getUserSettings();
          const webShopSettings = this.globalService.webShopSettings();
          const loggedInData = this.globalService.getLoggedInData();
          this.loggedInData = loggedInData;
          if (loggedInData !== null) {
            this.cartParams = {
              UserID: loggedInData.UserID,
              CustomerID: webShopSettings.CustomerID,
              CurrencyID: 20,
              UserBrowserID: userBrowserID,
              InitialDeliveryCountry: loggedInData.Country,
              ReturnOnlyVisibleItems: true,
              //  'ReturnOnlyValidCheckedVisibleCompleteItems': true   // new-code
            };
          } else {
            this.cartParams = {
              UserID: null,
              CustomerID: webShopSettings.CustomerID,
              CurrencyID: 20,
              UserBrowserID: userBrowserID,
              InitialDeliveryCountry: userSettings.CountryCode,
              ReturnOnlyVisibleItems: true,
              // 'ReturnOnlyValidCheckedVisibleCompleteItems': true    // new-code
            };
          }
          this.loaderShow = true;
    
          if(this.isVoucherKart){
            this.cartParams.IsVoucher = true;
          }else{
            this.cartParams.IsVoucher = false;
          }
          
          this._httpWebshop.getShoppingCart(this.cartParams).subscribe((data: any[]) => {
              this.shoppingCartList = data;
              sessionStorage.setItem("cartData", JSON.stringify(this.shoppingCartList));
              this.loaderShow = false;
              /** new-code => Restirct to display invisible: false items of shopping cart */
              const shoppingCartitemData = [];
              let k = 0;
              for (let i = 0; i < this.shoppingCartList["Items"].length; i++) {
                this.shoppingCartList["Items"][i].disableUpdate = true;
                if (this.shoppingCartList["Items"][i].isVisible) {
                  shoppingCartitemData[k] = data["Items"][i];
                  k++;
                }
              }
              this.totalPrice = 0;
             
             
              /** new-code => To check all steps are completed for product */
            
              if (!this.isParentCustomer) {
                const getLogged = this.globalService.getLoggedInData();
                this._httpWebshop.getRedeemUser(getLogged.UserID).subscribe((_res:any) => {
                    //this.balancePoints = res["RewardPoints"];
                    if(this.isVoucherKart){
                      this.balancePoints = _res.RewardPoints;
                      this.commonService.setPoints(this.balancePoints);
                    }
                    if (this.tempPoints < 0) {
                      this.tempPoints = 0;
                    }
                    this.avlPoints = this.tempPoints;
                    let a = this.campaignService.getActiveCampaign().subscribe((res) => {
                      if (res) {
                        this.balancePoints = res.BalancePoints;
                        this.commonService.setPoints(this.balancePoints);
                        this.tempPoints = this.balancePoints - this.totalPrice;
                      } else {
                        //this.commonService.setPoints(this.tempPoints);
                      }
                    });
                    a.unsubscribe();
                  });
              }
        
              this.balancePoints = this.avlPoints - this.totalPrice;
              // this._httpWebshop.changeMessage(data['Items'].length);
              this._httpWebshop.changeMessage(shoppingCartitemData.length);
              this._httpWebshop.currentMessage.subscribe(
                (message) => (this.cartCount = message)
              );
              //  this.cartCount = data.Items.length;
              this.cartCount = shoppingCartitemData.length;
    
              /** new-code */
              // sessionStorage.setItem('shoppingCartDatas', JSON.stringify(data['Items']));
              sessionStorage.setItem("shoppingCartDatas", JSON.stringify(shoppingCartitemData));
              /** new-code */
    
              sessionStorage.setItem("shoppingCartCount", this.cartCount.toString());
              this._httpWebshop.setCount(this.cartCount);
              setTimeout(() => {
                if (sessionStorage.getItem("joinOrderCounts") !== "undefined" && sessionStorage.getItem("joinOrderCounts") !== null && sessionStorage.getItem("joinOrderCounts") !== "") {
                  this.joinOrderCounts = JSON.parse(sessionStorage.getItem("joinOrderCounts"));
                }
              }, 5000);
            },
            _error => {
              this.loaderShow = false;
              //this.toastr.error(error);
            }
          );
        } catch (_error) {
          this.loaderShow = false;
         // this.toastr.error(error);
        }
      }

    home(){
        this.router.navigate(['/'])
    }

    showCategory(){
        this.showFilterBox = false;
        this.showMenuBox = false;
        this.showCategoryMenu = !this.showCategoryMenu;
    }
    voucherProducts(type, id){
      this.showCategoryMenu =false;
      this.showFilterBox = false;
      this.showMenuBox = false;
      this.router.navigate(['/vouchers', type, id], { replaceUrl: false })
    }
    hideCategory(){
        this.showCategoryMenu = false;
    }

    discountFilter(type){
      this.showFilterBox = false;
      this.discountOption = type;
      let discountOption = this.discountOption;
      let rdOption = this.rdOption;
      this.discount.emit(type);
      // if(this.redirect){
        this.router.navigate(['/voucher-filter'], {state: {discountOption, rdOption}})
      // }
    }
  
    redeemFilter(type){
      //this.discountOption = '';
      this.showFilterBox = false;
      this.rdOption = type;
      let discountOption = this.discountOption;
      let rdOption = this.rdOption;
      this.redeem.emit(type)
      if(this.redirect){
        this.router.navigate(['/voucher-filter'], {state: {discountOption, rdOption}})
      }
    }
    showFilter(){
        this.showCategoryMenu = false;
        this.showMenuBox = false;
        this.showFilterBox = !this.showFilterBox;
        /*if((this.isVoucherKart && this.loggedInData) || !this.isVoucherKart){
          this.showFilterBox = !this.showFilterBox;
        }else{
          this.toastr.error("Please login to access the feature")
        }*/
    }

    hideFilter(_e){
        this.showFilterBox = false;
    }

    showMenu(){
        this.showCategoryMenu = false;
        this.showFilterBox = false;
        this.showMenuBox = !this.showMenuBox;
    }

    hideMenu(){
        this.showMenuBox = false;
        this.showFilterBox = false;
        this.showCategoryMenu = false;
    }

    messageCenter() {
        const loggedInData = this.globalService.getLoggedInData();
        if (loggedInData) {
          this.router.navigate(["/messages/list/inbox"], { replaceUrl: false });
        } else {
          // this.translatedMessage = (this.translate.get('ERRORS.PLEASE_LOGIN_TO_ACCESS_MESSAGE_CENTER'));
          this.translate
            .get("ERRORS.PLEASE_LOGIN_TO_ACCESS_MESSAGE_CENTER")
            .subscribe((res: string) => {
              this.translatedMessage = res;
            });
          this.toastr.error(this.translatedMessage);
        }
      }

    searchProduct(categoryID, categoryName) {
        sessionStorage.removeItem('pageIndex');
        sessionStorage.removeItem('pageSize');
        sessionStorage.setItem('selectedCatId', categoryID);
     
        sessionStorage.removeItem('isOpenIndex');
    
        this.showCategoryMenu = false;
        sessionStorage.setItem('breadCrumbCategoryName', JSON.stringify(categoryName));
        // const str = categoryName;    
        // categoryName = str.replace(/ +/g, '');
        this.router.navigate(['/search', categoryID, categoryName], { replaceUrl: false });
      }

      logout() {
        try {
          // Remove saved data from sessionStorage
          if(!this.isParentCustomer){
            this.commonService.setPoints(0);
          }
          // based on filter section regarding product tags.
          if (sessionStorage.getItem("productTags") !== null && sessionStorage.getItem("productTags") !== undefined && sessionStorage.getItem("productTags") !== "") {
            sessionStorage.removeItem("productTags");
          }
          // Remove all saved data from sessionStorage
          const webshopDetail = sessionStorage.getItem("webshopDetail");
          sessionStorage.setItem("webshopDetail", webshopDetail);
          const data = JSON.parse(sessionStorage.getItem("webshopDetail"));
          //this.loggedInData = {};
          this.globalService.loggedin(null);
          // this.router.navigate([''], { preserveQueryParams: false, replaceUrl: true });
          this.globalService.overwriteSession("userSettings", "LanguageCode", data.DefaultLanguageCode);
          this.globalService.overwriteSession("userSettings", "LanguageID", data.DefaultLanguageID);
          this.globalService.overwriteSession("userSettings", "CurrencyCode", data.DefaultCurrencyCode);
          this.globalService.overwriteSession("userSettings", "CurrencyID", data.DefaultCurrencyID);
          //  location.reload();
          setTimeout(() => {
            sessionStorage.removeItem("webshopDetail");
            sessionStorage.removeItem("themeReApplyStatus");
          }, 100);
          this.router.navigate(['/']);
          setTimeout(() => {
              location.reload();
          }, 200);
            // location.reload();
          
        } catch (_error) {
        }
        setTimeout(() => {
        sessionStorage.clear();
        sessionStorage.removeItem("categoryList");
        sessionStorage.removeItem("colorsList");
        sessionStorage.removeItem("categoryOptionList");
        }, 100);
      }

      changeCampaign(){
      if(!this.isVoucherKart && !this.isSmartworks && !this.hrm){
          this.campaignModal.show({
            backdrop: 'static',
            keyboard: false
          });
        };
        this.loaderShow = true;
        this.campaignService.getCampaignForUser().subscribe((data: any) => {
          if(data) {
            sessionStorage.setItem('campaign', JSON.stringify(data))
            this.loaderShow = false;
            this.campaignList = data;
            if(this.isVoucherKart){
              this.campaignList = this.campaignList.filter((e:any)=>{return e.CampaignDesc === "voucherkart"});
              this.campaignService.setActiveCampaign(177);
              this.campaignSelect(this.campaignList[0]);
              this.balancePoints = this.campaignList[0].BalancePoints
              this.commonService.setPoints(this.campaignList[0].BalancePoints);
            }else{
              if(this.isSmartworks && !this.isParentCustomer){
                this.campaignService.setActiveCampaign(295);
                this.campaignSelect(this.campaignList[0]);
              }
              else if(this.hrm && !this.isParentCustomer){
                this.campaignService.setActiveCampaign(433);
                this.campaignSelect(this.campaignList[0]);
              }else{
                this.campaignList = this.campaignList.filter((e:any)=>{ return e.Aprovel === 'Aproved'});
              }
              this.campaignList = this.campaignList.filter((e:any)=>{ return e.Aprovel === 'Aproved'});
            }
          }
        },_error=>{
          this.loaderShow = false;
        });
      }

      campaignSelect(id) {
        let a = JSON.parse(sessionStorage.getItem('campaign'));
        if (!a || (a['CampaignId'] !== id['CampaignId'])) {
          sessionStorage.setItem('campaign', JSON.stringify(id));
          this.commonService.setPoints(id.BalancePoints);
          this.campaignService.setActiveCampaign(id);
          this.getCategories();
        }
        this.campaignName = id['CampaignName'];
        this.campaignModal.hide();
        if(!this.isVoucherKart && !this.isSmartworks){
          this.deleteShoppingCart();
        }
        if(!this.isVoucherKart && !this.isSmartworks && this.router.url  !== '/'){
          this.router.navigate(['/']);
        }
      }
      clearAll(){
        this.rdOption = '';
        this.discountOption = '';
        this.clearFilter.emit(true)
      }
      deleteShoppingCart() {
        const sessionShoppingCartData = JSON.parse(sessionStorage.getItem("shoppingCartDatas"));
        let a = JSON.parse(sessionStorage.getItem('campaign'));
        this.getShoppingCartIDList = [];
        if (sessionShoppingCartData && sessionShoppingCartData.length > 0) {
          let len3 = sessionShoppingCartData.length;
          for (let i = 0; i < len3; i++) {
            this.getShoppingCartIDList.push(sessionShoppingCartData[i].ShoppingCartItemID);
          }
        }
        if (this.getShoppingCartIDList.length > 0) {
          const userSettings = this.globalService.getUserSettings();
          const webShopSettings = this.globalService.webShopSettings();
          const loggedInData = this.globalService.getLoggedInData();
          if (loggedInData !== null) {
            this.cartParams = {
              UserID: loggedInData.UserID,
              CustomerID: webShopSettings.CustomerID,
              CurrencyID: userSettings.CurrencyID,
              DeleteShoppingCartItemIDs: this.getShoppingCartIDList,
            };
          } else {
            this.cartParams = {
              UserID: null,
              CustomerID: webShopSettings.CustomerID,
              CurrencyID: userSettings.CurrencyID,
              DeleteShoppingCartItemIDs: this.getShoppingCartIDList,
            };
          }
          try {
            this._httpWebshop.deleteShoppingCartItem(this.cartParams).subscribe(
              (_data) => {
                this.campaignService.setCartStatus(true)
              },
              (error) => {
                this.toastr.error(error);
              }
            );
          } catch (_error) {
            //this.toastr.error(error);
          }
        }
    
      }
  }