import { Component, OnInit, ViewChild } from '@angular/core';
import { OrdersService, ProductService } from '../../linknet-common/restful-services/restful.service';
import { ToastService } from '../../typescripts/pro';
import { GlobalService } from '../../linknet-common/globals/global.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'shop-app-order-unauthorised',
  templateUrl: './order-unauthorised.component.html',
  styleUrls: ['./order-unauthorised.component.scss']
})
export class OrderUnauthorisedComponent implements OnInit {
  @ViewChild('unAuthorizedModal') public unAuthorizedModal;
  @ViewChild('orderApprovedModal') public orderApprovedModal;
  @ViewChild("trackModal") public trackModal;
  public loaderShow: any;
  public orderList: any;
  public orderListNoRecord: Boolean = false;
  public translatedMessage: any;
  public clickedSubOrders: any;
  public order: any;
  public authorizedOrderArray: any;
  public isDisableauthorizeOrderwithRecalculatedTransportation: Boolean = false;
  public changeDeliveryDateText: Boolean = false;  // for display text as delivery date / Earliest disp. date in HTML Page
  public wsaStatus: Boolean = false;
  isParentCustomer: Boolean = false;
  setValue: boolean;
  searchData: any;
  item: any;
  deliveryCompleted: boolean;
  dispatchCompleted: boolean;
  inTransistCompleted: boolean;
  requested: boolean;
  isVoucherKart: boolean = false;
  constructor(private _httpOrdersService: OrdersService, private toastService: ToastService, public globalService: GlobalService,
    public router: Router, public _httpProductService: ProductService, public translate: TranslateService,
    private toastr: ToastrService, private datePipe: DatePipe) { }

  ngOnInit() {
    const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if (webshopDetail.ChangeDeliveryDateText) {
      this.changeDeliveryDateText = true;
    }

      if(webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName){
        this.isParentCustomer = true;
      }else{
        this.isParentCustomer = false;
      }
      if(webshopDetail.DomainName === "voucherkart"){
        this.isVoucherKart = true;
      }else{
        this.isVoucherKart = false;
      }
    if (Number(webshopDetail.CustomerID) === 45) {
      this.wsaStatus = true;
    }

    const userDetails = JSON.parse(sessionStorage.getItem('loggedInData'));
    if (userDetails === undefined || userDetails === null) {
      this.router.navigate(['/'], { replaceUrl: false });
    } else {
      this.getUnauthorisedOrderList();
    }
  }

  getUnauthorisedOrderList() {
    try {
      this.loaderShow = true;
      this._httpOrdersService.getOrderUnauthorised().subscribe(
        (data: any[]) => {
          this.orderList = data['ItemsCollection'];
          this.loaderShow = false;
          if (this.orderList.length === 0) {
            this.orderListNoRecord = true;
          }
        }, _error => {
          this.loaderShow = false;
          // this.toastService.error(JSON.parse(error._body).Message);
         // this.toastr.error(error.error.Message);
        })
    } catch (error) {
      this.loaderShow = false;
      //this.toastr.error(error);
    }
  }
  trackOrder(order, i){
    /*let orderid = order.OrderID;
    this.router.navigate(['/track-order'], { state: {orderid}, replaceUrl: false });*/
    const params = {
      "SBINumber": "",
      "AWb_no": "",
      "MemberName": "",
      "Trn_Id": "",
      "Rawb_no":"",
      "B_Awb_No":"",
      "ContactNo": "",
      "QAWb_No":"",
      "BatchName": order.OrderID,
      "CompanyName":""
  }
  this.loaderShow = true;
  this._httpOrdersService.getTrackOrderSearch(params).subscribe((data:any)=>{
      this.loaderShow = false;
      if(data && data.IsSuccess){
        this.searchData = data;
        this.searchData.TrackOrder_List.forEach((element:any)=>{
          element.F_Date = this.datePipe.transform(new Date(element.F_Date), "dd/MM/yyyy");
          element.Cour_Date = this.datePipe.transform(new Date(element.Cour_Date), "dd/MM/yyyy");
          element.qrCodeImage = 'data:image/jpeg;base64,'+ element.qrCodeImage;
        });
        this.item = this.searchData.TrackOrder_List[i];
        this.itemDetail(this.item);
      }
      this.trackModal.show();
  });
  }
  orderDetail(order, param, currencyCode, vATPercentage) {
    try {
      this.globalService.overwriteSession('orderDetail', 'OrderFullDetails', order);
      this.globalService.overwriteSession('orderDetail', 'SubOrder', param);
      this.globalService.overwriteSession('orderDetail', 'OrderedCurrencyCode', currencyCode);
      this.globalService.overwriteSession('orderDetail', 'VATPercentage', vATPercentage);
      this.router.navigate(['/orderdetail', order.OrderID, param.OrderItemID, true, true], { replaceUrl: false });
    } catch (error) {
      this.toastr.error('Error: ' + error);
    }
  }

  closeApprovedModel() {
    this.orderApprovedModal.hide();
    this.router.navigate(['/orderhistory'], { replaceUrl: false });
  }
  acceptUnAuthorizedOrder(productSubOrderInfo, order) {
    try {
      this.loaderShow = true;
      this.order = order;
      this._httpProductService.acceptUnAuthorizedOrders(productSubOrderInfo.OrderItemID).subscribe(
        _data => {
          this.loaderShow = false;

          try {
            this.loaderShow = true;
            this._httpOrdersService.getOrderUnauthorised().subscribe(
              (data: any[]) => {
                this.orderList = data['ItemsCollection'];
                this.loaderShow = false;
                if (this.orderList.length === 0) {
                  this.orderListNoRecord = true;
                }
                this.setValue = true;
                this.orderList.forEach(elements => {
                  if (this.order.OrderID === elements.OrderID) {
                    if (elements.ShowApproveOrderButton === true && this.setValue) {
                      this.orderApprovedModal.show();
                      this.authorizedOrderArray = elements;
                      this.setValue = false;
                    }
                  }

                });
                if (this.setValue === true) {
                  this.router.navigate(['/orderhistory'], { replaceUrl: false });
                }
              }, error => {
                // this.toastService.error(JSON.parse(error._body).Message);
                this.toastr.error(error.error.Message);
              })
          } catch (error) {
            this.toastr.error(error);
          }


        })
    } catch (error) {
      this.toastr.error(error);
    }
  }

  rejectUnAuthorizedOrder() {
    try {
      this.loaderShow = true;
      this._httpProductService.rejectUnAuthorizedOrders(this.clickedSubOrders.OrderItemID).subscribe(
        _data => {
          this.router.navigate(['/orderhistory'], { replaceUrl: false });
          this.loaderShow = false;
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }

  openUnAuthorizedModel(suborder) {
    this.clickedSubOrders = suborder;
    this.unAuthorizedModal.show();
  }

  authorizeOrderwithRecalculatedTransportation(order) {
    try {
      this.isDisableauthorizeOrderwithRecalculatedTransportation = true;
      if (order === undefined) {
        order = this.order;
      }
      if (order === undefined) {
        order = this.authorizedOrderArray;
        this.orderApprovedModal.hide();
      }
      this.loaderShow = true;
      this.unAuthorizedModal.hide();
      this._httpProductService.orderwithrecalculatedtransportation(order.OrderID).subscribe(
        (data: any[]) => {
          this.loaderShow = false;
          if (data) {
            this.router.navigate(['/orderhistory'], { replaceUrl: false });
          } else {
            //  this.translatedMessage = (this.translate.get('HEADING.CONTACT_WEBSHOP'));
            this.translate.get('HEADING.CONTACT_WEBSHOP').subscribe((res: string) => {
              this.translatedMessage = res;
            });
            this.toastr.error(this.translatedMessage);
          }
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }

  close(){
    this.trackModal.hide();
}
itemDetail(item: any){

this.item = item;

if(this.item.DEL_DATE){
  this.requested = true;
  this.dispatchCompleted = true;
  this.inTransistCompleted = true;
  this.deliveryCompleted = true;
}else if(this.item.Cour_Date){
  this.requested = true;
  this.dispatchCompleted = true;
  this.inTransistCompleted = true;
  this.deliveryCompleted = false;
}else if(this.item.Dispatch_Date){
  this.requested = true;
  this.dispatchCompleted = true;
  this.inTransistCompleted = false;
  this.deliveryCompleted = false;
}else if(this.item.F_Date){
  this.requested = true;
  this.dispatchCompleted = false;
  this.inTransistCompleted = false;
  this.deliveryCompleted = false;
}
//this.orderDetails(id);
}
}
