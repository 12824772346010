import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { GlobalService, CurrencySetterService } from '../../../linknet-common/globals/global.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ProductService } from 'app/linknet-common/restful-services/restful.service';

@Component({
  selector: 'shop-app-show-branding-price',
  templateUrl: './show-branding-price.component.html',
  styleUrls: ['./show-branding-price.component.scss']
})
export class ShowBrandingPriceComponent implements OnInit {

  public printPriceLength: any;
  public priceIndex: any;
  public printPrices: any;
  public currency: any;
  public start = 0;
  public end = 3;
  public productQty: any;
  public selectedLogoTypeID: any = '';
  public selectedLogoPositionID: any = '';
  public selectedLogoMaxAreaID: any = '';
  public isFixedLogos: Boolean = false;
  public isLogosFilterChanged: Boolean = false;
  public ProductLogoTypesFiltered = [];
  public ProductLogoPositionsFiltered = [];
  public ProductLogoMaxAreasFiltered = [];
  public printPricesFiltered = [];
  public printPriceChecked: Boolean = false;
  public justChangeImage: Boolean = false;
  @Input() productDetail: any;
  // @Input() productCurrentQty: any;
  @Input() shoppingCartData: any;
  @Input() productMinimumQty: any;
  @Input() productMaximumQty: any;

  public productLogoTypes: any;
  public productLogoPositions: any;
  public productLogoMaxAreas: any;
  public productPrintPrices: any;
  public productPrices: any;
  public productPriceIndex: any;
  @ViewChild('ProductLogoModal') public productLogoModal;

  public logoPositionDescription = [];
  public productPriceValues = [];
  printPriceIndex: any;


  @Output() callShoppingCartAPI = new EventEmitter();
  @Input() orderQuantity: any;
  @Output() orderQuantityChange = new EventEmitter();
  @Input() quantityChangeStatus: any;
  @Output() quantityChangeStatusChange = new EventEmitter();
  @Output() callSelectedBrandingAPI = new EventEmitter();
  @Output() callPreSelectedBrandingAPI = new EventEmitter();
  // @Output() showToggleStatus = new EventEmitter();
  public errorStatus: Boolean = false;
  public maxQtyValue: string;
  addBackgroundClassCartons: Boolean = false;
  addBackgroundClassUnits: Boolean = false;
  transportationhandlingcosts: any;
  public showToggleStatus: Boolean = true;
  public fixedUnitsMinimunValue: any;
  public selectBrandingStatus = [];
  public logoSelectedprintPriceIds = [];

  @Input() selectedIds: any;
  @Input() fixedLogoProductPrintPrices: any;

  constructor(public currencySetterService: CurrencySetterService, public globalService: GlobalService,
    private toastService: ToastService, private translate: TranslateService, public _httpProductService: ProductService) {
    try {
      this.currency = this.globalService.getUserSettings().CurrencyCode;
    } catch (error) {
      this.toastService.error(error);
    }
  }

  ngOnInit() {
    // this.productPrices['productPrintPrices'] = this.productPrintPrices;
    this.fixedUnitsMinimunValue = this.productDetail.MinimumOrder / this.productDetail.PiecesPerUnit
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("Changes in SHOW BRANDING : ", changes);
    /** new-code => preselect branding price based on choose branding type in advanced filter / left side filter sections  */
    if (changes.selectedIds !== undefined) {
      console.log("Changes in SHOW BRANDING  selectedIds: ");
      if (changes.selectedIds.currentValue.length === 1) {
        if (this.shoppingCartData.Logos.length === 1 && this.shoppingCartData.Logos[0]['PrintPriceID'] === undefined) {
          this.preSelectBranding(true, changes.selectedIds.currentValue[0]);
        }
      }
    }
    /** new-code => preselect branding price based on choose branding type in advanced filter / left side filter sections  */



    if (changes.orderQuantity !== undefined) {
      console.log("Changes in SHOW BRANDING  orderQuantity: ", changes.orderQuantity);
      if (String(changes.orderQuantity.currentValue) !== '' &&
        Number(changes.orderQuantity.currentValue) >= this.productMinimumQty &&
        this.productMaximumQty >= Number(changes.orderQuantity.currentValue)) {
        if (this.productDetail.ProductPrices.length > 0) {
          for (let x = 0; x < this.productDetail.ProductPrices.length; x++) {
            if (this.orderQuantity !== '' && this.orderQuantity >= this.productDetail.ProductPrices[x].MinimumQuantity
              && this.productDetail.ProductPrices[x].MaximumQuantity >= this.orderQuantity) {
              this.productPriceIndex = x;
            }
          }
          if (this.orderQuantity !== '' && this.productPriceIndex !== '' &&
            this.productDetail.ProductPrices[this.productPriceIndex].ProductPrintPrices &&
            this.productDetail.ProductPrices[this.productPriceIndex].ProductPrintPrices.length > 0) {
            this.productPrices = this.productDetail.ProductPrices;
            this.productPrintPrices = this.productDetail.ProductPrices[this.productPriceIndex].ProductPrintPrices;
            this.productLogoTypes = this.productDetail.ProductLogoTypes;
            this.productLogoPositions = this.productDetail.ProductLogoPositions;
            this.productLogoMaxAreas = this.productDetail.ProductLogoMaxAreas;
            this.translate.get('PRODUCT_DETAIL.RECOMMENDED').subscribe((res: string) => {
              this.productLogoPositions.forEach((element) => { if (element.isDefault) element.RecommendedText = " (" + res + ")" });
              this.productLogoTypes.forEach((element) => { if (element.isDefault) element.RecommendedText = " (" + res + ")" });
              this.productLogoMaxAreas.forEach((element) => { if (element.isDefault) element.RecommendedText = " (" + res + ")" });
            });

            this.ProductLogoPositionsFiltered = this.productLogoPositions;
            this.ProductLogoTypesFiltered = this.productLogoTypes;
            this.ProductLogoMaxAreasFiltered = this.productLogoMaxAreas;
            if (this.productPrices.length > 0) {
              this.preSelectBranding();
            }
          }
        }
      }
    }

    /** based on the shoppingcart Logo Datas no.of selected printPriceId will be assigned as Array values */
    if (changes.shoppingCartData !== undefined) {
      console.log("Changes in SHOW BRANDING  shoppingCartData: ", changes.shoppingCartData.currentValue);
      console.log("changes.shoppingCartData.currentValue.ProductPrintPrices123 : ", changes.shoppingCartData.currentValue.ProductPrintPrices);
      if (changes.shoppingCartData.currentValue !== undefined) {
        if (changes.shoppingCartData.currentValue.isFixedLogo !== undefined) {
          this.isFixedLogos = changes.shoppingCartData.currentValue.isFixedLogo;
        }
        if (changes.shoppingCartData.currentValue.Logos[0].PrintPriceID !== undefined) {

          this.logoSelectedprintPriceIds = []
          for (let i = 0; i < changes.shoppingCartData.currentValue.Logos.length; i++) {
            this.logoSelectedprintPriceIds[i] = changes.shoppingCartData.currentValue.Logos[i].PrintPriceID;
            if (changes.shoppingCartData.currentValue.isJoinOrder && changes.shoppingCartData.currentValue.isVisible) {
              this.productPrintPrices = changes.shoppingCartData.currentValue.ProductPrintPrices;
            }
          }

          /** New code for display product print prices values for fixed logo product */
          if (changes.shoppingCartData.currentValue.isFixedLogo !== undefined) {
            if (changes.shoppingCartData.currentValue.isFixedLogo) {
              console.log("changes.shoppingCartData.currentValue.ProductPrintPrices : ", changes.shoppingCartData.currentValue.ProductPrintPrices);
              this.productPrintPrices = changes.shoppingCartData.currentValue.ProductPrintPrices;
            }
          }
          this.preSelectBranding();
        }
      }
    }

    if(changes.fixedLogoProductPrintPrices !== undefined) {
      if(this.isFixedLogos) {
        this.productPrintPrices = changes.fixedLogoProductPrintPrices.currentValue;
        this.preSelectBranding();
      }

    }
    /** based on the shoppingcart Logo Datas no.of selected printPriceId will be assigned as Array values */


    /** based on the shoppingcart Logo Datas print price section will be highlight/dehighlight */
    if (this.productDetail.ProductPrices.length > 0) {
      if (changes.shoppingCartData !== undefined || changes.orderQuantity !== undefined) {
        // if (this.productPrintPrices.length > 0) {

        // }
      }
    }
    /** based on the shoppingcart Logo Datas print price section will be highlight/dehighlight */
  }

  preSelectBranding(callSelectedBrandingFunc = false, preSelectedBrandingPriceId = 0) {
    console.log("this.logoSelectedprintPriceIds : ", this.logoSelectedprintPriceIds);
    console.log("this.productPrintPrices : ", this.productPrintPrices);
    let i = 0;
    this.logoPositionDescription = [];
    if (this.productPrintPrices !== undefined) {
      for (let k = 0; k < this.productPrintPrices.length; k++) {
        this.selectBrandingStatus[i] = false;
        if (this.productPrintPrices[k]['LogoPositionDescription'] !== undefined &&
          this.productPrintPrices[k]['LogoPositionDescription'] !== null) {
          if (this.productPrintPrices[k]['LogoPositionDescription'] !== '' &&
            _.findIndex(this.logoPositionDescription, {
              'name':
                this.productPrintPrices[k]['LogoPositionDescription']
            }) < 0) {
            this.productPriceValues[i] = {
              'Description': this.productPrintPrices[k]['Description'],
              'LogoMaxAreaDescription': this.productPrintPrices[k]['LogoMaxAreaDescription'],
              'Price': this.productPrintPrices[k]['Price'],
              'HandlingCost': this.productPrintPrices[k]['HandlingCost'],
              'ScreenChargesCost': this.productPrintPrices[k]['ScreenChargesCost'],
              'ToolCost': this.productPrintPrices[k]['ToolCost'],
              'SetupCost': this.productPrintPrices[k]['SetupCost'],
              'CurrencyCode': this.productPrintPrices[k]['CurrencyCode'],
              'HandlingCostTypeCode': this.productPrintPrices[k]['HandlingCostTypeCode'],
              'PrintPriceID': this.productPrintPrices[k]['ProductPrintPriceID'],
              'NumberOfColors': this.productPrintPrices[k]['NumberOfColors'],
              'LogoTypeID': this.productPrintPrices[k]['LogoTypeID'],
              'LogoTypeName': this.productPrintPrices[k]['LogoTypeName'],
              'LogoPositionID': this.productPrintPrices[k]['LogoPositionID'],
              'LogoPositionDescription': this.productPrintPrices[k]['LogoPositionDescription'],
              'LogoMaxAreaID': this.productPrintPrices[k]['LogoMaxAreaID'],
              'dropDownDisable': false,
              'brandSelectStatus': (this.logoSelectedprintPriceIds.indexOf(this.productPrintPrices[k]['ProductPrintPriceID']) > -1 ||
                Number(preSelectedBrandingPriceId) === this.productPrintPrices[k]['ProductPrintPriceID']) ? true : false
            };
            const printData = {
              'name': this.productPrintPrices[k]['LogoPositionDescription'],
              'values': [this.productPriceValues[i]]
            }
            this.logoPositionDescription.push(printData);
            i++;
          } else {
            // tslint:disable-next-line:max-line-length
            const logoPositionDescriptionIndex = _.findIndex(this.logoPositionDescription, { 'name': this.productPrintPrices[k]['LogoPositionDescription'] });
            this.logoPositionDescription[logoPositionDescriptionIndex]['values'].push({
              'Description': this.productPrintPrices[k]['Description'],
              'LogoMaxAreaDescription': this.productPrintPrices[k]['LogoMaxAreaDescription'],
              'Price': this.productPrintPrices[k]['Price'],
              'HandlingCost': this.productPrintPrices[k]['HandlingCost'],
              'ScreenChargesCost': this.productPrintPrices[k]['ScreenChargesCost'],
              'ToolCost': this.productPrintPrices[k]['ToolCost'],
              'SetupCost': this.productPrintPrices[k]['SetupCost'],
              'CurrencyCode': this.productPrintPrices[k]['CurrencyCode'],
              'HandlingCostTypeCode': this.productPrintPrices[k]['HandlingCostTypeCode'],
              'PrintPriceID': this.productPrintPrices[k]['ProductPrintPriceID'],
              'NumberOfColors': this.productPrintPrices[k]['NumberOfColors'],
              'LogoTypeID': this.productPrintPrices[k]['LogoTypeID'],
              'LogoTypeName': this.productPrintPrices[k]['LogoTypeName'],
              'LogoPositionID': this.productPrintPrices[k]['LogoPositionID'],
              'LogoPositionDescription': this.productPrintPrices[k]['LogoPositionDescription'],
              'LogoMaxAreaID': this.productPrintPrices[k]['LogoMaxAreaID'],
              'dropDownDisable': false,
              'brandSelectStatus': (this.logoSelectedprintPriceIds.indexOf(this.productPrintPrices[k]['ProductPrintPriceID']) > -1 ||
                Number(preSelectedBrandingPriceId) === this.productPrintPrices[k]['ProductPrintPriceID']) ? true : false
            })
          }
        } else {
          if (this.productPrintPrices[k]['Description'] !== '' &&
            _.findIndex(this.logoPositionDescription, {
              'name': this.productPrintPrices[k]['Description']
            }) < 0) {
            this.productPriceValues[i] = {
              // 'Description': this.productPrintPrices[k]['LogoPositionDescription'],
              'LogoMaxAreaDescription': this.productPrintPrices[k]['Description'],
              'Price': this.productPrintPrices[k]['Price'],
              'HandlingCost': this.productPrintPrices[k]['HandlingCost'],
              'ScreenChargesCost': this.productPrintPrices[k]['ScreenChargesCost'],
              'ToolCost': this.productPrintPrices[k]['ToolCost'],
              'SetupCost': this.productPrintPrices[k]['SetupCost'],
              'CurrencyCode': this.productPrintPrices[k]['CurrencyCode'],
              'HandlingCostTypeCode': this.productPrintPrices[k]['HandlingCostTypeCode'],
              'PrintPriceID': this.productPrintPrices[k]['ProductPrintPriceID'],
              'NumberOfColors': this.productPrintPrices[k]['NumberOfColors'],
              'LogoTypeID': this.productPrintPrices[k]['LogoTypeID'],
              'LogoTypeName': this.productPrintPrices[k]['LogoTypeName'],
              'LogoPositionID': this.productPrintPrices[k]['LogoPositionID'],
              'LogoPositionDescription': this.productPrintPrices[k]['LogoPositionDescription'],
              'LogoMaxAreaID': this.productPrintPrices[k]['LogoMaxAreaID'],
              'dropDownDisable': false,
              'brandSelectStatus': (this.logoSelectedprintPriceIds.indexOf(this.productPrintPrices[k]['ProductPrintPriceID']) > -1 ||
                Number(preSelectedBrandingPriceId) === this.productPrintPrices[k]['ProductPrintPriceID']) ? true : false
            };
            const printData = {
              'name': this.productPrintPrices[k]['Description'],
              'values': [this.productPriceValues[i]]
            }
            this.logoPositionDescription.push(printData);
            i++;
          } else {
            // tslint:disable-next-line:max-line-length
            const logoPositionDescriptionIndex = _.findIndex(this.logoPositionDescription, { 'name': this.productPrintPrices[k]['Description'] });
            this.logoPositionDescription[logoPositionDescriptionIndex]['values'].push({
              // 'Description': this.productPrintPrices[k]['LogoPositionDescription'],
              'LogoMaxAreaDescription': this.productPrintPrices[k]['Description'],
              'Price': this.productPrintPrices[k]['Price'],
              'HandlingCost': this.productPrintPrices[k]['HandlingCost'],
              'ScreenChargesCost': this.productPrintPrices[k]['ScreenChargesCost'],
              'ToolCost': this.productPrintPrices[k]['ToolCost'],
              'SetupCost': this.productPrintPrices[k]['SetupCost'],
              'CurrencyCode': this.productPrintPrices[k]['CurrencyCode'],
              'HandlingCostTypeCode': this.productPrintPrices[k]['HandlingCostTypeCode'],
              'PrintPriceID': this.productPrintPrices[k]['ProductPrintPriceID'],
              'NumberOfColors': this.productPrintPrices[k]['NumberOfColors'],
              'LogoTypeID': this.productPrintPrices[k]['LogoTypeID'],
              'LogoTypeName': this.productPrintPrices[k]['LogoTypeName'],
              'LogoPositionID': this.productPrintPrices[k]['LogoPositionID'],
              'LogoPositionDescription': this.productPrintPrices[k]['LogoPositionDescription'],
              'LogoMaxAreaID': this.productPrintPrices[k]['LogoMaxAreaID'],
              'dropDownDisable': false,
              'brandSelectStatus': (this.logoSelectedprintPriceIds.indexOf(this.productPrintPrices[k]['ProductPrintPriceID']) > -1 ||
                Number(preSelectedBrandingPriceId) === this.productPrintPrices[k]['ProductPrintPriceID']) ? true : false
            })
          }
        }
      }
    }

    /** code for call(one time call) selectedBranding func by default when branding type is choose from advanced filter section */
    if (callSelectedBrandingFunc) {
      const result = _.filter(this.logoPositionDescription, { values: [{ PrintPriceID: Number(preSelectedBrandingPriceId) }] });
      if (result !== '') {
        for (let i = 0; i < result[0].values.length; i++) {
          if (result[0].values[i]['PrintPriceID'] === Number(preSelectedBrandingPriceId)) {
            console.log("Final value : ", result[0].values[i]);
            this.selectedBranding(result[0].values[i], true);
          }
        }
      }
    }
    /** code for call(one time call) selectedBranding func by default when branding type is choose from advanced filter section */
    console.log("Logo Position Descriptions : ", this.logoPositionDescription);
  }

  selectedBranding(data, preSelectStatus = false) {
    data.PrintPriceDescription = data.Description;
    data.isEmailLogoLater = true;
    data.DisableLogoColors = false;
    data.OrderLogoColors = [];
    if (preSelectStatus) {
      console.log("callSelectedBrandingAPI : ", data);
      this.callSelectedBrandingAPI.emit(data);  // for preselect from Advanced Search and get into PDP
    } else {
      setTimeout(() => {
        this.callSelectedBrandingAPI.emit(data);  // for select by User in PDP Page
      }, 50);
    }
  }


  showProductLogoModal() {
    this.productLogoModal.show();
  }

  getTransportationhandlingcosts(orderedQuantity) {
    if (this.productDetail.PiecesPerUnit !== undefined) {
      orderedQuantity = orderedQuantity * this.productDetail.PiecesPerUnit
    }
    if (this.productDetail.isInternalStockProduct && !this.productDetail.isTransportationHandlingCostFree) {
      this._httpProductService.getTransportationhandlingcosts({
        productID: this.productDetail.ProductID,
        quantity: orderedQuantity
      }).subscribe(
        (data: any[]) => {
          this.transportationhandlingcosts = data;
          if (this.transportationhandlingcosts.NumberOfCartons > 0) {
            this.addBackgroundClassCartons = false;
          } else {
            this.addBackgroundClassCartons = true;
          }
          if (this.transportationhandlingcosts.NumberOfUnits > 0) {
            this.addBackgroundClassUnits = false;
          } else {
            this.addBackgroundClassUnits = true;
          }
        });
    }
  }

  change(newValue) {
    this.errorStatus = false;
    if (this.productDetail.FixedUnits) {
      this.orderQuantity = Number(this.productDetail.PiecesPerUnit * newValue);
    } else {
      this.orderQuantity = newValue;
    }
    if (this.orderQuantity > this.maxQtyValue || this.orderQuantity < this.productDetail.MinimumOrder) {
      this.errorStatus = true;
    } else {
      /** new-code => Call Chopping cart API in product detail page based on QTY */
      this.callShoppingCartAPI.emit(this.orderQuantity);
      /** new-code => Call Chopping cart API in product detail page based on QTY */
    }
    this.orderQuantityChange.emit(newValue);
    this.quantityChangeStatus = 1;
    this.quantityChangeStatusChange.emit(newValue);
    if (Number(newValue) >= Number(this.maxQtyValue)) {
      this.orderQuantity = this.maxQtyValue;
    }
  }

  // for Number Only
  onlyNumberKey(event) {
    const pattern = new RegExp('^[0-9\b]+$');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!pattern.test(key) && event.charCode !== 0) {
      event.preventDefault();
      return false;
    }
  }


}
