import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { WebshopService } from '../../../linknet-common/restful-services/restful.service';
import { SettingsComponent } from 'app/webshop/settings/settings.component';

@Component({
  selector: 'shop-app-user-budget',
  templateUrl: './user-budget.component.html',
  styleUrls: ['./user-budget.component.scss']
})
export class UserBudgetComponent implements OnInit {
  public logIndata: any;
  public getUserCurrentBudget: any;
  public userSettings: any;
  public type: any;
  constructor(public _httpWebshopService: WebshopService,
    public globalService: GlobalService, public settingsComponent: SettingsComponent) {
    this.type = 'budget'
    settingsComponent.type = this.type;
    this.logIndata = this.globalService.getLoggedInData();
    this.userSettings = this.globalService.getUserSettings();
  }

  ngOnInit() {
    if (this.logIndata !== null) {
      if (this.logIndata.PaymentByInvoice) {
        this._httpWebshopService.getUserCurrentBudget().subscribe(
          (data: any[]) => {
            this.getUserCurrentBudget = data;
          });
      }
    }
  }

}
