
import { Component } from "@angular/core";
@Component({
    selector: 'shop-about',
  templateUrl: 'about-component.html',
  styleUrls: ['about-component.scss']
})

export class AboutComponent {

}