import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'shop-app-loaders',
  templateUrl: './loaders.component.html',
  styleUrls: ['./loaders.component.scss']
})
export class LoadersComponent implements OnInit {
  @Input() loaderType: string;
  constructor() { }

  ngOnInit() {
    this.loaderType = this.loaderType ? this.loaderType : 'default';
  }

}
