import { Component, OnInit } from '@angular/core';
import { WebshopService } from 'app/linknet-common/restful-services/restful.service';
import { ToastService } from '../../typescripts/pro/alerts';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-logo-bank',
  templateUrl: './logo-bank.component.html',
  styleUrls: ['./logo-bank.component.scss']
})
export class LogoBankComponent implements OnInit {
  public userLogos: any;
  public logoTitle: any;
  public customOptions = { allowedContentTypes: ['application/pdf', 'application/postscript', 'application/postscript'] };

  constructor(private _httpWebshopService: WebshopService, private toastService: ToastService, public titleService: Title,
    private translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit() {
    try {
      this.logoTitle = this.translate.get('HEADING.LOGO_BANK').subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
      this.getUserLogo();
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getUserLogo() {
    try {
      this._httpWebshopService.getUserLogo().subscribe(
        (data: any[]) => {
          this.userLogos = data;
        }, error => {
          // this.toastService.error('Error: ' + error._body);
          this.toastr.error('Error: ' + error.error.Message);
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }
}
