import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { GlobalService } from '../../linknet-common/globals/global.service';
import { HeaderOneComponent } from '../header-one/header-one.component';
import { Router } from '@angular/router';
import { WebshopService, CampaignService } from 'app/linknet-common/restful-services/restful.service';

@Component({
  selector: 'shop-app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit, OnDestroy {

  public products;
  public showSubcategory: Boolean = false;
  public subCategories;
  public categoryName;
  public loggedInData = {};
  public customerImage: any;
  public minWidth: any;
  public windowWidth: any;
  public leftAuto: any;
  public overflow: any = '';
  public overflowClassY: any = '';
  public categoryDropdownHeight: any;
  public categoryLength: any;
  public newCategoryData = [];
  public check = [];
  public mainCategoryDisable: Boolean = false;
  public displayCategoryImageSection: Boolean = true;

  @Input() categories;
  @Input() bsdropdown: HeaderOneComponent;
  isCategoryEmpty: boolean = false;
  productBrands: any;
  brandsItemsShow: boolean;
  CategoryItemsShow: boolean;
  categoryMainShow:boolean = true;
  tempCampaign: any;
  isSmartworks: boolean = false;
  isHrm: boolean = false;
  public campaignID: any = 0;
  constructor(private router: Router, public campaignService: CampaignService,public globalService: GlobalService ,public _httpWebshopService:WebshopService) {
    this.customerImage = this.globalService.webShopSettings().ImageURL;
    if(this.globalService.webShopSettings().DomainName === "smartworks" || this.globalService.webShopSettings().DomainName === "sw"){
      this.isSmartworks = true;
    }
    if(this.globalService.webShopSettings().DomainName === "247hrm" || this.globalService.webShopSettings().DomainName === "247hrm"){
      this.isHrm = true;
    }
  }

  ngOnInit() {

    const loggedInData = this.globalService.getLoggedInData();
     
    this.tempCampaign = this.campaignService.getActiveCampaign().subscribe((res) => {
      let params = {};
      if(res){
        this.campaignID = res.CampaignId;
        params = {
          UserID: loggedInData.UserID,
          CampignID: this.campaignID
        }
        
      }else{
        params = {
          UserID: loggedInData.UserID,
          CampignID: 0
        }
      }
      this._httpWebshopService.getBrands(params).subscribe(
        data => {
          this.productBrands = data;
        }
      );
    });
    
    this.setCategory();
    this.globalService.updateCategoryList.subscribe(_data => {
      this.categories = JSON.parse(sessionStorage.getItem('categoryList'));
      if (this.categories.length > 0) {
        this.isCategoryEmpty = false;
      } else {
        this.isCategoryEmpty = true;
      }
      this.setCategory();
    })
  }

  setCategory() {
    let length = this.categories.length;
    if (length > 3) {
      length = length / 3;
    }
    this.categoryLength = (Math.ceil(length));
    let k = 0;
    for (let j = 0; j < 3; j++) {
      const set = [];
      for (let i = 0; i < this.categoryLength; i++) {
        if (this.categories[k] !== undefined && this.categories[k] !== null) {
          set[i] = this.categories[k];
          k++;
        }
      }
      this.newCategoryData[j] = set;
    }
    //console.log('this.newCategoryData', this.newCategoryData);
    this.windowWidth = (Number(window.innerWidth));
    if (this.categories.length === 1) {
      this.minWidth = '315px';
    } else if (this.categories.length === 2) {
      this.minWidth = '615px';
    } else if (this.categories.length === 3) {
      this.minWidth = '915px';
    } else {
      this.minWidth = '920px';
    }

    setTimeout(() => {
      const domainName = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (Number(domainName.CustomerID) === 1 || Number(domainName.CustomerID) === 45) {
        this.displayCategoryImageSection = false;
      }
      if (this.displayCategoryImageSection) {
        // $('.newSvg').each(function () {
        //   const $img = jQuery(this);
        //   const imgID = $img.attr('id');
        //   const imgClass = $img.attr('class');
        //   const imgURL = $img.attr('src');
        //   jQuery.get(imgURL, function (data) {
        //     let $svg = jQuery(data).find('svg');
        //     if (typeof imgID !== 'undefined') {
        //       $svg = $svg.attr('id', imgID);
        //     }
        //     if (typeof imgClass !== 'undefined') {
        //       $svg = $svg.attr('class', imgClass + ' replaced-svg');
        //     }
        //     $svg = $svg.removeAttr('xmlns:a');
        //     $img.replaceWith($svg);
        //   }, 'xml');
        // });
      }
    }, 500);
  }
  brandsListShow(){
    this.brandsItemsShow = true;
    this.CategoryItemsShow = false;
    this.categoryMainShow = false;
    this.campaignID = 0;
    const loggedInData = this.globalService.getLoggedInData();
    this.tempCampaign = this.campaignService.getActiveCampaign().subscribe((res) => {
      if(res){
        this.campaignID = res.CampaignId;
      }
    });
      this._httpWebshopService.getBrands({
        UserID: loggedInData.UserID,
        CampignID: this.campaignID
      }).subscribe(
        data => {
            this.productBrands = [];
            this.productBrands = data;
        }
      );
    
  }
  catgeoryListShow(){
    this.CategoryItemsShow = true
    this.brandsItemsShow = false
    this.setCategory();
    this.categoryMainShow = false
  }
  toggleCategory() {
    this.showSubcategory = !this.showSubcategory;
  }
  searchProduct(categoryID, categoryName, catID) {
    sessionStorage.removeItem('pageIndex');
    sessionStorage.removeItem('pageSize');
    sessionStorage.setItem('selectedCatId', catID);
    // if(index){
    //   console.log('index',index);
    //   sessionStorage.setItem('isOpenIndex', index);
    // }
    sessionStorage.removeItem('isOpenIndex');

    this.showSubcategory = !this.showSubcategory;
    sessionStorage.setItem('breadCrumbCategoryName', JSON.stringify(categoryName));
    // const str = categoryName;    
    // categoryName = str.replace(/ +/g, '');
    this.router.navigate(['/search', categoryID, categoryName], { replaceUrl: false });
  }
  searchBrandProduct(BrandId, BrandName, brdID) {
    sessionStorage.removeItem('pageIndex');
    sessionStorage.removeItem('pageSize');
    sessionStorage.setItem('selectedBrandId', brdID);
    // if(index){
    //   console.log('index',index);
    //   sessionStorage.setItem('isOpenIndex', index);
    // }
    sessionStorage.removeItem('isOpenIndex');
    sessionStorage.setItem('breadCrumbCategoryName', JSON.stringify(BrandName));
    // const str = categoryName;    
    // categoryName = str.replace(/ +/g, '');
    this.router.navigate(['/searchbrands', BrandId, BrandName], { replaceUrl: false });
  }
  getElementHeight() {
    const x = document.getElementById('mainScreen');
    this.categoryDropdownHeight = x.clientHeight;
    if (x.clientHeight < 320) {
      this.overflow = 'visible'
    } else {
      this.overflowClassY = 'auto'
    }
  }

  showDropdownBottom(event, id) {
    this.mainCategoryDisable = true;
    const x = document.getElementById('mainScreen');
    this.categoryDropdownHeight = x.clientHeight;
    // var newWidth = event.clientX;
    let newHeight = event.clientY;
    newHeight = newHeight - 55;
    if (this.categoryDropdownHeight > 320) {
      this.categoryDropdownHeight = (this.categoryDropdownHeight / 2)
      if (this.categoryDropdownHeight < newHeight) {
        const y = document.getElementById(id);
        if (y !== null && y !== undefined) {
          y.className += ' menuTop';
        }
      }
    }
    const subId = 'sub-' + id;
    const t = document.getElementById(subId);
    if (t !== null && t !== undefined) {
      t.style.display = 'block';
    }
    const arrow = 'bottom-' + id;
    const bot = document.getElementById(arrow);
    if (bot !== null && bot !== undefined) {
      bot.style.display = 'block';
    }
  }

  closeDropdownBottom(_event, id) {
    this.mainCategoryDisable = false;
    const subId = 'sub-' + id
    const t = document.getElementById(subId);
    if (t !== null && t !== undefined) {
      setTimeout(() => {
        t.style.display = 'none';
      }, 100);
    }
    const arrow = 'bottom-' + id;
    const bot = document.getElementById(arrow);
    if (bot !== null && bot !== undefined) {
      bot.style.display = 'none';
    }
  }

  ngOnDestroy() {
    this.tempCampaign.unsubscribe();
  }

  MobileCategory() {
    /*var element = document.querySelectorAll(".navbar-toggler")[0] as HTMLElement;
    if(element){
      element.click();
    }*/
  }
}

