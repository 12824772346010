import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'shop-app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit, OnChanges {
  @Input() productDetail: any;
  @Input() selectColorId: any;
  public productInformation: any;
  public nextShipmentDisplayStatus: Boolean = false;
  public today: Date;
  public prodColors: any;
  public prodSize: any;
  public productIdManual: any;
  public wsaStatus: Boolean = false;
  mainCategoryList: any;
  isOpenIndex: any = -1;
  constructor(private router: Router) { }

  ngOnInit() {
    const domainName = JSON.parse(sessionStorage.getItem('webshopDetail'));
   
    if (Number(domainName.CustomerID) === 45) {
      this.wsaStatus = true;
    }
    this.productInformation = (this.productDetail.Description).replace(/↵/g, '<br/>');
    this.today = new Date();
    if (this.productDetail.NextShipmentDate !== undefined && this.productDetail.NextShipmentDate !== '') {
      const shipmentDate = new Date(this.productDetail.NextShipmentDate);
      if (this.today.getTime() <= shipmentDate.getTime()) {
        this.nextShipmentDisplayStatus = true;
      }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.productIdManual = this.productDetail['ProductIdManual'];
    if (this.productDetail.ProductBatchID !== undefined) {
      for (let i = 0; i < this.productDetail.ProductBatchColors.length; i++) {
        if (changes.selectColorId && changes.selectColorId.currentValue === this.productDetail.ProductBatchColors[i].ProductColorID) {
          this.productIdManual = this.productDetail.ProductBatchColors[i].ProductIdManual;
        }
      }
    }

  }

  categoryPageRedirect(categoryId, categoryName) {
    sessionStorage.removeItem('pageIndex');
    sessionStorage.removeItem('pageSize');
    sessionStorage.setItem('selectedCatId', categoryId);
    sessionStorage.removeItem('isOpenIndex');
    this.mainCategoryList = JSON.parse(sessionStorage.getItem('categoryList'));
    // if (sessionStorage.getItem('selectedCatId')) {
    //   sessionStorage.removeItem('isOpenIndex');
    let len = this.mainCategoryList ? this.mainCategoryList.length : 0;
    let mainCategory = false;
    for (let i = 0; i < len; i++) {
      if (Number(this.mainCategoryList[i].CategoryID) === Number(sessionStorage.getItem('selectedCatId'))) {
        mainCategory = true;
      }
    }
    if (!mainCategory) {
      // const result = this.mainCategoryList.filter(data => data.SubCategories === responseProductTags[i].productTagsGroupID);
      this.mainCategoryList.forEach(element => {
        element.SubCategories.forEach(data => {
          if (Number(data.CategoryID) === Number(sessionStorage.getItem('selectedCatId'))) {
            sessionStorage.setItem('selectedCatId', data.ParentID);
          }
        });
      });
    }
    // }

    sessionStorage.setItem('breadCrumbCategoryName', JSON.stringify(categoryName));
    this.router.navigate(['/search', categoryId, categoryName], { replaceUrl: false });
    // window.history.replaceState(' ', '', '/search/' + categoryId + '/' + categoryName);
    // window.location.reload();
  }
}
