import {AbstractControl} from '@angular/forms';
export class ValidationService {
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      'required': 'REQUIRED',
      'invalidCreditCard': 'INVALIDCREDITCARD',
      'invalidEmailAddress': 'INVALIDEMAILADDRESS',
      'invalidPhoneNumber': 'INVALIDPHONENUMBER',
      'invalidPassword': 'INVALIDPASSWORD',
      'minlength': 'MINLENGTH ' + `${validatorValue.requiredLength}`,
      'invalidQuantity': 'INVALIDQUANTITY',
      'notvalid': 'NOTVALID',
      'maxlength': 'MAXLENGTH ' + `${validatorValue.requiredLength}`,
    };
    return config[validatorName];
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    // tslint:disable-next-line:max-line-length
    if (control.value && control.value.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/)) {
      return null;
    } else {
      return { 'invalidEmailAddress': true };
    }
  }

  emailMobileValidate(value) {
    if (value.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/)) {
      return 'email';
    }else if (value.match( '^[#( 0-9 )-\/]*$' )) {
      return 'mobile';
    } else {
      return 'invalidinput';
    }
  }
  

  static quantityValidator(control) {
    // RFC 2822 compliant regex
    // tslint:disable-next-line:max-line-length
    if (control.value <= 2147483647) {
      return null;
    } else {
      return { 'invalidQuantity': true };
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&.*]{6,100}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }
  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('Password').value; // to get value in input tag
    const confirmPassword = AC.get('Confirmpassword').value; // to get value in input tag
     if (password !== confirmPassword) {
         AC.get('Confirmpassword').setErrors( {MatchPassword: true} )
     } else {
         return null
     }
 }

  static phoneNumberValidator(control) {
    // RFC 2822 compliant regex
    if (control.value.match( '^[#( 0-9 )-\/]*$' )) {
      return null;
    } else {
      return { 'invalidPhoneNumber': true };
    }
  }

  static isNumberCheck(control) {
    // RFC 2822 compliant regex
    if (control.value !== '' && control.value !== null && control.value !== undefined) {
      if (control.value.match( '^[0-9]+(\.[0-9]{0,2})?$' )) {
        return null;
      } else {
        return { 'notvalid': true };
      }
    }
  }

  static numberValidator(control) {
    // RFC 2822 compliant regex
    if (control.value.match( '^[#(0-9)-\/]*$' )) {
      return null;
    } else {
      return { 'invalidPhoneNumber': true };
    }
  }
}
