import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { GlobalService } from "../../globals/global.service";
import { BehaviorSubject, Observable } from "rxjs/Rx";
import "rxjs/Rx";

@Injectable()
export class VoucherService{
    selectedProductIndex: number = -1;
    constructor(private http: HttpClient) {
    }

    getTopVoucherCategories() {
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetTOPVoucherCategoryList", { responseType: "json" });
    }

    getVoucherCategories() {
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetVoucherCategoryList", { responseType: "json" });
    }

    getVoucherBrandsSearch() {
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetSearchBrandList", { responseType: "json" });
    }

    getVoucherBrands() {
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetVoucherBrandList", { responseType: "json" });
    }

    getAllProducts() {
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetVoucherProductList", { responseType: "json" });
    }

    getOMGBrands(i){
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetOMGBrand?Source="+i, { responseType: "json" });
    }

    getProducts(type, id) {
        // if(type === 'brandsname'){
        //     console.log("name")
        //     return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetSearchBrandName?BrandName="+id, { responseType: "json" });
        // }
        if(type === 'brands'){
           
            return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetBrandProductMappingList?BrandId="+id, { responseType: "json" });
            }
        else{
            if(type === 'brandname'){
                return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetSearchBrandName?BrandName="+id, { responseType: "json" });
            }
            else{
                return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetCategoryProductMappingList?CategoryId="+id, { responseType: "json" });
                }
            
            
            //return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetVoucherProductList?CategoryId="+id, { responseType: "json" });
        }
    }

    insertGiftingDetails(params){
        return this.http.post(environment.ENVIRONMENT.baseURL + "en/Categories/InsertGiftingDetails", params, { responseType: "json" });
    }

    getGiftingTheme(){
        return this.http.get(environment.ENVIRONMENT.baseURL + "en/Categories/GetGiftingDetails", { responseType: "json" });
    }
    
}