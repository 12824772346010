import { Component, OnInit, Input } from '@angular/core';
import { GlobalService, CurrencySetterService } from '../../../linknet-common/globals/global.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-product-properties',
  templateUrl: './product-properties.component.html',
  styleUrls: ['./product-properties.component.scss']
})
export class ProductPropertiesComponent implements OnInit {

  public productPropertyDatas: any;
  public currency: any;
  public withHeader: Boolean = true;
  @Input() productProperties: any;
  constructor(public currencySetterService: CurrencySetterService, public globalService: GlobalService,
    private toastService: ToastService, private toastr: ToastrService) {
    try {
      this.currency = this.globalService.getUserSettings().CurrencyCode;
    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnInit() {
    try {
      this.productPropertyDatas = this.productProperties;
    } catch (error) {
      this.toastr.error(error);
    }
  }

}
