const rdomain = (location.host.match(/([^.]+)\.\w{2,3}(?:\.\w{2})?$/) || [])[1];
const full = window.location.host;
let parts = "https://aeapi.rewardkartapi.in:8080";
let parts3 = "http://15.206.52.226:7000";
let parts2 = "https://aeapi.rewardkartapi.in:8080";
let fileserver = "http://15.206.52.226:8883";

let webshopBaseURL = parts;
let socialURL = parts;

let part = full.split(".");
let sub = part[0];
let pbase = 'https://dotnet.constient.com/RewardKartPayWeb/';
// if (sub === "localhost:4200" || !sub) {
//   sub = 'hyperkart';
// }

if (rdomain === "rewardkartshop") {
  //parts = 'http://52.43.40.173:8080/';
  parts = "https://rewardkartapi.in:8060";
  parts2 = "https://rewardkartapi.in:8002";
  parts3 = "https://rewardkartapi.in:8031";
  fileserver = 'https://fs.rewardkartapi.in:8050';
  webshopBaseURL = parts;
  socialURL = parts;
  pbase = 'https://rewardkartapi.in:8061/';
}
else if(rdomain === "gift1to1"){
  parts = "https://b2capi.voucherkart.co.in:8066";
  parts2 = "https://b2capi.voucherkart.co.in:8066";
  parts3 = "https://rewardkartapi.in:8031";
  pbase = 'https://rewardkartapi.in:8061/';
  fileserver = 'https://fs.rewardkartapi.in:8050';
}
else if (rdomain === 'us-constient') {
  parts = 'https://api.constient.us:8081/';
  parts2 =  'https://api.constient.us:8081/';
  fileserver = 'https://fs.rewardkartapi.in:8050';
  webshopBaseURL = parts;
  socialURL = parts;
  pbase = 'https://uk-constient.com:8885/';
}
else if (rdomain === 'hyperkart') {
  parts = 'https://aeapi.rewardkartapi.in:8080/';
  parts2 =  'https://aeapi.rewardkartapi.in:8080/';
  parts3 = 'https://aeapi.rewardkartapi.in:8080/';
  fileserver = 'https://aefs.rewardkartapi.in:8883/';
  webshopBaseURL = parts;
  socialURL = parts;
  pbase = 'https://uk-constient.com:8885/';
  if(sub === "hyperkart"){
    sub = "rewardkart";
  }else{
      sub = part[0];
  }
}
// const parts = full.split(':');

if (
  sessionStorage.getItem("baseURL") !== undefined &&
  sessionStorage.getItem("baseURL") !== null
) {
  if (sessionStorage.getItem("baseURL") !== "") {
    webshopBaseURL = JSON.parse(sessionStorage.getItem("baseURL")) + "-";
  }
}

export const environment = {
  production: true,
  productBlockDisplayCount: 5,
  ENVIRONMENT: {
    baseURL: parts + "/api/",
    baseURL2: parts2 + "/api/",
    fulfillment: parts3 + "/api/",
    pbase: pbase,
    shareURL: "http://" + parts + "/",
    paymentURL: parts,
    fileserver: fileserver,
    socialURL: webshopBaseURL + socialURL[0],
    minPageSize: 6,
    itemsPerPage: [
      {
        value: 20,
        label: 20,
      },
      {
        value: 40,
        label: 40,
      },
      {
        value: 60,
        label: 60,
      },
      {
        value: 80,
        label: 80,
      },
      {
        value: 100,
        label: 100,
      },
    ],
    advancedSearchSorter: [
      {
        value: 0,
        label: "ALL_PRODUCTS",
      },
      {
        value: 1,
        label: "NEWEST_PRODUCT",
      },
      {
        value: 2,
        label: "CHEAPEST_PRODUCT",
      },
      {
        value: 3,
        label: "OLDEST_PRODUCTS",
      },
      {
        value: 4,
        label: "EXPENSIVE_PRODUCT",
      },
    ],
  },
};
