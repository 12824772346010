import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MessageCenterComponent } from './message-center/message-center.component';
import { ListComponent } from './message-center/list/list.component';
import { ViewComponent } from './message-center/view/view.component';
import { ComposeComponent } from './message-center/compose/compose.component';
import { SignupComponent } from './signup/signup.component';



const routes: Routes = [{
  path: 'messages',
  component: MessageCenterComponent,
  children: [
    {
      path: 'list/:type',
      component: ListComponent,
    },
    {
      path: 'compose',
      component: ComposeComponent,
    },
    {
      path: ':type/:id',
      component: ViewComponent,
    }
  ]
}, {
  path: 'signup',
  component: SignupComponent,
  data: { title: 'SignUp' },
  pathMatch: 'full'
}];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopCommonRoutingModule { }
