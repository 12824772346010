import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getExtension'
})
export class GetExtensionPipe implements PipeTransform {

  transform(value: any): any {
    return value.substring(value.lastIndexOf('.') + 1, value.length) || value;
  }

}
