
import { Component } from "@angular/core";
@Component({
    selector: 'shop-faq',
  templateUrl: 'faq-component.html',
  styleUrls: ['faq-component.scss']
})

export class FaqComponent {

}