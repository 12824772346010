import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { INgxMyDpOptions } from 'ngx-mydatepicker';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { DatePipe } from '@angular/common';
import { ProductService } from 'app/linknet-common/restful-services/restful.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-new-order-modified',
  templateUrl: './new-order-modified.component.html',
  styleUrls: ['./new-order-modified.component.scss']
})
export class NewOrderModifiedComponent implements OnInit {

  deliverDate: string;
  @Input() productDetailPrice: any;
  @Input() steps: number;
  @Input() orderQuantity: any;
  @Input() productInputVal: any = {};
  @Input() productCurrentQty: any = {};
  @Input() productDetail: any = {};
  @Input() shoppingCartData: any = {};
  @Input() selectedIds: any = [];
  @Input() productionLineList: any;
  public productPriceData = [];
  @Input() dynamicProductRangePricesParent: number;
  @Input() dynamicProductRangeStatusParent: Boolean;
  public productPrintPriceFirstRecord: number;
  @ViewChild('PriceMoreInfoModal') public priceMoreInfoModal;
  public isExpressOrder: Boolean = false;
  public minDate: any;
  public isFutureStockProduct: Boolean = false;
  public newOrderProductionDays: number;
  public productDeliveryWeeksFrom: number;
  public newOrderProductionDaysTest: number;
  public minimumDeliveryDate: any;
  public currency: any;
  public initialDate: any;
  public initialDateSplit: any;
  public firstPrintPrice: any;
  public qtyChangeValue: any;
  public productHighlightPriceRange: any;
  public productHighlightPriceIndex: any;
  public orderDeliveryDate: any;
  public myOptions: INgxMyDpOptions = {
    dateFormat: 'dd-mm-yyyy'
  };
  public shoppingItems: Array<any> = [];
  public qtyChangeStatus: Boolean = false;
  @Output() shoppingCarts = new EventEmitter();
  @Input() expressOrder: Boolean = false;
  @Output() expressOrderChange = new EventEmitter();
  @Input() expressOrderDateValue: any;
  @Input() productMaxInputValue: any;
  @Input() disableNewOrderButton: Boolean;
  @Output() expressOrderDateValueChange = new EventEmitter();
  public stockModalDisplayStatus: Boolean = true;
  public newOrderDeliveryDatePosition: any;
  public newOrderPricePosition: any;
  public webshopDetails: any;
  transportationhandlingcosts: any;
  // tslint:disable-next-line:variable-name
  Calculatedprice: number;

  /** new-code */
  @Output() commonPrice = new EventEmitter();
  @Input() resetNewOrderStatus: Boolean;
  @Output() resetJoinOrderDate = new EventEmitter();
  /** new-code */

  constructor(public globalService: GlobalService, private toastService: ToastService,
    public datepipe: DatePipe, public _httpProductService: ProductService, private toastr: ToastrService) {
  }

  ngOnInit() {
    try {
      this.productCurrentQty = 0;

      // If user searched by quantity, put that value as initial quantity on product details page
      const breadCrumbsList = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
      var searchUrl = breadCrumbsList[breadCrumbsList.length - 1].url
      const idxQuantity = searchUrl.indexOf('Quantity=');
      if (idxQuantity >= 0 && searchUrl.length > idxQuantity + 9) {
        searchUrl = searchUrl.substring(idxQuantity);
        var quantityStr = '';
        if (searchUrl.indexOf('&') === -1) {
          quantityStr = searchUrl.substring(9);
        }
        else {
          quantityStr = searchUrl.substring(9, searchUrl.indexOf('&'));
        }
        if (quantityStr !== '') {
          this.productCurrentQty = Number(quantityStr);
        }
      }

      // If user searched by delivery date, set it as initial delivery date for new order
      var deliveryDateStr = '';
      var searchUrl = breadCrumbsList[breadCrumbsList.length - 1].url;
      const idxDeliveryDate = searchUrl.indexOf('DeliveryDate=');
      if (idxDeliveryDate >= 0 && searchUrl.length > idxDeliveryDate + 13) {
        searchUrl = searchUrl.substring(idxDeliveryDate);
        if (searchUrl.indexOf('&') === -1) {
          deliveryDateStr = searchUrl.substring(13);
        }
        else {
          deliveryDateStr = searchUrl.substring(13, searchUrl.indexOf('&'));
        }
      }

      this.webshopDetails = this.globalService.webShopSettings();
      if (this.productDetail.isInternalStockProduct && !this.productDetail.isTransportationHandlingCostFree) {
        this.newOrderDeliveryDatePosition = 2;
        this.newOrderPricePosition = 3;
      } else {
        this.newOrderDeliveryDatePosition = 2;
        this.newOrderPricePosition = 3;
      }

      if (this.productDetail.ProductPrices &&
        this.productDetail.ProductPrices[0].ProductPrintPrices.length > 0) {
        this.newOrderDeliveryDatePosition = 3;
        this.newOrderPricePosition = 4;
      }
      // this.productHighlightPriceIndex = 0;
      this.productPriceData = this.productDetailPrice;
      if (this.dynamicProductRangePricesParent === undefined) {
        this.dynamicProductRangePricesParent = 0;
      }
      if (this.productPriceData.length > 0) {
        //  this.firstPrintPrice = Number(this.productPriceData[0].Price) +
        //    Number(this.dynamicProductRangePricesParent);

        this.firstPrintPrice = Number(this.productPriceData[0].Price);
        var priceIndex = 0;
        if (this.productCurrentQty > 0) {
          this.productPriceData.forEach((prc, index) => {
            if ((this.productCurrentQty >= prc.MinimumQuantity) && (this.productCurrentQty <= prc.MaximumQuantity)) {
              this.firstPrintPrice = Number(prc.Price);
              priceIndex = index;
            }
          });
        }

        if (this.productPriceData[priceIndex].ProductPrintPrices.length > 0 && this.productPriceData[priceIndex].ProductPrintPrices[0].isFixedLogo) {
          this.Calculatedprice = 0;
          this.productPriceData[priceIndex].ProductPrintPrices.forEach(element => {
            this.Calculatedprice += element.Price;
          });
          this.firstPrintPrice = Number(this.firstPrintPrice) + Number(this.Calculatedprice);
        }
        this.firstPrintPrice = (this.firstPrintPrice).toFixed(2);

        /** new-code */
        this.currency = this.globalService.getUserSettings().CurrencyCode;
        this.commonPrice.emit({ 'commonPrice': this.firstPrintPrice, 'currency': this.currency });
        /** new-code */
      }

      /** TO Check Fixed Units */
      /* if (this.productDetail.FixedUnits) {
         this.productCurrentQty = this.productDetail.PiecesPerUnit * this.productCurrentQty;
       }
       this.qtyChangeValue = this.productCurrentQty; */

      this.currency = this.globalService.getUserSettings().CurrencyCode;

      /** We need to set New Order Delivery Date as Empty while visit product detail page initially */
      if (this.shoppingCartData.ShoppingCartItemID !== 0) {
        if (deliveryDateStr !== undefined && deliveryDateStr !== '') {
          try {
            deliveryDateStr = deliveryDateStr.split('%2F').join('-');
            this.deliverDate = this.datepipe.transform(deliveryDateStr, 'yyyy-MM-dd');
          } catch {
            // this.deliverDate = this.productDetail.SelectedDeliveryDate;
            this.deliverDate = (this.shoppingCartData !== '' && this.shoppingCartData !== null) ?
              this.shoppingCartData.DeliveryDate : this.productDetail.SelectedDeliveryDate;
          }
        } else {
          // this.deliverDate = this.productDetail.SelectedDeliveryDate;
          this.deliverDate = (this.shoppingCartData !== '' && this.shoppingCartData !== null) ?
            this.shoppingCartData.DeliveryDate : this.productDetail.SelectedDeliveryDate;
        }
      } else {
        this.deliverDate = '';
      }

      this.initialDate = this.productDetail.EarliestDeliveryDate.split('-');
      this.initialDateSplit = this.initialDate[2].split('T');
      this.initialDateSplit[0] = this.initialDateSplit[0] - 1;
      this.myOptions = {
        dateFormat: 'dd-mm-yyyy',
        disableUntil: { year: this.initialDate[0], month: this.initialDate[1], day: this.initialDateSplit[0] }
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    /** new-code */
    console.log("resetNewOrderStatus");
    console.log(this.resetNewOrderStatus)
    if (this.resetNewOrderStatus) {
      this.deliverDate = '';
    }
    /** new-code */

    /** To Check Fixed Units */
    if (this.productDetail.FixedUnits && changes.productCurrentQty) {
      this.productCurrentQty = this.productDetail.PiecesPerUnit * this.productCurrentQty;
    }

    this.productPriceData = [];
    let indexValue = 0;
    this.qtyChangeStatus = false;
    if (this.productDetailPrice.length > 0) {
      for (let _i = 0; _i < this.productDetailPrice.length; _i++) {

        // code for highlight price range in display
        if (this.productDetailPrice[_i].MaximumQuantity >= this.productCurrentQty &&
          this.productDetailPrice[_i].MinimumQuantity <= this.productCurrentQty) {
          this.productHighlightPriceRange = this.productDetailPrice[_i];
          this.productHighlightPriceIndex = _i;
          indexValue = _i;
        }
      }
      if (this.productHighlightPriceRange === '' || this.productHighlightPriceRange === undefined) {
        this.productHighlightPriceRange = [];
        this.productHighlightPriceIndex = 0;
      }

      for (let _i = 0; _i < this.productDetailPrice.length; _i++) {
        this.productPriceData.push(this.productDetailPrice[_i]);
        if (this.qtyChangeValue !== this.productCurrentQty) {
          this.qtyChangeStatus = true;
          this.qtyChangeValue = this.productCurrentQty;
          if (this.productDetailPrice[indexValue]['ProductPrintPrices'].length > 0) {
            this.dynamicProductRangePricesParent = this.productDetailPrice[indexValue]['ProductPrintPrices'][0]['Price'];
          }
        }
      }

      // if (changes.productCurrentQty && changes.productCurrentQty.previousValue !== undefined) {
      if (this.firstPrintPrice && this.firstPrintPrice !== null) {
        if (this.productPriceData.length !== 0) {
          const index = this.productHighlightPriceIndex;
          if (changes.productCurrentQty) {
            this.firstPrintPrice = Number(this.productPriceData[index].Price);
          } else if (changes.dynamicProductRangePricesParent) {
            this.firstPrintPrice = Number(this.productPriceData[index].Price) +
              Number(this.dynamicProductRangePricesParent);
          }
          console.log("First {rice Value");
          console.log(this.firstPrintPrice);
          if (this.productPriceData[index].ProductPrintPrices.length > 0 &&
            this.productPriceData[index].ProductPrintPrices[0].isFixedLogo && !changes.productionLineList && !changes.expressOrder
            && !changes.expressOrderDateValue) {
            this.Calculatedprice = 0
            this.productPriceData[index].ProductPrintPrices.forEach(element => {
              this.Calculatedprice += element.Price
            });
            this.firstPrintPrice = Number(this.firstPrintPrice) + Number(this.Calculatedprice);
          }
          this.firstPrintPrice = Number(this.firstPrintPrice).toFixed(2);
          /** new-code */
          if (this.deliverDate !== '') {
            this.commonPrice.emit({ 'commonPrice': this.firstPrintPrice, 'currency': this.currency });
          }
          /** new-code */
        }
      }
      // }
    }

    if (changes.dynamicProductRangePricesParent !== undefined) {
      if (changes.dynamicProductRangePricesParent.currentValue === '' ||
        changes.dynamicProductRangePricesParent.currentValue === '0') {
        this.newOrderDeliveryDatePosition = 2;
        this.newOrderPricePosition = 3;
        if (this.productDetail.ProductPrices &&
          this.productDetail.ProductPrices[0].ProductPrintPrices.length > 0) {
          this.newOrderDeliveryDatePosition = 3;
          this.newOrderPricePosition = 4;
        }
      }
    }
    else {
      if (this.productDetail.isInternalStockProduct && !this.productDetail.isTransportationHandlingCostFree) {
        this.newOrderDeliveryDatePosition = 2;
        this.newOrderPricePosition = 3;
      } else {
        this.newOrderDeliveryDatePosition = 2;
        this.newOrderPricePosition = 3;
      }
      if (this.productDetail.ProductPrices &&
        this.productDetail.ProductPrices[0].ProductPrintPrices.length > 0) {
        this.newOrderDeliveryDatePosition = 3;
        this.newOrderPricePosition = 4;
      }
    }
  }

  expressOrderDate(ev) {
    console.log(this.expressOrder)
    this.resetNewOrderStatus = false;
    if (ev.currentTarget.checked === true) {
      this.expressOrder = true;
      this.deliverDate = this.productDetail.ExpressOrderDeliveryDate;
      this.expressOrderDateValue = this.productDetail.ExpressOrderDeliveryDate;
    } else {
      this.expressOrder = false;
      // this.deliverDate = this.productDetail.SelectedDeliveryDate;
      this.deliverDate = (this.shoppingCartData !== '' && this.shoppingCartData !== null) ?
        this.shoppingCartData.DeliveryDate : this.productDetail.SelectedDeliveryDate;
      this.expressOrderDateValue = this.productDetail.ExpressOrderDeliveryDate;
    }
    this.expressOrderChange.emit(this.expressOrder);
    this.expressOrderDateValueChange.emit(this.expressOrderDateValue);
    /** new-code */
    this.resetJoinOrderDate.emit({ 'DeliveryDate': this.expressOrderDateValue });
    /** new-code */
  }

  setIsExpressOrder(params) {
    const product = params.product;
    this.isExpressOrder = params.checked;
    if (product.isInternalStockProduct === true
      && (product.ProductFutureStock) && (product.ProductFutureStock[0])) {
      this.isFutureStockProduct = true;
    }
    return this.calculateDays(params);
  }

  dateChanged(data) {
    console.log("Date Changed");
    console.log(data);
    this.resetNewOrderStatus = false;
    this.deliverDate = this.datepipe.transform(data.jsdate, 'yyyy-MM-dd');
    this.expressOrderDateValueChange.emit(this.deliverDate);
    /** new-code */
    this.resetJoinOrderDate.emit({ 'DeliveryDate': this.deliverDate });
    /** new-code */
  }

  calculateDays(parmDate) {
    if (parmDate.MinimumDeliveryDate) {
      this.minimumDeliveryDate = parmDate.minimumDeliveryDate.date;
      this.minimumDeliveryDate = (this.minimumDeliveryDate.year + '/'
        + this.minimumDeliveryDate.month + '/' + ((this.minimumDeliveryDate.day) + 5));
      const oneDay = 24 * 60 * 60 * 1000;
      let firstDate = new Date(this.minimumDeliveryDate ? this.minimumDeliveryDate : new Date());
      if (this.isFutureStockProduct) {
        firstDate = new Date();
      }
      const secondDate = new Date(parmDate.date);
      firstDate.setHours(0, 0, 0, 0);
      secondDate.setHours(0, 0, 0, 0);
      const diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
      return diffDays;
    }
  }

  showPriceMoreInfoModal() {
    this.priceMoreInfoModal.show();
  }

  shoppingCartShow(data) {
    console.log("shoppingCartShow");
    console.log(data);
    console.log(this.deliverDate);
    //  if (data.data.DeliveryDate !== '') {
    if (data.data !== undefined) {
      if (data.data.DeliveryDate !== '') {
        const param = {
          'DeliveryDate': data.data.DeliveryDate,
          'OrderQty': data.data.OrderQty,
          'isDisabled': data.data.isDisabled ? data.data.isDisabled : false
        }
        this.shoppingCarts.emit({ param });
      } else {
        this.shoppingCarts.emit({ 'DeliveryDate': this.deliverDate });
      }
    } else {
      this.shoppingCarts.emit({ 'DeliveryDate': this.deliverDate });
    }
  }


}
