import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NG_SELECT_DEFAULT_CONFIG } from '@ng-select/ng-select';
import { LandingPageComponent } from "./landing-page.component";

@NgModule({
    imports: [
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        NgSelectModule,
    ],
    declarations: [LandingPageComponent],
    exports: [LandingPageComponent],
    schemas: [NO_ERRORS_SCHEMA],
    providers:[DatePipe, {
        provide: NG_SELECT_DEFAULT_CONFIG,
        useValue: {
          notFoundText: 'Not found.'
        }
      }]
})

export class LandingPageModule{}