import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { ProductService } from 'app/linknet-common/restful-services/restful.service';
import { ToastService } from '../../../typescripts/pro/alerts';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-product-like-modal',
  templateUrl: './product-like-modal.component.html',
  styleUrls: ['./product-like-modal.component.scss'],
  exportAs: 'child'
})

export class ProductLikeModalComponent implements OnInit {
  @ViewChild('LikedModal') public likedModal;
  public likeProductForm: any;
  public productData: any = {};
  public translatedMessage: any;
  public showStatus: Boolean = false;
  public userSettings: any;

  constructor(private formBuilder: FormBuilder, private globalService: GlobalService,
    private _httpProducts: ProductService, private toastService: ToastService, private translate: TranslateService,
    private toastr: ToastrService) {
    try {
      this.userSettings = this.globalService.getUserSettings();
      this.likeProductForm = this.formBuilder.group({
        'Quantity': ['', [Validators.required]]
      });
    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnInit() { }

  showModal(param) {
    try {
      this.showStatus = true;
      const logInData = this.globalService.getLoggedInData();
      if (logInData) {
        this.productData = param;
        this.likedModal.show();
      } else {
       // this.translatedMessage = (this.translate.get('PRODUCT_DETAIL.PLEASE_LOGIN_TO_LIKE_PRODUCTS'));
        this.translate.get('PRODUCT_DETAIL.PLEASE_LOGIN_TO_LIKE_PRODUCTS').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  hideModal() {
    this.showStatus = false;
    this.likedModal.hide();
  }

  liked() {
    try {
      const logInData = this.globalService.getLoggedInData();
      if (logInData) {
        const postParameters = {
          'ProductID': this.productData.ProductID,
          'UserId': logInData.UserID,
          'Quantity': this.likeProductForm.value.Quantity
        };

        this._httpProducts.productLiked(postParameters).subscribe(
          (data: any[]) => {
            if (data) {
              this.hideModal();
             // this.translatedMessage = (this.translate.get('PRODUCT_DETAIL.PRODUCT_SAVED_TO_LIKED_PRODUCTS'));
              this.translate.get('PRODUCT_DETAIL.PRODUCT_SAVED_TO_LIKED_PRODUCTS').subscribe((res: string) => {
                this.translatedMessage = res;
              });
              this.toastr.success(this.translatedMessage);
              this.likeProductForm = this.formBuilder.group({
                'Quantity': ['', [Validators.required]]
              });
            }
          }, error => {
            this.toastr.error(error);
          });
      } else {
      //  this.translatedMessage = (this.translate.get('PRODUCT_DETAIL.PLEASE_LOGIN_TO_LIKE_PRODUCTS'));
        this.translate.get('PRODUCT_DETAIL.PLEASE_LOGIN_TO_LIKE_PRODUCTS').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  // for Decimal you can use this as
  onlyDecimalNumberKey(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // tslint:disable-next-line:curly
    if ((charCode < 48 || charCode > 57))
      return false;
    return true;
  }

}
