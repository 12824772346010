import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef, Renderer } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ValidationService } from '../../../linknet-common/validators';
import { ToastService } from '../../../typescripts/pro';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { WebshopService } from '../../../linknet-common/restful-services/restful.service';
import { TranslateService } from '@ngx-translate/core';
import { INgxMyDpOptions } from 'ngx-mydatepicker';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-credit-inquiry',
  templateUrl: './credit-enquiry.component.html',
  styleUrls: ['./credit-enquiry.component.scss'],
  exportAs: 'child'
})
export class CreditEnquiryComponent implements OnInit {
  @ViewChild('creditInquiryModal') public creditInquiryModal;
  @Input() productDetail: any;
  @Input() isMenuInquiry: any;
  @Input() product: any;
  @Output() showMenu = new EventEmitter<boolean>();
  public isUserImageURL: Boolean;
  public creditInquiryForm: any;
  public isInquiryPage = true;
  public imageURL;
  public userImageName: string;
  public translatedMessage: any;
  public userDetails: any;
  public userSettings: any;
  public currency: any;
  public userID: any;
  public postParams: any;
  public imageUrlList = [];
  public isUserLogged = false;
  public isParentCustomer: Boolean = false;
  public myOptions: INgxMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
  };
  public customOptions = {
    allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif',
      'application/pdf', 'application/postscript', 'application/postscript']
  };
  protected _element: ElementRef;
  protected _renderer: Renderer;
  public changeDeliveryDateText: Boolean = false;  // for display text as delivery date / Earliest disp. date in HTML Page
  public wsaStatus: Boolean = false;
  webshopData: any;
  isChildShop: boolean;
  constructor(public formBuilder: FormBuilder, public toastService: ToastService, public globalService: GlobalService,
    public _httpWebshopService: WebshopService, public translate: TranslateService, public datePipe: DatePipe,
    private toastr: ToastrService, _element: ElementRef, _renderer: Renderer) {
    this._element = _element;
    this._renderer = _renderer;
    const d: Date = new Date()
    this.myOptions = {
      dateFormat: 'dd-mm-yyyy',
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() - 1 }
    }
  }

  ngOnInit() {
    try {
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (webshopDetail.ChangeDeliveryDateText) {
        this.changeDeliveryDateText = true;
      }
      if (Number(webshopDetail.CustomerID) === 45) {
        this.wsaStatus = true;
      }
      this.webshopData = webshopDetail;
      if(this.webshopData.ParentCustomerDomainName === this.webshopData.DomainName){
        
        this.isParentCustomer = true;
       // console.log(this.isParentCustomer, "for true")
      }else{
        this.isParentCustomer = false;
        //console.log(this.isParentCustomer, "for false")
      }
      this.userDetails = JSON.parse(sessionStorage.getItem('loggedInData'));
      this.userSettings = JSON.parse(sessionStorage.getItem('userSettings'));
      if (this.userDetails !== null) {
        this.currency = this.userDetails.UserCurrencyCode;
      } else {
        this.currency = this.userSettings.CurrencyCode;
      }
      this.creditInquiryForm = this.formBuilder.group({
          'UserName': [this.userDetails.UserName, [Validators.required,  this.noWhitespaceValidator]],
          'CompanyName': [this.userDetails.CompanyName, [Validators.required,  this.noWhitespaceValidator]],
          'Email': [this.userDetails.Email, [Validators.required, ValidationService.emailValidator]],
          'ProductDescription': [this.userDetails.DeliveryPhoneNumber, [Validators.required, Validators.pattern("^[0-9]*$"),
          Validators.minLength(10), Validators.maxLength(10)]],
          'Comment': [''],
      });
      
    } catch (error) {
      this.toastr.error(error);
    }
  }

  setUploadedImageData(ev) {
    try {
      this.userImageName = ev;
    } catch (error) {
      this.toastr.error(error);
    }
  }

  showInquiryModal(_productDetail) {
    this.creditInquiryModal.show();
    this._renderer.setElementProperty(this._element.nativeElement, 'scrollTop', 0);
    setTimeout(() => {
      this._element.nativeElement.focus();
      const element = this._element.nativeElement.querySelector('[autofocus]');
      if (element) {
        element.focus();
      }
    }, 500);
  }

  sendInquiry() {
    try {
      
        this.postParams = {
          'Email': this.creditInquiryForm.value.Email,
          'UserName': this.creditInquiryForm.value.UserName,
          'CompanyName': this.creditInquiryForm.value.CompanyName,
          'Comment': this.creditInquiryForm.value.Comment,
          'ProductDescription':this.creditInquiryForm.value.ProductDescription,

      }
      Object.keys(this.postParams).forEach((key) => (this.postParams[key] === '') && (this.postParams[key] === null)
        && (this.postParams[key] === undefined) && delete this.postParams[key]);
      this._httpWebshopService.sendInquiry(this.postParams).subscribe(
        _data => {
          this.creditInquiryForm.get('Comment').setValue(null);
          this.translate.get('SETTINGS.INQUIRY_SUCCESS').subscribe((res: string) => {
            this.translatedMessage = res;
          });
          this.toastr.success("Credit enquiry form submitted successfully..!!");
          //this.creditInquiryModal.hide();
          this.close();
          //this.creditInquiryForm.reset();
        },
        error => {
          this.close();
          this.toastr.error(error.error.Message);
        });
    } catch (error) {
      this.close();
      this.toastr.error(error);
    }
  }
  // for Decimal you can use this as
  onlyNumbers(event) {
    const pattern = new RegExp("^[0-9\b]+$");
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!pattern.test(key) && event.charCode !== 0) {
      event.preventDefault();
      return false;
    }
  }

  cancel(){
    
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  close(){
    this.creditInquiryModal.hide(); 
    this.showMenu.emit(false);
  }

}
