import { Component, OnInit, ViewChild } from "@angular/core";
import { DatePipe } from '@angular/common';
import { OrdersService } from "app/linknet-common/restful-services/restful.service";
import { Router } from "@angular/router";

@Component({
    selector: 'shop-app-track-order',
    templateUrl: './track-order.component.html',
    styleUrls: ['./track-order.component.scss']
})

export class TrackOrderComponent implements OnInit{
    orderID: any;
    @ViewChild("trackModal") public trackModal;
    searchData: any;
    isDataAvailable: boolean;
    loaderShow: boolean = false;
    item: any;
    deliveryCompleted: boolean;
    dispatchCompleted: boolean;
    inTransistCompleted: boolean;
    requested: boolean;
    constructor(private _httpOrdersService: OrdersService, private datePipe: DatePipe, public router: Router){
        if(history.state.orderid){
            this.orderID = history.state.orderid;
            //this.orderID = 20539;
        }else{
            this.router.navigate(['/orderhistory'])
        }
    }
    ngOnInit() {
        const params = {
            "SBINumber": "",
            "AWb_no": "",
            "MemberName": "",
            "Trn_Id": "",
            "Rawb_no":"",
            "B_Awb_No":"",
            "ContactNo": "",
            "QAWb_No":"",
            "BatchName": this.orderID,
            "CompanyName":""
        }
        this.loaderShow = true;
        this._httpOrdersService.getTrackOrderSearch(params).subscribe((data)=>{
            this.loaderShow = false;
            if(data){
              this.searchData = data;
              this.searchData.TrackOrder_List.forEach((element:any)=>{
                element.F_Date = this.datePipe.transform(new Date(element.F_Date), "dd/MM/yyyy");
                element.Cour_Date = this.datePipe.transform(new Date(element.Cour_Date), "dd/MM/yyyy");
                element.qrCodeImage = 'data:image/jpeg;base64,'+ element.qrCodeImage;
              });

              if(this.searchData.TrackOrder_List && this.searchData.TrackOrder_List.length>0){
                this.isDataAvailable = true;
              }else{
                this.isDataAvailable = false;
              }
            }
        });
    }
    
    close(){
        this.trackModal.hide();
    }
  itemDetail(item: any){
    this.trackModal.show();
    this.item = item;
 
    if(this.item.DEL_DATE){
      this.requested = true;
      this.dispatchCompleted = true;
      this.inTransistCompleted = true;
      this.deliveryCompleted = true;
    }else if(this.item.Cour_Date){
      this.requested = true;
      this.dispatchCompleted = true;
      this.inTransistCompleted = true;
      this.deliveryCompleted = false;
    }else if(this.item.Dispatch_Date){
      this.requested = true;
      this.dispatchCompleted = true;
      this.inTransistCompleted = false;
      this.deliveryCompleted = false;
    }else if(this.item.F_Date){
      this.requested = true;
      this.dispatchCompleted = false;
      this.inTransistCompleted = false;
      this.deliveryCompleted = false;
    }
    //this.orderDetails(id);
  }

}