import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { ProductService } from 'app/linknet-common/restful-services/restful.service';

@Component({
  selector: 'shop-app-product-input',
  templateUrl: './product-input.component.html',
  styleUrls: ['./product-input.component.scss']
})
export class ProductInputComponent implements OnInit {
  @Input() orderQuantity: any;
  @Input() productDetail: any;
  @Output() orderQuantityChange = new EventEmitter();
  @Input() productPrices: any;
  @Input() quantityChangeStatus: any;
  @Output() quantityChangeStatusChange = new EventEmitter();
  public errorStatus: Boolean = false;
  public maxQtyValue: string;
  public fixedUnits: any;
  public fixedUnitsMinimunValue: any;
  transportationhandlingcosts: any;
  public currency: any;
  addBackgroundClassCartons: Boolean = false;
  addBackgroundClassUnits: Boolean = false;

  constructor(public globalService: GlobalService, public _httpProductService: ProductService) {
    this.currency = this.globalService.getUserSettings().CurrencyCode;
  }

  getTransportationhandlingcosts(orderedQuantity) {
    if (this.productDetail.PiecesPerUnit !== undefined) {
      orderedQuantity = orderedQuantity * this.productDetail.PiecesPerUnit
    }
    if (this.productDetail.isInternalStockProduct && !this.productDetail.isTransportationHandlingCostFree) {
      this._httpProductService.getTransportationhandlingcosts({
        productID: this.productDetail.ProductID,
        quantity: orderedQuantity
      }).subscribe(
        (data: any[]) => {
          this.transportationhandlingcosts = data;
          if (this.transportationhandlingcosts.NumberOfCartons > 0) {
            this.addBackgroundClassCartons = false;
          } else {
            this.addBackgroundClassCartons = true;
          }
          if (this.transportationhandlingcosts.NumberOfUnits > 0) {
            this.addBackgroundClassUnits = false;
          } else {
            this.addBackgroundClassUnits = true;
          }
        });
    }
  }

  ngOnInit() {
    try {
      if (this.productDetail.FixedUnits) {
        this.orderQuantity = this.productDetail.MinimumOrder / this.productDetail.PiecesPerUnit;
      }

      this.orderQuantity = '';

      const priceArrayLength = this.productDetail.ProductPrices.length;
      this.maxQtyValue = this.productDetail.ProductPrices[priceArrayLength - 1].MaximumQuantity;
      this.fixedUnitsMinimunValue = this.productDetail.MinimumOrder / this.productDetail.PiecesPerUnit
    } catch (_error) { }

    setTimeout(function () { document.getElementById("quantity").focus(); }, 1000);

    // If user searched by quantity, put that value as initial quantity on product details page
    const breadCrumbsList = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
    var searchUrl = breadCrumbsList[breadCrumbsList.length - 1].url;
    const idxQuantity = searchUrl.indexOf('Quantity=');
    var quantityStr = '';
    if (idxQuantity >= 0 && searchUrl.length > idxQuantity + 9) {
      searchUrl = searchUrl.substring(idxQuantity);
      if (searchUrl.indexOf('&') === -1) {
        quantityStr = searchUrl.substring(9);
      } else {
        quantityStr = searchUrl.substring(9, searchUrl.indexOf('&'));
      }
    }
    if (quantityStr !== '' && Number(quantityStr) >= this.productDetail.MinimumOrder) {
      this.orderQuantity = quantityStr;
      if (this.productDetail.FixedUnits) {
        this.orderQuantity = Math.floor(this.orderQuantity / this.productDetail.PiecesPerUnit);
      }
    }

    this.getTransportationhandlingcosts(this.orderQuantity);
  }

  change(newValue) {
    this.errorStatus = false;
    if (this.productDetail.FixedUnits) {
      this.orderQuantity = Number(this.productDetail.PiecesPerUnit * newValue);
    } else {
      this.orderQuantity = newValue;
    }
    if (this.orderQuantity > this.maxQtyValue || this.orderQuantity < this.productDetail.MinimumOrder) {
      this.errorStatus = true;
    }
    this.orderQuantityChange.emit(newValue);
    this.quantityChangeStatus = 1;
    this.quantityChangeStatusChange.emit(newValue);
    if (Number(newValue) >= Number(this.maxQtyValue)) {
      this.orderQuantity = this.maxQtyValue;
    }
  }

  // for Decimal you can use this as
  onlyDecimalNumberKey(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // tslint:disable-next-line:curly
    if (charCode !== 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  // for Number Only
  onlyNumberKey(event) {
    const pattern = new RegExp('^[0-9\b]+$');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!pattern.test(key) && event.charCode !== 0) {
      event.preventDefault();
      return false;
    }
  }
}
