import { Component, OnInit } from "@angular/core";
import {WebshopService, CampaignService} from "app/linknet-common/restful-services/restful.service";
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";
import { GlobalService } from "../../linknet-common/globals/global.service";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
@Component({
    selector: 'shop-app-wallet-topup',
    templateUrl: './wallet-topup.component.html',
    styleUrls: ['./wallet-topup.component.scss']
})

export class WalletTopUpComponent implements OnInit {
    headerText: string = 'Wallet Topup';
    walletData: any;
    isLoader: boolean = false;
    emptyItem: boolean = true;
    walletID: any = null;
    logIndata: any;
    campaign: any;
    CID: any;
    walletStatus: boolean = false;
    campaignList: any;
    isParentCustomer : boolean = false;
    constructor(private commonService: CommonServicesService, private campaignService: CampaignService, private _httpWebshopService: WebshopService, public globalService: GlobalService, public router: Router){
        this.logIndata = this.globalService.getLoggedInData();
        this.campaign = JSON.parse(sessionStorage.getItem('campaign'));
        let query = window.location.search;
        const webshop = JSON.parse(sessionStorage.getItem("webshopDetail"));
        if(webshop && webshop.ParentCustomerDomainName === webshop.DomainName) {
            this.isParentCustomer = true;
        }else {
            this.isParentCustomer = false;
        }
        
        if(query.indexOf("?Status=Y") !== -1){
            if(sessionStorage.getItem('topuppay')){
                let params = JSON.parse(sessionStorage.getItem('topuppay'));
                this.campaignService.addRewards(params).subscribe(data=>{
                    if(data === 2){      
                        let points = '';
                        let a =  this.commonService.getPoints().subscribe(data=>{
                            points = data;
                        });
                        a.unsubscribe();
                        let updated = parseInt(params.RewardPoints) + parseInt(points);
                        this.commonService.setPoints(updated);
                        this.campaignList[0].BalancePoints = updated;
                        this.campaignService.setActiveCampaign(this.campaignList[0]);
                        sessionStorage.removeItem('topuppay');
                    }
                });
                this.walletStatus = true;
                this.campaignService.getCampaignForUser().subscribe((data: any) => {
                    //this.campaign.CampaignId
                    if (data) {
                        this.campaignList = data;
                        this.campaignList = this.campaignList.filter((data)=> data.CampaignId === this.campaign.CampaignId);
                        sessionStorage.setItem('campaign', JSON.stringify(this.campaignList[0]));
                        this.commonService.setPoints(this.campaignList[0].BalancePoints);
                        this.campaignService.setActiveCampaign(this.campaignList[0])
                    }
                });
            }
            
        }
    }

    ngOnInit() {
        this.CID = this.router.routerState.snapshot.url;
        this.CID = this.CID.split("Status=");
        this.getWallet();
    }

    getWallet(){
        this.isLoader = true;
        this._httpWebshopService.getWallets().subscribe( (data:any) =>{
            this.isLoader = false;
            this.walletData = data.filter((i)=> i.isActive === true);
            if(this.walletData.length === 0){
                this.emptyItem = true;
            }else{
                this.emptyItem = false;
            }
        }, _eroor=>{
            this.isLoader = false;
        });
        
    }

    topup(){

        let campaignid = this.campaign.CampaignId;

        if(!campaignid){
            campaignid = '177'
        }

        let points = this.walletData.filter((e:any)=>{
            return e.WalletID === parseInt(this.walletID);
        })
        const domain = this.isParentCustomer ? "Hyperkart" : this.logIndata.DomainName;
        let query = "OrderId="+this.walletID+"&customerID="+this.logIndata.UserID+"&IsRewardKart=N&Domain="+domain+"&Pre=TopUp&ReturnURL="+window.location.href+"&Email="+this.logIndata.Email+"&Mobile="+this.logIndata.PhoneNumber+"&campaignID="+campaignid;
        let params = {
            "UserId": this.logIndata.UserID.toString(),
            "RewardPoints": points[0].Price,
            "Desc": "",
            "ExpairyDate": "",
            "CampaignId": parseInt(campaignid),
            "IsActive": true,
            "Action": "Add",
            "IsPointAdd": true,
            "Email": this.logIndata.Email,
            "PhoneNumber": this.logIndata.PhoneNumber,
            "UserName": this.logIndata.UserName,
            "page": 'topup'
        }
        
        if(this.logIndata.DomainName === "voucherkart"){
            params.Action = 'V';
        }
        sessionStorage.setItem('topuppay', JSON.stringify(params))
        const paymentURL = environment.ENVIRONMENT.pbase + 'AtomPayment/AtomPaymentRequest?' + query;
        window.location.href = paymentURL;   
       // window.location.assign(environment.ENVIRONMENT.paymentURL+'/PaymentForms/TopUpRequest.aspx?walletId='+this.walletID+'&userID='+this.logIndata.UserID+'&campaignID='+campaignid);
    }

    inrFormat(val: number) {
        return Number(val).toLocaleString("en-IN");
    }
}