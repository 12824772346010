import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GlobalService } from 'app/linknet-common/globals/global.service';

@Component({
  selector: 'shop-app-product-cost',
  templateUrl: './product-cost.component.html',
  styleUrls: ['./product-cost.component.scss']
})
export class ProductCostComponent implements OnInit, OnChanges {
  @Input() orderQuantity: any;
  @Input() productDetail: any;
  public rangePrice: any;
  public rangePriceVal: any;
  public firstCostPrice: any;
  public showGovernmentalCost: Boolean = true;

  constructor(public globalService: GlobalService) {
  }

  ngOnInit() {
    // this.ngOnChanges();
    const loggedInData = this.globalService.getLoggedInData();
    if (loggedInData === null) {
      this.showGovernmentalCost = false;
    }
  }

  ngOnChanges(): void {
    const initialMinimumQty = this.productDetail.MinimumOrder;
    /* tslint:disable:no-unused-variable */
    let j = 0;
    (this.productDetail.ProductAdditionalCosts).forEach((element) => {
      if (element.Prices !== undefined) {
        (element.Prices).forEach(elementA => {
          if (this.orderQuantity !== undefined) {
            if ((this.orderQuantity >= elementA.MinimumQuantity) && (this.orderQuantity <= elementA.MaximumQuantity)) {
              this.rangePrice = elementA.Price;
            }
          } else {
            if ((initialMinimumQty >= elementA.MinimumQuantity) && (initialMinimumQty <= elementA.MaximumQuantity)) {
              this.rangePrice = elementA.Price;
            }
          }
          element['rangePrice'] = this.rangePrice;
        });
      } else {
        let k = 0;
        if (element.ProductAdditionalCostOptions !== undefined) {
          (element.ProductAdditionalCostOptions).forEach(elementA => {
            let rangePriceVal = 0;
            (elementA.Prices).forEach(elementB => {
              if (this.orderQuantity !== undefined) {
                if (this.orderQuantity >= elementB.MinimumQuantity && elementB.MaximumQuantity >= this.orderQuantity) {
                  rangePriceVal = elementB.Price;
                }
              } else {
                if (initialMinimumQty >= elementB.MinimumQuantity && elementB.MaximumQuantity >= initialMinimumQty) {
                  rangePriceVal = elementB.Price;
                }
              }
            });
            element.ProductAdditionalCostOptions[k]['rangePrice'] = rangePriceVal;
            j++;
            k++;
          });
        }
      }
    });
  }
}
