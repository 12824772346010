import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { WebshopService } from 'app/linknet-common/restful-services/restful.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-list-files',
  templateUrl: './list-files.component.html',
  styleUrls: ['./list-files.component.scss']
})
export class ListFilesComponent implements OnInit {
  @Input() isDownloadable: any;
  @Input() logoData: any;
  @Input() uploadLocation: any;
  @Output() refresh = new EventEmitter();
  @Input() isShoppingCart: any;


  public translatedMessage: any;
  public logo: any;
  @ViewChild('LogoModal') public logoModal;

  constructor(private _httpWebshopService: WebshopService, private toastService: ToastService,
    private translate: TranslateService, private toastr: ToastrService) {

  }

  ngOnInit() {
  }

  deleteFile(param) {
    try {
      if (this.uploadLocation === 'userlogo') {
        this._httpWebshopService.deleteUserLogo(param.LogoID).subscribe(
          (data: any[]) => {
            if (data) {
             // this.translatedMessage = (this.translate.get('FILE_UPLOAD.FILE_DELETE_SUCCESS'));
              this.translate.get('FILE_UPLOAD.FILE_DELETE_SUCCESS').subscribe((res: string) => {
                this.translatedMessage = res;
              });
              this.toastr.success(this.translatedMessage);
              this.refresh.emit();
              this.logoModal.hide();
            }
          }, error => {
            // this.toastService.error(error._body);
            this.toastr.error('Error: ' + error.error.Message);
          }
        )
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  showLogoModal(logo) {
    this.logo = logo;
    this.logoModal.show();
  }
}
