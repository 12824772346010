import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'shop-app-stock-modal',
  templateUrl: './stock-modal.component.html',
  styleUrls: ['./stock-modal.component.scss'],
  exportAs: 'child'
})
export class StockModalComponent implements OnInit {
  public date: any;
  @Input() productDetail: any;
  @Input() productCurrentQty: any;
  @Input() productDeliveryDate: any;
  @Output() changeQtyDeliveryDate = new EventEmitter();
  public noFutureStockBalance: Boolean = false;
  public isShowInquiryButton: Boolean = false;
  public isFutureStockBalance: Boolean = false;
  public totalFutureStockBalance = 0;
  public futureStockDate: any;
  public param: any;
  public isShowNoFutureStock: Boolean;
  @ViewChild('stockModal') public stockModal;
  @ViewChild('futureStockModal') public futureStockModal;
  isShowinquirybutton: boolean;
  finaldeliverydate: any;
  showfuturestockwithoutstock: boolean;
  userproductiondates: string;
  finaldeliverydateformat: string;
  case2: Boolean = false;
  case1: Boolean = false;
  case3: Boolean = false;
  restquantity: number;
  public finaldeliverydateformats: any;
  batchProductColorCode: any;
  batchProductColorName: any;
  setStockBalance: any;
  constructor(public datePipe: DatePipe) { }

  ngOnInit() {
    if (this.productDetail.ProductFutureStock !== undefined &&
      this.productDetail.ProductFutureStock.length > 0) {
      this.productDetail.ProductFutureStock.forEach(data => {
        this.totalFutureStockBalance += data.Value;
      });
    }
  }
  showModals() {
    this.param = '';
    /** Case : 1 => There is no Future Stock and Have Stock Balance */
    if ((Number(this.productCurrentQty) > Number(this.productDetail.StockBalance)) && this.productDetail.StockBalance !== 0) {
      this.isShowNoFutureStock = true;
      this.stockModal.show();
    }

    /** Case : 2 => There is Future Stock and No Stock Balance */
    // tslint:disable-next-line:one-line
    else if (this.productDetail.StockBalance === 0) {
      this.isShowNoFutureStock = false;
      this.stockModal.show();
    }

    // tslint:disable-next-line:one-line
    else {
      // Stock balance is greater or Equal to Current Enter Stock
      if ((Number(this.productCurrentQty) <= Number(this.productDetail.StockBalance)) || !this.productDetail.StockBalance) {
        this.param = {
          'DeliveryDate': '',
          'OrderQty': ''
        }
        this.addShoppingCart();
      } else {
        this.stockModal.show();
      }
    }
  }


  showModal() {
    this.proceedNewOrder();
  }

  afterNDays(numDays, date) {
    const res = date.setTime(date.getTime() + (numDays * 24 * 60 * 60 * 1000));
    return date = new Date(res);
  };
  proceedNewOrder() {
    if (this.productDetail.ProductBatchID === undefined && this.productDetail.StockBalance !== undefined) {
      this.case1 = false;
      this.case2 = false;
      this.case3 = false;
      let total = 0;
      if (this.productDetail.ProductFutureStock !== undefined &&
        this.productDetail.ProductFutureStock.length > 0) {
        this.productDetail.ProductFutureStock.forEach(element => {
          total += element.Value;
          total = total;
        });
      }
      total = total + this.productDetail.StockBalance;
      let allowfuturedate = true;
      if (allowfuturedate) {
        if (Number(this.productCurrentQty) > this.productDetail.StockBalance) {

          this.productDetail.ProductFutureStock.forEach(items => {
            const setvalue = this.productDetail.StockBalance + items.Value
            if (Number(this.productCurrentQty) <= setvalue) {
              const checkdate = this.afterNDays((this.productDetail.DeliveryWeeksTo * 7), new Date(items.Date))
              if (new Date(checkdate).getTime() <= new Date(this.productDeliveryDate).getTime()) {
                allowfuturedate = false;
              }
            }
          });
        }
      }
      if (allowfuturedate !== true) {
        this.param = {
          'DeliveryDate': '',
          'OrderQty': ''
        }
        this.addShoppingCart();
      } else {

        if (this.productDetail.StockBalance < 0) {
          this.productDetail.StockBalance = 0;
        }
        if ((this.productDetail.StockBalance === 0 || Number(this.productCurrentQty) > this.productDetail.StockBalance) &&
          this.productDetail.ProductFutureStock.length === 0) {
          this.case1 = true;
          if (this.productDetail.isInternalStockProduct === true) {
            this.isShowinquirybutton = true;
          } else {
            this.isShowinquirybutton = false;
          }
          // $scope.shownofuturestock = true;
          this.isShowNoFutureStock = true;
          this.stockModal.show();
        }
        // tslint:disable-next-line:one-line
        else if ((this.productDetail.StockBalance === 0 && Number(this.productCurrentQty) > this.productDetail.StockBalance) &&
          this.productDetail.ProductFutureStock.length !== 0) {
          // tslint:disable-next-line:no-shadowed-variable
          let keepGoing = true;
          let allownofuturepopup = false;
          this.productDetail.ProductFutureStock.forEach(items => {
            if (keepGoing) {
              if (Number(this.productCurrentQty) <= items.Value) {
                this.finaldeliverydateformats = items.EarliestDeliveryDate;
                this.finaldeliverydateformat = moment(this.finaldeliverydateformats).format('DD-MM-YYYY');
                this.finaldeliverydate = moment(this.finaldeliverydateformats).format('DD-MM-YYYY');
                this.case2 = true;
                keepGoing = false;
                allownofuturepopup = true;
              }
            }
          });
          if (allownofuturepopup) {
            this.showfuturestockwithoutstock = true;
            this.futureStockModal.show();
            allownofuturepopup = false;
          }
          // tslint:disable-next-line:curly
          // tslint:disable-next-line:one-line
          else if (Number(this.productCurrentQty) <= total) {
            this.productDetail.ProductFutureStock.forEach(items => {
              this.finaldeliverydateformats = items.EarliestDeliveryDate;
              this.finaldeliverydateformat = moment(this.finaldeliverydateformats).format('DD-MM-YYYY');
              this.finaldeliverydate = moment(this.finaldeliverydateformats).format('DD-MM-YYYY');
              allownofuturepopup = true;
              this.case2 = true;
            });
            if (allownofuturepopup) {
              this.futureStockModal.show();
              allownofuturepopup = false;
            }
          }
          // tslint:disable-next-line:one-line
          else {
            if (this.productDetail.isInternalStockProduct === true) {
              this.isShowinquirybutton = true;
            } else {
              this.isShowinquirybutton = false;
            }
            this.stockModal.show();
          }
        }
        // tslint:disable-next-line:one-line
        else if ((this.productDetail.StockBalance > 0 && Number(this.productCurrentQty) > this.productDetail.StockBalance) &&
          this.productDetail.ProductFutureStock.length !== 0) {
          let keepGoing = true;
          let getfuturevalue = 0;
          let allowpopup = false;
          // tslint:disable-next-line:prefer-const
          // tslint:disable-next-line:no-shadowed-variable
          this.restquantity = (Number(this.productCurrentQty) - this.productDetail.StockBalance);


          this.productDetail.ProductFutureStock.forEach(items => {
            getfuturevalue += items.Value;
            if (keepGoing) {
              if (this.restquantity <= getfuturevalue) {
                this.finaldeliverydateformats = items.EarliestDeliveryDate;
                this.finaldeliverydateformat = moment(this.finaldeliverydateformats).format('DD-MM-YYYY');
                this.userproductiondates = moment(this.productDeliveryDate).format('DD-MM-YYYY');
                keepGoing = false;
                allowpopup = true;
                const futurestcokdetails = {
                  restquantity: this.restquantity,
                  StockBalancequantity: this.productDetail.StockBalance,
                  finaldeliverydatewithfuturestock: this.finaldeliverydateformat,
                  finaluserdeliverydate: this.userproductiondates,
                  userorderquantity: Number(this.productCurrentQty)

                }
                this.case3 = true;
                console.log(futurestcokdetails)
              }
            }
          });

          if (allowpopup) {
            this.showfuturestockwithoutstock = false;
            this.futureStockModal.show();
            allowpopup = false;
          }
          // tslint:disable-next-line:one-line
          else if (Number(this.productCurrentQty) <= total) {
            // tslint:disable-next-line:no-shadowed-variable
            this.restquantity = (Number(this.productCurrentQty) - this.productDetail.StockBalance);
            let allownofuturepopup = false;
            this.productDetail.ProductFutureStock.forEach(items => {
              this.finaldeliverydateformats = items.EarliestDeliveryDate;
              this.finaldeliverydateformat = moment(this.finaldeliverydateformats).format('DD-MM-YYYY');
              this.userproductiondates = moment(this.productDeliveryDate).format('DD-MM-YYYY');
              allownofuturepopup = true;
              const futurestcokdetails = {
                restquantity: this.restquantity,
                StockBalancequantity: this.productDetail.StockBalance,
                finaldeliverydatewithfuturestock: this.finaldeliverydateformat,
                finaluserdeliverydate: this.userproductiondates,
                userorderquantity: Number(this.productCurrentQty)

              }
              console.log(futurestcokdetails)
              this.case3 = true;
            });

            if (allownofuturepopup) {
              this.showfuturestockwithoutstock = false;
              this.futureStockModal.show();
              allownofuturepopup = false;
            }
          }
          // tslint:disable-next-line:one-line
          else {
            if (this.productDetail.isInternalStockProduct === true) {
              this.isShowinquirybutton = true;
            } else {
              this.isShowinquirybutton = false;
            }
            this.stockModal.show();
          }
        }
        // tslint:disable-next-line:one-line
        else {
          this.param = {
            'DeliveryDate': '',
            'OrderQty': ''
          }
          this.addShoppingCart();
        }
      }
    } else {
      this.param = {
        'DeliveryDate': '',
        'OrderQty': ''
      }
      this.addShoppingCart();
    }
    this.setStockBalance = this.productDetail.StockBalance;
    let set = this.productDetail.StockBalance;
    if (this.productDetail.FixedUnits) {
      set = this.productDetail.StockBalance / this.productDetail.PiecesPerUnit;
      if (set % 1 !== 0) {
        set = Math.trunc(this.productDetail.StockBalance / this.productDetail.PiecesPerUnit);
        set = set * this.productDetail.PiecesPerUnit;
        this.setStockBalance = set;
        this.restquantity = this.restquantity + Math.abs(this.productDetail.StockBalance - set)
      }

    }
  }

  checkingBatchProductStock() {
    if (this.productDetail.ProductBatchID !== undefined && this.productDetail.ProductFutureStock.length === 0) {
      if ((this.productDetail.ProductBatchColors && this.productDetail.ProductBatchColors.length !== 0) ||
        (this.productDetail.ProductBatchSizes && this.productDetail.ProductBatchSizes.length !== 0)) {
        if (this.productDetail.ProductBatchColors.length !== 0 && this.productDetail.ProductBatchSizes.length !== 0) {

          let approve = true;
          this.productDetail.ProductBatchSizes.forEach(element => {
            if (approve) {
              if (element.StockBalance >= this.productCurrentQty) {
                this.batchProductColorCode = element.ColorCode;
                this.batchProductColorName = element.Name;
                this.productDetail['ProductFutureStock'] = element.ProductFutureStock;
                this.productDetail['StockBalance'] = element.StockBalance;
                this.proceedNewOrder()
                approve = false;
              }
            }
          });


        } else if (this.productDetail.ProductBatchColors.length !== 0 && this.productDetail.ProductBatchSizes.length === 0) {

          let approve = true;
          this.productDetail.ProductBatchColors.forEach(element => {
            if (approve) {
              if (element.StockBalance >= this.productCurrentQty) {
                this.batchProductColorCode = element.ColorCode;
                this.batchProductColorName = element.Name;
                this.productDetail['ProductFutureStock'] = element.ProductFutureStock;
                this.productDetail['StockBalance'] = element.StockBalance;
                this.proceedNewOrder()
                approve = false;
              }
            }
          });


        } else if (this.productDetail.ProductBatchColors.length === 0 && this.productDetail.ProductBatchSizes.length !== 0) {


          let approve = true;
          this.productDetail.ProductBatchSizes.forEach(element => {
            if (approve) {
              if (element.StockBalance >= this.productCurrentQty) {
                this.batchProductColorCode = element.ColorCode;
                this.batchProductColorName = element.Name;
                this.productDetail['ProductFutureStock'] = element.ProductFutureStock;
                this.productDetail['StockBalance'] = element.StockBalance;
                this.proceedNewOrder()
                approve = false;
              }
            }
          });


        }
      }
    } else {
    }
  }

  productQtyDeliveryDate() {
    this.date = new Date(this.productDeliveryDate);
    this.param = {
      'DeliveryDate': this.datePipe.transform(this.date, 'yyyy-MM-dd'),
      'OrderQty': this.productDetail.StockBalance
    }
    this.addShoppingCart();
  }

  addShoppingCart() {
    this.stockModal.hide();
    const data = this.param;
    this.changeQtyDeliveryDate.emit({ data });
  }

  proceedToShoppingCart(case1, case2, case3, isMultipleCall) {
    this.param = {
      'DeliveryDate': '',
      'OrderQty': ''
    }
    if (case1) {
      this.param = {
        'DeliveryDate': '',
        'OrderQty': ''
      }
    } else if (case2) {
      this.param = {
        'DeliveryDate': this.datePipe.transform(this.finaldeliverydateformats, 'yyyy-MM-dd'),
        'OrderQty': this.productCurrentQty,
        'isDisabled': true
      }
    } else if (case3 && !isMultipleCall) {
      window.sessionStorage.showWidget = true;
      this.param = {
        'DeliveryDate': this.productDeliveryDate,
        'OrderQty': this.setStockBalance,
        'isDisabled': true
      }
    } else if (case3 && isMultipleCall) {
      let setUniqueString = Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      setUniqueString = setUniqueString + setUniqueString + '-' + setUniqueString + '-'
        + setUniqueString + '-' + setUniqueString + '-' + setUniqueString + setUniqueString + setUniqueString
      window.sessionStorage.guid = setUniqueString;
      this.param = {
        'DeliveryDate': this.datePipe.transform(this.finaldeliverydateformats, 'yyyy-MM-dd'),
        'OrderQty': this.restquantity,
        'isDisabled': true
      }
    }
    this.addShoppingCart();
  }

}
