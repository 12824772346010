import { Component, OnInit } from '@angular/core';
import { MessageService } from 'app/linknet-common/restful-services/message-services/message.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'shop-app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.scss']
})
export class MessageCenterComponent implements OnInit {
  public type = '';
  public isMobile: boolean = false;
  public numberOfUnreadEmails;
  private subscription: Subscription;
  public messageCenterTitle: any;

  constructor( public _httpMessageService: MessageService,
    public titleService: Title, private translate: TranslateService) {
    this.subscription = this._httpMessageService.getState().subscribe(
      selectionFormatState => {
        this.numberOfUnreadEmails = selectionFormatState;
      });
      if (window.innerWidth > 768) {
        this.isMobile = false;
        console.log("false")
      }else{
       this.isMobile = true;
       console.log("true")
      }
  }
  ngOnInit() {
    this.messageCenterTitle = this.translate.get('HEADING.MESSAGE_CENTER_TITLE').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
  }
}
