import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductService } from 'app/linknet-common/restful-services/restful.service';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { ToastService } from '../../../typescripts/pro/alerts';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-list-folders',
  templateUrl: './list-folders.component.html',
  styleUrls: ['./list-folders.component.scss'],
  exportAs: 'child'
})

export class ListFoldersComponent implements OnInit {
  public folderList: any;
  public translatedMessage: any;
  public selectedProduct: any;
  public alreadyAvailableInFolder: Boolean = false;
  public showStatus: Boolean = false;

  @ViewChild('FolderModal') public folderModal;

  constructor(private _httpProducts: ProductService, private globalService: GlobalService,
    private toastService: ToastService, private translate: TranslateService, private toastr: ToastrService) {
  }

  ngOnInit() { }

  getAllfolders() {
    try {
      this.alreadyAvailableInFolder = false;
      const logInData = this.globalService.getLoggedInData();
      if (logInData) {
        this._httpProducts.getUserFolders().subscribe(
          (data: any[]) => {
            this.folderList = data;
          }
        );
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  showFolderModal(product) {
    try {
      this.showStatus = true;
      const logInData = this.globalService.getLoggedInData();
      if (logInData) {
        this.getAllfolders();
        this.selectedProduct = product;
        this.folderModal.show();
      } else {
      //  this.translatedMessage = (this.translate.get('PRODUCT_DETAIL.PLEASE_LOGIN_TO_FOLDER_PRODUCTS'));
        this.translate.get('PRODUCT_DETAIL.PLEASE_LOGIN_TO_FOLDER_PRODUCTS').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  hideFolderModal() {
    this.showStatus = false;
    this.folderModal.hide();
  }

  addProductToFolder(folder) {
    try {
      const prodID = this.selectedProduct.ProductID;
      const folderID = folder.FolderID;
        const postParameters = {
          'FolderID': folderID,
          'ProductID': prodID,
          'Action': 'insert'
        };
        this._httpProducts.insertOrDeleteFolderProduct(postParameters).subscribe(
          (data: any[]) => {
            if (data) {
            //  this.translatedMessage = (this.translate.get('FOLDERS.PRODUCT_ADDED_TO_FOLDER'));
              this.translate.get('FOLDERS.PRODUCT_ADDED_TO_FOLDER').subscribe((res: string) => {
                this.translatedMessage = res;
              });
              this.toastr.success(this.translatedMessage);
              this.hideFolderModal();
            } else {
            //  this.translatedMessage = (this.translate.get('FOLDERS.PRODUCT_IS_ALREADY_PRESENT_IN_FOLDER'));
              this.translate.get('FOLDERS.PRODUCT_IS_ALREADY_PRESENT_IN_FOLDER').subscribe((res: string) => {
                this.translatedMessage = res;
              });
              this.toastr.error(this.translatedMessage);
            }
          }, error => {
            if (error) {
            //  this.translatedMessage = (this.translate.get('FOLDERS.PRODUCT_IS_ALREADY_PRESENT_IN_FOLDER'));
              this.translate.get('FOLDERS.PRODUCT_IS_ALREADY_PRESENT_IN_FOLDER').subscribe((res: string) => {
                this.translatedMessage = res;
              });
              this.toastr.error(this.translatedMessage);
            }
          });
    } catch (error) {
      this.toastr.error(error);
    }
  }
}
