import { Component, OnInit, Input } from '@angular/core';
import { ToastService } from 'app/typescripts/pro/alerts';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-product-colors',
  templateUrl: './product-colors.component.html',
  styleUrls: ['./product-colors.component.scss']
})
export class ProductColorsComponent implements OnInit {
  @Input() productDetail: any = {};
  @Input() withHeader: any;
  @Input() size: String;
  @Input() minimum: any;
  @Input() showLength: any;
  @Input() colorId: any;

  public start: any = 0;
  public end: any;
  public prodColors: any;
  constructor(private toastService: ToastService, private toastr: ToastrService) { }

  ngOnInit() {
    this.withHeader = this.withHeader ? this.withHeader : 'true';
    this.size = this.size ? this.size : 'lg';
    this.minimum = this.minimum ? this.minimum : null;
    this.end = this.minimum ? this.minimum : this.productDetail.ProductColors.length;
    this.prodColors = this.productDetail.ProductColors;
    // Display Only Filter Color in product Card Page
    if (this.colorId !== undefined && this.colorId !== '') {
      const result = this.prodColors.filter(
        data => (Number(data.ParentColorID) === Number(this.colorId) || (Number(data.ProductColorID) === Number(this.colorId))));
      this.prodColors = result;
      this.prodColors = this.prodColors.slice(0, 1);
    } else {
      this.colorId = '';
      this.prodColors = this.productDetail.ProductColors ?
        this.productDetail.ProductColors.slice(this.start, this.end) : null;
    }
  }

  nextColor() {
    try {
      if (this.end < this.productDetail.ProductColors.length) {
        this.end = parseInt(this.end, 10) + parseInt(this.minimum, 10);
        this.start = parseInt(this.start, 10) + parseInt(this.minimum, 10);
        this.prodColors = this.productDetail.ProductColors.slice(this.start, this.end);
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  previousColor() {
    try {
      if (this.start > 0) {
        this.end = parseInt(this.end, 10) - parseInt(this.minimum, 10);
        this.start = parseInt(this.start, 10) - parseInt(this.minimum, 10);
        this.prodColors = this.productDetail.ProductColors.slice(this.start, this.end);
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }
}
