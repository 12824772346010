import { Component, OnInit } from '@angular/core';
import { SettingsComponent } from '../settings.component';

@Component({
  selector: 'shop-app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  public type: any;
  constructor(public settingsComponent: SettingsComponent) {
    this.type = 'edituser'
    settingsComponent.type = this.type;
  }

  ngOnInit() {
  }

}
