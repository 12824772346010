import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { GlobalService } from "../../globals/global.service";
import { Router } from "@angular/router";
import "rxjs/Rx";
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class ProductService {
  public CID: any;
  public categoryId: any = "";

  public data;
  public queryParams: any;
  public colorID: any = "";
  public sortBy: any = "";
  public colorParam: any;
  private isShowDetail = new BehaviorSubject<Boolean>(false);
  currency: number | string;
  currencyCode: string = "";
  currencyID: string | number = JSON.parse(sessionStorage.getItem("countryID"))
  currentShowDetail = this.isShowDetail.asObservable();
  private navigationPerformed: boolean = false;
  countriesID:number | string;
  flag:string;
  countryNAME:string;
  constructor(
    private router: Router,
    private http: HttpClient,
    private _httpClient: HttpClient,
    public globalService: GlobalService
  ) {
    this.queryParams = this.router.routerState.snapshot.root.queryParams;
  }

  hasNavigationPerformed(): boolean {
    return this.navigationPerformed;
  }

  setNavigationPerformed(value: boolean): void {
    this.navigationPerformed = value;
  }

  changeShowDetail(status: Boolean) {
    this.isShowDetail.next(status);
  }
  setCurrency(data: number | string){
    this.currency = data
  }
  getCurrenct(){
    return this.currency;
  }
  setCurrencyCode(data:string){
    this.currencyCode = data;
  }
  getCurrencyCode() {
    return this.currencyCode;
  }
  setCountryID(data:number | string) {
    this.countriesID = data;
  }

  getCountryID() {
    return this.countriesID;
  }

  setflagImg(data:string) {
    this.flag = data;
  }

  getflagImg() {
    return this.flag;
  }
  setcountryname(data:string) {
    this.countryNAME = data;
  }

  getcountryname() {
    return this.countryNAME;
  }
  /**
   * Service for Products
   */
  getProducts(pageIndex, pageSize, postData) {
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const userData = this.globalService.getLoggedInData();
    this.data = postData;
    this.data.CurrencyID = userSettings.CurrencyID;
    this.data.CustomerID = webShopSettings.CustomerID;
    this.data.isWebshop = true;
    if (userData != null) this.data.UserID = userData.UserID;
    this.queryParams = this.router.routerState.snapshot.root.queryParams;
    this.CID = this.router.routerState.snapshot.url;

    if (
      this.queryParams["CategoryID"] === undefined &&
      this.CID.indexOf("/search/") !== -1
    ) {
      this.data.FirstSubcategoryRule = false;
    }
    if (pageIndex == null || pageIndex === undefined) {
      pageIndex = 0;
    }
    if (!pageSize) {
      pageSize = 20;
    }
    /** new-code => code based on WSA SHOP => if sort by is empty then it will set to 1 for WSA  */
    const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
    // if (webshopDetail.DomainName === 'wsa') {
    if (Number(webshopDetail.CustomerID) === 45) {
      if (
        this.queryParams.SortBy === undefined ||
        this.queryParams.SortBy === ""
      ) {
        this.data.SortBy = "1";
      }
    }

    /** new-code => code based on WSA SHOP => if sort by is empty then it will set to 3 (oldest product First) for GN  */
    if (Number(webshopDetail.CustomerID) === 15) {
      if (
        this.queryParams.SortBy === undefined ||
        this.queryParams.SortBy === ""
      ) {
        this.data.SortBy = "3";
      }
    }

    let currencyid = this.getCurrenct() ? this.getCurrenct(): JSON.parse(sessionStorage.getItem("countryID"));
    let countryId = this.getCountryID() ?  this.getCountryID() : JSON.parse(sessionStorage.getItem("dropCountryID"));
    if(currencyid !== "ALL" && currencyid !== null && currencyid !== undefined) {
      this.data.NewCurrencyID = currencyid;
    }
    if(countryId !== "ALL" && countryId !== null && countryId !== undefined) {
      this.data.NewCountryID = countryId;
    }
    /** new-code => code based on WSA SHOP => if sort by is empty then it will set to 1 for WSA */

    // return this.http.post(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + '/search?pageIndex='
    //   + pageIndex + '&pageSize=' + pageSize, this.data).pipe(map(
    //     (response: Response) => (response.json()))
    //   );
    return this.http.post(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/search?pageIndex=" +
        pageIndex +
        "&pageSize=" +
        pageSize,
      this.data,
      { responseType: "json" }
    );
  }

  getCampaignProducts(pageIndex, pageSize, postData) {
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const userData = this.globalService.getLoggedInData();
    this.data = postData;
    this.data.CurrencyID = userSettings.CurrencyID;
    this.data.CustomerID = webShopSettings.CustomerID;
    this.data.isWebshop = true;
    if (userData != null) this.data.UserID = userData.UserID;
    this.queryParams = this.router.routerState.snapshot.root.queryParams;
    this.CID = this.router.routerState.snapshot.url;

    if (
      this.queryParams["CategoryID"] === undefined &&
      this.CID.indexOf("/search/") !== -1
    ) {
      this.data.FirstSubcategoryRule = false;
    }
    if (pageIndex == null || pageIndex === undefined) {
      pageIndex = 0;
    }
    if (!pageSize) {
      pageSize = 20;
    }
    /** new-code => code based on WSA SHOP => if sort by is empty then it will set to 1 for WSA  */
    const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
    // if (webshopDetail.DomainName === 'wsa') {
    if (Number(webshopDetail.CustomerID) === 45) {
      if (
        this.queryParams.SortBy === undefined ||
        this.queryParams.SortBy === ""
      ) {
        this.data.SortBy = "1";
      }
    }

    /** new-code => code based on WSA SHOP => if sort by is empty then it will set to 3 (oldest product First) for GN  */
    if (Number(webshopDetail.CustomerID) === 15) {
      if (
        this.queryParams.SortBy === undefined ||
        this.queryParams.SortBy === ""
      ) {
        this.data.SortBy = "3";
      }
    }
    let currencyId = this.getCurrenct() ? this.getCurrenct() : JSON.parse(sessionStorage.getItem("countryID"));
    let countryId = this.getCountryID() ? this.getCountryID() : JSON.parse(sessionStorage.getItem("dropCountryID"));
    if(currencyId !== "ALL" && currencyId !== null && currencyId !== undefined){
      this.data.NewCurrencyID = currencyId;
    }
    if(countryId !== "ALL" && countryId !== null && countryId !== undefined){
      this.data.NewCountryID = countryId;
    }

    /** new-code => code based on WSA SHOP => if sort by is empty then it will set to 1 for WSA */

    // return this.http.post(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + '/search?pageIndex='
    //   + pageIndex + '&pageSize=' + pageSize, this.data).pipe(map(
    //     (response: Response) => (response.json()))
    //   );
    return this.http.post(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/SearchV1?pageIndex=" +
        pageIndex +
        "&pageSize=" +
        pageSize,
      this.data,
      { responseType: "json" }
    );
  }

  /**
   * Service for Product Blocks
   */
  productBlocks(customerID, isFirstCall, CampaignId) {
    const userSettings = this.globalService.getUserSettings();
    const userData = this.globalService.getLoggedInData();
   
    let url = "";
    if (isFirstCall) {
      url =
        environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/productblocks?filterBatchProducts=true&currencyID=" +
        userSettings.CurrencyID +
        "&CustomerID=" +
        customerID +
        (CampaignId != "" ? "&isChildShop=true&CampaignId=" + CampaignId : "") +
        (userData != null ? "&userID=" + userData.UserID : "");
    } else {
      url =
        environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/productblocks?filterBatchProducts=true&currencyID=" +
        userSettings.CurrencyID +
        "&CustomerID=" +
        customerID +
        (CampaignId != "" ? "&isChildShop=true&CampaignId=" + CampaignId : "") +
        (userData != null ? "&userID=" + userData.UserID : "");
    }
    return this._httpClient.get(url, { responseType: "json" });
  }

  /**
   * Service to search product
   */
  searchProduct(param,countryId) {
    const webShopSettings = this.globalService.webShopSettings();
    const userSettings = this.globalService.getUserSettings();
    const userData = this.globalService.getLoggedInData();
    let ccid = this.getCountryID() ? this.getCountryID() : JSON.parse(sessionStorage.getItem("dropCountryID"));
    // param['isWebshop'] = true;
    return this.http.get(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/search?&CustomerID=" +
        webShopSettings.CustomerID +
        "&name=" +
        param +
        (userData != null ? "&userID=" + userData.UserID : "") + (countryId !== null && countryId !== undefined && countryId !== 'ALL' ? "&NewCurrencyID=" + countryId : '') + (ccid !== null && ccid !== undefined && ccid !== 'ALL' ? "&NewCountryID=" + ccid : ''),
      { responseType: "json" } 
    );
  }

  searchCategoriesProducts(url, param, index, size){
    const userSettings = this.globalService.getUserSettings();
    return this.http.post(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + '/'+url+'?pageIndex='+index+'&pageSize='+size, param, {responseType: "json"})
  }

  /**
   * Get product colors
   */
  getProductColors(param) {
    const webShopSettings = this.globalService.webShopSettings();
    const userSettings = this.globalService.getUserSettings();
    const userData = this.globalService.getLoggedInData();
    return this.http.get(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/productcolors?onlyParentColors=" +
        param +
        "&CustomerID=" +
        webShopSettings.CustomerID +
        (userData != null ? "&userID=" + userData.UserID : "") +
        "&isWebshop=true",
      { responseType: "json" }
    );
  }
  /**
   * Get product colors
   */
  getProductColorsByID(colorStatus) {
    this.categoryId = "";
    const webShopSettings = this.globalService.webShopSettings();
    const userSettings = this.globalService.getUserSettings();
    const userData = this.globalService.getLoggedInData();
    this.queryParams = this.router.routerState.snapshot.root.queryParams;
    this.CID = this.router.routerState.snapshot.url;
    if (!colorStatus) {
      if (
        this.queryParams.ColorID !== undefined ||
        this.queryParams.ColorID !== ""
      ) {
        this.colorID = "";
      }
    } else {
      this.colorID = this.queryParams.ColorID;
    }

    /** new-code => code based on WSA SHOP => if sort by is empty then it will set to 1 for WSA  */
    const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
    const productTagDetails = JSON.parse(sessionStorage.getItem("productTags"));
    let productTagIds = [];
    if (
      productTagDetails != undefined &&
      productTagDetails != null &&
      productTagDetails.length > 0
    ) {
      productTagDetails.forEach((item) => {
        if (item.value != null && item.value.length > 0) {
          item.value.forEach((data) => {
            productTagIds.push(parseInt(data));
          });
        }
      });
    }

    // if (webshopDetail.DomainName === 'wsa') {
    if (Number(webshopDetail.CustomerID) === 45) {
      if (
        this.queryParams.SortBy !== undefined &&
        this.queryParams.SortBy !== ""
      ) {
        this.sortBy = this.queryParams.SortBy;
      } else {
        this.sortBy = "1";
      }
    } else if (Number(webshopDetail.CustomerID) === 15) {
      if (
        this.queryParams.SortBy !== undefined &&
        this.queryParams.SortBy !== ""
      ) {
        this.sortBy = this.queryParams.SortBy;
      } else {
        this.sortBy = "3";
      }
    } else {
      if (
        this.queryParams.SortBy !== undefined &&
        this.queryParams.SortBy !== ""
      ) {
        this.sortBy = this.queryParams.SortBy;
      } else {
        this.sortBy = "";
      }
    }
    /** new-code => code based on WSA SHOP => if sort by is empty then it will set to 1 for WSA */

    if (
      this.queryParams.CategoryID !== undefined &&
      this.queryParams.SubCategory === undefined
    ) {
      this.categoryId = this.queryParams.CategoryID;
    } else if (this.queryParams.SubCategory !== undefined) {
      this.categoryId = this.queryParams.SubCategory;
    } else {
      if (this.CID.indexOf("/search/") !== -1) {
        this.CID = this.CID.split("/");
        this.categoryId = this.CID[2];
      }
    }
    this.colorParam = {
      CategoryID: this.categoryId,
      ShowPricesIncludingLogoType: this.queryParams.ShowPricesIncludingLogoType
        ? this.queryParams.ShowPricesIncludingLogoType
        : null,
      Quantity: this.queryParams.Quantity ? this.queryParams.Quantity : "",
      PriceFrom: this.queryParams.PriceFrom ? this.queryParams.PriceFrom : "",
      PriceTo: this.queryParams.PriceTo ? this.queryParams.PriceTo : "",
      ColorID: this.colorID,
      productName: this.queryParams.ProductName
        ? this.queryParams.ProductName
        : "",
      DeliveryDate: this.queryParams.DeliveryDate
        ? this.queryParams.DeliveryDate
        : "",
      // 'SortBy': this.queryParams.SortBy ? this.queryParams.SortBy : '',
      SortBy: this.sortBy,
      CurrencyID: userSettings.CurrencyID,
      CustomerID: webShopSettings.CustomerID,
      isWebshop: true,
      UserID: userData != null ? userData.UserID : null,
      MinimumSupplierQualityLevel: this.queryParams.SupplierQualityLevel
        ? this.queryParams.SupplierQualityLevel
        : null,
      ProductTagIDs: productTagIds,
    };
    const url = this.router.routerState.snapshot.url;
    if (
      url.indexOf("/search/") !== -1 &&
      this.queryParams["CategoryID"] === undefined
    ) {
      this.colorParam.FirstSubcategoryRule = false;
    }
    return this.http.post(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/searchcolors",
      this.colorParam,
      { responseType: "json" }
    );
  }

  updateCategoriesSelectBar(productTagIds) {
    let ProductTagIDs = productTagIds;
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const userData = this.globalService.getLoggedInData();
    return this.http.post(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/webshopcategories?customerID=" +
        webShopSettings.CustomerID +
        (userData != null ? "&userID=" + userData.UserID : ""),
      ProductTagIDs,
      { responseType: "json" }
    );
  }

  /**
   * Service to Get A product detail
   * @param productID
   */

  getProductDetail( productID, lang, shoppingCartItemStatus = undefined, initialDeliveryCountry = undefined) {
    const webShopSettings = this.globalService.webShopSettings();
    const userSettings = this.globalService.getUserSettings();
    const userData = this.globalService.getLoggedInData();

    if (lang === undefined) {
      lang = userSettings.LanguageCode;
    }
    let shoppingCartQuery = "";
    let initialDeliveryCountryQuery = "";
    if (shoppingCartItemStatus) {
      shoppingCartQuery = "&shoppingCartItem=true";
    }
    if (initialDeliveryCountry !== null &&  initialDeliveryCountry !== undefined && initialDeliveryCountry !== "") {
      initialDeliveryCountryQuery = "&deliveryCountry=" + initialDeliveryCountry;
    }
    let CampaignId = null;
    if(webShopSettings.ParentCustomerDomainName === webShopSettings.DomainName) {
    }else{
      const campagin = JSON.parse(sessionStorage.getItem('campaign')) 
      if(campagin){
      CampaignId = campagin.CampaignId;
      }
    }
    return this.http.get(
      environment.ENVIRONMENT.baseURL + lang +"/products/" + productID + "?filterBatchProducts=true&currencyID=" + userSettings.CurrencyID + "&CustomerID=" + webShopSettings.CustomerID + "&isWebshop=true" + (userData != null ? "&userID=" + userData.UserID : "") + ( CampaignId != null ? "&CampaignId=" + CampaignId : "") + shoppingCartQuery + initialDeliveryCountryQuery, { responseType: "json" }
    );
  }

  productLiked(param) {
    const userSettings = this.globalService.getUserSettings();
    return this.http.post(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/productlikes?",
      param,
      { responseType: "json" }
    );
  }

  /**
   * Get all user folders
   */

  getUserFolders() {
    const webShopSettings = this.globalService.webShopSettings();
    const userSettings = this.globalService.getUserSettings();
    const userData = this.globalService.getLoggedInData();
    return this.http.get(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/" +
        userData.UserID +
        "/folders" +
        "?foldersType=USER&CurrencyID=" +
        userSettings.CurrencyID +
        "&CustomerID=" +
        webShopSettings.CustomerID,
      { responseType: "json" }
    );
  }
  /**
   * Get all user folders
   */

  getTransportationhandlingcosts(param) {
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    return this.http.get(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/transportationhandlingcosts" +
        "?productID=" +
        param.productID +
        "&quantity=" +
        param.quantity +
        "&currencyID=" +
        userSettings.CurrencyID +
        "&customerID=" +
        webShopSettings.CustomerID,
      { responseType: "json" }
    );
  }

  getTransportationhandlingcostPrices(ProductID) {
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    return this.http.get(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/transportationhandlingcostprices" +
        "?customerID=" +
        webShopSettings.CustomerID +
        "&currencyID=" +
        userSettings.CurrencyID +
        "&productID=" +
        ProductID +
        "&isWebshop=true",
      { responseType: "json" }
    );
  }

  /**
   * Save Product to Folder
   */
  insertOrDeleteFolderProduct(param) {
    const userSettings = this.globalService.getUserSettings();
    this.data = {
      ProductID: param.ProductID,
      Action: param.Action,
    };
    const data = [];
    data.push(this.data);
    return this.http.post(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/folders/" +
        param.FolderID,
      data,
      { responseType: "json" }
    );
  }

  /**
   * Create folder
   */

  createFolder(param) {
    const userSettings = this.globalService.getUserSettings();
    return this.http.post(
      environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + "/folders",
      param,
      { responseType: "json" }
    );
  }

  /**
   * Update folder
   */

  updateFolder(param) {
    const postparam = {
      FolderTitle: param.FolderTitle,
      Type: param.Type,
      UserID: param.UserID,
      action: param.action,
      isDeleted: param.isDeleted,
    };
    const userSettings = this.globalService.getUserSettings();
    return this.http.put(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/folders/" +
        param.FolderID,
      postparam,
      { responseType: "json" }
    );
  }

  /**
   * Delete Folder
   */

  deleteFolder(param) {
    const userSettings = this.globalService.getUserSettings();
    return this.http.delete(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/folders/" +
        param.FolderID,
      { responseType: "json" }
    );
  }

  getProductionLineDetails(param) {
    let userParam = "";
    const webShopSettings = this.globalService.webShopSettings();
    const userSettings = this.globalService.getUserSettings();
    if (param.userID !== undefined && param.userID !== null) {
      if (param.userID !== "") {
        userParam += "&userID=" + param.userID;
      }
    }
    return this.http.get(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/productionlines" +
        "?filterBatchProducts=true&ProductID=" +
        param.productID +
        "&CurrencyID=" +
        userSettings.CurrencyID +
        "&CustomerID=" +
        webShopSettings.CustomerID +
        userParam,
      { responseType: "json" }
    );
  }

  getFolderProducts(page, param) {
    const webShopSettings = this.globalService.webShopSettings();
    const userSettings = this.globalService.getUserSettings();
    if (page.pageIndex == null || page.pageIndex === undefined) {
      page.pageIndex = 0;
    }
    if (page.pageSize == null || page.pageSize === undefined) {
      page.pageIndex = 20;
    }
    return this.http.get(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/folders" +
        "/" +
        param.FolderID +
        "?filterBatchProducts=true&CurrencyID=" +
        userSettings.CurrencyID +
        "&CustomerID=" +
        webShopSettings.CustomerID +
        "&PageIndex=" +
        page.pageIndex +
        "&PageSize=" +
        page.pageSize,
      { responseType: "json" }
    );
  }

  /**
   * Get All liked products
   */

  getLikedProducts(pageIndex) {
    const webShopSettings = this.globalService.webShopSettings();
    const userSettings = this.globalService.getUserSettings();
    const userData = this.globalService.getLoggedInData();
    if (pageIndex == null || pageIndex === undefined) {
      pageIndex = 0;
    }
    return this.http.get(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/productlikes" +
        "?filterBatchProducts=true&UserID=" +
        userData.UserID +
        "&CurrencyID=" +
        userSettings.CurrencyID +
        "&CustomerID=" +
        webShopSettings.CustomerID +
        "&PageIndex=" +
        pageIndex.pageIndex +
        "&PageSize=" +
        pageIndex.pageSize,
      { responseType: "json" }
    );
  }

  /**
   * Delete liked product
   */

  deleteLikedProduct(param) {
    const userSettings = this.globalService.getUserSettings();
    return this.http.delete(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/productlikes/" +
        param.ProductLikeID,
      { responseType: "json" }
    );
  }

  /**
   * Delete liked product
   */

  getOrderStateItems(param) {
    const userSettings = this.globalService.getUserSettings();
    if (param === true) {
      return this.http.get(
        environment.ENVIRONMENT.baseURL +
          userSettings.LanguageCode +
          "/orderitemstates?roleCode=user",
        { responseType: "json" }
      );
    } else {
      return this.http.get(
        environment.ENVIRONMENT.baseURL +
          userSettings.LanguageCode +
          "/orderitemstates/" +
          param +
          "?roleCode=user",
        { responseType: "json" }
      );
    }
  }

  getTotalProduct() {
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const userData = this.globalService.getLoggedInData();
    let campagin = JSON.parse(sessionStorage.getItem('campaign'))
    if((webShopSettings.ParentCustomerDomainName === webShopSettings.DomainName) || !campagin){
    return this.http.get(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/productscount?customerID=" +
        webShopSettings.CustomerID +
        (userData != null ? "&userID=" + userData.UserID : ""),
      { responseType: "json" }
    );
    }else{
      return this.http.get(
        environment.ENVIRONMENT.baseURL +
          userSettings.LanguageCode +
          "/GetChildShopProductsCount?customerID=" +
          webShopSettings.CustomerID + "&userID=" + userData.UserID +"&CampaignId="+ campagin.CampaignId,
        { responseType: "json" });
    }
  }
  deleteUnAuthorizedOrders(orderItemId) {
    const userSettings = this.globalService.getUserSettings();
    const params = [];
    return this.http.put(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/unauthorized/" +
        orderItemId,
      params,
      { responseType: "json" }
    );
  }
  recalculateTransportationOrders(orderItemId) {
    const userSettings = this.globalService.getUserSettings();
    const params = [];
    return this.http.put(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/recalculateordertransportation/" +
        orderItemId,
      params,
      { responseType: "json" }
    );
  }
  acceptUnAuthorizedOrders(orderItemId) {
    const userSettings = this.globalService.getUserSettings();
    const params = [];
    return this.http.put(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/orderitems/userresponse/" +
        orderItemId +
        "/" +
        true,
      params,
      { responseType: "json" }
    );
  }
  rejectUnAuthorizedOrders(orderItemId) {
    const userSettings = this.globalService.getUserSettings();
    const params = [];
    return this.http.put(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/orderitems/userresponse/" +
        orderItemId +
        "/" +
        false,
      params,
      { responseType: "json" }
    );
  }
  orderwithrecalculatedtransportation(orderID) {
    const userSettings = this.globalService.getUserSettings();
    const params = [];
    return this.http.put(
      environment.ENVIRONMENT.baseURL +
        userSettings.LanguageCode +
        "/orders/authorizeorderwithrecalculatedtransportation/" +
        orderID,
      params,
      { responseType: "json" }
    );
  }

  /**
   * Service to Get A product tags by selecting tags from filter dropdown section
   * @param productID
   */

  getProductSelectedTags(productID) {
    const webShopSettings = this.globalService.webShopSettings();
    const userSettings = this.globalService.getUserSettings();
    const loggedInData = sessionStorage.getItem("loggedInData")
      ? JSON.parse(sessionStorage.getItem("loggedInData"))
      : null;
    let userParam = null;
    if (loggedInData !== null) {
      userParam = "&userID=" + loggedInData.UserID;
    } else {
      userParam = "&userID=";
    }
    const lang = userSettings.LanguageCode;
    return this.http.post(
      environment.ENVIRONMENT.baseURL +
        lang +
        "/webshopproducttagsgroups" +
        "?customerID=" +
        webShopSettings.CustomerID +
        userParam,
      productID,
      { responseType: "json" }
    );
  }
}
