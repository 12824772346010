import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '../validation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'shop-control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss'],
  exportAs: 'control'
})
export class ControlMessagesComponent {
  @Input() control: FormControl;
  public resultVal: any;
  constructor(private translate: TranslateService) { }
  get errorMessage() {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        // this.resultVal = JSON.stringify(this.translate.get('ERRORS.' +
        //   ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName])));
        // this.resultVal = JSON.parse(this.resultVal);

        this.translate.get('ERRORS.' +
          ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName])).subscribe((res: string) => {
            this.resultVal = JSON.stringify(res);
            this.resultVal = JSON.parse(this.resultVal);

          });
        return this.resultVal;

        // return this.resultVal.value;
      }
    }
    return null;
  }
}
