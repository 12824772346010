import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {WebshopService, VoucherService } from "../../../linknet-common/restful-services/restful.service";
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { ToastrService } from "ngx-toastr";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {NgbAccordion, NgbAccordionConfig} from '@ng-bootstrap/ng-bootstrap';
import { fromEvent, Observable, Subscription } from "rxjs";
import { Tab, initMDB } from "mdb-ui-kit";
import { OwlOptions } from 'ngx-owl-carousel-o';
initMDB({ Tab });

@Component({
    selector: 'shop-app-create-vouhcer',
    templateUrl: './create-voucher.component.html',
    styleUrls: ['./create-voucher.component.scss']
})

export class CreateVoucherComponent implements OnInit{
    resizeObservable$: Observable<Event>
    resizeSubscription$: Subscription;
    @ViewChild("tandcModal") public tandcModal;
    @ViewChild('acc') accordionComponent: NgbAccordion;
    productItem: any;
    activeId: string = 'panel-one';
    brandname: any;
    denomination: any;
    denominationError: string;
    summary: boolean = false;
    voucherAmount: any;
    vouherQuantity: any = 1;
    effectivePrice:any;
    finalPrice: any = 0;
    sendGift: boolean = false;
    logIndata: any;
    cartParam: any;
    shoppingCartItemID: any;
    cartItem: boolean = false;
    denominations: any;
    campaignId: any = 177;
    recieverName: string;
    recieverEmail: string;
    recieverMobile: string;
    comment: string;
    terms: boolean = false;
    loaderShow: boolean = false;
    public postParams = {};
    usag_icon: string;
    url: SafeResourceUrl;
    storeLocatorURL: SafeResourceUrl;
    denominationFlag: any;
    imageURL: any;
    giftImg: string = '../../../../assets/theme.jpg';
    imageUrl : string =  "https://dev-api.voucherkart.co.in/imgs/email_template/header/header_image-1701764031856.jpg"
    giftRecieverName = "Reciever";
    giftMessage: string = "You have received a Gift Vouhcer";
    public customOptions = { allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'] };
    themeList: Object;
    selectedTheme: any;
    selected: any = 0;
    filter: boolean = false;
    redirect: boolean = true;
    discountValue: any = 0;
    voucherFinalPrice: number;
    activeTab: number = 1;
    finalRewardPoints: number;
    recieverEmailVaild: boolean;
    isMobile: boolean = false;
    customOwlOptions: OwlOptions = {
        loop: false,
        margin: 10,
        autoplay:false,
        dots: false,
        navSpeed: 700,  
        slideBy: 5,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        responsive: {
          0: {
            items: 2,
            slideBy: 2
          },
          480: {
            items: 5,
            slideBy: 5
          },
          940: {
            items: 5,
            slideBy: 5
          }
        },
        nav: true
    }
    cartParamPrice: any;
    customerID: any = '';
    userID: any = '';
    constructor(config: NgbAccordionConfig, private sanitizer: DomSanitizer, private toastr: ToastrService,private router: Router, public globalService: GlobalService, private _httpWebshop: WebshopService,  private httpVoucher: VoucherService){
        if (window.innerWidth > 768) {
            this.isMobile = false;
          }else{
            this.isMobile = true;
            config.closeOthers = true;
          } 
        const webshopData = JSON.parse(sessionStorage.getItem('webshopDetail'));
        if (webshopData && webshopData.DomainName === "voucherkart") {

        }else{
            this.router.navigate(['/'])
        }
        if(history.state.item){
            this.productItem = history.state.item;
            if(this.productItem.usage_mode){
                this.productItem.ProductID = this.productItem.MappingId;
                if(this.productItem.usage_mode === 'Online'){
                    this.usag_icon = '../../../../assets/online.svg';
                }else if(this.productItem.usage_mode === 'Offline'){
                    this.usag_icon = '../../../../assets/offline.svg';
                }else{
                    this.usag_icon = '../../../../assets/omni_channel.svg';
                }
                if(this.productItem.Store_Locator){
                    this.storeLocatorURL = this.productItem.Store_Locator.trim();  
                }else{
                    this.storeLocatorURL = 0;
                }
                if(this.productItem.TandC){
                    this.url = '';
                }
                else if(this.productItem.tnc){
                    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.productItem.tnc);  
                }
                else{
                    this.url = 0;
                }
                  
            }
            this.denominationFlag = this.productItem.DenominationFlag;
           
        }else{
           this.router.navigate(['/vouchers']);
        }

        if(this.productItem){
            this.brandname = this.productItem.MappingId;
        }

        this.denominations = [
            {
                value: 250,
                label: '250'
            },{
                value: 500,
                label: '500'
            },{
                value: 1000,
                label: '1,000'
            },{
                value: 2500,
                label: '2,500'
            },{
                value: 5000,
                label: '5,000'
            },{
                value: 10000,
                label: '10,000'
            }
        ]

        this.denomination = Number(this.productItem.price);
        this.finalRewardPoints = Number(this.productItem.RewardPoints) 
        /*if(this.productItem && this.denominationFlag !== 'Y'){
            this.denomination = Number(this.productItem.price)
        }*/
        this.validate();
        this.getThemes();
    }

    ngOnInit() {
        this.resizeObservable$ = fromEvent(window, 'resize')
        this.resizeSubscription$ = this.resizeObservable$.subscribe( _evt => {
            if (window.innerWidth > 768) {
              this.isMobile = false;
            }else{
              this.isMobile = true;
            }
        })
        this.logIndata = this.globalService.getLoggedInData();
        const webshopSettings = this.globalService.webShopSettings();
        this.customerID = webshopSettings.CustomerID;
        if(this.logIndata){
            this.userID = this.logIndata.UserID;
        }
        this.denominations = this.denominations.filter(e=> Number(this.productItem.minPrice) <= e.value)
        this.denominations = this.denominations.filter(e=> e.value  <= Number(this.productItem.maxPrice))
    }

    getThemes(){
        this.httpVoucher.getGiftingTheme().subscribe((res:any)=>{
            let data = JSON.parse(res);
            this.themeList = (data.result);
            this.selectedTheme = this.themeList[0]; 
            this.selected = this.themeList[0].id;
            this.imgSelect(this.selectedTheme.header_image);            
        })
    }

    themeSelect(i){
        this.selectedTheme = this.themeList[i];
        this.selected = this.selectedTheme.id;
        this.imgSelect(this.selectedTheme.header_image);
    }

    commentInput(_e){
        this.giftMessage = this.comment;
    }

    selectTab(tabNumber: number) {
        this.activeTab = tabNumber;}

    nameInput(_e){
        this.giftRecieverName = this.recieverName;
    }

    validate(){
        /*if(this.denomination){
            let denomination = Number(this.denomination);
            let minPrice = Number(this.item.minPrice);
            let maxPrice = Number(this.item.maxPrice);
            if(denomination < minPrice){
                this.denominationError = 'Denomintation is lesser than minimum price';
            }
            else if(denomination > maxPrice){
                this.denominationError = 'Denomintation exceeds maximum price';
            }
            else if(denomination % 10 !== 0){
                this.denominationError = 'Denomintation must be mulitples of 10';
            }
            else{
                this.summary = true;
                this.voucherAmount = this.denomination;
                this.finalPrice = this.voucherAmount;
                this.points();
            }
        }*/
        this.summary = true;
        this.voucherAmount = this.denomination;
        this.finalPrice = this.voucherAmount;
        this.effectivePrice = Number(this.voucherAmount) * Number(this.vouherQuantity);
        if(this.productItem.ProductDiscountPercentage){
            this.discountValue = (this.effectivePrice  * this.productItem.ProductDiscountPercentage)/100;
        }
        this.voucherFinalPrice = this.effectivePrice - this.discountValue;
        this.points();
    }

    onlyNumberKey(event) {
        this.denominationError = '';
        const pattern = new RegExp("^[0-9\b]+$");
        const key = String.fromCharCode(
          !event.charCode ? event.which : event.charCode
        );
        if (!pattern.test(key) && event.charCode !== 0) {
          event.preventDefault();
          return false;
        }
    }

    points(){
        this.effectivePrice = Number(this.voucherAmount) * Number(this.vouherQuantity);
        
        if(this.productItem.ProductDiscountPercentage){
            this.discountValue = (this.effectivePrice  * this.productItem.ProductDiscountPercentage)/100;
        }
        this.finalRewardPoints = Number(this.productItem.RewardPoints ? this.productItem.RewardPoints : this.productItem.reward_points) * Number(this.vouherQuantity);
        this.finalPrice = this.effectivePrice - this.discountValue;
    }

    inrFormat(val: number) {
        return Number(val).toLocaleString("en-IN");
    }

    addCart(){
        let date = new Date()
        if(!this.shoppingCartItemID){
            this.cartParam = {
                "UserID": this.userID,
                "CustomerID": this.customerID,
                "CurrencyID": '20',
                "InitialDeliveryCountry": "IN",
                "CampaignId": this.campaignId,
                "VoucherMRP":  Number(this.voucherFinalPrice),
                "Item": {
                    "UserID": this.userID,
                    "ProductID": this.productItem.ProductID,
                    "DeliveryDate": date,
                    "OrderedQuantity": this.vouherQuantity,
                    "isExpressOrder": false,
                    "isSampleWithoutLogo": false,
                    "isSampleWithLogo": false,
                    "isProceedWithoutLogo": false,
                    "isEmailLogoLater": false,
                    "isChecked": true,
                    "isInitialStepCompleted": true,
                    "isColorStepCompleted": false,
                    "isSizesStepCompleted": false,
                    "isAdditionalCostsStepCompleted": false,
                    "isProductPropertiesStepCompleted": false,
                    "isLogosStepCompleted": false,
                    "VoucherMRP":  Number(this.voucherFinalPrice),
                    "isVisible": true,
                    "ReceiverMobile": Number(this.recieverMobile),
                    "ReceiverName": this.recieverName,
                    "ReceiverEmail": this.recieverEmail,
                    "CustomMessage": this.comment,
                    "CustomImageName": this.giftImg,
                    "TemplateID": this.sendGift ? this.selected: ''
                }
            }
        }else{
            this.cartParam.Item.OrderedQuantity = this.vouherQuantity;
            this.cartParam.Item.VoucherMRP = Number(this.voucherFinalPrice);
            this.cartParam.Item.ShoppingCartItemID = this.shoppingCartItemID;
            this.cartParam.Item.Price = this.cartParamPrice;
            this.cartParam.Item.Price.ProductPrice = Number(this.discountValue);
            this.cartParam.Item.Price.OrderedQuantity = this.vouherQuantity;
            this.cartParam.GrandTotal = Number(this.finalPrice);
            this.cartParam.TotalWithoutVAT = Number(this.finalPrice);
            this.cartParam.Item.ReceiverMobile = Number(this.recieverMobile),
            this.cartParam.Item.ReceiverName = this.recieverName,
            this.cartParam.Item.ReceiverEmail = this.recieverEmail,
            this.cartParam.Item.CustomMessage = this.comment,
            this.cartParam.Item.CustomImageName = this.giftImg,
            this.cartParam.Item.TemplateID =  this.sendGift ? this.selected: ''
            delete this.cartParam.Items;
        }
        if(this.logIndata){
            
        this.loaderShow = true;
        this._httpWebshop.addShoppingCart(this.cartParam).subscribe((data:any)=>{
            this.loaderShow = false;
            if(data){
                this.cartItem = true;
                this.cartParamPrice = data.Items[0].Price
                //this.shoppingCartItemID = data.Item.ShoppingCartItemID;
                if(!this.shoppingCartItemID){
                    const shoppingCartCount = Number(sessionStorage.getItem("shoppingCartCount")) + 1;
                    sessionStorage.setItem("shoppingCartCount", shoppingCartCount.toString());
                    this._httpWebshop.setCount(sessionStorage.getItem("shoppingCartCount"));
                    this.toastr.success('Voucher added to cart!');
                }else{
                    this.toastr.success('Voucher updated!');
                }
                this.getShoppingCart();
                //this.router.navigate(['/checkout'])
            }
        }, _error=>{
            this.loaderShow = false;
        })
        }else{
            sessionStorage.setItem('vocuherCartsItems', JSON.stringify(this.cartParam))
        }
    }

    getShoppingCart(){
        let param = {
            "UserID": this.userID,
            "CustomerID": this.customerID,
            "CurrencyID":20,
            "InitialDeliveryCountry":"IN",
            "ReturnOnlyVisibleItems":true,
            "IsVoucher":true
        }
        this.loaderShow = true;
        this._httpWebshop.getShoppingCart(param).subscribe((data:any)=>{
            this.loaderShow = false;
            this.cartParam = data;
            let l = data.Items.length;
            this.cartParam.Item = data.Items[l-1];
            
            this.shoppingCartItemID = this.cartParam.Item.ShoppingCartItemID;
            if(this.shoppingCartItemID && this.sendGift){
                let sendParams = {
                    "ReceiverName": this.recieverName,
                    "ReceiverEmail": this.recieverEmail,
                    "Message": this.comment,
                    "mobile": Number(this.recieverMobile),
                    "ImageURL": this.imageURL,
                    "UserID": this.userID
                }
                this.httpVoucher.insertGiftingDetails(sendParams).subscribe(_data=>{

                });
            }
        }, _error=>{
            this.loaderShow = false;
        });
    }

    imgSelect(img){
        this.giftImg = img;
    }

    setUploadedImageData(ev) {
        try {
          this.imageURL = ev[2];
          this.giftImg = ev[2];
        } catch (error) {
          this.toastr.error(error);
        }
      }

    closePage() {
        let perpage = JSON.parse(sessionStorage.getItem('prePages'));
        if (perpage) {
          let url = perpage[perpage.length - 1];
          let url_a = url.url.split('?');
          if (url_a[0] === '/search') {
            if (url_a[1]) {
              let k = url_a[1].split('&');
              for (let i = 0; i < k.length; i++) {
                let v = k[i].split('=');
                v[1] = v[1].replace(/%20/g, ' ');
                this.postParams[v[0]] = v[1]
              }
            }
            this.router.navigate(['' + url_a[0] + ''], { queryParams: this.postParams, replaceUrl: false });
          } else {
            this.router.navigate(['' + url_a[0] + '']);
          }
        } else {
          this.router.navigate(['/']);
        }
    }

    emailCheck(){
        if (this.recieverEmail && this.recieverEmail.match(/[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/)) {
            this.recieverEmailVaild = true;
        } else {
            this.recieverEmailVaild = false;
        }
    }

    emailVal(){
        if(!this.recieverEmailVaild){
            this.toastr.error('Please input valid email id')
        }
    }

    onPrint(){        
        let printContents:any = '';
        let popupWin;
        // console.log(this.imageUrl)
        // printContents = `<div><img src="${this.imageUrl}" width="80" height="80"></div>`;
       
        if(this.productItem.usage_mode !== 'Online'){
        printContents  = "<h3>How To Use</h3><p>Locate the nearest outlet of the brand by using the Google Search or visit the brand website link under Store Locator.</p>"+
        "<p>Visit the retail outlet of the brand and choose the products you want to purchase or the services you wish to avail.</p>"+
        "<ul><li>At the time of billing, share the voucher code of the EGV with the cashier.</li><li>You may be requested to validate it with a PIN or OTP or both as mandated by the brand.</li>"+
        "<li>In case the face value of the EGV is less than your billed amount, you need to pay the balance through any other mode of payment accpeted by the outlet.</li><li>You will not receive any refund for the unused value of the EGV.</li></ul>";
        }
        if(this.productItem.usage_mode !== 'Offline'){
        printContents  = "<h3>How To Use</h3><p>Visit the ecommerce site of the brand or choose to download the Mobile App of the brand on your phone to view their products and services.</p>"+
        "<ol><li>In case the brand allows  you to add your EGV to a Wallet linked to your account, please follow the Instructions to add the EDV code and validate it with a PIN or OTP as mandated by the brand.</li><li>Continue to search for products or services you wish to purchase and Add them to your Shopping Cart.</li></ol>"+
        "<ul><li>At the time of checkout, you can either apply the Voucher Code of the EGV or if you have already added it to your Wallet, choose the amount you wish to debit from the Available Balance.</li><li>You may be requested to validate it with a PIN or OTP or both as mandated by the brand.</li><li>In case the brand offers only the <span class='text-danger'>Apply Gift Voucher Option</span> at the time of checkout and the face value of the EGV is lesser than your bill value, you need to pay the balance through any other mode of payment accepted by the Payment Gateway.</li></ul>";
        }
        if(this.productItem.Store_Locator){
            printContents = `${printContents}<h3>Store Locator</h3><p>${this.productItem.Store_Locator}</p>`
        }

        printContents  = printContents + "<h3>Instructions</h3><p>"+this.productItem.Important_Instructions+"</p>";
        
        if(this.productItem.TandC){
            printContents =  printContents + '<h3>Terms and Conditions</h3>' + this.productItem.TandC;
        }
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title>Print tab</title>
            </head>
        <body onload="window.print();window.close()">${printContents}</body>
          </html>`
        );
        popupWin.document.close();
    }

    sendasgift(){
        if(!this.isMobile){
            if(this.sendGift){
                setTimeout(() =>{
                    document.getElementById('sendgiftcontainer').scrollIntoView({behavior: "smooth"});
                    setTimeout(() =>{
                        window.scrollBy(0, -8);
                    },350);
                },300);
            }
        }else{
            if(this.sendGift){
                setTimeout(() =>{
                    document.getElementById('sendgiftcontainer').scrollIntoView({behavior: "smooth"});
                    setTimeout(() =>{
                        window.scrollBy(0, -12);
                    },350);
                },300);
            }
        }
        
    }

    togglePanel(_event: Event, panelId: string) {
        // event.preventDefault();
        // console.log(event);
        // console.log(panelId,"panel id")
        // console.log(this.accordionComponent.isExpanded(panelId),"accordian component");
        const expanded:boolean = this.accordionComponent.isExpanded(panelId);
        setTimeout(() => {
            if(!expanded){
                const panelContent = document.getElementById(panelId) as HTMLDivElement;
                const panelContentPosition = panelContent.getBoundingClientRect();
        
                window.scrollTo({
                top: window.scrollY + panelContentPosition.top - 250,
                behavior: 'smooth'
                }); 
            }
        }, 500);
    }
}