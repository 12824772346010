import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../globals/global.service';
import { MessageService } from '../../restful-services/message-services/message.service';
import { ToastService } from '../../../typescripts/pro/alerts';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-compose',
  templateUrl: './compose.component.html',
  styleUrls: ['./compose.component.scss']
})
export class ComposeComponent implements OnInit {

  public htmlContent;
  public subject;
  public sendData;
  public translatedMessage;
  public isReply: any;
  public isReplyDescription: any;
  public isDraftPage = false;
  public numberOfUnreadEmails: any;
  public type = '';
  public emailID = '';
  public isDraftMessageStatus = false;

  constructor(private _httpMessagesService: MessageService, public globalService: GlobalService,
    private toastService: ToastService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.isReply = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.Subject : null;
    this.isDraftMessageStatus = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.isDraft : false;
    this.emailID = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.EmailID : '';
    this.isReplyDescription = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.Body : null;
    if (this.isReply !== undefined) {
      this.subject = this.isReply;
      this.htmlContent = this.isReplyDescription;
      this.isDraftPage = true;
    }
  }

  editor(event) {
    this.htmlContent = event.target.textContent;
  }
  sendMessage(draft) {
    {
      const loggedInData = this.globalService.getLoggedInData();
      const webshopSettings = this.globalService.webShopSettings();
      const userDetails = '<p>' + '<strong>Webshop Name: &nbsp; </strong>' + webshopSettings.Name + '</p>' +
        '<p>' + '<strong>Company Name: &nbsp; </strong>' + loggedInData.CompanyName + '</p>' +
        '<p>' + '<strong>User Name: &nbsp; </strong>' + loggedInData.Name + '</p>' +
        '<p>' + '<strong>Email: &nbsp; </strong>' + loggedInData.Email + '</p>' +
        '<p>' + '<strong>Phone Number: &nbsp; </strong>' + loggedInData.PhoneNumber + '</p>';
      this.sendData = {
        'ReceiverID': 0,
        'SenderType': 'g',
        'ReceiverType': 'webshop',
        'Subject': this.subject,
        'Body': userDetails + this.htmlContent,
        'Status': 'NEW',
        'isDraft': false,
        'EmailType': 'Email'
      };
      if (draft !== undefined) {
        this.sendData = {
          'ReceiverID': 0,
          'SenderType': 'g',
          'ReceiverType': 'webshop',
          'Subject': this.subject,
          'Body': userDetails + this.htmlContent,
          'Status': 'NEW',
          'isDraft': true,
          'EmailType': 'Email'
        };
      }
      console.log(this.isDraftMessageStatus);
      if (this.isDraftMessageStatus !== undefined && this.isDraftMessageStatus !== null) {
        if (this.isDraftMessageStatus) {
          this.sendData.EmailID = this.emailID;
          this.sendData.UserID = loggedInData.UserID;
          this._httpMessagesService.sendUpdateEmail(this.sendData).subscribe(
            _data => {
              if (draft !== undefined) {
                //  this.translatedMessage = (this.translate.get('MESSAGECENTER.SUCCESS_DRAFT'));
                this.translate.get('MESSAGECENTER.SUCCESS_DRAFT').subscribe((res: string) => {
                  this.translatedMessage = res;
                });
              } else {
                // this.translatedMessage = (this.translate.get('MESSAGECENTER.SUCCESS_SENT'));
                this.translate.get('MESSAGECENTER.SUCCESS_SENT').subscribe((res: string) => {
                  this.translatedMessage = res;
                });
              }
              this.toastr.success(this.translatedMessage);
              this.router.navigate(['/messages/list/inbox'], { queryParams: null, replaceUrl: true });
            }, error => {
              // this.toastService.error(JSON.parse(error._body).Message);
              this.toastr.error(error.error.Message);
            }, () => { }
          );
        } else{
          this.sendPostData(draft);
        }
      } else {
        this.sendPostData(draft);
      }
    }
  }

  sendPostData(draft){
    this._httpMessagesService.sendEmail(this.sendData).subscribe(
      _data => {
        if (draft !== undefined) {
          //  this.translatedMessage = (this.translate.get('MESSAGECENTER.SUCCESS_DRAFT'));
          this.translate.get('MESSAGECENTER.SUCCESS_DRAFT').subscribe((res: string) => {
            this.translatedMessage = res;
          });
        } else {
          // this.translatedMessage = (this.translate.get('MESSAGECENTER.SUCCESS_SENT'));
          this.translate.get('MESSAGECENTER.SUCCESS_SENT').subscribe((res: string) => {
            this.translatedMessage = res;
          });
        }
        this.toastr.success(this.translatedMessage);
        this.router.navigate(['/messages/list/inbox'], { queryParams: null, replaceUrl: true });
      }, error => {
        // this.toastService.error(JSON.parse(error._body).Message);
        this.toastr.error(error.error.Message);
      }, () => { }
    );
  }
}
