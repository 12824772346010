import { Component } from "@angular/core";

@Component({
    selector: 'shop-app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss']
})

export class MaintenanceComponent {

}