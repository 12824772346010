import { Component, OnInit, Input } from '@angular/core';
import { GlobalService, CurrencySetterService } from '../../../linknet-common/globals/global.service';

@Component({
  selector: 'shop-app-product-size',
  templateUrl: './product-size.component.html',
  styleUrls: ['./product-size.component.scss']
})
export class ProductSizeComponent implements OnInit {

  public printSizes: any;
  public currency: any;
  public withHeader: Boolean = true;
  public batchSizeStatus: Boolean = false;
  public productSizeStockStatus: Boolean = false;
  @Input() productSizes: any;
  @Input() productDetail: any;
  @Input() batchColorStatus: Boolean;
  @Input() productColorFutureStockStatus: Boolean;
  @Input() productColorStockStatus: Boolean;
  @Input() productSizeFutureStockStatus: Boolean;
  constructor(public currencySetterService: CurrencySetterService, public globalService: GlobalService) {
  }

  ngOnInit() {
    if (this.productSizes.length > 0) {
      for (let i = 0; i < this.productSizes.length; i++) {
        if ((this.productSizes[i].ProductFutureStock &&
          this.productSizes[i].ProductFutureStock.length > 0) ||
          (this.productSizes[i].StockBalance)) {
          this.productSizeStockStatus = true;
        }
      }
    }
  }


}
