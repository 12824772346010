import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { ProductService, WebshopService } from 'app/linknet-common/restful-services/restful.service';
import { ProductListComponent } from 'app/webshop/product-list/product-list.component';
import { ShoppingCartComponent } from '../shopping-cart/shopping-cart.component';

@Component({
  selector: 'app-currency-menu',
  templateUrl: './currency-menu.component.html',
  styleUrls: ['./currency-menu.component.scss']
})
export class CurrencyMenuComponent implements OnInit {
  public showMenu: boolean = false;
  showFlagMenu: boolean = false;
  public isMobile: boolean;
  public logIndata: any;
  public isParentCustomer: boolean = false;
  loaderShow: boolean = false;
  currencies: any = [];
  countries: any = [];
  indianCurrency:any;
  showCurrency: string = "ALL";
  currencyID: number;
  flag:string = "ALL";
  countryyID:number;
  countryName: string = "ALL";
  @ViewChild('productList') productList : ProductListComponent;
  @ViewChild('cart') cart: ShoppingCartComponent;
  constructor(public globalService: GlobalService,public http: WebshopService, public service:ProductService, public router: Router) { 
    if (/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  ngOnInit() {
    this.logIndata = this.globalService.getLoggedInData();
    const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    if(webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName){
      this.isParentCustomer = true;
    }else{
      this.isParentCustomer = false;
    }
    let currencyId = JSON.parse(sessionStorage.getItem("countryID"));
    if(currencyId){
      this.currencyID = currencyId;
      this.service.setCurrency(this.currencyID);
    }
    let code = sessionStorage.getItem("CurrencyCode");
    if(code){
      this.showCurrency = code;
      this.service.setCurrencyCode(this.showCurrency);
    }
    let flags:string = sessionStorage.getItem("dropCountryFlag");
    if(flags){
      this.flag = flags;
      this.service.setflagImg(this.flag);
    }
    let cName:string = sessionStorage.getItem("dropCountryName");
    if(cName){
      this.countryName = cName;
      this.service.setcountryname(this.countryName);
    }
    sessionStorage.setItem("CurrencyCode", this.showCurrency);
    sessionStorage.setItem("dropCountryName",this.countryName)
    this.getAllCurrencies();
    this.getCountry();

  }

  getCountry() {
    this.loaderShow = true;
    this.http.getCountryyy().subscribe({
      next: (data:any) => {
        this.loaderShow = false;
        if(data){
          this.countries = data;
          this.countries.unshift(
            {
              "CountryID": "ALL",
              "CountryName": "ALL"
            }
          )
          
        }
      }, error : (e:any) => {
        this.loaderShow = false;
        console.log(e.error.Message);
      }
    })

  }


  getAllCurrencies() {
    this.loaderShow = true;
    this.http.getCurrency().subscribe({
      next: (data:any) => {
        this.loaderShow = false;
        if(data){
          this.currencies = data;
          this.currencies.unshift({
              "CurrencyID": "ALL",
              "CurrencyName": "ALL",
              "Code": "ALL",
          })
        }
      }, error : (e:any) => {
        this.loaderShow = false;
        console.log(e.error.Message);
      }
    })

  }

  showMenuEvent(event){
    this.showMenu = event;
    this.showFlagMenu = event;
    this.hideMenu();
  }

  hideMenu() {
    this.showMenu = false;
    this.showFlagMenu = false;
    if(this.isMobile){
      var otherelement = document.querySelectorAll(".header-two .navbar-collapse.show")[0] as HTMLElement;
      if(otherelement){
        var closeelement = document.querySelectorAll(".header-two .navbar-toggler")[0] as HTMLElement;
        closeelement.click();
      }
      var element = document.querySelectorAll(".navbar-toggler")[0] as HTMLElement;
      if(element){
        element.click();
      }
    }
  }

  showMenus() {
    this.showFlagMenu = false;
    this.showMenu = true;
  }
  
  showFlag() {
    this.showFlagMenu = true;
    this.showMenu = false;
  }

  removeSessionItem() {
    sessionStorage.removeItem('pageIndex');
    sessionStorage.removeItem('pageSize');
  }

  changeCurrency(currency){
    if(this.showMenu){
      this.showMenu = false;
    }
    // if(this.countryName !== "ALL") {
    //   let data = {
    //     "CountryID": "ALL",
    //     "CountryName": "ALL"
    //      }
    //   this.changeCountry(data);
    // }

    if(this.currencyID !== currency.CurrencyID){

      this.showCurrency = currency.Code;
      this.currencyID = currency.CurrencyID;
      this.service.setCurrencyCode(this.showCurrency);
      sessionStorage.setItem("CurrencyCode", this.showCurrency);
      this.service.setCurrency(this.currencyID);
      sessionStorage.setItem("countryID",JSON.stringify(this.currencyID));
      this.service.setNavigationPerformed(false);
        // const page = {
        //   pageIndex: 1,
        //   pageSize: 20
        // }
        // const params = JSON.parse(sessionStorage.getItem("productreq"));
        // this.productList.getProducts(page, params);
      // if(location.pathname !== "/"){
        setTimeout(() => {
          this.router.navigate(['/']);
        }, 150);
      // }
      let count = JSON.parse(sessionStorage.getItem("shoppingCartDatas"));
      if(count && count.length > 0){
          let id = count.map(data => {
            return data.ShoppingCartItemID;
        })
        this.cart.deleteShoppingCartItem(0,id);
      }
    }
  }

  changeCountry(country) {
    if(this.showFlagMenu){
      this.showFlagMenu = false;
    }
    if(this.showCurrency !== "ALL") {
      let data = {
        "CurrencyID": "ALL",
        "CurrencyName": "ALL",
        "Code": "ALL",
      }
    this.changeCurrency(data);
    }
    if(this.countryyID !== country.CountryID){
      this.flag = country.ImageUrl ? country.ImageUrl : "ALL";
      this.countryyID = country.CountryID;
      this.countryName = country.CountryName;
      this.service.setcountryname(this.countryName);
      sessionStorage.setItem("dropCountryName",this.countryName)
      this.service.setCountryID(this.countryyID);
      sessionStorage.setItem("dropCountryID",JSON.stringify(this.countryyID));
      this.service.setflagImg(this.flag);
      sessionStorage.setItem("dropCountryFlag", this.flag);
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 150);
      let count = JSON.parse(sessionStorage.getItem("shoppingCartDatas"));
      if(count && count.length > 0){
          let id = count.map(data => {
            return data.ShoppingCartItemID;
        })
        this.cart.deleteShoppingCartItem(0,id);
      }
    }
    
  } 

}
