import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from 'app/typescripts/pro/alerts';
import { CategoryService, ProductService, MessageService } from 'app/linknet-common/restful-services/restful.service';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";

@Component({
  selector: 'shop-app-advance-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  priceTo: any;
  priceFrom: any;
  public postParams = {};
  mainCategoryName: string;
  public mainCategoryId: any;
  @Input() productListPage: any;
  @Input() isTagInput: any;
  @Input() productDetail: any;
  @Input() oldestProductFirstStatus: any;
  @Output() subCategoryDisplayStatusChange = new EventEmitter();
  @ViewChild('divToScroll') divToScroll: ElementRef;
  @Input() categoryIDS:any = [];

  public range: any;
  public productSearch: any;
  public productListSearchParam: any;
  public selectedColorID: any;
  public sortBy: any;
  public productListParam: any;
  public optionsSelect: Array<any>;
  public categories;
  public categorySelect: Array<any>;
  public categoryID: any = '';
  public colorsList = [];
  public colorsListData: Array<any>;
  public advancedSearchSorting = [];
  public LogoTypes = [];
  public ShowPricesIncludingLogoType: any;
  public Quantity;
  public productSearchList: Array<any>;
  public isShowFilter: any;
  public isProductBlocks: any;
  public selectedCityId: any;
  public cities: any;
  public queryParams: any;
  public CID: any;
  public likedProductPage: Boolean = false;
  public likedFolderPage: Boolean = false;
  public tagList: Array<any>;
  public tagLists: Array<any>;
  public categoryId: any;
  public subCategories: any = '';
  public showSubcategory: Boolean = false;
  public subCategoryData: any;
  public categoryHighlight: any;
  public categoryOpen: any;
  public subCategoryHighlight: any;
  public clearButtonEnableStatus: Boolean = false;
  public productsCount: any;
  public min: any;
  public max: any;
  public event: any;
  // tslint:disable-next-line:variable-name
  public SupplierQualityLevel: any;
  public webShopSettings: any;
  public showAccordion: boolean = true;
  public isParentCustomer: Boolean = false;
  webshopData: any;
  /** new-code => to show All Products Sort By option on Filter Section */
  public webshopDetail: any;
  /** new-code => to show All Products Sort By option on Filter Section */

  /** new-code => to show price slider on Filter Section */
  public webshopShowPriceSlider: Boolean = false;
  /** new-code => to show price slider on Filter Section */

  /** new-code => to show Filter Color on Filter Section */
  public webshopShowFilterColors: Boolean = false;
  /** new-code => to show Filter Color on Filter Section */

  /** new-code => to show Sort By on Filter Section */
  public webshopShowSortByFilterSection: Boolean = false;
  ProductTagsGroups: any = [];
  selectedProductTags: any = [];
  productTagsDetail: any;
  isTagsFilterExists: boolean = false;
  isOpenIndex: any = -1;
  /** new-code => to show Sort By on Filter Section */
  public mainCategoryList: any;
  public allCategoryList: any;
  public loaderShow: Boolean = false;
  setIndex: any;
  totalProductListItems: any;
  productBlocks: any;
  productMaxValue: any;
  configProductBlockCount: any;
  productMinValue: any;
  productAddedData: any =[];
  productAddedDataDetail: any=[];
  productTotalArayData:  any=[];
  prosductMaxPrice: any = [];
  prosductMinPrice: any = [];
  produtfilterMax: any;
  produtfilterMin: any;
  priceFilteReset: boolean = false;
  getCategories : any;
  CategoryIDs: any = [];

  constructor(private _httpCategories: CategoryService, private router: Router,
    private toastService: ToastService, private formBuilder: FormBuilder,
    private translate: TranslateService, public globalService: GlobalService, public _httpProductService: ProductService,
    private route: ActivatedRoute, private toastr: ToastrService, private _httpMessagesService: MessageService, private commonService: CommonServicesService ) {
    this.optionsSelect = [];
    this.productSearchList = [];
    this.queryParams = this.router.routerState.snapshot.root.queryParams;
    this.CID = this.router.routerState.snapshot.url;
    this.CID = this.CID.split('/');
    // To Hide Product Page Filter in Folder Page and Liked Products Page
    this.likedProductPage = (this.router.routerState.snapshot.root.queryParams.likedProducts) ? true : false;
    this.likedFolderPage = (this.router.routerState.snapshot.root.queryParams.FolderID) ? true : false;

  }
  methodName(event) {
    this.SupplierQualityLevel = event;
    this.postParams['SupplierQualityLevel'] = event;
  }

  ngOnInit() {
    this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.webshopData = this.webshopDetail;
      if(this.webshopData.ParentCustomerDomainName === this.webshopData.DomainName){
        this.isParentCustomer = true;
      }else{
        this.isParentCustomer = false;
      }
    this._httpMessagesService.currentProductTagsData.subscribe(data => this.ProductTagsGroups = data)
    let i = 0;
    const filterDetail = JSON.parse(sessionStorage.getItem('advanceSearchParams'));
    /** new-code => Default Sort By Select Option Based on WSA */
    let defaultSortByOption = '0';
    // if (this.webshopDetail.DomainName === 'wsa') {
    if (Number(this.webshopDetail.CustomerID) === 45) {
      defaultSortByOption = '1';
    }
    if (this.oldestProductFirstStatus !== undefined && this.oldestProductFirstStatus !== null
      && this.oldestProductFirstStatus) {
      defaultSortByOption = '3';
    }

    if (Number(this.webshopDetail.CustomerID) === 15) {
      defaultSortByOption = '3';
    }
    /** new-code => Default Sort By Select Option Based on WSA */
    this.productListSearchParam = this.formBuilder.group({
      'SortBy': [this.queryParams.SortBy ? this.queryParams.SortBy : defaultSortByOption, []],
      'SortByColor': [this.queryParams.ColorID ? parseInt(this.queryParams.ColorID || 0, 10) : '', []],
      'SupplierQualityLevel': [this.queryParams.SupplierQualityLevel ? (this.queryParams.SupplierQualityLevel) : '0'],
      'Quantity': [this.queryParams.Quantity ? (this.queryParams.Quantity) : ''],
      'ShowPricesIncludingLogoType': [this.queryParams.ShowPricesIncludingLogoType ? (this.queryParams.ShowPricesIncludingLogoType) : null],
      'priceFrom': [''],
      'priceTo': ['']
    });
    this.TotalProductPriceCal();
    this.selectedColorID = this.queryParams.ColorID ? parseInt(this.queryParams.ColorID || 0, 10) : '';
    this.SupplierQualityLevel = this.queryParams.SupplierQualityLevel ? this.queryParams.SupplierQualityLevel : 0;
    this.Quantity = this.queryParams.Quantity ? this.queryParams.Quantity : '';
    this.ShowPricesIncludingLogoType = this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType : null;
    // Clear Button Disable Code
    if ((this.queryParams.SortBy === '' || this.queryParams.SortBy === undefined) &&
      (this.queryParams.ColorID === '' || this.queryParams.ColorID === undefined)) {
      this.clearButtonEnableStatus = true;
    }


    /** new-code => to show price slider on Filter Section */
    if (this.webshopDetail.ShowAdvancedSearchPrices) {
      this.webshopShowPriceSlider = true;
    }
    /** new-code => to show price slider on Filter Section */

    /** new-code => to show Filter Color on Filter Section */
    if (this.webshopDetail.ShowAdvancedSearchColors) {
      this.webshopShowFilterColors = true;
    }
    /** new-code => to show Filter Color on Filter Section */

    /** new-code => to show Sort By on Filter Section */
    if (this.webshopDetail.ShowSortByFilter) {
      this.webshopShowSortByFilterSection = true;
    }
    /** new-code => to show Sort By on Filter Section */

    /** new-code => condition to show Filter Section as usual except WSA Shop */
    if (this.webshopDetail.ShowAllProductsInSortByFilter) {
      this.translate.get('HEADING.ALL_PRODUCTS_AZ').subscribe(
        (res: string) => {
          this.advancedSearchSorting[0] = {
            'value': 0,
            'label': res
          };
          // tslint:disable-next-line:no-unused-expression
        });
    }

    if (!this.webshopDetail.ShowAllProductsInSortByFilter) {
      i = 0;
    } else {
      i = i + 1;
    }

    if(this.isParentCustomer){
      this.translate.get('HEADING.ALL_PRODUCTS_ZA').subscribe(
        (res: string) => {
          this.advancedSearchSorting[i] = {
            'value': 5,
            'label': res
          };
        });
      this.translate.get('HEADING.CHEAPEST_PRODUCT').subscribe((res: string) => {
        this.advancedSearchSorting[i + 1] = {
          'value': 2,
          'label': res
        };
      });
      
      /*this.translate.get('HEADING.NEWEST_PRODUCT').subscribe((res: string) => {
        this.advancedSearchSorting[i] = {
          'value': 1,
          'label': res
        };
      });
      this.translate.get('HEADING.OLDEST_PRODUCTS').subscribe((res: string) => {
        this.advancedSearchSorting[i + 2] = {
          'value': 3,
          'label': res
        };
      });*/
      this.translate.get('HEADING.EXPENSIVE_PRODUCT').subscribe((res: string) => {
        this.advancedSearchSorting[i + 2] = {
          'value': 4,
          'label': res
        };
      });
    }else{
      this.translate.get('HEADING.LOW_REWARDS').subscribe((res: string) => {
        this.advancedSearchSorting[i] = {
          'value': 2,
          'label': res
        };
      });
      this.translate.get('HEADING.HIGH_REWARDS').subscribe((res: string) => {
        this.advancedSearchSorting[i + 1] = {
          'value': 4,
          'label': res
        };
      });

     
    }

    

    // Branding options
    this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_1COLOR').subscribe((res: string) => {
      this.LogoTypes[0] = {
        'value': 1,
        'label': res
      };
    });
    this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_2COLOR').subscribe((res: string) => {
      this.LogoTypes[1] = {
        'value': 2,
        'label': res
      };
    });
    this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_3COLOR').subscribe((res: string) => {
      this.LogoTypes[2] = {
        'value': 3,
        'label': res
      };
    });
    this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_4COLOR').subscribe((res: string) => {
      this.LogoTypes[3] = {
        'value': 4,
        'label': res
      };
    });
    this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_EMBROIDERY').subscribe((res: string) => {
      this.LogoTypes[4] = {
        'value': 5,
        'label': res
      };
    });
    this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_ENGRAVING').subscribe((res: string) => {
      this.LogoTypes[5] = {
        'value': 6,
        'label': res
      };
    });
    this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_EMBOSSING').subscribe((res: string) => {
      this.LogoTypes[6] = {
        'value': 7,
        'label': res
      };
    });
    this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_DEBOSSING').subscribe((res: string) => {
      this.LogoTypes[7] = {
        'value': 8,
        'label': res
      };
    });
    this.translate.get('HEADER_ONE.SHOW_PRICES_INCLUDING_LOGO_PHOTOPRINT').subscribe((res: string) => {
      this.LogoTypes[8] = {
        'value': 9,
        'label': res
      };
    });

    this._httpCategories.getMainCatgeories().subscribe( (data: any[]) => {
      this.mainCategoryList = data;
      this.mainCategoryList.forEach(data => {
        data['toggle'] = false;
        if(this.categoryIDS.length !== 0){
          if(this.categoryIDS.includes(data['CategoryID'].toString())){
            data['toggle'] = true;
          }
        }
      })
    })

    try {
      this.webShopSettings = this.globalService.webShopSettings();
      this.tagList = [];
      this.advancedSearchSorting.forEach(elementForSort => {
        if (elementForSort.value === parseFloat(this.queryParams.SortBy)) {
          this.tagList.push(elementForSort);
        }
      });
      sessionStorage.setItem('tagList', JSON.stringify(this.tagList));
      if (this.productListPage === true) {
        /** new-code => Remove unwanted call in Liked product, Block Page, Order you can Join Page  */
        let apiCallStatus = true;
        if (this.queryParams.FolderID || this.queryParams.likedProducts || this.queryParams.joinlinkorders ||
          this.CID.indexOf('productblocksorders') >= 0) {
          apiCallStatus = true;
        }
        /** new-code => Remove unwanted call in Liked product, Block Page, Order you can Join Page  */

        if (apiCallStatus) {
          this.loaderShow = true;
          this._httpProductService.getProductColorsByID(JSON.parse(sessionStorage.getItem('colorListDropDownStatus'))).subscribe(
            (data: any[]) => {
              this.loaderShow = false;
              this.colorsList = data;
                this.produtfilterMax = Math.max.apply(null, this.prosductMaxPrice)
              this.produtfilterMin = Math.min.apply(null, this.prosductMinPrice)
              if (this.colorsList.length > 0) {
                this.priceFrom = this.produtfilterMin
                this.min = this.colorsList[0].SliderMinPrice;
                this.max = this.colorsList[0].SliderMaxPrice;
                this.priceTo = this.produtfilterMax;
              }
              if(!this.queryParams.PriceFrom && !this.queryParams.PriceTo && this.colorsList.length > 0){
                this.priceFrom = this.produtfilterMin
                this.min = this.colorsList[0].SliderMinPrice;
                this.max = this.colorsList[0].SliderMaxPrice;
                this.priceTo = this.produtfilterMax;
              }
              if (this.queryParams.PriceFrom > 0) {
                this.priceFrom = this.queryParams.PriceFrom;
                this.min = this.queryParams.PriceFrom;
              }
              if (this.queryParams.PriceTo > 0) {
                this.priceTo = this.queryParams.PriceTo;
                this.max = this.queryParams.PriceTo;
              }
              // if (this.queryParams.PriceFrom === undefined && this.queryParams.PriceTo === undefined) {
              //   sessionStorage.setItem('priceFrom', this.priceFrom);
              //   sessionStorage.setItem('priceTo', this.priceTo);
              // }
              const productTagDetails = JSON.parse(sessionStorage.getItem('productTags'));
              let productTagIds = [];
              if (productTagDetails != undefined && productTagDetails != null && productTagDetails.length > 0) {
                productTagDetails.forEach((item) => {
                  if (item.value != null && item.value.length > 0) {
                    item.value.forEach((data) => {
                      productTagIds.push(parseInt(data));
                    })
                  }
                });
              }
              if (productTagIds.length > 0) {
                this._httpProductService.updateCategoriesSelectBar(productTagIds).subscribe(
                  (data: any[]) => {
                    this.categorySelect = [];
                    this.categories = data;
                    this.categories.forEach(element => {
                      const countryVal = {
                        'value': element.CategoryID,
                        'label': element.Name,
                      };
                      this.categorySelect.push(countryVal);
                    });
                    sessionStorage.setItem('categoryList', JSON.stringify(this.categories));
                    sessionStorage.setItem('categoryOptionList', JSON.stringify(this.categorySelect));
                    this.allCategoryList = data;
                    this.globalService.updateCategoryList.next(this.allCategoryList);
                  });
              } else {
                try {
                  //get categories after clearing tags
                  this._httpCategories.getCategories().subscribe(
                    (data: any[]) => {
                      this.categorySelect = [];
                      this.categories = data;
                      this.categories.forEach(element => {
                        const countryVal = {
                          'value': element.CategoryID,
                          'label': element.Name
                        };
                        this.categorySelect.push(countryVal);
                      });
                      sessionStorage.setItem('categoryList', JSON.stringify(this.categories));
                      this.allCategoryList = this.categories;
                      sessionStorage.setItem('categoryOptionList', JSON.stringify(this.categorySelect));
                      this.allCategoryList.forEach(data => {
                        data['toggle'] = false
                        if(this.categoryIDS.length !== 0){
                          if(this.categoryIDS.includes(data['CategoryID'].toString())){
                            data['toggle'] = true;
                          }
                        }
                      });
                      if (this.isOpenIndex > -1 && this.isOpenIndex != null) {
                        var target = document.getElementById("menu-" + this.isOpenIndex);
                        if (target) {
                          //  $('#scrollDiv').animate({ scrollTop: target.offsetTop });
                          var element = document.getElementById("menu-" + this.isOpenIndex);
                          element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                        }
                        // window.scrollTo(0, 0);
                        this.allCategoryList[this.isOpenIndex]['toggle'] = true;
                        this.CategoryIDs.push(this.allCategoryList[this.isOpenIndex]['CategoryID']);
                      }
                      this.globalService.updateCategoryList.next(this.allCategoryList);
                    },
                    _error => {
                      //this.toastr.error('Error: ' + error.Message);
                    });
                } catch (error) {
                  this.toastr.error(error);
                }
              }
            },
            _error => {
              // this.toastService.error('Error: ' + error._body);
              // this.toastr.error('Error: ' + error.error.Message);
              this.loaderShow = false;

            }
          );
        }
      } else {
        if (JSON.parse(sessionStorage.getItem('colorsList')) === undefined ||
          JSON.parse(sessionStorage.getItem('colorsList')) === null) {
          this._httpProductService.getProductColors(true).subscribe(
            (data: any[]) => {
              this.colorsList = data;
              this.priceFrom = this.colorsList[0].SliderMinPrice;
              this.priceTo = this.colorsList[0].SliderMaxPrice;
              this.min = this.colorsList[0].SliderMinPrice;
              this.max = this.colorsList[0].SliderMaxPrice;
              if (this.queryParams.PriceFrom > 0) {
                this.priceFrom = this.queryParams.PriceFrom;
                this.min = this.queryParams.PriceFrom;
              }
              if (this.queryParams.PriceTo > 0) {
                this.priceTo = this.queryParams.PriceTo;
                this.max = this.queryParams.PriceTo;
              }
              sessionStorage.setItem('colorsList', JSON.stringify(this.colorsList));
            },
            _error => {
              // this.toastService.error('Error: ' + error._body);
              //console.log(error)
              // this.toastr.error('Error: ' + error.error.Message);
            }
          );
        } else {
          this.colorsList = JSON.parse(sessionStorage.getItem('colorsList'));
        }
      }

      // new-code => code for display main category with sub category in home page based on Settings from Customer API
      if (sessionStorage.getItem('categoryList') !== undefined &&
        sessionStorage.getItem('categoryList') !== null) {
        if (this.webshopDetail.ShowCategoriesOnHomePage && (this.CID[1] === ',' || this.CID[1] === '' || this.CID[1] === '/')) {
          this.allCategoryList = JSON.parse(sessionStorage.getItem('categoryList'));
          this.allCategoryList.forEach(data => data['toggle'] = false);
          this.globalService.updateCategoryList.next(this.allCategoryList);
        }
      } else {
        try {
          this._httpCategories.getCategories().subscribe(
            (data: any[]) => {
              this.categorySelect = [];
              this.categories = data;
              this.categories.forEach(element => {
                const countryVal = {
                  'value': element.CategoryID,
                  'label': element.Name,
                };
                this.categorySelect.push(countryVal);
              });
              sessionStorage.setItem('categoryList', JSON.stringify(this.categories));
              sessionStorage.setItem('categoryOptionList', JSON.stringify(this.categorySelect));
              if (this.webshopDetail.ShowCategoriesOnHomePage && (this.CID === ',' || this.CID === '' || this.CID === '/')) {
                this.allCategoryList = JSON.parse(sessionStorage.getItem('categoryList'));
                this.allCategoryList.forEach(data => data['toggle'] = false);
                this.globalService.updateCategoryList.next(this.allCategoryList);
              }
            },
            _error => {
              //this.toastr.error('Error: ' + error.Message);
            });
        } catch (error) {
          this.toastr.error(error);
        }
      }
      // new-code => code for display main category with sub category in home page based on Settings from Customer API


      // code for display Subcategory based on select category from Menu
      this.CID = this.router.routerState.snapshot.url;
      if (this.CID.indexOf('/search/') !== -1 || this.CID.indexOf('/search?') !== -1) {
        this.allCategoryList = JSON.parse(sessionStorage.getItem('categoryList'));
        this.globalService.updateCategoryList.next(this.allCategoryList);
        //console.log("inside filter Component : ", this.route.snapshot.queryParams);
        const category = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.CategoryID : null;
        this.categoryId = category;
        this.categoryOpen = this.categoryId;
        if (this.CID.indexOf('/search/') !== -1) {
          this.CID = this.CID.split('/');
          this.categoryId = this.CID[2];
          this.subCategoryHighlight = this.categoryId;
          const subCategoryData = this.allCategoryList.filter(data1 => (Number(data1.CategoryID) === Number(this.subCategoryHighlight)));
          if(subCategoryData[0] === undefined) {
            this.subCategoryHighlight = sessionStorage.getItem('selectedCatId')
          }
        } else {
          const category = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.CategoryID : null;
          this.categoryId = category;
          this.subCategoryHighlight = this.categoryId;
          const subCategoryId = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.SubCategory : null;
          
          const subCategoryData = this.allCategoryList.filter(data1 => (Number(data1.CategoryID) === Number(this.subCategoryHighlight)));
          if(subCategoryData[0] === undefined) {
            this.subCategoryHighlight = sessionStorage.getItem('selectedCatId');
          }
          if (subCategoryId !== null && subCategoryId !== undefined) {
            //this.subCategoryHighlight = subCategoryId;
          }
        }
        if (this.subCategoryHighlight == undefined || this.subCategoryHighlight == null) {
          sessionStorage.removeItem('isOpenIndex');
        }
        let len = this.allCategoryList ? this.allCategoryList.length : 0;
        if (sessionStorage.getItem('selectedCatId')) {
          for (let i = 0; i < len; i++) {
            if (Number(this.allCategoryList[i].CategoryID) === Number(sessionStorage.getItem('selectedCatId'))) {
              sessionStorage.setItem('isOpenIndex', i.toString());
            }
            this.isOpenIndex = JSON.parse(sessionStorage.getItem('isOpenIndex'));
            if (Number(i) === Number(this.isOpenIndex)) {
              this.allCategoryList[i]['toggle'] = true;
            } else {
              this.allCategoryList[i]['toggle'] = false;
            }
          }
        }
      }
      if (this.categoryId !== undefined && this.categoryId !== '') {
        const categoryList = JSON.parse(sessionStorage.getItem('categoryList'));
        // To get category List and Display in Filter Section in Product List Page
        if (categoryList === null) {
          this._httpCategories.getCategories().subscribe(
            (data: any[]) => {
              this.categorySelect = [];
              this.categories = data;
              this.categories.forEach(element => {
                const countryVal = {
                  'value': element.CategoryID,
                  'label': element.Name,
                };
                this.categorySelect.push(countryVal);
              });
              sessionStorage.setItem('categoryList', JSON.stringify(this.categories));
              sessionStorage.setItem('categoryOptionList', JSON.stringify(this.categorySelect));
            },
            _error => {
              //this.toastr.error('Error: ' + error.Message);
            });
        }
        if (categoryList !== null) {
          this.subCategoryData = categoryList.filter(
            data1 => (Number(data1.CategoryID) === Number(this.categoryId))
          );
          if (this.subCategoryData.length !== 0) {
            this.productsCount = this.subCategoryData[0].ProductsCount
          }
          // if category is Main Category
          if (this.subCategoryData[0] !== undefined) {
            if (this.CID.indexOf('/search/') !== -1) {
              this.mainCategoryName = decodeURIComponent(this.CID[3]);
              this.mainCategoryId = decodeURIComponent(this.CID[2]);
            } else {
              this.mainCategoryName = this.subCategoryData[0]['Name'];
              this.mainCategoryId = this.subCategoryData[0]['CategoryID'];
            }
            this.subCategories = this.subCategoryData[0].SubCategories;
          }
          // if category is Sub Category
          // tslint:disable-next-line:one-line
          else {
            categoryList.forEach(element => {
              if (element.SubCategories.length > 0) {
                element.SubCategories.forEach(subcat => {
                  if (Number(subcat.CategoryID) === Number(this.categoryId)) {
                    this.subCategories = element.SubCategories;
                    this.productsCount = element.ProductsCount;
                  }
                });
              }
            });
            if (this.subCategories.length > 0) {
              const parentCategoryId = this.subCategories[0].ParentID;
              const parentCategoryList = JSON.parse(sessionStorage.getItem('categoryOptionList'));
              const parentCategoryName = parentCategoryList.filter(
                datas => (Number(datas.value) === Number(parentCategoryId)));
              this.mainCategoryName = parentCategoryName[0].label;
              this.mainCategoryId = parentCategoryName[0].value;
            }
          }
        }

        if (this.subCategories.length > 0) {
          this.subCategoryDisplayStatusChange.emit(true);
        } else {
          this.subCategoryDisplayStatusChange.emit(false);
        }

      } else {
        this.subCategories = '';
        this.subCategoryDisplayStatusChange.emit(false);
        this.mainCategoryName = '';
        this.mainCategoryId = '';
      }
    } catch (error) {
      // this.toastr.error(error);
      console.log(error,"error")
    }
    this.postParams = {
      'CategoryID': this.categoryId ? this.categoryId : '',
      'ShowPricesIncludingLogoType': this.ShowPricesIncludingLogoType ? this.ShowPricesIncludingLogoType : this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType : null,
      'Quantity': this.Quantity ? this.Quantity : this.queryParams.Quantity ? this.queryParams.Quantity : '',
      'PriceFrom': this.priceFrom ? this.priceFrom : this.queryParams.PriceFrom,
      'PriceTo': this.priceTo ? this.priceTo : this.queryParams.PriceTo,
      'ColorID': this.selectedColorID ? this.selectedColorID : '',
      'ProductName': this.queryParams.ProductName ? this.queryParams.ProductName : '',
      'DeliveryDate': this.queryParams.DeliveryDate ? this.queryParams.DeliveryDate : '',
      'SortBy': this.sortBy ? this.sortBy : this.queryParams.SortBy,
      'SupplierQualityLevel': this.SupplierQualityLevel ? this.SupplierQualityLevel : '',
      'isTagsFilterExists': this.isTagsFilterExists
    };
    this.SupplierQualityLevel = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.SupplierQualityLevel : '';

    const sessionProductTags = JSON.parse(sessionStorage.getItem('productTags'));
    //  this.ProductTagsGroups = this.productTagsDetail;
    const isSaved = sessionStorage.getItem('isSaveTagsFilterSaved');
    const responseProductTags = [];
    if (this.webshopDetail.ProductTagsGroups.length > 0 && this.webshopDetail.ProductTagsGroups !== null && this.webshopDetail.ProductTagsGroups !== undefined) {
      this.webshopDetail.ProductTagsGroups.forEach((item) => {
        let products = {
          productTagsGroupID: null,
          productTagsGroupName: null,
          value: [],
          label: [],
          productTags: []
        };
        products = {
          productTagsGroupID: item.ProductTagsGroupID,
          productTagsGroupName: item.Name,
          value: [],
          label: [],
          productTags: []
        }
        if (item.ProductTags.length > 0 && item.ProductTags !== null && item.ProductTags !== undefined) {
          item.ProductTags.forEach((element) => {
            products.productTags.push({
              'value': element.ProductTagID,
              'label': element.Name,
            })
          });
        }
        if (products.productTags.length > 0) {
          responseProductTags.push(products);
        }
      });
    }


    //  if (sessionProductTags != null && sessionProductTags != undefined && sessionProductTags.length > 0 && (this.queryParams['isTagsFilterExists'] === 'true' || isSaved == 'true')) {
    //     let productTagData = [];
    //     if (sessionProductTags !== undefined && sessionProductTags !== null && sessionProductTags.length > 0) {
    //       let productTag = sessionProductTags;
    //       for (let i = 0; responseProductTags.length > i; i++) {
    //         const checkData = productTag.filter(data => data.productTagsGroupID === responseProductTags[i].productTagsGroupID);
    //         if (checkData.length > 0) {
    //           if (checkData[0].value !== null) {
    //             checkData[0].value.forEach(function (item) {
    //               const isFindValue = responseProductTags[i].productTags.find(data => data.value == item)
    //               if (isFindValue) {
    //                 responseProductTags[i].value.push(item);
    //                 const lableVal = checkData[0].productTags.find(list => list.value == item)
    //                 responseProductTags[i].label.push(lableVal.label);
    //               }
    //             })
    //           }
    //           productTagData.push(responseProductTags[i]);
    //         } else {
    //           productTagData.push(responseProductTags[i]);
    //         }
    //       }
    //     }
    //     sessionStorage.setItem('productTags', JSON.stringify(productTagData));
    //     this.productTagsDetail = JSON.parse(sessionStorage.getItem('productTags'));
    //     this.ProductTagsGroups = this.productTagsDetail;
    //   } else {
    //     this.ProductTagsGroups = [];
    //     this.ProductTagsGroups = responseProductTags;
    //   }

    if (sessionProductTags != null && sessionProductTags != undefined && sessionProductTags.length > 0) {
      this.productTagsDetail = JSON.parse(sessionStorage.getItem('productTags'));
      this.ProductTagsGroups = this.productTagsDetail;
    } else {
      this.ProductTagsGroups = [];
      this.ProductTagsGroups = responseProductTags;

    }
    if(this.categoryIDS.length !== 0){
      this.categoryIDS.forEach(e=> {
        if(e){
          this.CategoryIDs.push(e)
        }
      });
    }
  }

  selectedSortBy() {
    try {
      let pfrom = parseInt(this.productListSearchParam.value.priceFrom);
      let pto = parseInt(this.productListSearchParam.value.priceTo)
      if(pto && pto <= pfrom){
        this.toastr.error("Minimum price can't be lesser than maximum price")
        return false;
      }
      sessionStorage.removeItem('pageIndex');
      sessionStorage.removeItem('pageSize');
      
      this.CID = this.router.routerState.snapshot.url;
      // if(!this.sortBy){
        this.sortBy = this.commonService.getsort();
      // }
      const subCategoryId = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.SubCategory : null;
      if (subCategoryId !== null && subCategoryId !== undefined) {
        this.postParams = {
          'CategoryID': this.queryParams.CategoryID ? this.queryParams.CategoryID : '',
          'ShowPricesIncludingLogoType': this.ShowPricesIncludingLogoType ? this.ShowPricesIncludingLogoType : this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType : null,
          'Quantity': this.Quantity ? this.Quantity : this.queryParams.Quantity ? this.queryParams.Quantity : '',
          'PriceFrom': this.productListSearchParam.value.priceFrom,
          'PriceTo': this.productListSearchParam.value.priceTo,
          'ColorID': this.selectedColorID ? this.selectedColorID : this.queryParams.ColorID,
          'ProductName': this.queryParams.ProductName ? this.queryParams.ProductName : '',
          'DeliveryDate': this.queryParams.DeliveryDate ? this.queryParams.DeliveryDate : '',
          'SortBy': this.sortBy ? this.sortBy : this.queryParams.SortBy,
          'SubCategory': subCategoryId,
          'SupplierQualityLevel': this.SupplierQualityLevel ? this.SupplierQualityLevel : null,
          'isTagsFilterExists': this.isTagsFilterExists
        }
      }
      if(this.CID.indexOf('/searchbrands/') !== -1){
        let a = this.CID.split('/');
        let id = a['2'];
        this.postParams['BrandId'] = id;
        this.postParams['CategoryID'] = null;
      }
      if(this.queryParams.BrandId){
        this.postParams['BrandId'] = this.queryParams.BrandId;
        this.postParams['CategoryID'] = null;
      }
      this.postParams['isTagsFilterExists'] = this.isTagsFilterExists;
      this.postParams['PriceFrom'] = this.productListSearchParam.value.priceFrom;
      this.postParams['PriceTo'] = this.productListSearchParam.value.priceTo;
      /*if (this.priceFilteReset) {
        this.postParams['PriceFrom'] = null;
        this.postParams['PriceTo'] = null;
      }*/
    //  if(this.postParams['SortBy'] === undefined || this.postParams['SortBy'] === null || this.postParams['SortBy'] === "") {
      this.postParams['SortBy'] = this.sortBy;
    //  }
      this.router.navigate(['/search'], { queryParams: this.postParams, replaceUrl: false });
    } catch (error) {
      this.toastr.error('Error: ' + error);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    /** new-code => on WSA Shop we need to display oldest product first for “BX 2020.H2” sub-category */
    if (changes.oldestProductFirstStatus !== undefined && changes.oldestProductFirstStatus !== null) {
      if (changes.oldestProductFirstStatus.currentValue) {
        this.productListSearchParam.patchValue({
          SortBy: '3'
        })
      }
    }
    /** new-code => on WSA Shop we need to display oldest product first for “BX 2020.H2” sub-category */
  }

  clearFilter() {
    let perpage = JSON.parse(sessionStorage.getItem('prePages'));
    this.commonService.setClearAll(true);
    if(perpage){
      let url  = perpage[perpage.length - 1];
      let url_a = url.url.split('?');
      if(url_a[1]){
        this.router.navigate(['/']);
      }
    }else{
      this.router.navigate(['/']);
    }
   /* try {
      const sessionData = JSON.parse(sessionStorage.getItem('advanceSearchParams'));
      this.clearButtonEnableStatus = true;
      this.selectedColorID = '';
      this.sortBy = '';
      this.postParams = {
        'CategoryID': this.queryParams.CategoryID ? this.queryParams.CategoryID : '',
        'ShowPricesIncludingLogoType': null,
        'Quantity': '',
        'PriceFrom': '',
        'PriceTo': '',
        'ColorID': this.selectedColorID ? this.selectedColorID : '',
        'ProductName': this.queryParams.ProductName ? this.queryParams.ProductName : '',
        'DeliveryDate': this.queryParams.DeliveryDate ? this.queryParams.DeliveryDate : '',
        'SortBy': this.sortBy ? this.sortBy : '',
        'isTagsFilterExists': false

      };

      const searchParams = {
        'CategoryID': sessionData ? sessionData.CategoryID : null,
        'ShowPricesIncludingLogoType': sessionData ? sessionData.ShowPricesIncludingLogoType : null,
        'Quantity': sessionData ? sessionData.Quantity : null,
        'PriceFrom': sessionData ? sessionData.PriceFrom : null,
        'PriceTo': sessionData ? sessionData.PriceTo : null,
        'ColorID': sessionData ? sessionData.ColorID : null,
        'ProductName': sessionData ? sessionData.ProductName : null,
        'DeliveryDate': sessionData ? sessionData.DeliveryDate : null,
      };
      sessionStorage.setItem('advanceSearchParams', JSON.stringify(searchParams));
      sessionStorage.removeItem('productTags');
      sessionStorage.removeItem('categoryList');
      try {
        //get categories after clear
        this._httpCategories.getCategories().subscribe(
          (data: any[]) => {
            this.categorySelect = [];
            this.categories = data;
            this.categories.forEach(element => {
              const countryVal = {
                'value': element.CategoryID,
                'label': element.Name,
              };
              this.categorySelect.push(countryVal);
            });
            sessionStorage.setItem('categoryList', JSON.stringify(this.categories));
            this.allCategoryList = this.categories;
            sessionStorage.setItem('categoryOptionList', JSON.stringify(this.categorySelect));
            this.globalService.updateCategoryList.next(this.allCategoryList);
            try {
              this.router.navigate(['/search'], { queryParams: this.postParams, replaceUrl: false });
            } catch (error) {
              this.toastr.error('Error: ' + error);
            }
          },
          error => {
            this.toastr.error('Error: ' + error.Message);
          });
      } catch (error) {
        this.toastr.error(error);
      }
      if (this.ProductTagsGroups.length > 0 && this.ProductTagsGroups !== undefined && this.ProductTagsGroups !== null) {
        this.ProductTagsGroups.forEach(item => {
          item.value = [];
        })
      }

    } catch (error) {
      this.toastr.error(error);
    }*/
  }

  // setArrowFun(category, index) {
  //   this.allCategoryList.forEach(data => data.toggle = false);
  //   category.toggle = !category.toggle;
  //   if (this.setIndex && this.setIndex != index) {
  //     this.allCategoryList[this.setIndex].toggle = false;
  //   }
  //   this.setIndex = index;
  // }

  setArrowFun(category, index) {
    const chectStatus = category.toggle;
    this.allCategoryList.forEach(data => data.toggle = false);
    if (this.setIndex && this.setIndex !== index) {
      // this.allCategoryList[this.setIndex].toggle = false;
    }
    this.allCategoryList[index].toggle = !chectStatus;
    this.setIndex = index;
  }

  searchProduct(categoryID, categoryName, index, catId, isSubCategory?) {
    sessionStorage.setItem('selectedCatId', catId);
    sessionStorage.setItem('selectedCatName', categoryName);
    if (isSubCategory) {
      sessionStorage.setItem('isOpenIndex', index);
    } else {
      //console.log('22222', isSubCategory)
      this.isOpenIndex = -1;
      sessionStorage.setItem('isOpenIndex', this.isOpenIndex);
    }
    //console.log(' this.isOpenIndex', this.isOpenIndex);
    this.categoryHighlight = false;
    sessionStorage.removeItem('pageIndex');
    sessionStorage.removeItem('pageSize');
    this.showSubcategory = !this.showSubcategory;
    this.CID = this.router.routerState.snapshot.url;
    sessionStorage.setItem('breadCrumbCategoryName', JSON.stringify(categoryName));
    if (this.CID.indexOf('/search?') !== -1) {
      this.postParams = {
        'CategoryID': categoryID ? categoryID : '',
        // tslint:disable-next-line:max-line-length
        'ShowPricesIncludingLogoType': this.ShowPricesIncludingLogoType ? this.ShowPricesIncludingLogoType : this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType : null,
        'Quantity': this.Quantity ? this.Quantity : this.queryParams.Quantity ? this.queryParams.Quantity : '',
        'PriceFrom': this.queryParams.PriceFrom ? this.queryParams.PriceFrom : '',
        'PriceTo': this.queryParams.PriceTo ? this.queryParams.PriceTo : '',
        'ColorID': this.selectedColorID ? this.selectedColorID : this.queryParams.ColorID,
        'ProductName': this.queryParams.ProductName ? this.queryParams.ProductName : '',
        'DeliveryDate': this.queryParams.DeliveryDate ? this.queryParams.DeliveryDate : '',
        'SortBy': this.sortBy ? this.sortBy : this.queryParams.SortBy,
        'SubCategory': '',
        'isTagsFilterExists': this.isTagsFilterExists,
      };
      try {
        //this.router.navigate(['/search'], { queryParams: this.postParams, replaceUrl: false });
        this.commonService.setClearAll(true);
        this.router.navigate(['/search', categoryID, categoryName], { replaceUrl: false });
      } catch (error) {
        this.toastr.error('Error: ' + error);
      }
    } else {
      const str = categoryName;
      categoryName = str.replace(/ +/g, '');
      this.router.navigate(['/search', categoryID, categoryName], { replaceUrl: false });
    }
  }

  myOnUpdate(event) {
    this.event = event;
    this.postParams = {
      'CategoryID': this.queryParams.CategoryID ? this.queryParams.CategoryID : this.categoryId,
      'ShowPricesIncludingLogoType': this.ShowPricesIncludingLogoType ? this.ShowPricesIncludingLogoType : this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType : null,
      'Quantity': this.Quantity ? this.Quantity : this.queryParams.Quantity ? this.queryParams.Quantity : '',
      'PriceFrom': event.from ? event.from : this.queryParams.PriceFrom ? this.queryParams.PriceFrom : '',
      'PriceTo': event.to ? event.to : this.queryParams.PriceTo ? this.queryParams.PriceTo : '',
      'ColorID': this.selectedColorID ? this.selectedColorID : this.queryParams.ColorID,
      'ProductName': this.queryParams.ProductName ? this.queryParams.ProductName : '',
      'DeliveryDate': this.queryParams.DeliveryDate ? this.queryParams.DeliveryDate : '',
      'SortBy': this.sortBy ? this.sortBy : this.queryParams.SortBy,
      'SupplierQualityLevel': this.SupplierQualityLevel ? this.SupplierQualityLevel : this.queryParams.SupplierQualityLevel,
      'isTagsFilterExists': this.isTagsFilterExists
    };
    this.zoomValues();
    sessionStorage.removeItem('pageIndex');
    sessionStorage.removeItem('pageSize');
  }

  zoomValues() {
    if (this.event !== undefined) {
      if (this.event.from !== undefined) {
        this.priceFrom = this.event.from;
        this.min = this.event.from;
      }
      if (this.event.to !== undefined) {
        this.priceTo = this.event.to;
        this.max = this.event.to;
      }
    }
  }

  resetValues() {
    // if (this.colorsList.length > 0) {
    //   // this.priceFrom = this.colorsList[0].SliderMinPrice;
    //   // this.min = this.colorsList[0].SliderMinPrice;
    //   // this.max = this.colorsList[0].SliderMaxPrice;
    //   // this.priceTo = this.colorsList[0].SliderMaxPrice;
    // }
    // this.priceFrom = 1;
    // this.min = 1;
    // this.produtfilterMin =  1;
    
    // this.priceTo = this.produtfilterMax;
    // this.max =this.produtfilterMax;
    // this.produtfilterMax = this.produtfilterMax
    // if (this.queryParams.PriceFrom > 0) {
    //   this.priceFrom =1;
    //   this.min = 1;
    //   this.produtfilterMin = 1;
    // }
    // if (this.queryParams.PriceTo > 0) {
    //   this.priceTo = this.produtfilterMax;
    //   this.max = this.produtfilterMax;
    //   this.produtfilterMax = this.produtfilterMax;
    // }
    // if (sessionStorage.getItem('priceFrom') !== undefined) {
    //   this.priceFrom = 1;
    //   this.min = this.priceFrom;
    //   this.produtfilterMin =  this.priceFrom;
    //   this.priceTo = sessionStorage.getItem('priceTo');
    //   this.max = this.priceTo;
    //   this.produtfilterMax = this.priceTo;
    // }
    this.priceFilteReset = true;
    if (this.event !== undefined) {
      if (this.event.from !== undefined) {
        this.priceFrom = this.event.min;
        this.min = this.event.min;
        this.produtfilterMin =  this.event.min;
      }
      if (this.event.to !== undefined) {
        this.priceTo = this.event.max;
        this.max = this.event.max;
        this.produtfilterMax = this.event.max;
      }
    }else{
      
      this.priceFrom = 1;
      this.min = 1;
      this.produtfilterMin =  1;
      
      this.priceTo = this.produtfilterMax;
      this.max =this.produtfilterMax;
      this.produtfilterMax = this.produtfilterMax;
       if (this.queryParams.PriceFrom > 0) {
        this.priceFrom =this.produtfilterMin;
        this.min = this.produtfilterMin;
        this.produtfilterMin = this.produtfilterMin;
      }
      if (this.queryParams.PriceTo > 0) {
        this.priceTo = this.produtfilterMax;
        this.max = this.produtfilterMax;
        this.produtfilterMax = this.produtfilterMax;
      }
    }
  }

 
  onFilterUpdate(event, type, isTagValue) {
    this.clearButtonEnableStatus = false;

    if (type === 'sortby' && isTagValue === undefined) {
      this.commonService.setSort(event.SortBy.value);
      this.sortBy = event.SortBy.value;
    } else if (type === 'sortby' && isTagValue !== undefined) {
      this.sortBy = event.value;
    } else if (type === 'branding') {
      this.ShowPricesIncludingLogoType = event.ShowPricesIncludingLogoType.value;
    } else if (type === 'quantity') {
      this.Quantity = event.Quantity.value;
    }
    else { // type === 'sortByColor'
      sessionStorage.setItem('colorListDropDownStatus', JSON.stringify(0));
      this.selectedColorID = event;
    }

    this.CID = this.router.routerState.snapshot.url;
    this.CID = this.CID.split('/');
    if (this.CID.length > 2) {
      this.categoryID = this.CID[2];
    } else {
      if (this.queryParams.CategoryID !== undefined) {
        this.categoryID = this.queryParams.CategoryID;
      } else {
        this.categoryID = '';
      }
    }

    this.postParams = {
      'CategoryID': this.categoryID,
      'ShowPricesIncludingLogoType': this.ShowPricesIncludingLogoType ? this.ShowPricesIncludingLogoType : this.queryParams.ShowPricesIncludingLogoType ? this.queryParams.ShowPricesIncludingLogoType : null,
      'Quantity': this.Quantity ? this.Quantity : this.queryParams.Quantity ? this.queryParams.Quantity : '',
      'PriceFrom': this.queryParams.PriceFrom ? this.queryParams.PriceFrom : '',
      'PriceTo': this.queryParams.PriceTo ? this.queryParams.PriceTo : '',
      'ColorID': this.selectedColorID ? this.selectedColorID : this.queryParams.ColorID,
      'ProductName': this.queryParams.ProductName ? this.queryParams.ProductName : '',
      'DeliveryDate': this.queryParams.DeliveryDate ? this.queryParams.DeliveryDate : '',
      'SortBy': this.sortBy ? this.sortBy : this.queryParams.SortBy,
      'SupplierQualityLevel': this.SupplierQualityLevel ? this.SupplierQualityLevel : this.queryParams.SupplierQualityLevel,
      'isTagsFilterExists': this.isTagsFilterExists
    };

    // this.selectedSortBy()
  }

  onTagFilterUpdate(item, val) {
    this.ProductTagsGroups.forEach((list) => {
      if (list.productTagsGroupID == item.productTagsGroupID) {
        list.label = [];
      }
    })
    val.forEach(list => {
      const listVal = item.productTags.find((item) => {
        return item.value == list
      });

      const productTags = this.ProductTagsGroups.find((list) => {
        return list.productTagsGroupID == item.productTagsGroupID
      });
      productTags.label.push(listVal.label);
      this.isTagsFilterExists = true;

    })
    sessionStorage.setItem('productTags', JSON.stringify(this.ProductTagsGroups));



    const sessionProductTags = JSON.parse(sessionStorage.getItem('productTags'));
    if (this.webshopDetail.ProductTagsGroups.length > 0 && this.webshopDetail.ProductTagsGroups !== null && this.webshopDetail.ProductTagsGroups !== undefined) {
      let productIds = [];
      if (sessionProductTags !== null && sessionProductTags !== undefined) {
        if (sessionProductTags.length > 0) {
          sessionProductTags.forEach((item) => {
            if (item.value.length > 0) {
              item.value.forEach((val) => {
                productIds.push(parseInt(val));
              });
            }
          });
        }
      }
      this._httpProductService.getProductSelectedTags(productIds).subscribe(
        (_tagdata: any[]) => {
          this.ProductTagsGroups = [];
          _tagdata.forEach((item, index) => {
            let selectedValue = [];
            let selectedlabel = [];
            if (sessionProductTags[index] !== undefined && sessionProductTags[index] !== null) {
              selectedValue = sessionProductTags[index].value;
              selectedlabel = sessionProductTags[index].label;
            }
            let products = {
              productTagsGroupID: null,
              productTagsGroupName: null,
              value: [],
              label: [],
              productTags: []
            };
            products = {
              productTagsGroupID: item.ProductTagsGroupID,
              productTagsGroupName: item.Name,
              value: selectedValue,
              label: selectedlabel,
              productTags: []
            }
            if (item.ProductTags.length > 0 && item.ProductTags !== null && item.ProductTags !== undefined) {
              item.ProductTags.forEach((element) => {
                products.productTags.push({
                  'value': element.ProductTagID,
                  'label': element.Name,
                })
              });
            }
            if (products.productTags.length > 0) {
              this.ProductTagsGroups.push(products);
            }
          });
          //console.log("onTagFilterUpdate this.ProductTagsGroups : ", this.ProductTagsGroups);
          sessionStorage.setItem('productTags', JSON.stringify(this.ProductTagsGroups));
          this._httpMessagesService.setProductTagsSource(this.ProductTagsGroups);
        });
    }
  }

  clearTagsFilter() {
    this.isTagsFilterExists = false;
    sessionStorage.removeItem('productTags');
    if (this.ProductTagsGroups.length > 0 && this.ProductTagsGroups !== undefined && this.ProductTagsGroups !== null) {
      this.ProductTagsGroups.forEach(item => {
        item.value = [];
      });
    }
    this.selectedSortBy();
    sessionStorage.setItem('isSaveTagsFilterSaved', JSON.stringify(false));
  }

  saveTagsFilter() {
    sessionStorage.setItem('isSaveTagsFilterSaved', JSON.stringify(true));
    sessionStorage.setItem('productTags', JSON.stringify(this.ProductTagsGroups));
  }

  goMainCategory() {
    this.categoryHighlight = true;
    this.router.navigate(['/search', this.mainCategoryId, this.mainCategoryName], { replaceUrl: false });
  }
  TotalProductPriceCal(){
    this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    this.CID = this.router.routerState.snapshot.url;
    if(this.isParentCustomer){
      this._httpProductService.productBlocks(this.webshopDetail.CustomerID, true, '').subscribe(
        (data: any[]) => {
          this.productBlocks = data;
          this.productAddedData = [];
          let len3 = this.productBlocks.length
          for (let i = 0; i < len3; i++) {
            this.productBlocks[i].Products = data[i].Products.slice(0, this.configProductBlockCount);
            data[i].Products.forEach(val =>{
              this.productAddedData.push(val) 
            })
          }
          if(this.productAddedData){
            let len4 = this.productAddedData.length;
            for (let i = 0; i < len4; i++) {
              this.prosductMaxPrice.push(this.productAddedData[i].MaxPrice)
              this.prosductMinPrice.push(this.productAddedData[i].MaxPrice)
            
            }
          }
          this.produtfilterMax = Math.max.apply(null, this.prosductMaxPrice)
          this.produtfilterMin = Math.min.apply(null, this.prosductMaxPrice)
          this.priceFrom = this.produtfilterMin;
          this.priceTo = this.produtfilterMax;
          this.productListSearchParam.get('priceFrom').setValue((this.queryParams.PriceFrom) ?  this.queryParams.PriceFrom : '');
          this.productListSearchParam.get('priceTo').setValue((this.queryParams.PriceTo) ?  this.queryParams.PriceTo : '');
        }
      );
    }else{
      let data = JSON.parse(sessionStorage.getItem('campaignProducts'));
      if(data){
        this.productBlocks = data;
        this.productAddedData = [];
        let len = this.productBlocks.ItemsCollection.length
          for (let i = 0; i < len; i++) {
            //this.productBlocks[i].ItemsCollection = data[i].ItemsCollection.slice(0, this.configProductBlockCount);
            this.productBlocks.ItemsCollection.forEach(val =>{
              this.productAddedData.push(val) 
            })
          }
          if(this.productAddedData){
            let len2 = this.productAddedData.length;
            for (let i = 0; i < len2; i++) {
              this.prosductMaxPrice.push(this.productAddedData[i].MaxPrice)
              this.prosductMinPrice.push(this.productAddedData[i].MaxPrice)
            
            }
          }
          this.produtfilterMax = Math.max.apply(null, this.prosductMaxPrice)
          this.produtfilterMin = Math.min.apply(null, this.prosductMinPrice)
          this.priceFrom = this.produtfilterMin;
          this.priceTo = this.produtfilterMax;
          this.productListSearchParam.get('priceFrom').setValue((this.queryParams.PriceFrom) ?  this.queryParams.PriceFrom : '');
          this.productListSearchParam.get('priceTo').setValue((this.queryParams.PriceTo) ?  this.queryParams.PriceTo : '');
      }
    }
  }

  onlyNumberKey(event) {
    const pattern = new RegExp('^[0-9\b]+$');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!pattern.test(key) && event.charCode !== 0) {
      event.preventDefault();
      return false;
    }
  }

  selectMainCategory(i){
    let len = this.mainCategoryList.length;
    if (this.mainCategoryList[i].toggle) {
      this.CategoryIDs.push(this.mainCategoryList[i].CategoryID.toString())
    }else{
      const getIndex = this.CategoryIDs.findIndex(get => get === this.mainCategoryList[i].CategoryID.toString());
      this.CategoryIDs.splice(getIndex, 1);
    }
    if(this.CategoryIDs.length !== 0){
      this.searchCategory();
    }else{
      this.router.navigate(['/'], {replaceUrl: false});
    }
  }

  selectCategory(i){
    let len = this.allCategoryList.length;
    if (this.allCategoryList[i].toggle) {
      this.CategoryIDs.push(this.allCategoryList[i].CategoryID.toString())
    }else{
      const getIndex = this.CategoryIDs.findIndex(get => get === this.allCategoryList[i].CategoryID.toString());
      this.CategoryIDs.splice(getIndex, 1);
    }
    if(this.CategoryIDs.length !== 0){
      this.searchCategory();
    }else{
      this.router.navigate(['/'], {replaceUrl: false});
    }
  }

  searchCategory(){
    let ids = '';
    this.CategoryIDs.forEach(e=>{
      ids = ids+e+'/';
    })
    this.router.navigate(['/multi-search', ids], { replaceUrl: false });
  }

}
