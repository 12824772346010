import { Component, OnInit } from '@angular/core';
import { ToastService } from 'app/typescripts/pro/alerts';
import { Validators, FormBuilder } from '@angular/forms';
import { SettingsComponent } from 'app/webshop/settings/settings.component';
import { SecurityService } from 'app/linknet-common/restful-services/restful.service';
import { TranslateService } from '@ngx-translate/core';
import { ValidationService } from '../../../linknet-common/validators/index';
import { AppComponent } from 'app/app.component';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-change-user-password',
  templateUrl: './change-user-password.component.html',
  styleUrls: ['./change-user-password.component.scss']
})
export class ChangeUserPasswordComponent implements OnInit {
  public signupFormStep1: any;
  public type: any;
  public translatedMessage: any;
  public loggedInData: any;
  constructor(public toastService: ToastService, public formBuilder: FormBuilder, public settingsComponent: SettingsComponent,
    public _httpSecurity: SecurityService, public translate: TranslateService, public appComponent: AppComponent,
    public globalService: GlobalService, public router: Router, private toastr: ToastrService) {
    this.type = 'changepassword'
    settingsComponent.type = this.type;
    try {
      this.signupFormStep1 = this.formBuilder.group({
        'Oldpassword': ['', [Validators.required]],
        'Password': ['', [Validators.required, ValidationService.passwordValidator]],
        'Confirmpassword': ['', [Validators.required]],
      });
    } catch (error) {
      this.toastr.error(error);
    }
    if (appComponent.loggedInData != null && Object.keys(appComponent.loggedInData).length > 0) {
      this.loggedInData = appComponent.loggedInData;
    }
    globalService.loggedIn$.subscribe(
      data => {
        this.loggedInData = data;
      });
  }

  ngOnInit() {
  }
  createNewPassword() {
    try {
      const postParameters = {
        'autopass': this.signupFormStep1.value.Oldpassword,
        'newpass': this.signupFormStep1.value.Password,
        'confpass': this.signupFormStep1.value.Confirmpassword,
      }
      this._httpSecurity.newPassword(postParameters).subscribe(
        (data: any[]) => {
          if (data) {
            this.signupFormStep1 = this.formBuilder.group({
              'Oldpassword': ['', [Validators.required]],
              'Password': ['', [Validators.required, ValidationService.passwordValidator]],
              'Confirmpassword': ['', [Validators.required]],
            });
            this.logout()
            //  this.translatedMessage = (this.translate.get('SETTINGS.PASSWORD_SUCCESS'));
            this.translate.get('SETTINGS.PASSWORD_SUCCESS').subscribe((res: string) => {
              this.translatedMessage = res;
            });
            this.toastr.success(this.translatedMessage);
          }
        }, error => {
          if (error.status === 404) {
            this.signupFormStep1.patchValue({
              Oldpassword: '',
            }, { onlySelf: false, emitEvent: false });
            //  this.translatedMessage = (this.translate.get('HEADING.OLD_PASS_NOT_MATCH'));
            this.translate.get('HEADING.OLD_PASS_NOT_MATCH').subscribe((res: string) => {
              this.translatedMessage = res;
            });
            this.toastr.error(this.translatedMessage);
          } else {
            this.toastr.error(error);
          }
        });
    } catch (error) {
      if (error.status === 404) {
        // this.translatedMessage = (this.translate.get('HEADING.OLD_PASS_NOT_MATCH'));
        this.translate.get('HEADING.OLD_PASS_NOT_MATCH').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
        this.signupFormStep1.patchValue({
          Oldpassword: '',
        }, { onlySelf: false, emitEvent: false });
      } else {
        this.toastr.error(error);
      }

    }
  }
  // Logout Function
  logout() {
    try {
      // Remove saved data from sessionStorage
      sessionStorage.removeItem('loggedInData');
      // Remove all saved data from sessionStorage
      const webshopDetail = sessionStorage.getItem('webshopDetail');
      sessionStorage.setItem('webshopDetail', webshopDetail)
      this.loggedInData = {};
      this.globalService.loggedin(null);
      const data = JSON.parse(sessionStorage.getItem('webshopDetail'));
      this.globalService.overwriteSession('userSettings', 'LanguageCode', data.DefaultLanguageCode);
      this.globalService.overwriteSession('userSettings', 'LanguageID', data.DefaultLanguageID);
      this.globalService.overwriteSession('userSettings', 'CurrencyCode', data.DefaultCurrencyCode);
      this.globalService.overwriteSession('userSettings', 'CurrencyID', data.DefaultCurrencyID);
      if (this.router.navigate(['/'], { replaceUrl: false })) {
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  userPasswordValid(password) {
    const userDetails = JSON.parse(sessionStorage.getItem('loggedInData'));
    const userName = userDetails.UserName;
    if (userName !== '' && password !== '') {
      if (userName === password) {
        // this.translatedMessage = (this.translate.get('SIGNUP.USER_PASS_NOT_MATCH'));
        this.translate.get('SIGNUP.USER_PASS_NOT_MATCH').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
        this.signupFormStep1.patchValue({
          Password: '',
          Confirmpassword: ''
        }, { onlySelf: false, emitEvent: false });
      }
    }
  }
}
