import { Component, OnInit, Output, Input, OnChanges, EventEmitter } from '@angular/core';
import { ToastService } from 'app/typescripts/pro/alerts';
import { Router } from '@angular/router';
import { GlobalService, CurrencySetterService } from 'app/linknet-common/globals/global.service';

@Component({
  selector: 'shop-app-show-product-price',
  templateUrl: './show-product-price.component.html',
  styleUrls: ['./show-product-price.component.scss']
})
export class ShowProductPriceComponent implements OnInit {
  public printPrices: any;
  public currency: any;
  public start = 0;
  public end = 2;
  @Input() productPrices: any;
  @Input() productUnitName: String;
  @Input() productDetail: any;
  public productionOrderStatus: boolean = false;
  queryParams: any;
  public showToggleStatus: Boolean = true;

  constructor(public currencySetterService: CurrencySetterService, public globalService: GlobalService,
    private toastService: ToastService, private router: Router) {
    try {
      this.queryParams = this.router.routerState.snapshot.root.queryParams;
      this.currency = this.globalService.getUserSettings().CurrencyCode;
    } catch (error) {
      this.toastService.error(error);
    }
  }

  ngOnInit() {
    try {
      if (this.productPrices.length > 0) {
        if (this.productDetail.MinimumProductionOrder !== undefined && this.productDetail.MinimumProductionOrder !== null) {
          if (Number(this.productDetail.MinimumProductionOrder) > 0) {
            this.productionOrderStatus = true;
          }
        }
      }
    } catch (error) {
      this.toastService.error(error);
    }
  }
}
