import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { WebshopService } from '../../../linknet-common/restful-services/restful.service';
import { CreditEnquiryComponent } from '../credit-enquiry/credit-enquiry.component';

@Component({
  selector: 'shop-app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public logIndata: any;
  public subMenu: boolean = false;
  public showMenu: boolean = false;
  public getUserCurrentBudget: any;
  public userPaymentByInvoiceStatus: boolean = false;
  public isParentCustomer: boolean = false;
  public isVoucherKart: boolean = false;
  public isMobile: boolean;
  isSmartworks: boolean = false;
  isHrm: boolean = false;
  @ViewChild('creditInquiryModal') creditInquiryModal:CreditEnquiryComponent;
  isPenna:boolean = false;
  constructor(public _httpWebshopService: WebshopService,
    public globalService: GlobalService) {
      if (/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(navigator.userAgent)) {
				this.isMobile = true;
			} else {
				this.isMobile = false;
			}
    this.logIndata = this.globalService.getLoggedInData();
    const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if(webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName){
        this.isParentCustomer = true;
        if(webshopDetail.DomainName === "smartworks"){
          this.isSmartworks = true;
        }
        if(webshopDetail.DomainName === "247hrm"){
          this.isHrm = true;
        }
      }else{
        this.isParentCustomer = false;
        if(webshopDetail.DomainName === "voucherkart"){
          this.isVoucherKart = true;
        }else if(webshopDetail.DomainName === "sw"){
          this.isSmartworks = true;
        }
        else if(webshopDetail.DomainName === "247hrm"){
          this.isHrm = true;
        }
      }
      if(webshopDetail.DomainName === "pc"){
        this.isPenna = true;
      }
  }

  ngOnInit() {
    if (this.logIndata !== null) {
      this.userPaymentByInvoiceStatus = this.logIndata.PaymentByInvoice;
      if (this.userPaymentByInvoiceStatus) {
        this._httpWebshopService.getUserCurrentBudget().subscribe(
          (data: any[]) => {
            this.getUserCurrentBudget = data;
          });
      }
    }
    this.showMenu = true;
  }

  hideMenu() {
    this.showMenu = false;
    if(this.isMobile){
      var otherelement = document.querySelectorAll(".header-two .navbar-collapse.show")[0] as HTMLElement;
      if(otherelement){
        var closeelement = document.querySelectorAll(".header-two .navbar-toggler")[0] as HTMLElement;
        closeelement.click();
      }
      var element = document.querySelectorAll(".navbar-toggler")[0] as HTMLElement;
      if(element){
        element.click();
      }
    }
  }

  showMenus() {
    this.showMenu = true;
  }

  showMenuEvent(event){
    this.showMenu = event;
    this.hideMenu();
  }

  removeSessionItem() {
    sessionStorage.removeItem('pageIndex');
    sessionStorage.removeItem('pageSize');
  }
}
