import { Component, OnInit, ViewChild, Input, ElementRef, Renderer, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../../../linknet-common/validators';
import { ToastService } from '../../../typescripts/pro';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { WebshopService } from '../../../linknet-common/restful-services/restful.service';
import { TranslateService } from '@ngx-translate/core';
import { INgxMyDpOptions } from 'ngx-mydatepicker';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-product-inquiry',
  templateUrl: './product-inquiry.component.html',
  styleUrls: ['./product-inquiry.component.scss'],
  exportAs: 'child'
})
export class ProductInquiryComponent implements OnInit {
  @ViewChild('inquiryModal') public inquiryModal;
  @Input() productDetail: any;
  @Input() isMenuInquiry: any;
  @Input() product: any;
  @Input() enqFrom: string;
  public isUserImageURL: Boolean;
  public inquiryForm: any;
  public isInquiryPage = true;
  public imageURL;
  public userImageName: string;
  public translatedMessage: any;
  public userDetails: any;
  public userSettings: any;
  public currency: any;
  public userID: any;
  public postParams: any;
  public imageUrlList = [];
  public isUserLogged = false;
  public isParentCustomer: Boolean = false;
  public myOptions: INgxMyDpOptions = {
    dateFormat: 'dd-mm-yyyy',
  };
  public customOptions = {
    allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif',
      'application/pdf', 'application/postscript', 'application/postscript']
  };
  protected _element: ElementRef;
  protected _renderer: Renderer;
  public changeDeliveryDateText: Boolean = false;  // for display text as delivery date / Earliest disp. date in HTML Page
  public wsaStatus: Boolean = false;
  webshopData: any;
  isChildShop: boolean;
  constructor(public formBuilder: FormBuilder, public toastService: ToastService, public globalService: GlobalService,
    public _httpWebshopService: WebshopService, public translate: TranslateService, public datePipe: DatePipe,
    private toastr: ToastrService, _element: ElementRef, _renderer: Renderer) {
    this._element = _element;
    this._renderer = _renderer;
    const d: Date = new Date()
    this.myOptions = {
      dateFormat: 'dd-mm-yyyy',
      disableUntil: { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() - 1 }
    }
  }

  ngOnInit() {
    this.onLoaded()
    
  }

  onLoaded(){
    try {
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (webshopDetail.ChangeDeliveryDateText) {
        this.changeDeliveryDateText = true;
      }
      if (Number(webshopDetail.CustomerID) === 45) {
        this.wsaStatus = true;
      }
      this.webshopData = webshopDetail;
      if(this.webshopData.ParentCustomerDomainName === this.webshopData.DomainName){
        
        this.isParentCustomer = true;
       // console.log(this.isParentCustomer, "for true")
      }else{
        this.isParentCustomer = false;
        //console.log(this.isParentCustomer, "for false")
      }
      this.userDetails = JSON.parse(sessionStorage.getItem('loggedInData'));
      this.userSettings = JSON.parse(sessionStorage.getItem('userSettings'));
      if (this.userDetails !== null) {
        this.currency = this.userDetails.UserCurrencyCode;
      } else {
        this.currency = this.userSettings.CurrencyCode;
      }
      if (this.productDetail !== undefined) {
        this.inquiryForm = this.formBuilder.group({
          'UserName': ['', [Validators.required]],
          'CompanyName': ['', [Validators.required]],
          'Email': ['', [Validators.required, ValidationService.emailValidator]],
          'ProductName': ['', [Validators.required]],
          'ProductManualId': [''],
          'ProductDescription': [''],
          'Quantity': ['', [ValidationService.quantityValidator]],
          'Color': [''],
          'DeliveryDate': [''],
          'Logo': [''],
          'TargetPrice': [''],
          'Comment': [''],

        });
      
        this.inquiryForm.patchValue({
          ProductName: this.productDetail.Name,
          ProductManualId: this.productDetail.ProductIdManual
        }, { onlySelf: false, emitEvent: false });
      } else {
        this.inquiryForm = this.formBuilder.group({
          'UserName': ['', [Validators.required]],
          'CompanyName': ['', [Validators.required]],
          'Email': ['', [Validators.required, ValidationService.emailValidator]],
          'ProductName': ['', [Validators.required]],
          'ProductManualId': [''],
          'ProductDescription': ['', [Validators.required]],
          'Quantity': ['', [Validators.required, ValidationService.quantityValidator]],
          'Color': [''],
          'DeliveryDate': ['', [Validators.required]],
          'Logo': [''],
          'TargetPrice': [''],
          'Comment': [''],

        });
      }
      if (this.userDetails !== null) {
        this.inquiryForm.patchValue({
          UserName: this.userDetails.UserName,
          CompanyName: this.userDetails.CompanyName,
          Email: this.userDetails.Email,
        }, { onlySelf: false, emitEvent: false });
        this.isUserLogged = true;
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }
  
  cancel() {
    // tslint:disable-next-line:no-unused-expression
    this.imageUrlList = [];
    this.inquiryModal.hide();
    this.isUserImageURL = false;
  }

  setUploadedImageData(ev) {
    try {
      this.userImageName = ev;
    } catch (error) {
      this.toastr.error(error);
    }
  }

  showInquiryModal(_productDetail) {

    this.inquiryModal.show();
    if(_productDetail){
      if(_productDetail.productImageURL){
        this.productDetail.ImageURL = _productDetail.ImageURL;
      }
      this.onLoaded();
    }
    /*this._renderer.setElementProperty(this._element.nativeElement, 'scrollTop', 0);
    setTimeout(() => {
      this._element.nativeElement.focus();
      const element = this._element.nativeElement.querySelector('[autofocus]');
      if (element) {
        element.focus();
      }
    }, 500);*/
  }

  sendInquiry() {
    if(!this.inquiryForm.value.ProductDescription || !this.inquiryForm.value.Quantity || !this.inquiryForm.value.DeliveryDate){
      this.toastr.error("Please fill the Mandatory Fields");
      // this.inquiryModal.hide();
      return;
    }
    try {
      const webShopSettings = this.globalService.webShopSettings();
      if (this.userDetails === null) {
        delete this.userID
      } else {
        this.userID = this.userDetails.UserID;
      }
      if (this.inquiryForm.value.DeliveryDate === null) {
        delete this.inquiryForm.value.DeliveryDate.formatted;
      } else {
        this.inquiryForm.value.DeliveryDate = this.datePipe.transform(this.inquiryForm.value.DeliveryDate.jsdate, 'yyyy-MM-dd');
      }
      if(this.isParentCustomer){
        this.isChildShop = false
      }else{
        this.isChildShop = true
      }
      if (this.productDetail !== undefined || this.userImageName === undefined || this.userImageName === null) {
        
        this.postParams = {
          'ProductName': this.inquiryForm.value.ProductName,
          'ProductIdManual': this.inquiryForm.value.ProductManualId,
          'CustomerID': webShopSettings.CustomerID,
          'Email': this.inquiryForm.value.Email,
          'UserName': this.inquiryForm.value.UserName,
          'CompanyName': this.inquiryForm.value.CompanyName,
          'UserID': this.userID,
          'ProductDescription': this.inquiryForm.value.ProductDescription,
          'Quantity': this.inquiryForm.value.Quantity,
          'Color': this.inquiryForm.value.Color,
          'Logo': this.inquiryForm.value.Logo,
          'DeliveryTime': this.inquiryForm.value.DeliveryDate,
          'TargetPrice': this.inquiryForm.value.TargetPrice,
          'Comment': this.inquiryForm.value.Comment,
          'IsChildShop':this.isChildShop
        }
      } else {
        // if(!this.inquiryForm.value.ProductDescription || !this.inquiryForm.value.Quantity || !this.inquiryForm.value.DeliveryDate){
        //   this.toastr.error("Please fill the Mandatory Fields");
        //   this.inquiryModal.hide();
        //   return;
        // }
        this.postParams = {
          'ProductName': this.inquiryForm.value.ProductName,
          'ProductIdManual': this.inquiryForm.value.ProductManualId,
          'CustomerID': webShopSettings.CustomerID,
          'Email': this.inquiryForm.value.Email,
          'UserName': this.inquiryForm.value.UserName,
          'CompanyName': this.inquiryForm.value.CompanyName,
          'UserID': this.userID,
          'InquiryFiles': this.userImageName,
          'ProductDescription': this.inquiryForm.value.ProductDescription,
          'Quantity': this.inquiryForm.value.Quantity,
          'Color': this.inquiryForm.value.Color,
          'Logo': this.inquiryForm.value.Logo,
          'DeliveryTime': this.inquiryForm.value.DeliveryDate,
          'TargetPrice': this.inquiryForm.value.TargetPrice,
          'Comment': this.inquiryForm.value.Comment,
          'IsChildShop':this.isChildShop
        }
      }
      this.ngOnInit();
      Object.keys(this.postParams).forEach((key) => (this.postParams[key] === '') && (this.postParams[key] === null)
        && (this.postParams[key] === undefined) && delete this.postParams[key]);
      this._httpWebshopService.sendInquiry(this.postParams).subscribe(
        _data => {
          // this.translatedMessage = (this.translate.get('SETTINGS.INQUIRY_SUCCESS'));
          this.translate.get('SETTINGS.INQUIRY_SUCCESS').subscribe((res: string) => {
            this.translatedMessage = res;
          });
          this.toastr.success(this.translatedMessage);
          this.cancel();
        },
        error => {
          // this.toastService.error(JSON.parse(error._body).Message);
          this.toastr.error(error.error.Message);
        });
    } catch (error) {
      this.toastr.error(error);
    }
  }
  // for Decimal you can use this as
  onlyDecimalNumberKey(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // tslint:disable-next-line:curly
    if ((charCode < 48 || charCode > 57))
      return false;
    return true;
  }
}
