import { Component, OnInit } from '@angular/core';
import { ToastService } from 'app/typescripts/pro/alerts';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-product-likes',
  templateUrl: './product-likes.component.html',
  styleUrls: ['./product-likes.component.scss']
})
export class ProductLikesComponent implements OnInit {
  public pageIndex: any = 1;
  public translatedMessage: any;

  constructor(private toastService: ToastService, private router: Router,
    private translate: TranslateService, private globalService: GlobalService, private toastr: ToastrService) { }

  ngOnInit() {
    this.getLikedProducts();
  }

  getLikedProducts() {
    try {
      const logInData = this.globalService.getLoggedInData();
      if (logInData) {
        const postParams = { 'likedProducts': 'true' }
        this.router.navigate(['/search'], { queryParams: postParams, replaceUrl: true });
      } else {
       // this.translatedMessage = (this.translate.get('PRODUCT_DETAIL.PLEASE_LOGIN_TO_LIKE_PRODUCTS'));
        this.translate.get('PRODUCT_DETAIL.PLEASE_LOGIN_TO_LIKE_PRODUCTS').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }
}
