import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges } from "@angular/core";
import { CampaignService,ProductService} from "../../../linknet-common/restful-services/restful.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastService } from "app/typescripts/pro/alerts";
import { environment } from "../../../../environments/environment";
import { GlobalService } from "app/linknet-common/globals/global.service";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";

@Component({
  selector: "shop-app-product-blocks",
  templateUrl: "./product-blocks.component.html",
  styleUrls: ["./product-blocks.component.scss"],
})
export class ProductBlocksComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() categoryProduct: any;
  @Input() categoryProductCount: any;
  @Input() homeCategoryName: any;
  @Input() homeCategoryID: any;
  // @Input() selectedCampaign: any;
  showProductBlocks = false;
  public webshopDetail;
  public productBlocks;
  public responseFullProductBlocks;
  public loaderShow = true;
  public hideDelete: Boolean = true;
  public folderID: any;
  public likedProducts: any;
  public perPageCount: any;
  public totalCount: any;
  public currentPage = 1;
  public start = [];
  public end = [];
  public productBlockDisplayCount = 0;
  public hideNextButton: Boolean = true;
  public webShopSettings: any;
  public displayFilterStatus: Boolean = false;
  public categoryProductStartCount = 0;
  public categoryProductEndCount = environment.productBlockDisplayCount;
  public categoryProductCountStart = 0;
  public categoryProductCountEnd = 0;
  public selectedCampaignPoints;
  tempCampaign: Subscription;
  selectedCampaign: any;
  isMobile: boolean;
  public showFiltersOnHomePage: Boolean = false;
  campaignid: any = '';
  isParentShop: boolean = false;
  constructor(
    public _httpProducts: ProductService,
    private commonService: CommonServicesService,
    public route: ActivatedRoute,
    public toastService: ToastService,
    public campaignService: CampaignService,
    public router: Router,
    public globalService: GlobalService,
    private toastr: ToastrService
  ) {
    if (/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    try {
      this.loaderShow = true;
      this.folderID = this.route.snapshot.queryParams
        ? this.route.snapshot.queryParams.FolderID
        : null;
      this.likedProducts = this.route.snapshot.queryParams
        ? this.route.snapshot.queryParams.likedProducts
        : null;
      if (this.folderID || this.likedProducts) {
        this.hideDelete = false;
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnInit() {
    this.webShopSettings = this.globalService.webShopSettings();
    this.webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
    if (this.webshopDetail) this.isParentShop = this.webshopDetail.DomainName === this.webshopDetail.ParentCustomerDomainName;
    if (this.webshopDetail.ShowFiltersOnHomePage) {
      this.showFiltersOnHomePage = true;
    }
    // tslint:disable-next-line:max-line-length
    this.displayFilterStatus =
      this.showFiltersOnHomePage &&
      (this.webShopSettings.ShowAdvancedSearchBranding ||
        this.webShopSettings.ShowAdvancedSearchPrices ||
        this.webShopSettings.ShowSupplierRating ||
        this.webShopSettings.ShowAdvancedSearchColors ||
        this.webShopSettings.ShowSortByFilter)
        ? true
        : false;

    environment.productBlockDisplayCount =  this.isParentShop ? 5 : this.displayFilterStatus ? 4 : 5;
    this.categoryProductCountEnd = this.isParentShop ? 5 : this.displayFilterStatus ? 4 : 5;
    this.categoryProductEndCount = environment.productBlockDisplayCount;
    this.productBlockDisplayCount = environment.productBlockDisplayCount;
    if (this.categoryProduct) {
      this.loaderShow = false;
    } else {
      this.getProductBlocks(true, this.campaignid);
    }
    // console.log("product loading", this.selectedCampaign);
    this.tempCampaign = this.campaignService
      .getActiveCampaign()
      .subscribe((res) => {
        this.selectedCampaign = res;
        if (res) {
          this.commonService.setPoints(res.BalancePoints);
          this.campaignid = res.CampaignId;
          this.getProductBlocks(false, res.CampaignId);
        }
      });
      this.tempCampaign.unsubscribe();
  }
  ngAfterViewInit() {
    // setTimeout(() => {
    //      this.getProductBlocks(false)
    //    }, 10000);
  }

  ngOnChanges(_changes: SimpleChanges) {
   // console.log("changes", changes);
  }
  getProductBlocks(isFirstCall, campaignId) {
    this.showProductBlocks = false;
    // if (JSON.parse(sessionStorage.getItem('productBlocks')) === null) {
    try {
      this.loaderShow = true;
      this.webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
      this._httpProducts
        .productBlocks(this.webshopDetail.CustomerID, isFirstCall, campaignId)
        .subscribe((data: any[]) => {
          this.loaderShow = false;
          this.productBlocks = data;
          // console.log(this.productBlocks)
          for (let i = 0; i < this.productBlocks.length; i++) {
            this.productBlocks[i].start = 0;
            // tslint:disable-next-line:max-line-length
            this.productBlocks[i].end = this.isParentShop ? 5 : 
              this.showFiltersOnHomePage &&
              (this.webShopSettings.ShowAdvancedSearchBranding ||
                this.webShopSettings.ShowAdvancedSearchPrices ||
                this.webShopSettings.ShowSupplierRating ||
                this.webShopSettings.ShowAdvancedSearchColors ||
                this.webShopSettings.ShowSortByFilter)
                ? 4
                : 5;
          }
          // let prePages = JSON.parse(sessionStorage.getItem('prePages'));
          // document.getElementById(prePages[prePages.length - 2]['id']).focus();
          // console.log(JSON.parse(sessionStorage.getItem('prePages')));
          sessionStorage.setItem(
            "productBlocks",
            JSON.stringify(this.productBlocks)
          );
          this.showProductBlocks = true;
          // this.productBlocks.forEach(element => {
          //   element['start'] = 0;
          //   element['end'] = 6;
          // });

          setTimeout(() => {
            this._httpProducts
              .productBlocks(this.webshopDetail.CustomerID, false, this.campaignid)
              .subscribe((data1: any[]) => {
                this.responseFullProductBlocks = data1;
                this.hideNextButton = false;
               
                sessionStorage.setItem("productBlocks", JSON.stringify(data1));
                for (
                  let i = 0;
                  i < this.responseFullProductBlocks.length;
                  i++
                ) {
                  // tslint:disable-next-line:max-line-length
                  let k = this.isParentShop ? 5 : 
                    this.showFiltersOnHomePage &&
                    (this.webShopSettings.ShowAdvancedSearchBranding ||
                      this.webShopSettings.ShowAdvancedSearchPrices ||
                      this.webShopSettings.ShowSupplierRating ||
                      this.webShopSettings.ShowAdvancedSearchColors ||
                      this.webShopSettings.ShowSortByFilter)
                      ? 4
                      : 5;

                  for (let j = 0; j < this.responseFullProductBlocks[i].Products.length;j++) {
                    let productExists;
                    if(this.productBlocks.length !== 0 && this.productBlocks[i].Products){
                    productExists = this.productBlocks[i].Products.filter(
                      (option) => {
                        return (
                          option.ProductID ===
                          this.responseFullProductBlocks[i].Products[j]
                            .ProductID
                        );
                      }
                    );
                    
                    if (!productExists) {
                      this.productBlocks[i].Products[k] = this.responseFullProductBlocks[i].Products[j];
                      k++;
                    }
                  }
                    // this.productBlocks[i].Products[j] = this.responseFullProductBlocks[i].Products[j];
                  }
                }
              });
          }, 1500);
        });
    } catch (error) {
      this.toastr.error(error);
    }
    // } else {
    //   this.loaderShow = false;
    //   this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    //   this.productBlocks = JSON.parse(sessionStorage.getItem('productBlocks'));
    //   console.log(this.productBlocks);
    //   this.showProductBlocks = true;
    // }
  }

  loadAllActiveProductBlocksOrders(productBlockID, productBlockName) {
    try {
      this.router.navigate(
        ["/productblocksorders", productBlockID, productBlockName],
        { replaceUrl: false }
      );
      let productBlockNamestr = productBlockName;
      productBlockNamestr = productBlockNamestr.toLowerCase();
      sessionStorage.setItem(
        "ProductBlockName",
        productBlockNamestr.replace(/\b\w/g, (l) => l.toUpperCase())
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }

  loadAllHomeCategoryProduct() {
    try {
      this.router.navigate(
        ["/search", this.homeCategoryID, this.homeCategoryName],
        { replaceUrl: false }
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }
  next(productBlock) {
    this.loaderShow = true;
    productBlock.start =
      productBlock.start + environment.productBlockDisplayCount;
    productBlock.end = productBlock.end + environment.productBlockDisplayCount;
    this.loaderShow = false;
  }
  pre(productBlock) {
    this.loaderShow = true;
    productBlock.start =
      productBlock.start - environment.productBlockDisplayCount;
    productBlock.end = productBlock.end - environment.productBlockDisplayCount;
    this.loaderShow = false;
  }

  nextCategoryproduct(_productBlock) {
    this.loaderShow = true;
    this.categoryProductCountStart =
      this.categoryProductCountStart + environment.productBlockDisplayCount;
    this.categoryProductCountEnd =
      this.categoryProductCountEnd + environment.productBlockDisplayCount;
    this.categoryProductStartCount =
      this.categoryProductStartCount + environment.productBlockDisplayCount;
    this.categoryProductEndCount =
      this.categoryProductEndCount + environment.productBlockDisplayCount;
    this.loaderShow = false;
  }
  preCategoryproduct(_productBlock) {
    this.loaderShow = true;
    this.categoryProductCountStart =
      this.categoryProductCountStart - environment.productBlockDisplayCount;
    this.categoryProductCountEnd =
      this.categoryProductCountEnd - environment.productBlockDisplayCount;
    this.categoryProductStartCount =
      this.categoryProductStartCount - environment.productBlockDisplayCount;
    this.categoryProductEndCount =
      this.categoryProductEndCount - environment.productBlockDisplayCount;
    this.loaderShow = false;
  }
}
