import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { ProductService } from 'app/linknet-common/restful-services/restful.service';
import { ToastService } from '../../../typescripts/pro/alerts';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss']
})

export class CreateFolderComponent implements OnInit {
  @Output() refreshfolder = new EventEmitter();
  public folderForm: any;
  public translatedMessage: any;
  public showUpdateButton: any;

  constructor(private formBuilder: FormBuilder, private _httpProducts: ProductService, private globalService: GlobalService,
    private translate: TranslateService, private toastService: ToastService, private toastr: ToastrService) {
    try {
      this.folderForm = this.formBuilder.group({
        'FolderTitle': ['', [Validators.required]]
      });
    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnInit() {
  }

  createFolder() {
    if (this.folderForm.value.FolderTitle !== '') {
      try {
        const logInData = this.globalService.getLoggedInData();
        if (logInData) {
          const param = {
            'FolderTitle': this.folderForm.value.FolderTitle,
            'Type': 'USER',
            'UserID': this.globalService.getLoggedInData().UserID
          };
          this._httpProducts.createFolder(param).subscribe(
            (data: any[]) => {
              if (data) {
                this.refreshfolder.emit();
               // this.translatedMessage = (this.translate.get('FOLDERS.FOLDER_CREATE_SUCCESS'));
                this.translate.get('FOLDERS.FOLDER_CREATE_SUCCESS').subscribe((res: string) => {
                  this.translatedMessage = res;
                });
                this.toastr.success(this.translatedMessage);
                this.clear();
              } else {
               // this.translatedMessage = (this.translate.get('FOLDERS.FOLDER_ALREADY_EXISTS'));
                this.translate.get('FOLDERS.FOLDER_ALREADY_EXISTS').subscribe((res: string) => {
                  this.translatedMessage = res;
                });
                this.toastr.error(this.translatedMessage);
              }
            }, error => {
              this.toastr.error(error);
            });
        } else {
         // this.translatedMessage = (this.translate.get('FOLDERS.PLEASE_LOGIN_TO_CREATE_FOLDER'));
          this.translate.get('FOLDERS.PLEASE_LOGIN_TO_CREATE_FOLDER').subscribe((res: string) => {
            this.translatedMessage = res;
          });
          this.toastr.error(this.translatedMessage);
        }
      } catch (error) {
        this.toastr.error(error);
      }
    }
  }

  clear() {
    this.folderForm = this.formBuilder.group({
      'FolderTitle': ['', [Validators.required]]
    });
  }
}
