import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShopCommonRoutingModule } from "./linknet-common-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MDBBootstrapModule } from "../typescripts/free";
import { LoginComponent } from "./login/login.component";
import { ToastModule } from "../typescripts/pro/alerts";
import { HttpClient } from "@angular/common/http";
import { HttpClientModule } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SocialShareComponent } from "./social-share/social-share.component";
import { GlobalService, CurrencySetterService, MessageDataService} from "./globals/global.service";
import { CommonServicesService } from "./common-services/common-services.service";
import { WebshopService, CategoryService, OrdersService, ProductService, SecurityService, MessageService, CampaignService, VoucherService} from "./restful-services/restful.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ValidationService, ControlMessagesComponent} from "./validators/index";
import { PaginationComponent } from "./pagination/pagination.component";
import { MDBBootstrapModulePro } from "../typescripts/pro";
import { MessageCenterComponent } from "./message-center/message-center.component";
import { ViewComponent } from "./message-center/view/view.component";
import { ListComponent } from "./message-center/list/list.component";
import { ComposeComponent } from "./message-center/compose/compose.component";
import { FileUploaderComponent } from "./file-upload/file-uploader/file-uploader.component";
import { ListFilesComponent } from "./file-upload/list-files/list-files.component";
import { NgUploaderModule } from "ngx-uploader";
import { NgxMyDatePickerModule } from "ngx-mydatepicker";
import { GetExtensionPipe } from "../get-extension.pipe";
import { AdvancedSearchComponent } from "./advanced-search/advanced-search.component";
import { HeadingComponent } from "./heading/heading.component";
import { ShareButtonsModule } from "ngx-sharebuttons";
import { LoadersComponent } from "./loaders/loaders.component";
import { NgxEditorModule } from "ngx-editor";
import { AuthGuardService } from "app/linknet-common/auth-guard.service";
import { TitleComponent } from "./heading/title/title.component";
import { UnreadCountComponent } from "./message-center/unread-count/unread-count.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { NG_SELECT_DEFAULT_CONFIG } from "@ng-select/ng-select";
import { HeaderComponent } from "./header/header.component";
import { SignupComponent } from "./signup/signup.component";
import { SignupShopComponent } from "./signup-shop/signup-shop.component";
import { NgPipesModule } from "ngx-pipes";
import { UserDataPolicyComponent } from "./user-data-policy/user-data-policy.component";
import { TermsConditionComponent } from "./terms-condition/terms-condition.component";
import { FileUploaderNewComponent } from "./file-upload/file-uploader-new/file-uploader-new.component";
import { NgxCaptchaModule } from "ngx-captcha";
import { MaintenanceComponent } from './maintenance/maintenance.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, "./assets/i18n/", ".json");
}

@NgModule({
  imports: [
    CommonModule,
    ShopCommonRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    MDBBootstrapModulePro.forRoot(),
    ToastModule.forRoot(),
    NgbModule.forRoot(),
    ShareButtonsModule.forRoot(),
    NgUploaderModule,
    NgxEditorModule,
    NgxMyDatePickerModule.forRoot(),
    NgSelectModule,
    NgPipesModule,
    HttpClientModule,
    NgxCaptchaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    LoginComponent,
    ControlMessagesComponent,
    PaginationComponent,
    SocialShareComponent,
    MessageCenterComponent,
    ViewComponent,
    ListComponent,
    ComposeComponent,
    FileUploaderComponent,
    FileUploaderNewComponent,
    ListFilesComponent,
    GetExtensionPipe,
    AdvancedSearchComponent,
    HeadingComponent,
    LoadersComponent,
    MaintenanceComponent,
    TermsConditionComponent,
    TitleComponent,
    UnreadCountComponent,
    HeaderComponent,
    SignupComponent,
    SignupShopComponent,
    UserDataPolicyComponent,
    FileUploaderNewComponent,
  ],
  providers: [
    VoucherService,
    WebshopService,
    CategoryService,
    OrdersService,
    ProductService,
    SecurityService,
    GlobalService,
    CurrencySetterService,
    MessageDataService,
    ValidationService,
    CommonServicesService,
    AuthGuardService,
    MessageService,
    CampaignService,
    {
      provide: NG_SELECT_DEFAULT_CONFIG,
      useValue: {
        notFoundText: "Loading..",
      },
    },
  ],
  exports: [
    LoginComponent,
    ToastModule,
    ControlMessagesComponent,
    PaginationComponent,
    SocialShareComponent,
    FileUploaderComponent,
    FileUploaderNewComponent,
    ListFilesComponent,
    AdvancedSearchComponent,
    HeadingComponent,
    NgxMyDatePickerModule,
    LoadersComponent,
    UnreadCountComponent,
    HeaderComponent,
    SignupComponent,
    SignupShopComponent,
    TermsConditionComponent,
    UserDataPolicyComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class ShopCommonModule {}
