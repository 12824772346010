import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { SecurityService, WebshopService } from '../restful-services/restful.service';
import { ValidationService } from '../validators/index';
import { Router } from '@angular/router';
import { ToastService } from '../../typescripts/pro/alerts';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import 'rxjs/Rx';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs/Rx';
import { environment } from 'environments/environment';

@Component({
  selector: 'shop-app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  currencyDropDownClicked: string;
  langaugeDropDownClicked: string;
  dropDownClicked: string;
  message: string;
  indexForCurrency: any;
  @Input() isSetting: any;
  @Input() isLoginPage: any;
  @ViewChild('termsAndCondition') public termsAndCondition;
  @ViewChild('signUpSuccess') public signUpSuccess;
  @ViewChild('payFail') public payFail;
  // @ViewChild('userDataPolicyModal') public userDataPolicyModal;
  public currentStepTitle: string;
  public step1: boolean;
  public step2: boolean;
  public step3: boolean;
  public step4: boolean;
  public countries: Array<any>;
  public register: any;
  public customerID: string;
  public userName: string;
  public languageID: string;
  public optionsSelect: Array<any>;
  public optionsSelectSalutation: Array<any>;
  public signupFormStep1: any;
  public signupFormStep2: any;
  public signupFormStep3: any;
  public optionsSelectLanguage: Array<any>;
  public optionsSelectCurrency: Array<any>;
  public availableLanguages: any;
  public userImageURL: any = null;
  public userImageName: any = null;
  public panImageName: any = null;
  public gstImageName: any = null;
  public rocImageName: any = null;
  public imageTypeName: any = null;
  public isVatRequired: any;
  public translatedMessage: any;
  public userDetail: any;
  public isSettingPage: any;
  public loggedInData: any;
  public priFillLanguage: any;
  public priFillCountry: any;
  public priFillDeliveryCountry: any;
  public postParams: any;
  public availableCurrencies: any;
  public selectedCurrency: any;
  public termsAndConditionText: any;
  public agreeTermsConditionsStatus: boolean = false;
  public agreeTermsConditionsStatusCheckbox: boolean = false;
  public customOptions = { allowedContentTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif','application/pdf'] };
  registerParameters: Object;
  public userResponseData: any;
  public postalCodeErrorCount = 0;
  public postalCodeDeliveryCountryErrorCount = 0;
  public loaderShow: boolean = false;
  public kycStatus: boolean = false;
  public isParentCustomer: boolean = false;
  public isVoucherKart: boolean = false;
  /** new-code => Check webshop child name to display Email/Username field in SignUp Form */
  public showEmailLabel: boolean = false;
  /** new-code => Check webshop child name to display Email/Username field in SignUp Form */
  public mobileotpValidated: boolean = false;
  public customerImage: any;
  public otpSection:boolean = false;
  public mobileotpSection:boolean = false;
  public otpValidated:boolean = false;
  isMobile: boolean;
  isSpringBoard: boolean = false;
  subscriptionPlanId: any;
  btnSelect: any = 2;
  dealCode: string;
  @ViewChild('avatarform') public avatarLoginform;
  public goldenberryStatus: boolean = false;
  planList: any;
  dealSuccess: boolean = false;
  transactionID: any;
  hidePayment: boolean;
  payFailed: boolean = false;
  webshopDetail: any;
  isZydus: boolean = false;

  constructor(public router: Router,
    public _httpSecurity: SecurityService, public _httpWebshop: WebshopService,
    public _httpWebshopService: WebshopService, private toastService: ToastService, public formBuilder: FormBuilder,
    private translate: TranslateService, public globalService: GlobalService, private toastr: ToastrService) {
      
      this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if (/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(navigator.userAgent)) {
				this.isMobile = true;
			} else {
				this.isMobile = false;
			}
      let url1 = this.getParameterByName('IsSb91',  window.location.href);
      if(url1 && url1 === "Y"){
        this.isSpringBoard = true;
      }
    try {
      this._httpWebshopService.currentCurrency.subscribe(message => this.message = message)
      this.currentStepTitle = 'GENERAL_INFORMATION';
      this.register = {};
      this.optionsSelectLanguage = [];
      this.optionsSelectSalutation = [
        {
          'value': 'CAPT',
          'label': 'CAPT'
        },
        {
          'value': 'DR',
          'label': 'DR'
        },
        {
          'value': 'MR',
          'label': 'MR'
        },
        {
          'value': 'MRS',
          'label': 'MRS'
        },
        {
          'value': 'MISS',
          'label': 'MISS'
        },
        {
          'value': 'MS',
          'label': 'MS'
        }
    ];
      this.customerImage = this.globalService.webShopSettings().ImageURL;
      if( this.webshopDetail.DomainName === "zyduswellness"){
        this.isZydus = true;
      }
      if(!this.isZydus){
        this.signupFormStep2 = this.formBuilder.group({
          'DeliveryCompanyName': [''],
          'DeliveryContactPerson': ['', [Validators.required]],
          'DeliveryPhoneNumber': ['', [Validators.required, Validators.maxLength(25)]],
          'Email': ['', [Validators.required, ValidationService.emailValidator]],
          'EmailForInvoices': [''],
          'DeliveryEmail': ['', [Validators.required, ValidationService.emailValidator]],
          'Address': ['', [Validators.required]],
          'Address2': [''],
          'Address3': [''],
          'City': ['', [Validators.required]],
          'PostalCode': ['', [Validators.required]],
          'Country': [null, [Validators.required]],
          'DeliveryAddress': ['', [Validators.required]],
          'DeliveryAddress2': [''],
          'DeliveryAddress3': [''],
          'DeliveryCity': ['', [Validators.required]],
          'DeliveryPostalCode': ['', [Validators.required]],
          'DeliveryCountry': [null, [Validators.required]],
          'Checked': [''],
          'emailOTP': ['']
        });
      }else{
        this.signupFormStep2 = this.formBuilder.group({
          'DeliveryCompanyName': [''],
          'DeliveryContactPerson': [''],
          'DeliveryPhoneNumber': ['', [Validators.maxLength(25)]],
          'Email': ['', [ValidationService.emailValidator]],
          'EmailForInvoices': [''],
          'DeliveryEmail': ['', [ValidationService.emailValidator]],
          'Address': [''],
          'Address2': [''],
          'Address3': [''],
          'City': [''],
          'PostalCode': [''],
          'Country': [null],
          'DeliveryAddress': [''],
          'DeliveryAddress2': [''],
          'DeliveryAddress3': [''],
          'DeliveryCity': [''],
          'DeliveryPostalCode': [''],
          'DeliveryCountry': [null],
          'Checked': [''],
          'emailOTP': ['']
        });
      }

      this.signupFormStep3 = this.formBuilder.group({
        'VAT': [''],
        'Comment': [''],
        'eanNumber': ['']
        //  'AgreeTermsConditions': ['', [Validators.required]],
        //  'UserDataPolicy': ['', [Validators.required]],
      });
      setTimeout(() => {
        const availableLang = JSON.parse(sessionStorage.getItem('availableLang'));
        if (availableLang !== null) {
          this.optionsSelectLanguage = [];
          this.availableLanguages = availableLang;
          this.availableLanguages.forEach(element => {
            const langVal = {
              'value': element.LanguageID,
              'label': element.Name,
            };
            this.optionsSelectLanguage.push(langVal);
          });
        } else {
          this.getAllLanguages();
        }
      }, 100);
    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnInit() {
    const domainUrl = window.location.host;
    // tslint:disable-next-line:quotemark

    try {
      this.optionsSelectCurrency = [];
      // console.log(this.optionsSelectCurrency)
      const availableCurrency = sessionStorage.getItem('availableCurrencies');
      if (availableCurrency !== null) {
        this.availableCurrencies = JSON.parse(sessionStorage.getItem('availableCurrencies'));
        this.setAvailableCurrencies(this.availableCurrencies);
      } else {
        this._httpWebshop.getCurrencies().subscribe(
          (data: any[]) => {
            this.availableCurrencies = data;
            this.setAvailableCurrencies(this.availableCurrencies);
          },
          error => {
            this.toastr.error(error);
          });
      }
    } catch (error) {
      this.toastr.error(error);
    }
    if (this.isSetting === undefined) {
      this.signupFormStep1 = this.formBuilder.group({
        'Salutation': [null, [Validators.required]],
        'FirstName': ['', [Validators.required]],
        'LastName': ['', [Validators.required]],
        'UserName': ['', [Validators.required]],
        'Password': ['', [Validators.required, ValidationService.passwordValidator]],
        'JobPosition': [''],
        'Department': [''],
        'Confirmpassword': ['', [Validators.required]],
        'Language': [1, [Validators.required]],
        'CurrencyID': [20, [Validators.required]],
        'DeliveryInstructions': [''],
        'PhoneNumber': ['', [Validators.required, Validators.maxLength(25)]],
        'CompanyName': ['', [Validators.required]],
        'mobileOTP': ['']
      });
    } else {
      this.currentStepTitle = 'USER_INFORMATION';
      this.isSettingPage = true;
      const userSettings = this.globalService.getLoggedInData();
      this.priFillLanguage = userSettings.LanguageID;
      this.priFillCountry = userSettings.Country;
      this.priFillDeliveryCountry = userSettings.DeliveryCountry;
      this.agreeTermsConditionsStatus = userSettings.isTermsAndPolicyAccepted;
      this.agreeTermsConditionsStatusCheckbox = userSettings.isTermsAndPolicyAccepted;
      this.signupFormStep1 = this.formBuilder.group({
        'Salutation': [userSettings.Salutation, [Validators.required]],
        'FirstName': [userSettings.FirstName, [Validators.required]],
        'LastName': [userSettings.LastName, [Validators.required]],
        'UserName': [userSettings.UserName, [Validators.required]],
        'JobPosition': [userSettings.JobPosition],
        'Department': [userSettings.Department],
        'Language': [userSettings.LanguageID, [Validators.required]],
        'CurrencyID': [userSettings.CurrencyID, [Validators.required]],
        'DeliveryInstructions': [userSettings.DeliveryInstructions],
        'PhoneNumber': [userSettings.PhoneNumber.substring(0, 25), [Validators.required]],
        'CompanyName': [userSettings.CompanyName, [Validators.required]],
      });
      this._httpWebshop.currentCurrencyByID.subscribe(message =>
        this.signupFormStep1.patchValue({
          CurrencyID: message ? message : userSettings.CurrencyID,
        }, { onlySelf: false, emitEvent: false }))
      if(!this.isZydus){
        this.signupFormStep2 = this.formBuilder.group({
          'DeliveryCompanyName': [userSettings.DeliveryCompanyName],
          'DeliveryContactPerson': [userSettings.DeliveryContactPerson, [Validators.required]],
          'DeliveryPhoneNumber': [userSettings.DeliveryPhoneNumber.substring(0, 25), [Validators.required]],
          'Email': [userSettings.Email, [Validators.required, ValidationService.emailValidator]],
          'EmailForInvoices': [userSettings.EmailForInvoices],
          'DeliveryEmail': [userSettings.DeliveryEmail, [Validators.required, ValidationService.emailValidator]],
          'Address': [userSettings.Address, [Validators.required]],
          'Address2': [userSettings.Address2],
          'Address3': [userSettings.Address3],
          'City': [userSettings.City, [Validators.required]],
          'PostalCode': [userSettings.PostalCode, [Validators.required]],
          'Country': [userSettings.Country, [Validators.required]],
          'DeliveryAddress': [userSettings.DeliveryAddress, [Validators.required]],
          'DeliveryAddress2': [userSettings.DeliveryAddress2],
          'DeliveryAddress3': [userSettings.DeliveryAddress3],
          'DeliveryCity': [userSettings.DeliveryCity, [Validators.required]],
          'DeliveryPostalCode': [userSettings.DeliveryPostalCode, [Validators.required]],
          'DeliveryCountry': [userSettings.DeliveryCountry, [Validators.required]],
          'Checked': [userSettings.isSameAsInvoiceAddress],
          'KYCStatus': [userSettings.KYCStatus]
        });
      }else{
        this.signupFormStep2 = this.formBuilder.group({
          'DeliveryCompanyName': [userSettings.DeliveryCompanyName],
          'DeliveryContactPerson': [userSettings.DeliveryContactPerson],
          'DeliveryPhoneNumber': [userSettings.DeliveryPhoneNumber.substring(0, 25)],
          'Email': [userSettings.Email, [ValidationService.emailValidator]],
          'EmailForInvoices': [userSettings.EmailForInvoices],
          'DeliveryEmail': [userSettings.DeliveryEmail, [ValidationService.emailValidator]],
          'Address': [userSettings.Address],
          'Address2': [userSettings.Address2],
          'Address3': [userSettings.Address3],
          'City': [userSettings.City],
          'PostalCode': [userSettings.PostalCode],
          'Country': [userSettings.Country],
          'DeliveryAddress': [userSettings.DeliveryAddress],
          'DeliveryAddress2': [userSettings.DeliveryAddress2],
          'DeliveryAddress3': [userSettings.DeliveryAddress3],
          'DeliveryCity': [userSettings.DeliveryCity],
          'DeliveryPostalCode': [userSettings.DeliveryPostalCode],
          'DeliveryCountry': [userSettings.DeliveryCountry],
          'Checked': [userSettings.isSameAsInvoiceAddress],
          'KYCStatus': [userSettings.KYCStatus]
        });
      }
      this.signupFormStep3 = this.formBuilder.group({
        'VAT': [userSettings.VAT],
        'Comment': [userSettings.Comment],
        'eanNumber': [userSettings.EANNumber]
      });
      this.kycStatus = userSettings.KYCStatus;
    }
    try {
    
      this.step1 = true;
      this.customerID = JSON.parse(sessionStorage.getItem('webshopDetail')).CustomerID;
      this._httpWebshopService.getAllCountries().subscribe(
        (data: any[]) => {
          this.optionsSelect = [];
          this.countries = data;
          this.countries.forEach(elementForCountry => {
            const countryVal = {
              'value': elementForCountry.Alpha2Code,
              'label': elementForCountry.Name,
            };
            this.optionsSelect.push(countryVal);
          });
        },
        error => {
          // this.toastService.error(JSON.parse(error._body).Message);
          this.toastr.error(error.error.Message);
        });
    } catch (error) {
      this.toastr.error(error);
    }

    /** new-code => Check webshop child name to display Email/Username field in SignUp Form */
    const domainName = this._httpWebshopService.getSubdomain(window.location.hostname);
    // const customerName = JSON.parse(sessionStorage.getItem('webshopDetail')).Name;
    const customerName = JSON.parse(sessionStorage.getItem('webshopDetail')).CustomerID;
   
    if (Number(customerName) === 45) {
      this.showEmailLabel = true;
    }
    if(this.webshopDetail.ParentCustomerDomainName === this.webshopDetail.DomainName){
      this.isParentCustomer = true;
    }else{
      this.isParentCustomer = false;
    }
    if(this.webshopDetail.DomainName === "voucherkart"){
      this.isVoucherKart = true;
    }
    /** new-code => Check webshop child name to display Email/Username field in SignUp Form */

    if(sessionStorage.getItem('signuppay') && sessionStorage.getItem('signuppay') === this.webshopDetail.DomainName){
      this.step1 = false;
      this.step4 = true;
      sessionStorage.removeItem('signuppay')
      this.getPlans();
    }
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    //console.log("charCode : ", charCode);
    if ((charCode < 48 || charCode > 58)) {
      return false;
    }
    return true;
  }

  getParameterByName(name, url = '') {
    if (url === '') url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  onInputChange(event: any) {
    const regex = /[^a-zA-Z]/g;
    const input = event.target.value;
    event.target.value = input.replace(regex, '');
  }

  onInputAN(event: any) {
    const regex = /[^a-zA-Z0-9@.]/g;
    const input = event.target.value;
    event.target.value = input.replace(regex, '');
  }
  
  setAvailableCurrencies(availableCurrencies) {
    this.selectedCurrency = JSON.parse(sessionStorage.getItem('userSettings')).CurrencyID;
    this.indexForCurrency = (availableCurrencies.map(function (e) { return e.CurrencyID; }).indexOf(this.selectedCurrency));

    availableCurrencies.forEach(element => {
      const currVal = {
        'value': element.CurrencyID,
        'label': element.Name,
      };
      this.optionsSelectCurrency.push(currVal);
    });
    // console.log(this.optionsSelectCurrency);
  }
  // showUserDataPolicyModal() {
  //   try {
  //     this.userDataPolicyComponent.showUserDataPolicyModal();
  //   } catch (error) {
  //     this.toastService.error(error);
  //   }
  // }
  agreeTermsConditions(data) {
    if (data.currentTarget.checked) {
      this.agreeTermsConditionsStatus = true;
    } else {
      this.agreeTermsConditionsStatus = false;
    }
  }

  openTermAndConditionModel() {
    this.termsAndCondition.show()
    const webShopSettings = this.globalService.webShopSettings();
    this.termsAndConditionText = webShopSettings.CustomersConditionText
  }

  checkVATRequired(ev) {
    if (ev !== '') {
      try {
        this.countries.forEach(elementForVaT => {
          if (elementForVaT.Alpha2Code === ev.value) {
            this.isVatRequired = elementForVaT.isVATNumberMandatory
          }
        });
      } catch (error) {
        this.toastr.error(error);
      }
    }
  }

  sameDeliveryAddress(ev) {
    try {
      this.postalCodeDeliveryCountryErrorCount = 0 // code to reset count for validate delivery postal code
      if (ev.currentTarget.checked === true) {
        this.signupFormStep2.patchValue({
          DeliveryPhoneNumber: this.signupFormStep1.value.PhoneNumber.substring(0, 25),
          DeliveryCompanyName: this.signupFormStep1.value.CompanyName,
          DeliveryEmail: this.signupFormStep2.value.Email,
          DeliveryCountry: this.signupFormStep2.value.Country,
          DeliveryAddress: this.signupFormStep2.value.Address,
          DeliveryAddress2: this.signupFormStep2.value.Address2,
          DeliveryAddress3: this.signupFormStep2.value.Address3,
          DeliveryCity: this.signupFormStep2.value.City,
          DeliveryPostalCode: this.signupFormStep2.value.PostalCode,
          DeliveryContactPerson: this.signupFormStep1.value.FirstName+' '+this.signupFormStep1.value.LastName,
          DeliveryInstructions: this.signupFormStep1.value.DeliveryInstructions
        }, { onlySelf: false, emitEvent: false });
      } else {
        this.signupFormStep2.patchValue({
          DeliveryPhoneNumber: '',
          DeliveryCompanyName: '',
          DeliveryEmail: '',
          DeliveryCountry: '',
          DeliveryAddress: '',
          DeliveryAddress2: '',
          DeliveryAddress3: '',
          DeliveryCity: '',
          DeliveryPostalCode: '',
          DeliveryContactPerson: '',
          DeliveryInstructions: ''
        }, { onlySelf: false, emitEvent: false });
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  startsWithSearchFn(term: string, item) {
    term = term.toString().toLowerCase();
    return item.label.toLowerCase().lastIndexOf(term, 0) === 0;
  }

  saveUserData(action) {
    let imgCount = 0;
    let languageID = this.signupFormStep1.value.Language;
    let country = this.signupFormStep2.value.Country;
    let deliveryCountry = this.signupFormStep2.value.DeliveryCountry;
    if (this.signupFormStep1.value.Language) {
      languageID = this.signupFormStep1.value.Language.value;
    }
    if (this.signupFormStep2.value.Country) {
      country = this.signupFormStep2.value.Country.value;
    }
    if (this.signupFormStep2.value.DeliveryCountry) {
      deliveryCountry = this.signupFormStep2.value.DeliveryCountry.value;
    }
    const userSettings = this.globalService.getLoggedInData();
    try {
      // For Currency ID and Code Set in Session Storage
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      const currencyID = this.signupFormStep1.value.CurrencyID ? this.signupFormStep1.value.CurrencyID : webshopDetail.DefaultCurrencyID;
      if (this.availableCurrencies !== undefined && this.availableCurrencies !== null && this.availableCurrencies !== '') {
        const currencyIndex = (this.availableCurrencies.map(function (e) { return e.CurrencyID; }).indexOf(Number(currencyID)));
        // To Overwrite Currency ID
        this.globalService.overwriteSession('userSettings', 'CurrencyID', currencyID);

        // To Overwrite Currency Code
        this.globalService.overwriteSession('userSettings', 'CurrencyCode', this.availableCurrencies[currencyIndex]['Code']);

        // To Overwrite Currency Symbol
        this.globalService.overwriteSession('userSettings', 'CurrencySymbol', this.availableCurrencies[currencyIndex]['Symbol']);

        // To Overwrite Language Id
        this.globalService.overwriteSession('userSettings', 'LanguageID', languageID);

        // To Overwrite Language Code
        const languageCode = this.availableLanguages.filter((option) => {
          return option.LanguageID === languageID;
        });
        this.globalService.overwriteSession('userSettings', 'LanguageCode', 'en');

        this._httpWebshopService.changeCurrency(this.availableCurrencies[currencyIndex]['Code'])
      }

      let countryName = null;
      const customerName = JSON.parse(sessionStorage.getItem('webshopDetail')).CustomerID;
      let isPaymentByInvoice = false;
      if ((deliveryCountry === 'DK' || deliveryCountry === 'dk') && Number(customerName) === 1) {
        isPaymentByInvoice = true;
      }
      if (deliveryCountry) {
        const cnty = this.getCountryName(this.countries, deliveryCountry);
        countryName = cnty.Name;
      }
      if (this.userImageName === null && userSettings !== null) {
        this.userImageName = userSettings.ImageName;
        imgCount++;
      }

      if (this.panImageName === null && userSettings !== null) {
        this.panImageName = userSettings.PanImageName;
        imgCount++;
      }

      if (this.gstImageName === null && userSettings !== null) {
        this.gstImageName = userSettings.GSTImageName;
        imgCount++;
      }

      if (this.rocImageName === null && userSettings !== null) {
        this.rocImageName = userSettings.ROCImageName;
        imgCount++;
      }

      if (this.userImageName === '') {
        this.userImageName = null;
        imgCount--;
      }

      if(imgCount == 4){
        this.kycStatus = true;
      }

      this.postParams = {
        "Salutation": this.signupFormStep1.value.Salutation.trim(),
        "Name": this.signupFormStep1.value.FirstName.trim()+ " "+this.signupFormStep1.value.LastName.trim(),
        "FirstName": this.signupFormStep1.value.FirstName.trim(),
        "LastName": this.signupFormStep1.value.LastName.trim(),
        "UserName": this.signupFormStep1.value.UserName.trim(),
        "JobPosition": this.signupFormStep1.value.JobPosition.trim(),
        "Password": this.signupFormStep1.value.Password,
        "CompanyName": this.signupFormStep1.value.CompanyName.trim(),
        "CustomerID": this.customerID,
        "LanguageID": languageID ? languageID: 1,
        "CurrencyID": currencyID,
        "PhoneNumber": this.signupFormStep1.value.PhoneNumber.trim(),
        "Email": this.signupFormStep2.value.Email.trim(),
        "EmailForInvoices": this.signupFormStep2.value.EmailForInvoices.trim(),
        "Address": this.signupFormStep2.value.Address.trim(),
        "Address2": this.signupFormStep2.value.Address2.trim(),
        "Address3": this.signupFormStep2.value.Address3.trim(),
        "City": this.signupFormStep2.value.City.trim(),
        "PostalCode": this.signupFormStep2.value.PostalCode.trim(),
        "Country": country ? country.trim() : 'IN',
        "DeliveryCompanyName": this.signupFormStep2.value.DeliveryCompanyName.trim(),
        "DeliveryPhoneNumber": this.signupFormStep2.value.DeliveryPhoneNumber.trim(),
        "DeliveryEmail": this.signupFormStep2.value.DeliveryEmail.trim(),
        "DeliveryContactPerson": this.signupFormStep2.value.DeliveryContactPerson.trim(),
        "DeliveryInstructions": this.signupFormStep1.value.DeliveryInstructions,
        "DeliveryAddress": this.signupFormStep2.value.DeliveryAddress.trim(),
        "DeliveryAddress2": this.signupFormStep2.value.DeliveryAddress2.trim(),
        "DeliveryAddress3": this.signupFormStep2.value.DeliveryAddress3.trim(),
        "DeliveryCity": this.signupFormStep2.value.DeliveryCity.trim(),
        "DeliveryPostalCode": this.signupFormStep2.value.DeliveryPostalCode.trim(),
        "DeliveryCountry": deliveryCountry? deliveryCountry.trim(): 'IN',
        "SameAsCompany": true,
        "Department": this.signupFormStep1.value.Department.trim(),
        "Comment": this.signupFormStep3.value.Comment.trim(),
        "EANNumber": this.signupFormStep3.value.eanNumber.trim(),
        "ImageName": this.userImageName,
        "PanImageName": this.panImageName,
        "GSTImageName": this.gstImageName,
        "ROCImageName":this.rocImageName,
        "RoleCode": "User",
        "UserType": "g",
        "FirstLogin": true,
        "VAT": this.signupFormStep3.value.VAT.trim(),
        "PaymentByInvoice": isPaymentByInvoice,
        "PaymentByCard": true,
        "isDeleted": false,
        "CountryName": countryName ? countryName.trim() : 'IN',
        "isSameAsInvoiceAddress": this.signupFormStep2.value.Checked,
        "isTermsAndPolicyAccepted": this.agreeTermsConditionsStatus,
        "KYCStatus": this.kycStatus,
        "IsParentShop": this.isParentCustomer,
        "IsVoucher":this.isVoucherKart,
        "Is91SpringBoard": this.isSpringBoard,
        "SpringBoardPlan": this.isSpringBoard ? this.subscriptionPlanId: null,
        "SpringBoardDealCode": this.dealCode,
        "DomainName": this.webshopDetail.DomainName,
        "zyduswellness": this.webshopDetail.DomainName === "zyduswellness" ? true : false
      };
      Object.keys(this.postParams).forEach((key) => (this.postParams[key] === '') && (this.postParams[key] === null)
        && (this.postParams[key] === undefined) && delete this.postParams[key]);
      if (action === 'update') {
        this.loaderShow = true;
        this._httpWebshopService.getUserData(userSettings.UserID).subscribe(
          (data1: any[]) => {
            this.loaderShow = false;
            this.userResponseData = data1;
            this.postParams.PaymentByInvoice = this.userResponseData.PaymentByInvoice;
            this._httpSecurity.updateUser(this.postParams).subscribe(
              (data: any[]) => {
                if (data) {
                  sessionStorage.removeItem('categoryList');
                  // this.translatedMessage = (this.translate.get('SIGNUP.USER_UPDATE_SCUCESS'));
                  this.translate.get('SIGNUP.USER_UPDATE_SCUCESS').subscribe((res: string) => {
                    this.translatedMessage = res;
                  });
                  this.toastr.success(this.translatedMessage);
                  sessionStorage.setItem('loggedInData', JSON.stringify(data));
                  this.globalService.loggedin(data);
                  this.router.navigate(['/']);
                    // if (this.router.navigate(['/'])) {
                    //   location.reload();
                    // }
                  setTimeout(function () { location.reload(); }, 50);
                  
                  // this.logout()
                }
              }, error => {
                this.loaderShow = false;
                this.toastr.error(error);
              });
          });
      } else {
        this.loaderShow = true;
        this._httpSecurity.registerUser(this.postParams).subscribe(
          (data: any[]) => {
            this.loaderShow = false;
            if (data['IsSuccess']) {
              
              this.translatedMessage = (this.translate.get('SIGNUP.ALERT_USER_REG_SUCCESSFULLY'));
              this.translate.get('SIGNUP.ALERT_USER_REG_SUCCESSFULLY').subscribe((res: string) => {
                this.toastr.success(res);
              });
              if(this.dealSuccess){
                this.signUpSuccess.show({
                  backdrop: 'static',
                  keyboard: false
                });
              }
            } else {
              const requireUsersApproval = JSON.parse(sessionStorage.getItem('webshopDetail')).RequireUsersApproval;
              if (requireUsersApproval) {
                this.translate.get('SIGNUP.ALERT_USER_REG_SUCCESSFULLY_PENDING').subscribe((res: string) => {
                  this.toastr.success(res);
                });
              }
              if(this.dealSuccess){
                this.signUpSuccess.show({
                  backdrop: 'static',
                  keyboard: false
                });
              }
            }
            if(this.dealSuccess){
              this.router.navigate(['/']);
              setTimeout(()=>{
                location.reload();
              },25000)
            }else{
              this.router.navigate(['/']);
              if (this.isLoginPage) {
                location.reload();
              }
            }
          }, error => {
            this.loaderShow = false;
            this.toastr.error(error);
          });
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  saveUserDataPay(){
    let params = JSON.parse(sessionStorage.getItem('signuppayuser'));
    this.loaderShow = true;
    this._httpSecurity.registerUser(params).subscribe(
      (data: any[]) => {
        this.loaderShow = false;
        if (data['IsSuccess']) {
          this.translatedMessage = (this.translate.get('SIGNUP.ALERT_USER_REG_SUCCESSFULLY'));
          this.translate.get('SIGNUP.ALERT_USER_REG_SUCCESSFULLY').subscribe((res: string) => {
            this.toastr.success(res);
          });
        } else {
          const requireUsersApproval = JSON.parse(sessionStorage.getItem('webshopDetail')).RequireUsersApproval;
          if (requireUsersApproval) {
            this.translate.get('SIGNUP.ALERT_USER_REG_SUCCESSFULLY_PENDING').subscribe((res: string) => {
              this.toastr.success(res);
            });
          }
        }
        
        this.signUpSuccess.show({
          backdrop: 'static',
          keyboard: false
        });

        this.router.navigate(['/']);
        setTimeout(()=>{
          location.reload();
        },25000)
        
      }, error => {
        this.loaderShow = false;
        this.toastr.error(error);
      });
  }

  getPlans(){
    this.step3 = false;
    this.step4 = true;
    this.currentStepTitle = '';
    this.loaderShow = true;
    this._httpWebshopService.getallSubscriptions().subscribe(data=>{
      this.loaderShow = false;
      this.planList = data;
      this.subscriptionPlanId = data[2].PlanId;
      let url = this.getParameterByName('Status', window.location.href);
      if(url === 'Y'){
        this.hidePayment = true;
        this.saveUserDataPay()
      }else if(url === 'N'){
        this.payFailed = true;
        this.payFail.show();
      }
    });
  }

  plans(id, i){
    this.subscriptionPlanId = id;
    this.btnSelect = i;
  }

  validatePostalCode() {
    var country = '';
    var postalCode = '';
    if (this.signupFormStep2.controls.Country.value) {
      country = this.signupFormStep2.controls.Country.value.trim();
    }
    if (this.signupFormStep2.controls.PostalCode.value) {
      postalCode = this.signupFormStep2.controls.PostalCode.value.trim()
    }

    var deliveryPostalCode = '';
    var deliveryCountry = '';
    if (this.signupFormStep2.controls.DeliveryCountry.value) {
      deliveryCountry = this.signupFormStep2.controls.DeliveryCountry.value.trim();
    }
    if (this.signupFormStep2.controls.DeliveryPostalCode.value) {
      deliveryPostalCode = this.signupFormStep2.controls.DeliveryPostalCode.value.trim()
    }

    let postalcodeErrorExample = '';
    let postalcodeErrorIs = '';
    let deliveryPostalcodeError = '';
    let invoicePostalcodeError = '';
    let postalcodeProceedText = '';
    this.translate.get('SIGNUP.POSTALCODE_ERROR_EXAMPLE').subscribe((res: string) => {
      postalcodeErrorExample = res;
    });
    this.translate.get('SIGNUP.POSTALCODE_ERROR_IS').subscribe((res: string) => {
      postalcodeErrorIs = res;
    });
    this.translate.get('SIGNUP.DELIVERY_POSTALCODE_ERROR').subscribe((res: string) => {
      deliveryPostalcodeError = res;
    });
    this.translate.get('SIGNUP.INVOICE_POSTALCODE_ERROR').subscribe((res: string) => {
      invoicePostalcodeError = res;
    });
    this.translate.get('SIGNUP.POSTALCODE_PROCEED_TEXT').subscribe((res: string) => {
      postalcodeProceedText = res;
    });

    this.validatePostalCodeCheck(country, postalCode).subscribe((data) => {
      let proceedStatus = false;
      if (data['ValidPostalRegex']) {
        proceedStatus = true;
        // this.proceed(2);
      } else {
        this.postalCodeErrorCount += 1;
        if (Number(this.postalCodeErrorCount) > 1) {
          // this.proceed(2);
          proceedStatus = true;
        } else {
          proceedStatus = false;
          const countryResponse = this.countries.find(item => item.Alpha2Code === country);
          let displayText = '';
          if (data['VaildPostalcode'] !== '' && postalCode && country) {
            displayText = invoicePostalcodeError + countryResponse.Name + ' ' + postalcodeErrorIs + ' ' +
              data['PostalValidMessage'] + '\n  <br/>' + postalcodeErrorExample + data['VaildPostalcode'] + '\n  <br/>' +
              postalcodeProceedText;
              this.toastr.error('Error: ' + displayText, '', { closeButton: true, enableHtml: true });
          } else {
            proceedStatus = true;
          }
        }
      }


      let proceedStatus1 = false;
      this.validatePostalCodeCheck(deliveryCountry, deliveryPostalCode).subscribe((data1) => {
        if (data1['ValidPostalRegex']) {
          //  this.proceed(2);
          proceedStatus1 = true;
        } else {
          this.postalCodeDeliveryCountryErrorCount += 1;
          if (Number(this.postalCodeDeliveryCountryErrorCount) > 1) {
            // this.proceed(2);
            proceedStatus1 = true;
          } else {
            proceedStatus1 = false;
            const countryResponse = this.countries.find(item => item.Alpha2Code === deliveryCountry);
            let displayText = '';
            if (data1['VaildPostalcode'] !== ''&& postalCode && country) {
              displayText = deliveryPostalcodeError + countryResponse.Name + ' ' + postalcodeErrorIs + ' ' +
                data1['PostalValidMessage'] + '\n  <br/>' + postalcodeErrorExample + data1['VaildPostalcode'] + '\n  <br/>' +
                postalcodeProceedText;
              this.toastr.error('Error: ' + displayText, '', { closeButton: true, enableHtml: true });
            } else {
              proceedStatus1 = true;
            }
          }
        }

        if (proceedStatus && proceedStatus1) {
          //console.log("IF this.postalCodeErrorCount : ", this.postalCodeErrorCount);
          //console.log("IF this.postalCodeDeliveryCountryErrorCount : ", this.postalCodeDeliveryCountryErrorCount);
          this.proceed(2);
        } else {
          //console.log("Else this.postalCodeErrorCount : ", this.postalCodeErrorCount);
          //console.log("Else this.postalCodeDeliveryCountryErrorCount : ", this.postalCodeDeliveryCountryErrorCount);
          return false;
        }
      });
    });
  }

  validatePostalCodeCheck(country, postalCode): Observable<any> {
    var subject = new Subject<any>();
    this._httpSecurity.checkPostalCode(country, postalCode).subscribe(
      data => {
        subject.next(data);
      }, error => {
        subject.next(error.error.Message);
      });
    return subject.asObservable();
  }

  validateMobile(){
    const fullname = this.signupFormStep1.value.FirstName + ' '+this.signupFormStep1.value.LastName;
    const data = 'Mobile='+ this.signupFormStep1.value.PhoneNumber.trim() + '&CustomerName='+fullname+'&UserName='+ this.signupFormStep1.value.UserName.trim() + '&language_id=1&CustomerTheme=#f23d49';
    this.loaderShow = true;
    this._httpWebshopService.triggerMobileOTP(data).subscribe((_res)=>{
      this.loaderShow = false;
      this.mobileotpSection= true;
    },_error=>{
      this.toastr.error('Error in generating OTP');
      this.loaderShow = false;
    });
  }

  validateEmail(){
    const fullname = this.signupFormStep1.value.FirstName + ' '+this.signupFormStep1.value.LastName;
    const data = 'Email='+ this.signupFormStep2.value.Email.trim() + '&CustomerName='+fullname+'&UserName='+ this.signupFormStep1.value.UserName.trim() + '&DomainName='+this.webshopDetail.DomainName+'&language_id=1&CustomerTheme=#f23d49';
    this.loaderShow = true;
    this._httpWebshopService.triggerEmailOTP(data).subscribe((_res)=>{
      this.loaderShow = false;
      this.otpSection= true;
    },_error=>{
      this.toastr.error('Error in generating OTP');
      this.loaderShow = false;
    });
  }

  validateMobileOTP(){ 
    /*const fullname = this.signupFormStep1.value.FirstName + ' '+this.signupFormStep1.value.LastName;
    this.mobileotpValidated = true;
    this.mobileotpSection = false;*/
    this.loaderShow = true;
    const data = 'Mobile='+ this.signupFormStep1.value.PhoneNumber.trim() + '&UserName='+ this.signupFormStep1.value.UserName.trim() + '&OTP=' + this.signupFormStep1.value.mobileOTP;
    this._httpWebshopService.validateMobileOTP(data).subscribe((res)=>{
      if(res){
        this.mobileotpValidated = true;
        this.mobileotpSection = false;
        this.loaderShow = false;
      }
    },_error=>{
      this.toastr.error('Error: Please enter the correct OTP');
      this.loaderShow = false;
    });
  }

  validateOTP(){
    const data = 'Email='+ this.signupFormStep2.value.Email.trim() + '&UserName='+ this.signupFormStep1.value.UserName.trim() + '&OTP=' + this.signupFormStep2.value.emailOTP;
    this._httpWebshopService.validateEmailOTP(data).subscribe((res)=>{
      if(res){
        this.otpSection = false;
        this.otpValidated = true;
        this.validatePostalCode();
      }
    },_error=>{
      this.toastr.error('Error: Please enter the correct OTP');
      this.loaderShow = false;
    });
  }

  proceed(nextStep) {
    try {
      switch (nextStep) {
        case 1:
          if (this.signupFormStep1.value.Password === this.signupFormStep1.value.Confirmpassword) {
            this.step1 = false;
            this.step3 = false;
            this.step2 = true;
            this.currentStepTitle = 'ADDRESS';
          } else {
            // this.translatedMessage = (this.translate.get('SIGNUP.NOT_MATCHED'));
            this.translate.get('SIGNUP.NOT_MATCHED').subscribe((res: string) => {
              this.translatedMessage = res;
            });
            this.toastr.error(this.translatedMessage);
          }
          break;
        case 2:
          this.step1 = false;
          this.step2 = false;
          this.step3 = true;
          this.currentStepTitle = 'VAT_INFORMATION';
          break;
        case 'E':
          this.step1 = false;
          this.step2 = false;
          this.step3 = true;
          this.currentStepTitle = (this.isSetting) ? 'USER_INFORMATION' : 'VAT_INFORMATION';
          break;
        case 0:
          this.step2 = false;
          this.step3 = false;
          this.step1 = true;
          this.currentStepTitle = (this.isSetting) ? 'USER_INFORMATION' : 'GENERAL_INFORMATION';
          break;
        default:
          this.step1 = true;
          this.currentStepTitle = (this.isSetting) ? 'USER_INFORMATION' : 'GENERAL_INFORMATION';
          break;
      }
      //console.log("Current Title :", this.currentStepTitle);
      //console.log("Next Step : ", nextStep);
      // tslint:disable-next-line:quotemark
      /*setTimeout(function () { document.getElementById("orangeForm-name").focus(); }, 100);
      // tslint:disable-next-line:quotemark
      setTimeout(function () { document.getElementById("orangeForm-CompanyName").focus(); }, 100);
      setTimeout(function () { document.getElementById('orangeForm-VAT').focus(); }, 100);*/

    } catch (error) {
    }
  }

  setUploadedImageData(ev) {
    try {
      this.userImageURL = ev[2];
      if(ev[3] == 'photo'){
        this.userImageName = ev[1];
    }
      if(ev[3] == 'pan'){
        this.panImageName = ev[1];
    }

    if(ev[3] == 'gst'){
      this.gstImageName = ev[1];
    }

    if(ev[3] == 'roc'){
      this.rocImageName = ev[1];
    }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getCountryName(myArray, nameKey) {
    try {
      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i].Alpha2Code === nameKey) {
          return myArray[i];
        }
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getAllLanguages() {
    try {
      this._httpWebshopService.getLanguages().subscribe(
        (data: any[]) => {
          this.optionsSelectLanguage = [];
          this.availableLanguages = data;
          this.availableLanguages.forEach(element => {
            const langVal = {
              'value': element.LanguageID,
              'label': element.Name,
            };
            this.optionsSelectLanguage.push(langVal);
          });
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }

  // Logout Function
  logout() {
    try {
      // Remove saved data from sessionStorage
      sessionStorage.removeItem('loggedInData');
      // Remove all saved data from sessionStorage
      const webshopDetail = sessionStorage.getItem('webshopDetail');
      sessionStorage.setItem('webshopDetail', webshopDetail)
      this.loggedInData = {};
      this.globalService.loggedin(null);
      this.router.navigate([''], { preserveQueryParams: false, replaceUrl: true });
      const data = JSON.parse(sessionStorage.getItem('webshopDetail'));
      this.globalService.overwriteSession('userSettings', 'LanguageCode', data.DefaultLanguageCode);
      this.globalService.overwriteSession('userSettings', 'LanguageID', data.DefaultLanguageID);
      this.globalService.overwriteSession('userSettings', 'CurrencyCode', data.DefaultCurrencyCode);
      this.globalService.overwriteSession('userSettings', 'CurrencyID', data.DefaultCurrencyID);
      location.reload();
    } catch (error) {
      this.toastr.error(error);
    }
  }

  userExists(postParams) {
    try {
      if (postParams === '' || postParams === null) {

      } else {
        this._httpWebshopService.getExistsUser(postParams).subscribe(
          (data: any[]) => {
            if (Boolean(data) === false) {
              this.translatedMessage = (this.translate.get('SIGNUP.USER_DOESNOT_EXISTS'));
              // this.toastService.error(this.translatedMessage.value);
              this.translate.get('SIGNUP.USER_DOESNOT_EXISTS').subscribe((res: string) => {
                this.toastr.error(res);
              });
              {
                this.signupFormStep1.patchValue({
                  UserName: '',
                }, { onlySelf: false, emitEvent: false });
              }
            }
          })
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  // for Decimal you can use this as
  onlyDecimalNumberKey(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    // tslint:disable-next-line:curly
    if ((charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  userPasswordValid(userName, password) {
    if (userName !== '' && password !== '') {
      if (userName === password) {
        // this.translatedMessage = (this.translate.get('SIGNUP.USER_PASS_NOT_MATCH'));
        this.translate.get('SIGNUP.USER_PASS_NOT_MATCH').subscribe((res: string) => {
          this.translatedMessage = res;
        });
        this.toastr.error(this.translatedMessage);
        this.signupFormStep1.patchValue({
          Password: '',
        }, { onlySelf: false, emitEvent: false });
      }
    }
  }
  goLogin() {
    location.reload()
  }

  activeClass(type) {
    if (type === 'language') {
      this.langaugeDropDownClicked = 'clicked';
    } else {
      this.currencyDropDownClicked = 'clicked';
    }
  }

  deActiveClass(type) {
    if (type === 'language') {
      this.langaugeDropDownClicked = '';
    } else {
      this.currencyDropDownClicked = '';
    }
  }

  onlyNumberKey(event) {
    const pattern = new RegExp('^[0-9\b]+$');
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!pattern.test(key) && event.charCode !== 0) {
      event.preventDefault();
      return false;
    }
  }

  inrFormat(val: number) {
    return Number(val).toLocaleString("en-IN");
  }

  subscriptionBack(){
    this.step4 = false;
    this.step3 = true;
  }

  applyDeal(){
    this.loaderShow = true;
    this._httpWebshopService.signUpDealCode(this.dealCode).subscribe((data:any)=>{
      this.loaderShow = false;
      if(data.IsSuccess){
        this.dealSuccess = true;
      }else{
        this.toastr.error('Please Provide Valid Deal Code')
      }
    });
  }

  makePayment(){
    this._httpWebshopService.subscriptionPayment(this.subscriptionPlanId).subscribe((data:any)=>{
      if(data.IsSuccess){
        this.transactionID = data.TransId;
        this.loaderShow = true;
        this._httpWebshopService.webTinyUrl().subscribe(data =>{
          this.loaderShow = false;
          let webshop = JSON.parse(sessionStorage.getItem('webshopDetail'))
          sessionStorage.setItem('signuppay',webshop.DomainName);
          if(sessionStorage.getItem('signuppayuser')){
            this.postParams = JSON.parse(sessionStorage.getItem('signuppayuser'));
          }else{
          let languageID = this.signupFormStep1.value.Language;
          let country = this.signupFormStep2.value.Country;
          let deliveryCountry = this.signupFormStep2.value.DeliveryCountry;
          const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
          const currencyID = this.signupFormStep1.value.CurrencyID ? this.signupFormStep1.value.CurrencyID : webshopDetail.DefaultCurrencyID;
          this.postParams = {
            'Salutation': this.signupFormStep1.value.Salutation.trim(),
            'Name': this.signupFormStep1.value.FirstName.trim()+ ' '+this.signupFormStep1.value.LastName.trim(),
            'FirstName': this.signupFormStep1.value.FirstName.trim(),
            'LastName': this.signupFormStep1.value.LastName.trim(),
            'UserName': this.signupFormStep1.value.UserName.trim(),
            'JobPosition': this.signupFormStep1.value.JobPosition.trim(),
            'Password': this.signupFormStep1.value.Password,
            'CompanyName': this.signupFormStep1.value.CompanyName.trim(),
            'CustomerID': this.customerID,
            'LanguageID': languageID,
            'CurrencyID': currencyID,
            'PhoneNumber': this.signupFormStep1.value.PhoneNumber.trim(),
            'Email': this.signupFormStep2.value.Email.trim(),
            'EmailForInvoices': this.signupFormStep2.value.EmailForInvoices.trim(),
            'Address': this.signupFormStep2.value.Address.trim(),
            'Address2': this.signupFormStep2.value.Address2.trim(),
            'Address3': this.signupFormStep2.value.Address3.trim(),
            'City': this.signupFormStep2.value.City.trim(),
            'PostalCode': this.signupFormStep2.value.PostalCode.trim(),
            'Country': country.trim(),
            'DeliveryCompanyName': this.signupFormStep2.value.DeliveryCompanyName.trim(),
            'DeliveryPhoneNumber': this.signupFormStep2.value.DeliveryPhoneNumber.trim(),
            'DeliveryEmail': this.signupFormStep2.value.DeliveryEmail.trim(),
            'DeliveryContactPerson': this.signupFormStep2.value.DeliveryContactPerson.trim(),
            'DeliveryInstructions': this.signupFormStep1.value.DeliveryInstructions,
            'DeliveryAddress': this.signupFormStep2.value.DeliveryAddress.trim(),
            'DeliveryAddress2': this.signupFormStep2.value.DeliveryAddress2.trim(),
            'DeliveryAddress3': this.signupFormStep2.value.DeliveryAddress3.trim(),
            'DeliveryCity': this.signupFormStep2.value.DeliveryCity.trim(),
            'DeliveryPostalCode': this.signupFormStep2.value.DeliveryPostalCode.trim(),
            'DeliveryCountry': deliveryCountry.trim(),
            'SameAsCompany': true,
            'Department': this.signupFormStep1.value.Department.trim(),
            'Comment': this.signupFormStep3.value.Comment.trim(),
            'EANNumber': this.signupFormStep3.value.eanNumber.trim(),
            'ImageName': this.userImageName,
            'PanImageName': this.panImageName,
            'GSTImageName': this.gstImageName,
            'ROCImageName':this.rocImageName,
            'RoleCode': 'User',
            'UserType': 'g',
            'FirstLogin': true,
            'VAT': this.signupFormStep3.value.VAT.trim(),
            'PaymentByInvoice': false,
            'PaymentByCard': true,
            'isDeleted': false,
            'CountryName': 'India',
            'isSameAsInvoiceAddress': this.signupFormStep2.value.Checked,
            'isTermsAndPolicyAccepted': this.agreeTermsConditionsStatus,
            'KYCStatus': this.kycStatus,
            'IsParentShop': this.isParentCustomer,
            'IsVoucher':this.isVoucherKart,
            'Is91SpringBoard': this.isSpringBoard,
            'SpringBoardPlan': this.isSpringBoard ? this.subscriptionPlanId: null,
            'SpringBoardDealCode': this.dealCode
          };
          }
          sessionStorage.setItem('signuppayuser', JSON.stringify(this.postParams))
          const domain = this.isParentCustomer ? "Hyperkart" : webshop.DomainName;
          let query = "OrderId="+ this.transactionID +"&customerID="+webshop.CustomerID+"&IsRewardKart=N&Domain="+domain+"&Pre=SB&ReturnURL="+data+"&Email="+this.postParams.Email+"&Mobile="+this.postParams.PhoneNumber;
          const paymentURL = environment.ENVIRONMENT.pbase + 'AtomPayment/AtomPaymentRequest?' + query;
          window.location.href = paymentURL;   
        });
      }
    });
  }

  closeFail(){
    this.payFail.hide();
  }

  closeSuccess(){
    this.signUpSuccess.hide();
    this.router.navigate(['/']);
    setTimeout(()=>{
      location.reload();
    },200)
  }
}
