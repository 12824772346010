import { Component, OnInit, ViewChild } from "@angular/core";
import { OrdersService, WebshopService, ProductService, CampaignService } from "../../linknet-common/restful-services/restful.service";
import { ToastService } from "app/typescripts/pro/alerts";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { GlobalService } from "app/linknet-common/globals/global.service";
import { ToastrService } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";
import { CommonServicesService } from "app/linknet-common/common-services/common-services.service";
import { Subscription } from "rxjs";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "shop-app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
  exportAs: "child",
})
export class HomePageComponent implements OnInit {
  productBlockDisplayCount: any;
  showProductBlocks: boolean;
  productBlocks: any;
  public myInterval: number = 10000;
  public webshopDetail: any;
  public products: any;
  public loaderShow = true;
  public hideDelete: Boolean = true;
  public folderID: any;
  public likedProducts: any;
  public pageIndex: any = 1;
  public pageSize: any = 0;
  public perPageCount: any;
  public showNoRecord: any;
  public totalCount: any;
  public totalPage: any;
  public currentPage = 1;
  public type = "";
  public page: any;
  public homeTitle: any;
  public homeCategoryProduct: any;
  public categoryProductCount: any;
  public homeCategoryName: any;
  public homeCategoryID: any;
  public hideNextButton: Boolean = true;
  public hideJoinOrderNextButton: Boolean = true;
  public joinOrderPageIndex: any = 1;
  public joinOrderPosition: any = 1;
  public joinOrderTotalPage: any = 0;
  public sendData: any;
  public billBoardList: any;
  campaignSearch: Boolean = false;
  parentProductSearch:Boolean = false;
  campaignId: any = '';
  /** new-code => to show filter on home page */
  public showFiltersOnHomePage: Boolean = false;
  public showJoinOrderSectionOnHomePage: Boolean = false;
  public webShopSettings: any;
  public displayFilterStatus: Boolean = false;
  public configProductBlockCount = 0;
  loggedInData: any;
  public balancePoints: any;
  public cartParams: any;
  spinnerShow: Boolean = false;
  isParentCustomer: Boolean = true;
  tempPoints: any;
  tempCampaign: Subscription;
  shoppingCartitemData: any = [];
  public selectedCampaign: any;
  @ViewChild("newsLetterModal") public newsLetterModal;
  public responseFullJoinOrderBlocks;
  webshopDetailTitle: any;
  public campaignList = [];
  public optionsSelectCampaign = [];
  public getShoppingCartIDList: any;
  shoppingCartParams: any;
  isMobile: boolean;
  isVoucherkart: boolean = false;
  isParentShop: boolean = false;
  Productload :boolean =true;
  brandList:any = [];
  customOptions: OwlOptions = {
    loop: false,
    margin: 10,
    autoplay:true,
    dots: false,
    navSpeed: 700,  
    slideBy: 6,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    responsive: {
      0: {
        items: 2,
        slideBy: 2
      },
      480: {
        items: 4,
        slideBy: 4
      },
      940: {
        items: 6,
        slideBy: 5
      }
    },
    nav: true
}
  constructor(
    private commonService: CommonServicesService,
    private cookieService: CookieService,
    public _httpProducts: ProductService,
    public router: Router,
    public _httpOrders: OrdersService,
    public _httpWebshop: WebshopService,
    public toastService: ToastService,
    public campaignService: CampaignService,
    public route: ActivatedRoute,
    public titleService: Title,
    private translate: TranslateService,
    public _httpProductService: ProductService,
    public globalService: GlobalService,
    private toastr: ToastrService,
  ) {
    if (/Android|webOS|iPhone|iPad|iPod|pocket|psp|kindle|avantgo|blazer|midori|Tablet|Palm|maemo|plucker|phone|BlackBerry|symbian|IEMobile|mobile|ZuneWP7|Windows Phone|Opera Mini/i.test(navigator.userAgent)) {
      this.isMobile = true;
  } else {
      this.isMobile = false;
  }
    const webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
    if (webshopDetail.ParentCustomerDomainName === webshopDetail.DomainName) {
      this.isParentCustomer = true;
      this.titleService.setTitle('Parent Shop');
    } else {
      this.isParentCustomer = false;
      this.titleService.setTitle('Child Shop');
      if(webshopDetail.DomainName === "voucherkart"){
        let query = window.location.search;
        this.isVoucherkart = true;
        if(query.indexOf("?Status=Y") !== -1){
        }else{
          // this.titleService.setTitle("Voucherkart");
          this.titleService.setTitle("gift1to1");
          this.router.navigate(['/vouchers']);
        }
      }
    else{
      this.campaignService.getActiveCampaign().subscribe((res) => {
        if (res) {
          const loggedInData = this.globalService.getLoggedInData();
          if(loggedInData){
          this.campaignId = res.CampaignId;
          this.commonService.setPoints(res.BalancePoints);
          this.selectCampaign(res);
         }
        }
      });
    }
   }
    
    try {
      this.folderID = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.FolderID : null;
      this.likedProducts = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.likedProducts : null;
      if (this.folderID || this.likedProducts) {
        this.hideDelete = false;
      }
    } catch (error) {
      this.toastr.error(error);
    }

    this.sendData = {
      "CategoryID": "",
      "ShowPricesIncludingLogoType": null,
      "ProductName": null,
      "Quantity": null,
      "PriceFrom": null,
      "PriceTo": null,
      "ColorID": null,
      "DeliveryDate": null,
      "SortBy": null,
      "MinimumSupplierQualityLevel": null,
      "FilterBatchProducts": true,
      "ProductTagIDs": [],
      "FirstSubcategoryRule": true,
    }
    this.campaignService.setCampaignStatus(false);
  }
  ngAfterViewInit() {
    setTimeout(() =>{
      this.Productload = sessionStorage.getItem('CurrencyCode') ==="ALL" && sessionStorage.getItem('dropCountryName') ==="ALL"
    },1000)
    
    /*this.tempCampaign = this.campaignService.getActiveCampaign().subscribe((res) => {
      if (res) {
        this.commonService.setPoints(res.BalancePoints);
        this.selectCampaign(res);
      }
    });
    this.tempCampaign.unsubscribe();*/
  }


  ngOnInit() {
    if(this.isParentCustomer){
      this.getBrandList();
    }
    this.productBlockDisplayCount = environment.productBlockDisplayCount;
    if (this.webshopDetail) this.isParentShop = this.webshopDetail.DomainName === this.webshopDetail.ParentCustomerDomainName;
    this.commonService.setClearAll(true);
    let p_f = sessionStorage.getItem('priceFrom');
          if(p_f){
            sessionStorage.removeItem('priceFrom');
          }
          let p_t = sessionStorage.getItem('priceTo');
          if(p_t){
            sessionStorage.removeItem('priceTo');
          }

    try {
      /** Code to check Whether Loggedin user is Active/InActive */
      const loggedInData = this.globalService.getLoggedInData();
      if (loggedInData !== null) {
        this._httpWebshop
          .getUserActiveStatus({
            UserID: loggedInData.UserID,
          })
          .subscribe(
            (_data) => {
              if (!this.isParentCustomer) {
                //this.getCampaigns();
              }else{
                this.parentProductSearch = true;
              }
              this.getProductBlocks();
            },
            (error) => {
              if (error.status === 500) {
                try {
                  // Remove saved data from sessionStorage
                  sessionStorage.removeItem("loggedInData");
                  sessionStorage.removeItem("categoryList");
                  sessionStorage.removeItem("colorsList");
                  sessionStorage.removeItem("categoryOptionList");
                  // based on filter section regarding product tags.
                  if (
                    sessionStorage.getItem("productTags") !== null &&
                    sessionStorage.getItem("productTags") !== undefined &&
                    sessionStorage.getItem("productTags") !== ""
                  ) {
                    sessionStorage.removeItem("productTags");
                  }
                  // Remove all saved data from sessionStorage
                  const webshopDetail = sessionStorage.getItem("webshopDetail");
                  sessionStorage.setItem("webshopDetail", webshopDetail);
                  const data = JSON.parse(
                    sessionStorage.getItem("webshopDetail")
                  );
                  this.loggedInData = {};
                  this.globalService.loggedin(null);
                  // this.router.navigate([''], { preserveQueryParams: false, replaceUrl: true });
                  this.globalService.overwriteSession(
                    "userSettings",
                    "LanguageCode",
                    data.DefaultLanguageCode
                  );
                  this.globalService.overwriteSession(
                    "userSettings",
                    "LanguageID",
                    data.DefaultLanguageID
                  );
                  this.globalService.overwriteSession(
                    "userSettings",
                    "CurrencyCode",
                    data.DefaultCurrencyCode
                  );
                  this.globalService.overwriteSession(
                    "userSettings",
                    "CurrencyID",
                    data.DefaultCurrencyID
                  );
                  //  location.reload();
                  setTimeout(() => {
                    sessionStorage.removeItem("webshopDetail");
                    const storageData = {
                      CustomerID: data.CustomerID,
                    };
                    sessionStorage.setItem(
                      "webshopDetail",
                      JSON.stringify(storageData)
                    );
                    sessionStorage.removeItem("themeReApplyStatus");
                  }, 100);
                  const postParams = {
                    userdeactive: true,
                  };
                  setTimeout(() => {
                    this.router.navigate(["/"], {
                      queryParams: postParams,
                      replaceUrl: false,
                    });
                    setTimeout(() => {
                      location.reload();
                    }, 100);
                  }, 100);
                } catch (error) {
                  this.toastr.error(error);
                }
              } else {
                this.toastr.error(error.error.Message);
              }
            }
          );
      }else{
        this.getProductBlocks();
      }

      this.webshopDetailTitle = JSON.parse(
        sessionStorage.getItem("webshopDetail")
      );
      this.titleService.setTitle(this.webshopDetailTitle.CompanyName);
      // this.homeTitle = this.translate.get('HEADING.WEBSHOP').subscribe((res: string) => {
      //   this.titleService.setTitle(this.webshopDetailTitle.CompanyName);
      // });
      this.page = {
        pageIndex: 1,
        pageSize: 20,
      };

      this.webShopSettings = this.globalService.webShopSettings();
      this.webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
      // this.campaignService.getCampaignForUser().subscribe((res) => {
      //   console.log("res", res);
      // });

      if (this.webshopDetail.HomePageCategoryID !== undefined) {
        this.homePageCategoryList();
      }

      /** new-code => to show filter on home page */
      if (this.webshopDetail.ShowFiltersOnHomePage) {
        this.showFiltersOnHomePage = true;
      }
      /** new-code => to show filter on home page */

      /** new-code => to show join order on home page */
      if (this.webshopDetail.ShowJoinOrdersOnHomePage) {
        this.showJoinOrderSectionOnHomePage = true;
      }
      /** new-code => to show join order on home page */

      /** new-code */
      // tslint:disable-next-line:max-line-length
      this.displayFilterStatus =
        this.showFiltersOnHomePage &&
          (this.webShopSettings.ShowAdvancedSearchBranding ||
            this.webShopSettings.ShowAdvancedSearchPrices ||
            this.webShopSettings.ShowSupplierRating ||
            this.webShopSettings.ShowAdvancedSearchColors ||
            this.webShopSettings.ShowSortByFilter)
          ? true
          : false;
      // tslint:disable-next-line:max-line-length
      this.configProductBlockCount = this.isParentShop ? 5 : this.displayFilterStatus ? 4 : 5;
      this.pageSize = this.configProductBlockCount;
      this.productBlockDisplayCount = this.configProductBlockCount;
      /** new-code */
      this.getJoinOrderProducts(this.page, false);
    } catch (error) {
      this.toastr.error(error);
    }
    this.getAllBillBoardList();
    // window.scrollTo(0, 0)
  }
  getBrandList(){
    const loggedInData = this.globalService.getLoggedInData();
    const campaginnew = JSON.parse(sessionStorage.getItem('campaign'))
    let params:any = {};
    let format: boolean;
    if(this.isParentCustomer){
      params.CustomerId = loggedInData.CustomerID;
      format = true;
    }
    this.loaderShow = true;
    this._httpWebshop.getBrandsNew(params,format).subscribe((data: any) => {
      this.loaderShow = false;
      this.brandList = data;
    });
}
  getShoppingCart(){
    
    let getLogged = JSON.parse(sessionStorage.getItem("loggedInData"));
    if (getLogged) {
      this.shoppingCartParams = {
        UserID: getLogged.UserID,
        CustomerID: getLogged.CustomerID,
        CurrencyID: 20,
        UserBrowserID: this.cookieService.get("shoppingCardTokenId"),
        InitialDeliveryCountry: "IN",
        ReturnOnlyVisibleItems: true,
      };
    }
    this.loaderShow = true;
    this._httpWebshop.getShoppingCart(this.shoppingCartParams).subscribe(
      (data: any[]) => {
        this.loaderShow = false;
        let totalPrice = 0;
        this.shoppingCartitemData = [];
        let k = 0;
        let len4 = data["Items"].length;
        for (let i = 0; i < len4; i++) {
          if (data["Items"][i].isVisible) {
            this.shoppingCartitemData[k] = data["Items"][i];
            k++;
          }
        }

        let len2 = this.shoppingCartitemData.length
        this.campaignService.setCartStatus(true)
        this._httpWebshop.setCount(len2);
        for (let i = 0; i < len2; i++) {
          if (this.shoppingCartitemData[i].isChecked) {
            totalPrice = totalPrice + this.shoppingCartitemData[i].Price.TotalProductPrice;
          }
        }
        const getLogged = this.globalService.getLoggedInData();
        
      },
      (_error) => {
        this.loaderShow = false;
      }
    );
  }


  selectCampaign(id) {
    sessionStorage.setItem('campaign', JSON.stringify(id));
    this.getShoppingCart();
    this.campaignId = id.CampaignId;
    this.getAllBillBoardList();
    this.campaignSearch = false;
    this.getProductBlocks();
    setTimeout(()=>{
      this.campaignSearch = true;
    },300)
  }

 

  getJoinOrderProducts(page, _isNavigation) {
    try {
      this.loaderShow = true;
      this.webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
      this._httpOrders
        .getJoinOrdersList(page, this.pageSize, false)
        .subscribe((data: any[]) => {
          this.products = data["ItemsCollection"];
          this.homeCategoryProduct = data["HomePageCategoryProducts"];
          this.categoryProductCount = data["HomePageCategoryProductsCount"];
          this.homeCategoryName = data["HomePageCategoryName"];
          this.homeCategoryID = data["HomePageCategoryID"];
          this.loaderShow = false;
          this.perPageCount = data["PageSize"];
          this.totalCount = data["TotalItemsCount"];
          if (this.totalCount > 0) {
            this.joinOrderTotalPage = Math.ceil(
              this.totalCount / this.productBlockDisplayCount
            );
          }
          // this.getProductBlocks();
          this.showProductBlocks = true;
          sessionStorage.setItem(
            "joinOrderData",
            JSON.stringify(data["ItemsCollection"])
          );
          sessionStorage.setItem(
            "joinOrderCounts",
            JSON.stringify(this.totalCount)
          );
          this.totalPage = Math.ceil(this.totalCount / this.pageSize);
          if (
            data["TotalItemsCount"] === undefined ||
            Number(data["TotalItemsCount"] === 0) ||
            this.webshopDetail.ShowJoinOrdersOnHomePage === false
          ) {
            this.totalCount = 0;
            this.showNoRecord = true;
          }
          this.currentPage = data["PageIndex"];

          setTimeout(() => {
            if (
              this.homeCategoryID !== null &&
              this.homeCategoryID !== undefined &&
              this.homeCategoryID !== "" &&
              this.homeCategoryID !== 0 &&
              this.pageSize < this.categoryProductCount
            ) {
              const postParams = {
                'CategoryID': this.homeCategoryID,
                'ShowPricesIncludingLogoType': null,
                'ProductName': null,
                'Quantity': null,
                'PriceFrom': null,
                'PriceTo': null,
                'ColorID': null,
                'DeliveryDate': null,
                'SortBy': null,
                'MinimumSupplierQualityLevel': null,
                'FilterBatchProducts': true,
                'ProductTagIDs': [],
                'FirstSubcategoryRule': false
              };
              // this._httpProductService.getProducts(page.pageIndex, page.pageSize, postParams).subscribe(
              this._httpProductService
                .getProducts(
                  page.pageIndex,
                  this.categoryProductCount,
                  postParams
                )
                .subscribe((data1: any[]) => {
                  this.totalCount = data["TotalItemsCount"];
                  this.perPageCount = data["PageSize"];
                  this.homeCategoryProduct = data1["ItemsCollection"];
                });
            }
            if (this.totalCount > this.configProductBlockCount) {
              this._httpOrders
                .getJoinOrdersList(page, this.totalCount, false)
                .subscribe((data1: any[]) => {
                  this.responseFullJoinOrderBlocks = data1;
                  this.hideJoinOrderNextButton = false;
                  sessionStorage.setItem(
                    "joinOrderData",
                    JSON.stringify(data1["ItemsCollection"])
                  );
                });
            }
          }, 1500);
        });
    } catch (error) {
      this.toastr.error(error);
    }
  }

  nextJoinOrder(joinOrderBlockPage) {
    this.joinOrderPosition =
      joinOrderBlockPage * environment.productBlockDisplayCount;
    this.loaderShow = true;
    const data = JSON.parse(sessionStorage.getItem("joinOrderData"));
    let k = 0;
    let j = 0;
    for (
      let i = this.joinOrderPosition;
      i < this.joinOrderPosition + this.configProductBlockCount;
      i++
    ) {
      if (data[i] !== undefined) {
        this.products[k] = data[i];
        k++;
        j++;
      } else {
        delete this.products[j];
        j++;
      }
    }
    this.loaderShow = false;
    this.joinOrderPageIndex = this.joinOrderPageIndex + 1;
  }

  preJoinOrder() {
    this.joinOrderPosition =
      this.joinOrderPosition - environment.productBlockDisplayCount;
    this.loaderShow = true;
    const data = JSON.parse(sessionStorage.getItem("joinOrderData"));
    let k = 0;
    let j = 0;
    for (
      let i = this.joinOrderPosition;
      i < this.joinOrderPosition + this.configProductBlockCount;
      i++
    ) {
      if (data[i] !== undefined) {
        this.products[k] = data[i];
        k++;
        j++;
      } else {
        delete this.products[j];
        j++;
      }
    }
    this.loaderShow = false;
    this.joinOrderPageIndex = this.joinOrderPageIndex - 1;
  }

  loadAllActiveLinkOrders() {
    const postParams = {
      joinlinkorders: true,
    };
    this.router.navigate(["/search"], {
      queryParams: postParams,
      replaceUrl: false,
    });
  }


  nextProduct() {
    try {
      this.products = [];
      this.pageIndex = this.pageIndex + 1;
      //  window.sessionStorage.joinOrdersPosition = window.scrollY;
      this.getJoinOrderProducts(this.pageIndex, true);
    } catch (error) {
      this.toastr.error(error);
    }
  }

  previousProduct() {
    try {
      this.products = [];
      this.pageIndex = this.pageIndex - 1;
      //  window.sessionStorage.joinOrdersPosition = window.scrollY;
      this.getJoinOrderProducts(this.pageIndex, true);
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getProductBlocks() {
    this.showProductBlocks = false;
    // if (JSON.parse(sessionStorage.getItem('productBlocks')) === null) {
    try {
      // this.loaderShow = true;
      this.webshopDetail = JSON.parse(sessionStorage.getItem("webshopDetail"));
      this._httpProducts.productBlocks(this.webshopDetail.CustomerID, true, this.campaignId)
        .subscribe((data: any[]) => {
          // this.loaderShow = false;
          this.productBlocks = data;
          // this.productBlocks.unshift({ 'Name': 'Orders you can joi', 'Products': ItemsCollection });
          let len =  this.productBlocks.length;
          for (let i = 0; i < len; i++) {
            this.productBlocks[i].Products = data[i].Products.slice(
              0,
              this.configProductBlockCount
            );
          }
          sessionStorage.setItem("productBlocks", JSON.stringify(this.productBlocks));
          this.showProductBlocks = true;
        });
    } catch (error) {
      this.toastr.error(error);
    }
    // } else {
    //   this.loaderShow = false;
    //   this.webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
    //   this.productBlocks = JSON.parse(sessionStorage.getItem('productBlocks'));
    //   console.log(this.productBlocks);
    //   this.showProductBlocks = true;
    // }
  }

  loadAllActiveProductBlocksOrders(productBlockID, productBlockName) {
    try {
      this.router.navigate(
        ["/productblocksorders", productBlockID, productBlockName],
        { replaceUrl: false }
      );
      let productBlockNamestr = productBlockName;
      productBlockNamestr = productBlockNamestr.toLowerCase();
      sessionStorage.setItem(
        "ProductBlockName",
        productBlockNamestr.replace(/\b\w/g, (l) => l.toUpperCase())
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }

  next(productBlock) {
    this.loaderShow = true;
    productBlock.start = productBlock.start + this.configProductBlockCount;
    productBlock.end = productBlock.end + this.configProductBlockCount;
    this.loaderShow = false;
  }
  pre(productBlock) {
    this.loaderShow = true;
    productBlock.start = productBlock.start - this.configProductBlockCount;
    productBlock.end = productBlock.end - this.configProductBlockCount;
    this.loaderShow = false;
  }
  openLink(billBoard) {
    if(this.isParentCustomer || this.isVoucherkart){
      billBoard.AdURL = billBoard.AdURL.replace(/ /g, "");
      //billBoard.AdURL = billBoard.AdURL.toLowerCase();
      if (billBoard.AdURL !== "" && billBoard.AdURL.indexOf("newsletter") <= 0) {
        window.open(billBoard.AdURL);
      } else if (billBoard.AdURL.indexOf("newsletter") > 0) {
        /* If URL contain Newsletter then NewsLetter Popup Modal Shown */
        this.newsLetterModal.showNewsLetterModal();
      }
    }
  }
  homePageCategoryList() {
    // try {
    //   const params = '';
    //   this._httpProductService.getProducts(1, 20, params).subscribe(
    //     data => {
    //       this.homePageCategoryList = data.ItemsCollection;
    //     });
    // } catch (error) {
    //   this.toastService.error(error);
    // }
  }

  getAllBillBoardList() {
    try {
      let check;
      if(this.isParentCustomer){
       check = this._httpWebshop.getAllBillBoards();
      }else{
        check = this._httpWebshop.getAllCmapaignBillBoards(this.campaignId);
      }
      check.subscribe(
        (data: any[]) => {
          this.billBoardList = data["ItemsCollection"];
          this.billBoardList = this.billBoardList.filter(item => item.VisibleOnLinkNetwork === true);
          //sessionStorage.setItem('billBoardList', JSON.stringify(this.billBoardList));
        },
        (error) => {
          this.toastr.error(error);
        }
      );
    } catch (error) {
      this.toastr.error(error);
    }
  }

}
