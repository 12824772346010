import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { ToastService } from 'app/typescripts/pro/alerts';
import { GlobalService } from '../../../linknet-common/globals/global.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'shop-app-join-order-modified-new',
  templateUrl: './join-order-modified-new.component.html',
  styleUrls: ['./join-order-modified-new.component.scss']
})
export class JoinOrderModifiedNewComponent implements OnInit, OnChanges {
  @Input() productionLineList: any;
  @Input() productDetail: any;
  @Input() productCurrentQty: any;
  @Input() parentCustData: any;
  public productionLineListData: any;
  public joinOrders;
  public qtyChangeValue: any;
  public nextIconStatus: Boolean = true;
  public productionLineListIndex = 0;
  public currency: any;
  public currentProductionLinePrice: any;
  public nextProductionLinePrice: any;
  public productionPieces: any;
  @Input() dynamicProductRangePricesParent: number;
  @Input() productMaxInputValue: any;
  public productionLineData: any;
  public productionLineListLength: any;
  public productLinePosition: number;
  public productionLineDataQty: number;
  public joinOrderPrice: any;
  public currentRange;
  public range;
  public progressBarPercentage;
  public nextPriceDrop;
  public showUpArrow = false;
  public qtyChangeStatus: Boolean = false;
  public isInitialQuantitySet: Boolean = false;
  public isFirstCall: Boolean = true;
  public stopBlinking: Boolean = false;
  @ViewChild('ProductModal') public productModal;
  @ViewChild('MoreInfoModal') public moreInfoModal;
  @ViewChild('PriceMoreInfoModal') public priceMoreInfoModal;
  @Output() shoppingCarts = new EventEmitter();
  @Output() firstTabStatusValueChange = new EventEmitter();
  @Input() shoppingCartData: any;
  @Input() orderQuantity: any;
  webshopDetails: any;
  calculatedPrice: number;
  public logoProductPrintPrice: any;
  public logoProductHandlingCost: any;
  public additionalCostForProduct: any;
  public governmentalCostForProduct: any;
  public totalProductPrice: any;
  public changeDeliveryDateText: Boolean = false;  // for display text as delivery date / Earliest disp. date in HTML Page
  public disableAddtoCartButton: Boolean = false;
  public wsaStatus: Boolean = false;
  isparentCustHide: boolean;

  constructor(private toastService: ToastService, public globalService: GlobalService, private toastr: ToastrService) {
    this.webshopDetails = this.globalService.webShopSettings();
  }

  ngOnInit() {
    try {
      const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
      if(this.parentCustData){
        this.isparentCustHide = true
      }else{
        this.isparentCustHide = false
      }

      if (webshopDetail.ChangeDeliveryDateText) {
        this.changeDeliveryDateText = true;
      }
      if (Number(webshopDetail.CustomerID) === 45) {
        this.wsaStatus = true;
      }

      if (this.shoppingCartData !== undefined) {
        if (!this.isInitialQuantitySet && (this.shoppingCartData.ShoppingCartItemID === ''
          || this.shoppingCartData.ShoppingCartItemID === 0)) {
          this.productCurrentQty = 0;
        }
      }

      if (this.productionLineList) {
        this.productionLineDataQty = this.productionLineList[0].Quantity + this.productCurrentQty;
        this.qtyChangeValue = this.productCurrentQty;
        this.currency = this.globalService.getUserSettings().CurrencyCode;
        this.productionLineListData = this.productionLineList[this.productionLineListIndex];
        this.productionLineListLength = this.productionLineList.length;
        if (this.productionLineListLength === 1) {
          this.nextIconStatus = false;
        }

        /** TO Check Fixed Units */
        if (this.productDetail.FixedUnits) {
          if (this.productCurrentQty === undefined) {
            this.productCurrentQty = 1;
            this.productCurrentQty = Number(this.productDetail.PiecesPerUnit * this.productCurrentQty);
          }
        }

        if (this.productCurrentQty === undefined) {
          this.productCurrentQty = this.productDetail.MinimumOrder;
        }
        // this.ngOnChanges();
      }

      /** new-code => To Enable Checkout Button in First tab when Product Have only one Tab */
      // if (this.productDetail.MinimumOrder > this.productCurrentQty || this.productMaxInputValue < this.productCurrentQty) {
      //   this.firstTabStatusValueChange.emit(false);
      // }
      /** new-code => To Enable Checkout Button in First tab when Product Have only one Tab */

    } catch (error) {
      this.toastr.error(error);
    }
  }

  ngOnChanges(): void {
    if (this.isFirstCall) {
      // If user searched by quantity, put that value as initial quantity on product details page
      const breadCrumbsList = JSON.parse(sessionStorage.getItem('breadCrumbsList'));
      var searchUrl = breadCrumbsList[breadCrumbsList.length - 1].url
      const idxQuantity = searchUrl.indexOf('Quantity=');
      if (idxQuantity >= 0 && searchUrl.length > idxQuantity + 9) {
        searchUrl = searchUrl.substring(idxQuantity);
        var quantityStr = '';
        if (searchUrl.indexOf('&') === -1) {
          quantityStr = searchUrl.substring(9);
        }
        else {
          quantityStr = searchUrl.substring(9, searchUrl.indexOf('&'));
        }
        if (quantityStr !== '') {
          this.productCurrentQty = Number(quantityStr);
          this.isInitialQuantitySet = true;
          if (this.productDetail.FixedUnits) {
            this.productCurrentQty = Math.floor(this.productCurrentQty / this.productDetail.PiecesPerUnit);
          }
        }
      }
      this.isFirstCall = false;
    }

    this.productionLineListData = this.productionLineList[this.productionLineListIndex];
    console.log("productionLineList : ", this.productionLineList);
    console.log("productionLineListIndex : ", this.productionLineListIndex);
    this.qtyChangeStatus = false;
    if (this.qtyChangeValue !== this.productCurrentQty) {
      this.qtyChangeStatus = true;
      /** TO Check Fixed Units */
      if (this.productDetail.FixedUnits) {
        this.productCurrentQty = Number(this.productDetail.PiecesPerUnit * this.productCurrentQty);
      }
      this.qtyChangeValue = this.productCurrentQty;
    }

    /** new-code => To Enable Checkout Button in First tab when Product Have only one Tab */
    if (this.productDetail.MinimumOrder > this.productCurrentQty || this.productMaxInputValue < this.productCurrentQty) {
      this.firstTabStatusValueChange.emit(false);
    }
    /** new-code => To Enable Checkout Button in First tab when Product Have only one Tab */

    let deliveryDate = new Date(moment(this.productionLineListData.DeliveryDate).format('MM-DD-YYYY'));
    let earliestDeliveryDate = new Date(moment(this.productDetail.EarliestDeliveryDate).format('MM-DD-YYYY'));
    const checkDeliveryDate = deliveryDate.getTime();
    const check1EarliestDeliveryDate = earliestDeliveryDate.getTime();
    this.disableAddtoCartButton = false;
    if (Number(checkDeliveryDate) < Number(check1EarliestDeliveryDate)) {
      // this.disableAddtoCartButton = true;
    }
    this.progressChanges();
  }

  progressChanges() {
    if (this.shoppingCartData !== undefined) {
      if (!this.isInitialQuantitySet && (this.shoppingCartData.OrderedQuantity < Number(this.shoppingCartData.MinimumOrder)) && (this.shoppingCartData.ShoppingCartItemID === ''
        || this.shoppingCartData.ShoppingCartItemID === 0)) {
        this.productCurrentQty = (this.shoppingCartData !== undefined) ? this.shoppingCartData.MinimumOrder : 0;
        console.log("Inside condition : ", this.productCurrentQty);
      }
    }

    if (this.productionLineListData !== undefined) {
      this.nextProductionLinePrice = '';
      console.log("productCurrentQty : ", this.productCurrentQty);
      for (let _i = 0; _i < this.productionLineListData.ProductionLinePrices.length; _i++) {
        // tslint:disable-next-line:max-line-length
        if (this.productionLineListData.ProductionLinePrices[_i].MaximumQuantity >= (Number(this.productionLineListData.Quantity) + Number(this.productCurrentQty)) &&
          // tslint:disable-next-line:max-line-length
          (this.productionLineListData.ProductionLinePrices[_i].MinimumQuantity <= (Number(this.productionLineListData.Quantity) + Number(this.productCurrentQty)))) {
          this.currentProductionLinePrice = this.productionLineListData.ProductionLinePrices[_i].Price;

          /** Price range display section => To add printprice, handling cost if product is Fixed logo => START HERE */
          if (this.shoppingCartData.isFixedLogo) {
            // tslint:disable-next-line:max-line-length
            if (this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices.length > 0) {
              for (let j = 0; j < this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices.length; j++) {
                // tslint:disable-next-line:max-line-length
                if (this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices[j] !== null && this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices[j] !== undefined) {
                  if (this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices[j].isFixedLogo) {
                    if (this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices[j].Price !== null
                      && this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices[j].Price !== undefined) {
                      // tslint:disable-next-line:max-line-length
                      this.currentProductionLinePrice += Number(this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices[j].Price);
                    }
                    // tslint:disable-next-line:max-line-length
                    if (this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices[j].HandlingCost !== null && this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices[j].HandlingCost !== undefined) {
                      // tslint:disable-next-line:max-line-length
                      this.currentProductionLinePrice += Number(this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices[j].HandlingCost);
                    }
                  }
                }
              }
            }

            // this.currentProductionLinePrice += Number(this.shoppingCartData.ProductPrintPrices[0].Price);
            // if (this.shoppingCartData.ProductPrintPrices[0].HandlingCost !== undefined &&
            //   this.shoppingCartData.ProductPrintPrices[0].HandlingCost !== null) {
            //     this.currentProductionLinePrice += Number(this.shoppingCartData.ProductPrintPrices[0].HandlingCost);
            // }
          }
          /** Price range display section => To add printprice, handling cost if product is Fixed logo => END HERE */

          if (this.productionLineListData.ProductionLinePrices[_i + 1] !== undefined) {
            this.nextProductionLinePrice = this.productionLineListData.ProductionLinePrices[_i + 1].Price;

            /** Price range display section => To add printprice, handling cost if product is Fixed logo => START HERE */
            if (this.shoppingCartData.isFixedLogo) {
              if (this.productionLineListData.ProductionLinePrices[_i + 1].ProductionLinePrintPrices.length > 0) {
                for (let j = 0; j < this.productionLineListData.ProductionLinePrices[_i + 1].ProductionLinePrintPrices.length; j++) {
                  // tslint:disable-next-line:max-line-length
                  if (this.productionLineListData.ProductionLinePrices[_i + 1].ProductionLinePrintPrices[j] !== null && this.productionLineListData.ProductionLinePrices[_i + 1].ProductionLinePrintPrices[j] !== undefined) {
                    // tslint:disable-next-line:max-line-length
                    if (this.productionLineListData.ProductionLinePrices[_i + 1].ProductionLinePrintPrices[j].isFixedLogo) {
                      // tslint:disable-next-line:max-line-length
                      if (this.productionLineListData.ProductionLinePrices[_i + 1].ProductionLinePrintPrices[j].Price !== null && this.productionLineListData.ProductionLinePrices[_i + 1].ProductionLinePrintPrices[j].Price !== undefined) {
                        // tslint:disable-next-line:max-line-length
                        this.nextProductionLinePrice += Number(this.productionLineListData.ProductionLinePrices[_i + 1].ProductionLinePrintPrices[j].Price);
                      }
                      // tslint:disable-next-line:max-line-length
                      if (this.productionLineListData.ProductionLinePrices[_i + 1].ProductionLinePrintPrices[j].HandlingCost !== null && this.productionLineListData.ProductionLinePrices[_i + 1].ProductionLinePrintPrices[j].HandlingCost !== undefined) {
                        // tslint:disable-next-line:max-line-length
                        this.nextProductionLinePrice += Number(this.productionLineListData.ProductionLinePrices[_i + 1].ProductionLinePrintPrices[j].HandlingCost);
                      }
                    }
                  }
                }
              }

              // this.nextProductionLinePrice += Number(this.shoppingCartData.ProductPrintPrices[0].Price);
              // if (this.shoppingCartData.ProductPrintPrices[0].HandlingCost !== undefined &&
              //   this.shoppingCartData.ProductPrintPrices[0].HandlingCost !== null) {
              //     this.nextProductionLinePrice += Number(this.shoppingCartData.ProductPrintPrices[0].HandlingCost);
              // }
            }
            /** Price range display section => To add printprice, handling cost if product is Fixed logo => END HERE */
          }



          console.log("productionLineListData : ", this.productionLineListData);
          if (this.qtyChangeStatus) {
            this.joinOrderPrice = Number(this.productionLineListData.ProductionLinePrices[_i].Price);
            console.log("qtyChangeStatus :  ", this.joinOrderPrice);
          } else {
            this.joinOrderPrice = Number(this.productionLineListData.ProductionLinePrices[_i].Price) + Number(this.dynamicProductRangePricesParent);
            console.log("qtyChangeStatus Else:  ", this.dynamicProductRangePricesParent);
            console.log("qtyChangeStatus Else:  ", this.joinOrderPrice);
          }
          if (this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices.length > 0 &&
            this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices[0].isFixedLogo) {
            this.calculatedPrice = 0;
            this.productionLineListData.ProductionLinePrices[_i].ProductionLinePrintPrices.forEach(_element => {
              // this.calculatedPrice += element.Price;
            });
            this.joinOrderPrice = this.joinOrderPrice + this.calculatedPrice;
          }
          this.joinOrderPrice = (this.joinOrderPrice).toFixed(2);
          console.log("Final join Order price:  ", this.joinOrderPrice);

          /** new-code => for ProductPrintPrice, handling cost, additonal cost, governmental cost  */
          this.logoProductPrintPrice = 0;
          this.logoProductHandlingCost = 0;
          this.additionalCostForProduct = 0;
          this.governmentalCostForProduct = 0;
          console.log("this.shoppingCartData : ", this.shoppingCartData);
          console.log("this.shoppingCartData.Price.LOGOS : ", this.shoppingCartData.Price.Logos);
          /** new-code => AFter first time visit PDP page, if it fixed logo then price will add into 
           * join order price section
           */

          if (this.shoppingCartData.isFixedLogo) {
            let totalQty = 0;
            totalQty += Number(this.productCurrentQty);
            // tslint:disable-next-line:max-line-length
            if (this.productionLineListData.NextPriceDropQuantity !== undefined && this.productionLineListData.NextPriceDropQuantity !== null) {
              totalQty += Number(this.productionLineListData.Quantity);
            }
            console.log("totalQty : ", totalQty);
            console.log("ProductionLinePrices : ", this.productionLineListData.ProductionLinePrices);
            // tslint:disable-next-line:max-line-length
            var logoprice = this.productionLineListData.ProductionLinePrices.filter(e => (e.MinimumQuantity <= totalQty && e.MaximumQuantity >= totalQty));
            if (logoprice.length > 0) {
              // tslint:disable-next-line:max-line-length
              if (logoprice[0].ProductionLinePrintPrices.length > 0) {
                for (let j = 0; j < logoprice[0].ProductionLinePrintPrices.length; j++) {
                  if (logoprice[0].ProductionLinePrintPrices[j].isFixedLogo) {
                    // tslint:disable-next-line:max-line-length
                    this.logoProductPrintPrice += Number(logoprice[0].ProductionLinePrintPrices[j].Price);
                    if (logoprice[0].ProductionLinePrintPrices[j].HandlingCost !== undefined &&
                      logoprice[0].ProductionLinePrintPrices[j].HandlingCost !== null) {
                      this.logoProductPrintPrice += Number(logoprice[0].ProductionLinePrintPrices[j].HandlingCost);
                    }
                  }
                }
              }
            } else {
              this.logoProductPrintPrice += Number(this.shoppingCartData.ProductPrintPrices[0].Price) +
                Number(this.shoppingCartData.ProductPrintPrices[0].HandlingCost);
            }

            // this.logoProductPrintPrice += Number(this.shoppingCartData.ProductPrintPrices[0].Price);
            // if (this.shoppingCartData.ProductPrintPrices[0].HandlingCost !== undefined &&
            //   this.shoppingCartData.ProductPrintPrices[0].HandlingCost !== null) {
            //     this.logoProductPrintPrice += Number(this.shoppingCartData.ProductPrintPrices[0].HandlingCost);
            // }
          }


          if (this.shoppingCartData.Price.Logos !== undefined && this.shoppingCartData.Price.Logos !== null) {
            for (let j = 0; j < this.shoppingCartData.Price.Logos.length; j++) {
              // Logo Print Price
              // tslint:disable-next-line:max-line-length            
              if (this.shoppingCartData.Price.Logos[j].ProductPrintPrice !== undefined && this.shoppingCartData.Price.Logos[j].ProductPrintPrice !== null && !this.shoppingCartData.isFixedLogo) {
                console.log(this.shoppingCartData.Price.Logos[j]);
                this.logoProductPrintPrice += Number(this.shoppingCartData.Price.Logos[j].ProductPrintPrice);
              }

              // HANDLING COST FOR PRODUCT PRICE SECTION
              // tslint:disable-next-line:max-line-length
              if (this.shoppingCartData.Price.Logos[j].HandlingCost !== undefined && this.shoppingCartData.Price.Logos[j].HandlingCost !== null && this.shoppingCartData.Price.Logos[j].HandlingCostTypeName === 'per piece' && !this.shoppingCartData.isFixedLogo) {
                this.logoProductHandlingCost += (this.shoppingCartData.Price.Logos[j].HandlingCost);
              }

              // ADDITIONAL COSTS For Product Price Section
              if (this.shoppingCartData.Price.AdditionalCosts !== undefined && this.shoppingCartData.Price.AdditionalCosts !== null) {
                for (let j = 0; j < this.shoppingCartData.Price.AdditionalCosts.length; j++) {
                  if (this.shoppingCartData.Price.AdditionalCosts[j].AdditionalCostTypeCode === 'PerUnit') {
                    if (this.shoppingCartData.Price.AdditionalCosts[j].AdditionalCostPrice !== undefined &&
                      this.shoppingCartData.Price.AdditionalCosts[j].AdditionalCostPrice !== null) {
                      this.additionalCostForProduct += (this.shoppingCartData.Price.AdditionalCosts[j].AdditionalCostPrice);
                    }
                  }
                }
              }

              // GOVERNMENTAL COSTS For Product Price Section
              if (this.shoppingCartData.Price.GovernmentalCosts !== undefined && this.shoppingCartData.Price.GovernmentalCosts !== null) {
                for (let j = 0; j < this.shoppingCartData.Price.GovernmentalCosts.length; j++) {
                  if (this.shoppingCartData.Price.GovernmentalCosts[j].GovernmentalCostTypeCode === 'PerUnit') {
                    this.governmentalCostForProduct += (this.shoppingCartData.Price.GovernmentalCosts[j].GovernmentalCostPrice);
                  }
                }
              }
            }
          }
          this.logoProductPrintPrice = Number(this.logoProductPrintPrice).toFixed(2);
          this.logoProductHandlingCost = Number(this.logoProductHandlingCost).toFixed(2);
          this.additionalCostForProduct = Number(this.additionalCostForProduct).toFixed(2);
          this.governmentalCostForProduct = Number(this.governmentalCostForProduct).toFixed(2);



          console.log("this.logoProductPrintPrice : ", this.logoProductPrintPrice);
          console.log("this.logoProductHandlingCost : ", this.logoProductHandlingCost);
          console.log("this.additionalCostForProduct : ", this.additionalCostForProduct);
          console.log("this.governmentalCostForProduct : ", this.governmentalCostForProduct);
          // tslint:disable-next-line:max-line-length
          this.totalProductPrice = (Number(this.joinOrderPrice) + Number(this.logoProductPrintPrice) + Number(this.logoProductHandlingCost) + Number(this.additionalCostForProduct) + Number(this.governmentalCostForProduct));
          // if (this.globalService.getUserSettings().CurrencyCode !== 'zł') {
          this.totalProductPrice = Number(this.totalProductPrice).toFixed(2);
          // }
          /** new-code => for ProductPrintPrice, handling cost, additonal cost, governmental cost  */

          // Progress bar old code - didn't work when minimal price is already achieved
          // if (_i === this.productionLineListData.ProductionLinePrices.length - 1) {
          //   this.currentRange = 100;
          // } else {
          //   this.currentRange = (Number(this.productionLineListData.Quantity) + Number(this.productCurrentQty)) - this.productionLineListData.ProductionLinePrices[_i].MinimumQuantity;
          // }          
          // this.range = this.productionLineListData.ProductionLinePrices[_i].MaximumQuantity - this.productionLineListData.ProductionLinePrices[_i].MinimumQuantity;

          // if (Number(this.productionLineListData.ProductionLinePrices[_i].MaximumQuantity) === 2147483647) {
          //   this.nextPriceDrop = this.productionLineListData.ProductionLinePrices[_i].MinimumQuantity;
          //   this.showUpArrow = true;
          // } 
          // else {
          //   this.nextPriceDrop = (this.productionLineListData.
          //     ProductionLinePrices[_i].MaximumQuantity
          //     - (Number(this.productionLineListData.Quantity) + Number(this.productCurrentQty))) + 1;
          //   this.showUpArrow = false;
          // }

          // Progress bar
          if (Number(this.productionLineListData.ProductionLinePrices[_i].MaximumQuantity) === 2147483647) {
            this.progressBarPercentage = 100;
            this.nextPriceDrop = 0;
          }
          else {
            this.progressBarPercentage = ((Number(this.productionLineListData.Quantity) + Number(this.productCurrentQty)) / (Number(this.productionLineListData.ProductionLinePrices[_i].MaximumQuantity) + 1) * 100).toFixed(0);
            this.nextPriceDrop = (Number(this.productionLineListData.ProductionLinePrices[_i].MaximumQuantity) + 1) - (Number(this.productionLineListData.Quantity) + Number(this.productCurrentQty));
          }

          this.productDetail.DropMinQuantity = this.productionLineListData.ProductionLinePrices[_i].MinimumQuantity;
        }
      }
    }
  }

  next() {
    try {
      this.qtyChangeStatus = true;
      if (this.productionLineListIndex < this.productionLineList.length) {
        this.productionLineListIndex = this.productionLineListIndex + 1;
        this.productionLineListData = this.productionLineList[this.productionLineListIndex];
      }
      this.productLinePosition = this.productLinePosition + 1;
      const length = (this.productionLineList.length) - 1;
      if (this.productionLineListIndex === length) {
        this.nextIconStatus = false;
        this.stopBlinking = true;
      } else {
        this.nextIconStatus = true;
      }
      this.ngOnChanges();
    } catch (error) {
      this.toastr.error(error);
    }
  }

  pre() {
    try {
      this.productLinePosition = this.productLinePosition - 1;
      this.nextIconStatus = true;
      this.productionLineListIndex = this.productionLineListIndex - 1;
      this.productionLineListData = this.productionLineList[this.productionLineListIndex];
      this.ngOnChanges();
    } catch (error) {
      this.toastr.error(error);
    }
  }

  showProductModal() {
    this.productModal.show();
  }

  showMoreInfoModal() {
    this.moreInfoModal.show();
  }

  showPriceMoreInfoModal() {
    this.priceMoreInfoModal.show();
  }

  // shoppingCartShow(deliveryDate) {
  //   this.shoppingCarts.emit({ 'DeliveryDate': deliveryDate });
  // }
  shoppingCartShow(data) {
    if (data.data.DeliveryDate !== '') {
      // const param = {
      //   'DeliveryDate': data.data.DeliveryDate,
      //   'OrderQty': data.data.OrderQty,
      //   'joinOrderButtonClickStatus': true,
      //   'paramExists': true
      // }
      // this.shoppingCarts.emit({ param });
      this.shoppingCarts.emit({
        'DeliveryDate': data.data.DeliveryDate,
        'OrderQty': data.data.OrderQty,
        'joinOrderButtonClickStatus': true,
        'paramExists': true
      });
    } else {
      this.shoppingCarts.emit({ 'DeliveryDate': this.productionLineListData.DeliveryDate, 'joinOrderButtonClickStatus': true });
    }
  }
}
