import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../globals/global.service';
import 'rxjs/Rx';
import { map } from 'rxjs/operators';

@Injectable()
export class SecurityService {


  public data;

  constructor(private http: HttpClient, public globalService: GlobalService) { }

  /**
   * Service for login
   */
  login(username, password) {
    const webShopSettings = this.globalService.webShopSettings();
    const userSettings = this.globalService.getUserSettings();
   // const qryExtension = password ? ('&password=' + password + '&RoleCode=User&customerID='
    //  + webShopSettings.CustomerID) : ('&customerID=' + webShopSettings.CustomerID)
    let qryExtension = '';
    if (password) {
     qryExtension = password ? ('&password=' + password + '&RoleCode=User&Domain='
      + webShopSettings.DomainName) : ('&Domain=' + webShopSettings.DomainName)
    } else {
      qryExtension = password ? ('&password=' + password + '&RoleCode=User&customerID='
     + webShopSettings.CustomerID) : ('&customerID=' + webShopSettings.CustomerID)
    }
    if (password) {
      // return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode
      //   + '/users?username=' + username + qryExtension).pipe(map(
      //   (response: Response) => (response.json()))
      //   );
      return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode
        + '/users?username=' + username + qryExtension, {responseType: 'json'});
    } else {
      const data = {
        'username': username,
        'CustomerID': webShopSettings.CustomerID
       // 'Domain': webShopSettings.DomainName
      }
      // return this.http.post(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode
      //   + '/users?username=' + username + qryExtension, data).pipe(map(
      //   (response: Response) => (response.json()))
      //   );
      return this.http.post(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode
        + '/users?username=' + username + qryExtension, data, {responseType: 'json'});
    }
  }

  keylogin(key, data, shop){
    const userSettings = this.globalService.getUserSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode
      + '/customer/GetSmartWorkToken?Data=' + data +'&Key='+ key+'&IsParentShop='+shop, {responseType: 'json'});
  }

  hrmKeylogin( data,key, shop){
    const userSettings = this.globalService.getUserSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL
      + `CRM247/SSOLogin?Data=${data}&Key=${key}&IsParentShop=${shop}`, {responseType: 'json'});
  }

    rwdKeylogin( data,key, shop, domain){
    const userSettings = this.globalService.getUserSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode
      + `/SSOLogin?Data=${data}&Key=${key}&IsParentShop=${shop}&Domain=${domain}`, {responseType: 'json'});
  }

  tokenlogin(token, domain){
    const userSettings = this.globalService.getUserSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode
      + '/PennaCement/PennaUserDetails?token_id=' + token +'&DomainName='+ domain, {responseType: 'json'});
  }


  /**
 * Service to register user
 */

  registerUser(params) {
    const userSettings = this.globalService.getUserSettings();
    return this.http.post(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + '/users', params, {responseType: 'json'});
  }

  /**
 * Service to Check Postal Code for Selected Country
 */

checkPostalCode(countryCode, postalCode) {
 // const userSettings = this.globalService.getUserSettings();
  return this.http.get(environment.ENVIRONMENT.baseURL
    + 'Countries/GetPostalRegex/' + countryCode + '/' + postalCode, {responseType: 'json'});
}

  
  /**
* Update to register user
*/

  updateUser(params) {
    const loggedDetails = this.globalService.getLoggedInData();
    const userSettings = this.globalService.getUserSettings();
    return this.http.put(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + '/users/' + loggedDetails.UserID, params,
    {responseType: 'json'});
  }

  newPassword(params) {
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    const loggedDetails = this.globalService.getLoggedInData();
    return this.http.post(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + '/users?autopass=' +
      params.autopass + '&username=' + loggedDetails.UserName + '&newpass=' + params.newpass + '&confpass=' +
      params.confpass + '&CustomerID=' + webShopSettings.CustomerID, '', {responseType: 'json'});
  }
  // tslint:disable-next-line:eofline
  triggerOTP(data){
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode +'/LoginOTP?'+ data+'&DomainName='+webShopSettings.DomainName, { responseType: 'json' });
  }

  validateOTP(data){
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode +'/ValidateLoginOTP?'+ data+'&DomainName='+webShopSettings.DomainName, { responseType: 'json' });
  }

  vcUser(data){
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode +'/VCUser?Username='+ data+'&CustomerId='+webShopSettings.CustomerID, { responseType: 'json' });
  }

  vcGenOTP(data){
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode +'/LoginOTP?Mobile='+ data+'&Email&UserName&DomainName='+webShopSettings.DomainName, { responseType: 'json' });
  }

  vcValOTP(data){
    const userSettings = this.globalService.getUserSettings();
    const webShopSettings = this.globalService.webShopSettings();
    return this.http.get(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode +'/ValidateLoginOTP?Mobile='+ data.mobile +'&Email&UserName&OTP='+data.OTP+'&DomainName='+webShopSettings.DomainName, { responseType: 'json' });
  }

  vcSignUp(params){
    const userSettings = this.globalService.getUserSettings();
    return this.http.post(environment.ENVIRONMENT.baseURL + userSettings.LanguageCode + '/VCSignUp', params, {responseType: 'json'});
  }
}