import { Component, OnInit, ViewChild, SimpleChanges, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductService, WebshopService } from 'app/linknet-common/restful-services/restful.service';
import { ToastService } from 'app/typescripts/pro/alerts';
import { Title, Meta } from '@angular/platform-browser';
import { Location, DatePipe } from '@angular/common';
import { GlobalService } from 'app/linknet-common/globals/global.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { MenuRightComponent } from '../header-one/menu-right/menu-right.component';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from 'ngx-image-gallery';
import * as moment from 'moment';
import { INgxMyDpOptions } from 'ngx-mydatepicker';
import { CommonServicesService } from 'app/linknet-common/common-services/common-services.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-marketingproduct-detail',
  templateUrl: './marketingproduct-detail.component.html',
  styleUrls: ['./marketingproduct-detail.component.scss']
})
export class MarketingproductDetailComponent implements OnInit {
  public productDetail: any = '';
  public lang: any;
  public newOrderValues: any = {};
  public productionLineList: any;
  public hideJoinOrders: Boolean = false;
  public stepCount: number;
  public currQty: any;
  public colorsList: Array<any>;
  public orderQuantity: number;
  public orderQuantityChangeStatus: Boolean = false;
  public orderQuantityParent = 0;
  public dynamicProductRangePricesParent = 0;
  public expressOrderStatus: Boolean = false;
  public selectColorId: any = null;
  public responseData: any;
  public expressOrderDate: any;
  public cookieValue: any;
  public shoppingCartPostParams: any;
  public browserShoppingToken: any = 0;
  public loginUserId: any = 0;
  public currency: any;
  downloadFileName: any;
  public productFileDownloadUrl: any = '';
  public wsaStatus: Boolean = false;
  @ViewChild('SampleOrder') public sampleOrder;
  @ViewChild('noProductModal') public noProductModal;
  @ViewChild('stockModal') public stockModal;
  @ViewChild('productStockModal') public productStockModal;
  @ViewChild('noBrandingConfirmation') public noBrandingConfirmation;
  @Input() productId: any;
  @Output() callProductDetailAPI = new EventEmitter();
  public showDetail: Boolean = false;
  public posImages: GALLERY_IMAGE[] = [];
  @ViewChild(NgxImageGalleryComponent) ngxPosImageGallery: NgxImageGalleryComponent;
  public spinnerShow = false;
  public loaderHideClass: Boolean = false;



  constructor(private router: Router, private _httpWebshopService: WebshopService,
    public globalService: GlobalService, public route: ActivatedRoute, public _httpProductService: ProductService, public toastService: ToastService,
    public titleService: Title, public meta: Meta, private location: Location,
    private cookieService: CookieService, public translate: TranslateService,
    public commonServicesService: CommonServicesService, public datepipe: DatePipe,
    public menuRightComponent: MenuRightComponent, private toastr: ToastrService) { }

  ngOnInit() {
    const domainName = JSON.parse(sessionStorage.getItem('webshopDetail'));
    let productID = this.route.snapshot.params ? this.route.snapshot.params.productID : null;
    if (productID === undefined) {
      productID = this.productId
      this.openProductDetailModel(productID)
    } else {
      this.lang = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.lang : null;
      this.showDetail = true;
      if (this.lang !== undefined) {
        if (this.translate.getDefaultLang() !== this.lang) {
          try {
            let languageID = 1;
            if (this.lang === 'dk') {
              languageID = 2;
            }
            // this.router.navigate(['/']);
            this.translate.use(this.lang);
            this.globalService.overwriteSession('userSettings', 'LanguageCode', this.lang);
            this.globalService.overwriteSession('userSettings', 'LanguageID', languageID);
            this.openProductDetailModel(productID)
          } catch (error) {
            // this.toastService.error(error);
          }
        } else {
          this.openProductDetailModel(productID)
        }
      } else {
        this.openProductDetailModel(productID)
      }

    }


    try {
      const customerName = window.location.href.replace(/.*?:\/\//g, "").split('.')[0];
     
      if (Number(domainName.CustomerID) === 45) {
        this.wsaStatus = true;
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }


  openPosImage(imageURL) {
    this.posImages = [];
    const imageList = {
      'url': imageURL,
      'extUrl': imageURL,
      'thumbnailUrl': imageURL
    };
    this.posImages.push(imageList);
    setTimeout(() => {
      this.ngxPosImageGallery.open();
    }, 1);
  }

  openProductDetailModel(productID) {
    try {
      // alert("openProductDetailModel");
      let initialDeliveryCountry = '';
      this.spinnerShow = true;
      const webShopSettings = this.globalService.webShopSettings();
      this._httpProductService.getProductDetail(productID, this.lang, true, initialDeliveryCountry).subscribe(
        data => {
          this.loaderHideClass = true;
          this.spinnerShow = false;
          this.productDetail = data;
          let set = data['Name'];
          set = set.replace(/\//g, '')
          set = set.replace(/"/g, '').replace(/'/g, '').replace(/\(|\)/g, '');

          window.history.replaceState(' ', '', '/marketingproductdetail/' + data['ProductID'] + '/' + set);
          /** For Marketing products */
          if (this.productDetail.ProductDownloads !== null && this.productDetail.ProductDownloads !== undefined) {
            if (this.productDetail.ProductDownloads.length > 0 && this.productDetail.ProductDownloads.length === 1) {
              this.productFileDownloadUrl = this.productDetail.ProductDownloads[0].URL;
            }
          }
          this.callProductDetailAPI.emit(data);
        }, error => {
          if (error.status === 500) {
            this.noProductModal.show();
          } else {
            this.toastr.error(error);
          }
        });

    } catch (error) {
      this.toastr.error(error);
    }
  }

  closeProductDetail() {
    window.history.replaceState(' ', '', '/');
  }

}
