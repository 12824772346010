import { Component, OnInit, ViewChild } from '@angular/core';
import { OrdersService, ProductService } from '../../linknet-common/restful-services/restful.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../typescripts/pro';
import { GlobalService } from '../../linknet-common/globals/global.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss'],
  exportAs: 'child'
})
export class OrderStatusComponent implements OnInit {
  @ViewChild('orderStatus') public orderStatus;
  public orderState: any;
  public orderItemState: any;
  public orderItemStates: any;
  public translatedMessage: any;
  constructor(public _httpOrdersService: OrdersService, public route: ActivatedRoute, public toastService: ToastService,
    public _httpProductService: ProductService, public globalService: GlobalService, public router: Router,
    public translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit() {
    const userData = this.globalService.getLoggedInData();
    if (userData !== null) {
      this.globalService.overwriteSession('userSettings', 'LanguageCode', userData.LanguageCode);
      this.globalService.overwriteSession('userSettings', 'LanguageID', userData.LanguageID);
      this.getOrderItemStates()
    } else {
      this.router.navigate(['/']);
    }
  }

  getOrderItemStates() {
    try {
      this._httpProductService.getOrderStateItems(true).subscribe(
        (data: any[]) => {
          this.orderItemState = data;
          this.orderItemStates = this.orderItemState;
          this.getOrderDetail()
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }

  getOrderDetail() {
    try {
      const orderId = this.route.snapshot.params.OrderID;
      this._httpOrdersService.getOrdersDetails(orderId).subscribe(
        (data: any[]) => {
          const userData = this.globalService.getLoggedInData();
          const webshopDetail = JSON.parse(sessionStorage.getItem('webshopDetail'));
          if ((Number(data['UserID']) === Number(userData.UserID)) || (webshopDetail.isPrivate === 'false')) {
            const orderItemID = this.route.snapshot.params.OrderItemID;
            if (data['Suborders'].length === 0) {
              data['OrderItems'].forEach(elements => {
                if (elements.OrderItemID === Number(orderItemID)) {
                  this.openOrderStatus(elements)
                }
              });
            } else {
              data['Suborders'].forEach(elements => {
                elements['OrderItems'].forEach(element => {
                  if (element.OrderItemID === Number(orderItemID)) {
                    this.openOrderStatus(element)
                  }
                });
              });
            }
          } else {
            //  this.translatedMessage = (this.translate.get('HEADING.NO_ORDERS_FOUND'));
            this.translate.get('HEADING.NO_ORDERS_FOUND').subscribe((res: string) => {
              this.translatedMessage = res;
            });
            this.toastr.info(this.translatedMessage);
            this.router.navigate(['/']);
          }
        }, error => {
          // this.toastService.error(JSON.parse(error._body).Message);
          this.toastr.error(error.error.Message);
        })
    } catch (error) {
      this.toastr.error(error);
    }
  }
  openOrderStatus(params) {
    try {
      this.orderState = params.OrderItemStates;
      console.log("openOrderStatus : ", this.orderState);
      console.log("orderItemStates : ", this.orderItemStates);
      if (this.orderState) {
        this.orderItemStates.forEach(element => {
          this.orderState.forEach(state => {
            /** new-code : for display order status name */
            if (element.StateCode === 'ORDER_RECIEVED') {
              this.translate.get('HEADER_ONE.ORDER_RECIEVED').subscribe((res: string) => {
                element.StateName = res;
              });
            }
            if (element.StateCode === 'ORDER_ACCEPTED' || element.StateCode === 'NOT_DECIDED' ||
              element.StateCode === 'REJECT_ORDER') {
              this.translate.get('HEADER_ONE.ACCEPTED').subscribe((res: string) => {
                element.StateName = res;
              });
            }
            if (element.StateCode === 'MINIMUM_QUANTITY_REACHED') {
              this.translate.get('HEADER_ONE.MIN_PRODUCTION_REACHED').subscribe((res: string) => {
                element.StateName = res;
              });
            }
            if (element.StateCode === 'LOGO_SKETCH_APPROVED' || element.StateCode === 'NOT_DECIDED' ||
              element.StateCode === 'IN_PROGRESS' || element.StateCode === 'ENABLE_LOGO_SAMPLE') {
              this.translate.get('HEADER_ONE.LOGO_SKETCH_APPROVED').subscribe((res: string) => {
                element.StateName = res;
              });
            }
            if (element.StateCode === 'LOGO_SAMPLE_APPROVED' || element.StateCode === 'NOT_DECIDED' ||
              element.StateCode === 'IN_PROGRESS') {
              this.translate.get('HEADER_ONE.LOGO_SAMPLE_APPROVED').subscribe((res: string) => {
                element.StateName = res;
              });
            }
            if (element.StateCode === 'USER_PAID' || element.StateCode === 'NOT_PAID' || element.StateCode === 'WEBSHOP_PAID'
            || element.StateCode === 'REWARDKART_PAID') {
              this.translate.get('HEADER_ONE.USER_PAID').subscribe((res: string) => {
                element.StateName = res;
              });
            }
            if (element.StateCode === 'PRODUCTION_STARTED') {
              this.translate.get('HEADER_ONE.PRODUCTION_START').subscribe((res: string) => {
                element.StateName = res;
              });
            }
            if (element.StateCode === 'PRODUCTION_COMPLETED') {
              this.translate.get('HEADER_ONE.PRODUCTION_COMPLETED').subscribe((res: string) => {
                element.StateName = res;
              });
            }
            if (element.StateCode === 'DISPATCHED' || element.StateCode === 'NOT_DISPATCHED') {
              this.translate.get('HEADER_ONE.DISPATCHED').subscribe((res: string) => {
                element.StateName = res;
              });
            }
            if (element.StateCode === 'DELIVERED' || element.StateCode === 'NOT_DELIVERED') {
              this.translate.get('HEADER_ONE.DELIVERED').subscribe((res: string) => {
                element.StateName = res;
              });
            }
            /** new-code : for display order status name */

            if (element.OrderItemStateID === state.OrderItemStateID) {
              element['lastupdateddate'] = state.Properties.date;
              const label = element.Options.filter(
                // tslint:disable-next-line:no-shadowed-variable
                data => ((data.StateOptionCode) === (state.Properties.StateOptionCode)));
              if (label.length !== 0) {
                element['label'] = label[0].StateOptionName;
              }
              if (state.Properties.StateOptionCode === 'ACCEPT_ORDER' || state.Properties.StateOptionCode === 'LOGO_SAMPLE_APPROVED'
                || state.Properties.StateOptionCode === 'PRODUCTION_STARTED' ||
                state.Properties.StateOptionCode === 'PRODUCTION_COMPLETED' || state.Properties.StateOptionCode === 'DISPATCHED' ||
                state.Properties.StateOptionCode === 'DELIVERED' || state.Properties.StateOptionCode === 'COMPLETED' ||
                state.StateCode === 'ORDER_RECIEVED') {
                element['checkedclassname'] = true;
              } else if (state.Properties.StateOptionCode === 'NOT_DECIDED') {
                element['timerclassname'] = true;
              } else if (state.Properties.StateOptionCode === 'NOT_PAID' || state.Properties.StateOptionCode === 'NOT_DISPATCHED' ||
                state.Properties.StateOptionCode === 'NOT_DELIVERED' || state.Properties.StateOptionCode === 'REJECT_ORDER') {
                element['removeclassname'] = true;
              } else if (state.Properties.StateOptionCode === 'PAY_BY_CREDIT_CARD' ||
                state.Properties.StateOptionCode === 'PAY_BY_INVOICE' || state.Properties.StateOptionCode === 'PAY_BY_PAYPAL') {
                element['fileclassname'] = true;
              } else if (state.Properties.StateOptionCode === 'MINIMUM_QUANTITY_REACHED') {
                element['greencircleclassname'] = true;
              } else if (state.StateCode === 'PRODUCTION_STARTED' || state.StateCode === 'PRODUCTION_COMPLETED') {
                element['greencircleclassname'] = true;
              } else {
                element['greycircleclassname'] = true;
              }
            }
          });
        });
        this.orderStatus.show();
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }
  closeOrderStatus() {
    const userLogged = this.globalService.getLoggedInData();
    if (userLogged !== null) {
      this.router.navigate(['/orderproduction']);
    } else {
      this.router.navigate(['/']);
    }
  }
}
