import { Component, OnInit, Input, ViewChild, HostListener, SimpleChanges } from '@angular/core';
import { GlobalService, CurrencySetterService } from '../../../linknet-common/globals/global.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastService } from '../../../typescripts/pro/alerts';
import { ProductService } from 'app/linknet-common/restful-services/restful.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'shop-app-marketing-products',
  templateUrl: './marketing-products.component.html',
  styleUrls: ['./marketing-products.component.scss']
})
export class MarketingProductsComponent implements OnInit {
  public showDetail: Boolean = false;
  @Input() productDetail: any;
  @Input() hideDelete: Boolean;
  @Input() isProductBlock: Boolean;
  @Input() showOrderDetail: any = true;
  @Input() showProgress: any = false;
  @Input() priceStatus: any = true;
  @Input() loopValue: any;
  @ViewChild('DeleteModal') public deleteModal;

  public currency: any;
  public userSettings: any;
  public folderID: any;
  public likedProducts: any;
  public translatedMessage: any;
  public today: Date;
  public queryParams: any;
  public priceLineDisplayStatus: Boolean = true;
  public colorID: any;
  public showStatus: Boolean = false;
  public getPopupHeight: any;
  public webShopSettings: any;
  public productData: any = '';
  downloadFileName: any;
  public productFileDownloadUrl: any = '';
  public wsaStatus: Boolean = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public currencySetterService: CurrencySetterService,
    private globalService: GlobalService, private toastService: ToastService,
    public _httpProductService: ProductService, private translate: TranslateService,
    private route: ActivatedRoute, private toastr: ToastrService) {
    try {
      this.webShopSettings = this.globalService.webShopSettings();
      this.today = new Date();
      this.currency = currencySetterService.currency;
      this.userSettings = this.globalService.getUserSettings();
      this.folderID = this.activatedRoute.snapshot.queryParams ? this.activatedRoute.snapshot.queryParams.FolderID : null;
      this.likedProducts = this.activatedRoute.snapshot.queryParams ? this.activatedRoute.snapshot.queryParams.likedProducts : null;
      if ((this.folderID) || (this.likedProducts)) {
        this.hideDelete = false;
      }
      if (this.folderID) {
        this.likedProducts = true;
      }
      this.showProgress = this.activatedRoute.snapshot.queryParams ? this.activatedRoute.snapshot.queryParams.joinlinkorders : false;
    } catch (error) {
      this.toastr.error(error);
    }
  }


  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const keyPressed = event.keyCode;
    if (keyPressed === 27) {
      this.showDetail = false;
    }
  }

  ngOnInit() {
    try {
      const domainName = JSON.parse(sessionStorage.getItem('webshopDetail'));
      this._httpProductService.currentShowDetail.subscribe(data => this.showDetail = data)
      const quantityQueryParam = this.route.snapshot.queryParams ? this.route.snapshot.queryParams.Quantity : null;
      if (quantityQueryParam !== null || quantityQueryParam !== '') {
        this.priceLineDisplayStatus = false;
      }
      this.queryParams = this.router.routerState.snapshot.root.queryParams;
      if (this.queryParams.ColorID !== undefined || this.queryParams.ColorID !== '') {
        this.colorID = this.queryParams.ColorID;
      }

      if (this.queryParams.Quantity === undefined || this.queryParams.Quantity === '') {
        this.priceStatus = false;
      }
      if (this.productDetail.OrderedQuantity) {
        for (let _i = 0; _i < this.productDetail.ProductPrices.length; _i++) {
          if (this.productDetail.ProductPrices[_i].MinimumQuantity <=
            this.productDetail.OrderedQuantity && this.productDetail.ProductPrices[_i].MaximumQuantity >=
            this.productDetail.OrderedQuantity) {
            this.productDetail.DropMinQuantity = this.productDetail.ProductPrices[_i].MinimumQuantity;
            this.productDetail.ProductPrices[_i].activePrice = true;
          } else if (this.productDetail.ProductPrices[_i].MaximumQuantity < this.productDetail.OrderedQuantity) {
            delete this.productDetail.ProductPrices[_i];
          }
        }
      } else {
        if (this.productDetail.ProductPrices[0]) {
          this.productDetail.ProductPrices[0].activePrice = true;
        }
      }
      this.productDetail.ProductPrices = this.productDetail.ProductPrices.filter(function (val) { return val; });

      const customerName = window.location.href.replace(/.*?:\/\//g, "").split('.')[0];
    
      if (Number(domainName.CustomerID) === 45) {
        this.wsaStatus = true;
      }

      /** For Marketing products */
      if (this.productDetail.ProductDownloads !== null && this.productDetail.ProductDownloads !== undefined) {
        if (this.productDetail.ProductDownloads.length > 0 && this.productDetail.ProductDownloads.length === 1) {
          this.productFileDownloadUrl = this.productDetail.ProductDownloads[0].URL;
        }
      }
    } catch (error) {
      this.toastr.error(error);
    }
    this.getScreenheight();
  }

  productDetailData($event) {
    this.productData = $event;
    console.log("productData DATA $event : ", $event);
  }
  showProductDetail(_productID, _productName) {
    try {
      this.productData = '';
      this.showDetail = true;
      // this.router.navigate(['/productdetail', productID, productName], { replaceUrl: false });
    } catch (error) {
      this.toastr.error(error);
    }
  }
  closeProductDetail() {
    window.history.replaceState(' ', '', '/');
  }

  deleteCard(param) {
    try {
      if (this.folderID) {
        const postParameters = {
          'FolderID': this.folderID,
          'ProductID': param.ProductID,
          'Action': 'delete'
        };
        this._httpProductService.insertOrDeleteFolderProduct(postParameters).subscribe(
          () => {
            // this.translatedMessage = (this.translate.get('FOLDERS.PRODUCT_REMOVED_FROM_FOLDER'));
            this.translate.get('FOLDERS.PRODUCT_REMOVED_FROM_FOLDER').subscribe((res: string) => {
              this.translatedMessage = res;
            });
            this.toastr.success(this.translatedMessage);
            this.router.navigate([this.router.url.split('?')[0]], { preserveQueryParams: true, replaceUrl: false });
          }
        );
      } else if (this.likedProducts) {
        const postParameters = {
          'ProductLikeID': param.ProductLikeID
        }
        this._httpProductService.deleteLikedProduct(postParameters).subscribe(
          data => {
            if (data === true || data === 'true') {
              //  this.translatedMessage = (this.translate.get('FOLDERS.PRODUCT_REMOVED_FROM_FOLDER_LIKED_PRODUCT'));
              this.translate.get('FOLDERS.PRODUCT_REMOVED_FROM_FOLDER_LIKED_PRODUCT').subscribe((res: string) => {
                this.translatedMessage = res;
              });
              this.toastr.success(this.translatedMessage);
              this.router.navigate([this.router.url.split('?')[0]], { preserveQueryParams: true, replaceUrl: false });
            }
          }
        );
      }
    } catch (error) {
      this.toastr.error(error);
    }
  }

  deleteProductModel(param) {
    this.showStatus = true;
    this.deleteModal.show(param);
  }

  getScreenheight() {
    const body = document.body;
    const screenHeight = body.offsetHeight;
    this.getPopupHeight = screenHeight - 135;
  }

}
